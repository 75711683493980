import ng from 'angular';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('passportsPageView', {
    bindings: {
      filterLevel: '<?',
      queryParams: '<?',
    },
    template: require('./passports-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    'PageService',
    'ContractsService',
    'PassportFilterConfigService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    PageService: PageService,
    ContractsService: ContractsService,
    PassportFilterConfigService: any,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;

    vm.currency = 'local';

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'passports-page-view';
      PageService.setConfig(getPageConfig());
    };

    ////////////////

    function getPageConfig() {
      const config: any = {
        class: 'page-header-main-prices-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.ScreenCaptureTask(),
        ],
        pages: ContractsService.getPassportPagesConfig(),
      };
      config.filters = PassportFilterConfigService.getFilterConfig(vm.filterLevel);
      CustomValuesService.getCustomFieldFilters({
        purpose_model: 'passport',
      }).then((customFieldsFilters: any) => {
        config.filters.multiSelects.push(...customFieldsFilters.multiSelects);
        config.filters.selects.push(...customFieldsFilters.selects);
        config.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
        config.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
        config.filters.dateSelects.push(...customFieldsFilters.dateSelects);
      });
      return config;
    }
  }
})();
