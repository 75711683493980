import type ng from 'angular';

import template from './logistics-page-view.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const LogisticsPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gettext',
    'PageService',
    'gtFilterService',
    'FinancesService',
    'LogisticsService',
    'GtUtils',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CustomValuesService: CustomValuesService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      currencies: any;
      filterLevel = 'logistics-page-view';
      gettext: ng.gettext.gettextFunction;
      groupBy: any;
      gtFilterService: GtFilterService;
      quickAdd: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        PageService: PageService,
        gtFilterService: GtFilterService,
        FinancesService: FinancesService,
        LogisticsService: LogisticsService,
        GtUtils: GtUtilsService,
        CustomValuesService: CustomValuesService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.FinancesService = FinancesService;
        this.LogisticsService = LogisticsService;
        this.CustomValuesService = CustomValuesService;
        this.GtUtils = GtUtils;
        this.quickAdd = false;
        this.groupBy = null;
        this.currencies = [];
      }
      $onInit() {
        this.updateCurrencies().then(this.PageService.setConfig(this.getPageConfig()));
        this.$scope.$on('group-by', function (this: any, ev: any, data: any) {
          this.groupBy = data;
        });
      }

      ////////////////

      updateCurrencies() {
        return this.FinancesService.Currency.query((data: any) => {
          data.results.forEach(function (this: any, currency: any) {
            this.currencies.push({
              id: currency.id,
              title: currency.symbol,
            });
          });
        }).$promise;
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
            new this.PageService.buttons.ImportGoogleDrive('logistics.logistic'),
            new this.PageService.buttons.Import('logistics.logistic'),
            new this.PageService.buttons.Admin('logistics.logistic', this.filterLevel),
            new this.PageService.buttons.Export('logistics.logistic', this.filterLevel),
            new this.PageService.buttons.ScreenCaptureTask(),
          ],
          quickAdd: () => {
            this.quickAdd = !this.quickAdd;
          },
          pages: [],
        };

        config.filters = {
          groupBy: {
            supplier: this.gettext('Supplier'),
            buyer: this.gettext('Buyer'),
            exporter: this.gettext('Exporter'),
            terminal: this.gettext('Terminal'),
            cargo: this.gettext('Cargo'),
            crop_year: this.gettext('Crop year'),
          },
          filterLevel: this.filterLevel,
          clicked: false,
          search: true,
          dates: true,
          useFlexDateRange: true,
          invert_filters: true,
          dateSelects: [
            {
              argument: 'create_time',
              placeholder: this.gettext('Create date'),
            },
            {
              argument: 'loading_date',
              placeholder: this.gettext('Loading date'),
            },
            {
              argument: 'receiving_date',
              placeholder: this.gettext('Receiving date'),
            },
            {
              argument: 'payment_date',
              placeholder: this.gettext('Payment date'),
            },
          ],
          selects: [],
          multiSelects: [
            {
              argument: 'id_list',
              placeholder: this.gettext('vehicles'),
              resource: 'logistics.Logistic',
              related_fk: 'id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'custom_status_list',
              placeholder: this.gettext('custom status'),
              resource: 'core.CustomStatus',
              related_fk: 'custom_status',
              related_model: 'logistics.Logistic',
              queryParams: {
                content_type__model: 'logistic',
              },
            },
            {
              argument: 'cargo_list',
              placeholder: this.gettext('commodities'),
              resource: 'crops.Crop',
              related_fk: 'cargo',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'forwarder_list',
              placeholder: this.gettext('forwarders'),
              resource: 'clients.deliverer',
              related_fk: 'forwarder_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'exporter_list',
              placeholder: this.gettext('exporters'),
              resource: 'clients.exporter',
              related_fk: 'exporter_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'station_list',
              placeholder: this.gettext('station deps'),
              resource: 'logistics.station',
              related_fk: 'station_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'station_receiving_list',
              placeholder: this.gettext('station recs'),
              resource: 'logistics.station',
              related_fk: 'station_receiving_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'elevator_list',
              placeholder: this.gettext('elevators'),
              resource: 'clients.elevator',
              related_fk: 'elevator_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'owner_list',
              placeholder: this.gettext('owners'),
              resource: 'clients.owner',
            },
            {
              argument: 'receiver_list',
              placeholder: this.gettext('receivers'),
              resource: 'clients.client',
              related_fk: 'receiver_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'producer_list',
              placeholder: this.gettext('producers'),
              resource: 'clients.client',
              related_fk: 'producer_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_contract_supplier_list',
              placeholder: this.gettext('suppliers'),
              resource: 'clients.supplier',
              related_fk: 'supplier_contract__supplier_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_contract_buyer_list',
              placeholder: this.gettext('buyers'),
              resource: 'clients.buyer',
              related_fk: 'buyer_contract__buyer_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_contract_responsible_list',
              placeholder: this.gettext('buyer managers'),
              resource: 'auth.user',
              related_fk: 'buyer_contract__responsible_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_contract_responsible_list',
              placeholder: this.gettext('supplier managers'),
              resource: 'auth.user',
              related_fk: 'supplier_contract__responsible_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_contract_list',
              placeholder: this.gettext('buyer contracts'),
              resource: 'contracts.SaleContract',
              related_fk: 'buyer_contract_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'intermediate_contract_list',
              placeholder: this.gettext('intermediate contracts'),
              resource: 'contracts.IntermediateContract',
              related_fk: 'intermediate_contract_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_contract_list',
              placeholder: this.gettext('supplier contracts'),
              resource: 'contracts.PurchaseContract',
              related_fk: 'supplier_contract_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'multiselect_origins_of_crop_purchase_list',
              placeholder: this.gettext('purchase origins'),
              resource: 'location.country',
              related_fk: 'supplier_contract__origins_of_crop',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'multiselect_origins_of_crop_sale_list',
              placeholder: this.gettext('sale origins'),
              resource: 'location.country',
              related_fk: 'buyer_contract__origins_of_crop',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'multiselect_destinations_of_crop_purchase_list',
              placeholder: this.gettext('purchase destinations'),
              resource: 'location.country',
              related_fk: 'supplier_contract__destinations_of_crop',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'multiselect_destinations_of_crop_sale_list',
              placeholder: this.gettext('sale destinations'),
              resource: 'location.country',
              related_fk: 'buyer_contract__destinations_of_crop',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'port_list',
              placeholder: this.gettext('ports'),
              resource: 'logistics.Port',
              related_fk: 'terminal__port_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'terminal_list',
              placeholder: this.gettext('terminals'),
              resource: 'logistics.Terminal',
              related_fk: 'terminal_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_nineteen_invoice_list',
              placeholder: this.gettext('buyer prepay invoices'),
              resource: 'finances.Finance',
              queryParamsLookups: ['buyer_contract_list'],
              related_fk: 'buyer_nineteen_invoice_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_ten_invoice_list',
              placeholder: this.gettext('buyer balance invoices'),
              resource: 'finances.Finance',
              queryParamsLookups: ['buyer_contract_list'],
              related_fk: 'buyer_ten_invoice_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_nineteen_invoice_list',
              placeholder: this.gettext('supplier prepay invoices'),
              resource: 'finances.Finance',
              queryParamsLookups: ['supplier_contract_list'],
              related_fk: 'supplier_nineteen_invoice_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_ten_invoice_list',
              placeholder: this.gettext('supplier balance invoices'),
              resource: 'finances.Finance',
              queryParamsLookups: ['supplier_contract_list'],
              related_fk: 'supplier_ten_invoice_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'supplier_basis_list',
              placeholder: this.gettext('supplier contract basis'),
              resource: 'logistics.basis',
              related_fk: 'supplier_contract__basis_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'buyer_basis_list',
              placeholder: this.gettext('buyer contract basis'),
              resource: 'logistics.basis',
              related_fk: 'buyer_contract__basis_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'companygroups_list',
              placeholder: this.gettext('company groups'),
              resource: 'clients.companygroup',
              related_fk: 'logistics_companygroups_list',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'acceptance_certificate_list',
              placeholder: this.gettext(
                String(this.$rootScope.user.settings.ACCEPTANCE_CETRIFICATE_NAME),
              ),
              resource: 'logistics.acceptancecertificate',
              related_fk: 'acceptance_certificate_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'business_unit_list',
              placeholder: this.gettext('business unit'),
              resource: 'core.BusinessUnit',
              getQueryParams: this.GtUtils.getBUQueryParams,
            },
            {
              argument: 'shipment_business_unit_list',
              placeholder: this.gettext('Shipment business unit'),
              resource: 'core.BusinessUnit',
              getQueryParams: this.GtUtils.getBUQueryParams,
              related_fk: 'shipment_business_unit_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'unloading_business_unit_list',
              placeholder: this.gettext('Unloading business unit'),
              resource: 'core.BusinessUnit',
              related_fk: 'unloading_business_unit_id',
              related_model: 'logistics.Logistic',
              getQueryParams: this.GtUtils.getBUQueryParams,
            },
            {
              argument: 'billoflading_list',
              placeholder: this.gettext('BLs'),
              resource: 'logistics.billoflading',
            },
            {
              argument: 'multicontract_sale_list',
              placeholder: this.gettext('multicontract sale'),
              resource: 'contracts.multicontract',
              related_fk: 'buyer_contract__multicontract_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'multicontract_purchase_list',
              placeholder: this.gettext('multicontract purchase'),
              resource: 'contracts.multicontract',
              related_fk: 'supplier_contract__multicontract_id',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'shipment_warehouse_list',
              placeholder: this.gettext('shipment warehouses'),
              resource: 'logistics.warehouse',
              related_fk: 'shipment_warehouse',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'unloading_warehouse_list',
              placeholder: this.gettext('unloading warehouses'),
              resource: 'logistics.warehouse',
              related_fk: 'unloading_warehouse',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'warehouse_list',
              placeholder: this.gettext('warehouses (all)'),
              resource: 'logistics.warehouse',
              queryParams: { is_group: '0' },
            },
            {
              argument: 'shipment_elevator_list',
              placeholder: this.gettext('shipment elevators'),
              resource: 'clients.Elevator',
              related_fk: 'shipment_elevator',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'shipment_terminal_list',
              placeholder: this.gettext('shipment terminals'),
              resource: 'logistics.Terminal',
              related_fk: 'shipment_terminal',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'unloading_terminal_list',
              placeholder: this.gettext('unloading terminals'),
              resource: 'logistics.Terminal',
              related_fk: 'unloading_terminal',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'export_contract_list',
              placeholder: this.gettext('export contracts'),
              resource: 'contracts.ContractBase',
              related_fk: 'export_contract',
              related_model: 'logistics.Logistic',
              queryParams: {
                deal_type: 'export',
                contract_type: 'sale',
              },
            },
            {
              argument: 'forwarder_contract_list',
              placeholder: this.gettext('forwarder contracts'),
              resource: 'contracts.ContractBase',
              related_fk: 'forwarder_contract',
              related_model: 'logistics.Logistic',
              queryParams: {
                deal_type: 'services',
              },
            },
            {
              argument: 'cargo_brand_list',
              placeholder: this.gettext('cargo brands'),
              resource: 'clients.CargoBrand',
              related_fk: 'cargo_brand',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'disbursementbl_list',
              placeholder: this.gettext('disbursement bill of lading'),
              resource: 'finances.DisbursementBL',
              related_fk: 'disbursementbl',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'contract_passport_list',
              placeholder: this.gettext('contract passport'),
              resource: 'passports.Passport',
            },
            {
              argument: 'costs_invoices_list',
              placeholder: this.gettext('costs invoices'),
              resource: 'finances.Finance',
              related_fk: 'costs_invoices',
              related_model: 'logistics.Logistic',
            },
            {
              argument: 'contract_balance_payment_list',
              placeholder: this.gettext('contracts balance payment'),
              resource: 'finances.BalancePayment',
            },
            {
              argument: 'voyage_list',
              placeholder: this.gettext('Voyages'),
              resource: 'logistics.Voyage',
            },
            {
              argument: 'custom_declaration_list',
              placeholder: this.gettext('customs declarations'),
              resource: 'logistics.CustomsCargoDeclaration',
            },
            {
              argument: 'deliverer_in_port_list',
              placeholder: this.gettext('Deliverer in port'),
              resource: 'clients.Deliverer',
            },
            {
              argument: 'charge_list',
              placeholder: this.gettext('Charge list'),
              resource: 'finances.Charge',
            },
            {
              argument: 'uninvoiced_charge_list',
              placeholder: this.gettext('Uninvoiced cost'),
              resource: 'finances.Charge',
            },
            {
              argument: 'consolidated_cost_invoice_list',
              placeholder: this.gettext('Consolidated costs invoice'),
              resource: 'finances.Finance',
              related_fk: 'transport_charges__invoice_positions__invoice_id',
              related_model: 'logistics.Logistic',
            },
          ],
          nestedSelects: [
            this.gtFilterService.getBoolFilter(
              'has_uninvoiced_cost',
              this.gettext('Has uninvoiced cost'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_volume_departed_real',
              this.gettext('Volume Departed Real'),
            ),
            this.gtFilterService.getBoolFilter(
              'receiving_date_changed',
              this.gettext('Receiving date was changed'),
            ),
            this.gtFilterService.getBoolFilter(
              'loading_date_changed',
              this.gettext('Loading date was changed'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_volume_departed',
              this.gettext('Volume Departed'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_volume_received',
              this.gettext('Volume Received'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_volume_boarded',
              this.gettext('Volume Boarded'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_buyer_nineteen_invoice',
              this.gettext('Buyer 90 invoice'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_buyer_ten_invoice',
              this.gettext('Buyer 10 invoice'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_nineteen_invoice',
              this.gettext('Supplier 90 invoice'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_ten_invoice',
              this.gettext('Supplier 10 invoice'),
            ),
            this.gtFilterService.getBoolFilter(
              'broker_confirmation',
              this.gettext('Broker confirmation'),
            ),
            this.gtFilterService.getBoolFilter('vet', this.gettext('Vet')),
            this.gtFilterService.getBoolFilter('has_cargo_custom_declaration', this.gettext('CCD')),
            this.gtFilterService.getBoolFilter(
              'has_periodic_custom_declaration',
              this.gettext('PCD'),
            ),
            this.gtFilterService.getBoolFilter('quality', this.gettext('Quality date')),
            this.gtFilterService.getBoolFilter('railway', this.gettext('Railway')),
            this.gtFilterService.getBoolFilter('eur_one', this.gettext('EUR 1')),
            this.gtFilterService.getBoolFilter(
              'with_buyer_contract',
              this.gettext('Sale Contract'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_contract',
              this.gettext('Purchase Contract'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_buyer_contract_cargo_confirmation',
              this.gettext('Has buyer contract cargo confirmation'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_contract_cargo_confirmation',
              this.gettext('Has supplier contract cargo confirmation'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_buyer_contract_payment_confirmation',
              this.gettext('Has buyer contract payment confirmation'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_contract_payment_confirmation',
              this.gettext('Has supplier contract payment confirmation'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_buyer_contract_final_confirmation',
              this.gettext('Has buyer contract final confirmation'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_supplier_contract_final_confirmation',
              this.gettext('Has supplier contract final confirmation'),
            ),
            this.gtFilterService.getBoolFilter('with_note', this.gettext('Note')),
            this.gtFilterService.getBoolFilter(
              'with_additional_info',
              this.gettext('Additional info'),
            ),
            this.gtFilterService.getBoolFilter(
              'supplier_invoiced',
              this.gettext('Supplier invoiced'),
            ),
            {
              argument: 'supplier_paid',
              placeholder: this.gettext('Supplier paid'),
              items: [
                { id: 'yes', title: this.gettext('Yes') },
                { id: 'in_process', title: this.gettext('In process') },
                { id: 'no', title: this.gettext('No') },
              ],
            },
            this.gtFilterService.getBoolFilter(
              'with_business_unit',
              this.gettext('Has Business Unit'),
            ),
            this.gtFilterService.getBoolFilter('buyer_invoiced', this.gettext('Buyer invoiced')),
            {
              argument: 'buyer_paid',
              placeholder: this.gettext('Buyer paid'),
              items: [
                { id: 'yes', title: this.gettext('Yes') },
                { id: 'in_process', title: this.gettext('In process') },
                { id: 'no', title: this.gettext('No') },
              ],
            },
            this.gtFilterService.getBoolFilter(
              'has_dbl',
              this.gettext('Has disbursement bill of lading'),
            ),
            this.gtFilterService.getBoolFilter(
              'is_supplier_contract_iscc',
              this.gettext('Supplier contract ISCC'),
            ),
            this.gtFilterService.getBoolFilter(
              'is_buyer_contract_iscc',
              this.gettext('Buyer contract ISCC'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_purchase_price',
              this.gettext('Has own purchase price'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_purchase_price_diff',
              this.gettext('Purchase price differs'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_sale_price',
              this.gettext('Has own sale price'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_sale_price_diff',
              this.gettext('Sale price differs'),
            ),
            this.gtFilterService.getBoolFilter(
              'volume_trader_less_departed_real',
              this.gettext('Has volume reconciliation'),
            ),
            this.gtFilterService.getBoolFilter(
              'amount_trader_less_sale_amount',
              this.gettext('Has amount reconciliation'),
            ),
            this.gtFilterService.getBoolFilter(
              'sale_discount_more_than_purchase',
              this.gettext('Sale discount more then purchase'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_export_invoice',
              this.gettext('Has export invoice'),
            ),
            this.gtFilterService.getBoolFilter(
              'has_customs_declaration',
              this.gettext('Has customs declaration'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_checklist_copy',
              this.gettext('Checklist completed (copy)'),
            ),
            this.gtFilterService.getBoolFilter(
              'with_checklist_original',
              this.gettext('Checklist completed (original)'),
            ),
            this.gtFilterService.getBoolFilter(
              'exclude_from_warehouse',
              this.gettext('Exclude from warehouse'),
            ),
            {
              argument: 'logistic_type',
              placeholder: this.gettext('Logistic type'),
              items: [
                { id: 'main', title: this.gettext('Main') },
                { id: 'intermediate', title: this.gettext('Intermediate') },
              ],
            },
            {
              argument: 'has_quality',
              placeholder: this.gettext('Quality'),
              items: [
                { id: 'has_any_quality', title: this.gettext('Has any quality') },
                { id: 'has_no_quality', title: this.gettext('Has no quality') },
                { id: 'has_empty_qualities', title: this.gettext('Has empty qualities') },
                { id: 'has_filled_qualities', title: this.gettext('Has filled qualities') },
                { id: 'has_only_empty_qualities', title: this.gettext('Has only empty qualities') },
                {
                  id: 'has_only_filled_qualities',
                  title: this.gettext('Has only filled qualities'),
                },
              ],
            },
          ],

          nestedMultiSelects: [
            {
              argument: 'supplier_contract__currency',
              placeholder: this.gettext('Purchase Currencies'),
              items: this.currencies,
            },
            {
              argument: 'buyer_contract__currency',
              placeholder: this.gettext('Sale Currencies'),
              items: this.currencies,
            },
            {
              argument: 'crop_year_list',
              placeholder: this.gettext('crop year'),
              items: this.GtUtils.getYearList(),
            },
            {
              argument: 'vehicle_type_list',
              placeholder: this.gettext('Vehicle type'),
              items: [
                { id: 'car', title: this.gettext('Car') },
                { id: 'wagon', title: this.gettext('Wagon') },
                { id: 'container', title: this.gettext('Container') },
                { id: 'vessel', title: this.gettext('Vessel') },
              ],
            },
            {
              argument: 'supplier_contract_status_list',
              placeholder: this.gettext('Supplier contract status'),
              items: [
                { id: 'washout', title: this.gettext('Washout') },
                { id: 'new', title: this.gettext('New') },
                { id: 'delivery_period', title: this.gettext('Delivery period') },
                { id: 'shipment', title: this.gettext('Shipment') },
                { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                { id: 'executed', title: this.gettext('Executed') },
                { id: 'approved', title: this.gettext('Approved') },
                { id: 'cancelled', title: this.gettext('Cancelled') },
              ],
            },
            {
              argument: 'buyer_contract_status_list',
              placeholder: this.gettext('Buyer contract status'),
              items: [
                { id: 'washout', title: this.gettext('Washout') },
                { id: 'new', title: this.gettext('New') },
                { id: 'delivery_period', title: this.gettext('Delivery period') },
                { id: 'shipment', title: this.gettext('Shipment') },
                { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                { id: 'executed', title: this.gettext('Executed') },
                { id: 'approved', title: this.gettext('Approved') },
                { id: 'cancelled', title: this.gettext('Cancelled') },
              ],
            },
            {
              argument: 'season_list',
              placeholder: this.gettext('Season year'),
              items: this.GtUtils.getYearList(),
            },
            {
              argument: 'approval_status_list',
              placeholder: this.gettext('Approval status'),
              items: [
                { id: 'wait', title: 'No status' },
                { id: 'approved', title: 'Approved' },
                { id: 'process', title: 'Needs approval' },
                { id: 'rejected', title: 'Rejected' },
              ],
            },
          ],
        };
        this.CustomValuesService.getCustomFieldFilters({
          purpose_model: 'logistic',
        }).then((customFieldsFilters: any) => {
          config.filters.multiSelects.push(...customFieldsFilters.multiSelects);
          config.filters.selects.push(...customFieldsFilters.selects);
          config.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
          config.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
          config.filters.dateSelects.push(...customFieldsFilters.dateSelects);
        });
        config.pages = this.LogisticsService.getPagesConfig();
        return config;
      }
    },
  ],
};
