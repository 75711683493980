import type { Warehouse } from '~/shared/api';
import { logisticsWarehousesList } from '~/shared/api';
import type { Paginated } from '~/shared/common';

import type { WarehouseListParams } from '../types';

export class WarehouseRepository {
  query = async (query: WarehouseListParams): Promise<Paginated<Warehouse>> => {
    const { data } = await logisticsWarehousesList({ query });
    return data;
  };
}
