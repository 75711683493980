import {
  SaleContractCreate,
  SaleContractList,
  SaleContractUpdate,
  SaleContractsListParams,
} from '~/features/deals/sale-contracts';
import {
  SaleContract,
  contractsSaleContractsCreate,
  contractsSaleContractsDestroy,
  contractsSaleContractsListInfoList,
  contractsSaleContractsPartialUpdate,
  contractsSaleContractsRetrieve,
  contractsSaleContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class SaleContractsRepository extends EntityRepository<
  SaleContract,
  SaleContractsListParams,
  SaleContractCreate,
  SaleContractList
> {
  get = async (id: number) => {
    const { data } = await contractsSaleContractsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: SaleContractsListParams) => {
    const { data } = await contractsSaleContractsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: SaleContractCreate) => {
    const res = await contractsSaleContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: SaleContractUpdate) => {
    const { data } = await contractsSaleContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsSaleContractsDestroy({ path: { id } });
  };

  getTotals = async (query: SaleContractsListParams) => {
    const { data } = await contractsSaleContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
