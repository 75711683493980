import {
  ServicesContractCreate,
  ServicesContractList,
  ServicesContractUpdate,
  ServicesContractsListParams,
} from '~/features/deals/services-contracts';
import {
  ServicesContract,
  contractsServicesContractsCreate,
  contractsServicesContractsDestroy,
  contractsServicesContractsListInfoList,
  contractsServicesContractsPartialUpdate,
  contractsServicesContractsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class ServicesContractsRepository extends EntityRepository<
  ServicesContract,
  ServicesContractsListParams,
  ServicesContractCreate,
  ServicesContractList
> {
  get = async (id: number) => {
    const { data } = await contractsServicesContractsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: ServicesContractsListParams) => {
    const { data } = await contractsServicesContractsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: ServicesContractCreate) => {
    const res = await contractsServicesContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: ServicesContractUpdate) => {
    const { data } = await contractsServicesContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsServicesContractsDestroy({ path: { id } });
  };

  getTotals = async (_query: ServicesContractsListParams) => {
    return Promise.resolve({});
  };
}
