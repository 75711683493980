import React from 'react';

import type { RenderArgs } from '~/core/page-views';
import type { CellDataType, ColumnDef } from '~/shared/ui/components/data-grid';

import {
  CheckboxCellRead,
  DateCellWrite,
  EmailCellWrite,
  EntityCellWrite,
  LabelCellWrite,
  MultiselectCellWrite,
  NumberCellWrite,
  PersonCellRead,
  PhoneCellWrite,
  SelectCellWrite,
  TextFieldCellWrite,
  UrlCellWrite,
} from '../cell-table-adapters/';

export const DynamicWriteCell = ({
  value,
  valueChanged,
  rowId,
  cellTemplateContext,
  dataType,
  typeExtra,
  disabled,
  render,
  row,
}: {
  dataType: CellDataType;
  rowId: number;
  value: unknown;
  valueChanged: (value?: unknown) => void;
  template?: React.ElementType;
  cellTemplateContext?: ColumnDef<unknown>['cellTemplateContext'];
  typeExtra: string;
  disabled?: boolean;
  render?: (args: RenderArgs) => JSX.Element;
  row: { id: number };
}) => {
  if (render) {
    return render({
      value,
      valueChanged: valueChanged as (value: unknown) => void,
      id: rowId,
      editing: !disabled,
      record: row,
    });
  }

  if (dataType === 'text') {
    return <TextFieldCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'number') {
    return <NumberCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'string') {
    return <TextFieldCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'url') {
    return <UrlCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'date') {
    return (
      <DateCellWrite
        value={value}
        valueChanged={valueChanged}
        disabled={disabled}
        typeExtra={typeExtra}
      />
    );
  }

  if (dataType === 'person') {
    return <PersonCellRead value={value} />;
  }

  if (dataType === 'select') {
    return (
      <SelectCellWrite
        value={value}
        valueChanged={valueChanged}
        cellTemplateContext={cellTemplateContext}
        disabled={disabled}
      />
    );
  }

  if (dataType === 'entity') {
    return (
      <EntityCellWrite id={rowId} value={value} valueChanged={valueChanged} typeExtra={typeExtra} />
    );
  }

  if (dataType === 'email') {
    return <EmailCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'phone') {
    return <PhoneCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'checkbox') {
    return <CheckboxCellRead value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'label') {
    return <LabelCellWrite value={value} valueChanged={valueChanged} disabled={disabled} />;
  }

  if (dataType === 'multiselect') {
    return (
      <MultiselectCellWrite
        value={value}
        cellTemplateContext={cellTemplateContext}
        valueChanged={valueChanged}
        disabled={disabled}
      />
    );
  }
};
