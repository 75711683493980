import ng from 'angular';

import type { CurrentUser } from '~/core/auth/lib';
import { AuthStore } from '~/core/auth/services';
import { AuthRepository } from '~/core/auth/services/auth.repository';
import { ContentTypesRepository } from '~/core/content-types';
import { AccountsRepository, UserRepository } from '~/features/accounts';
import type { Approval } from '~/shared/api';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import approvalsModalTemplate from './legacy/common/approvals-modal/approvals-modal.tpl.html?raw';
import approversModalTemplate from './legacy/common/approvers-modal/approvers-modal.tpl.html?raw';
import logentryModalTemplate from './legacy/logentry-modal/logentry-modal.tpl.html?raw';
import userModalTemplate from './legacy/user-modal/user-modal.tpl.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';

export class AccountsService {
  accountsRepo;
  authRepo;
  authStore;
  userRepo;
  contentTypesRepo;

  User;

  user: CurrentUser | null = null;

  UserSelf: any;
  Group: any;
  Permission: any;
  LogEntry: any;
  Approval: any;
  ApprovalConfig: any;
  RegionalManager: any;

  static readonly $inject = [
    '$resource',
    '$rootScope',
    '$uibModal',
    '$injector',
    'gettext',
    'CoreService',
  ];
  constructor(
    private readonly $resource: ng.resource.IResourceService,
    private readonly $rootScope: GtRootScopeService,
    private readonly $uibModal: ng.ui.bootstrap.IModalService,
    private readonly $injector: ng.auto.IInjectorService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly CoreService: CoreService,
  ) {
    this.authRepo = container.resolve(AuthRepository);
    this.accountsRepo = container.resolve(AccountsRepository);
    this.authStore = container.resolve(AuthStore);
    this.userRepo = container.resolve(UserRepository);
    this.contentTypesRepo = container.resolve(ContentTypesRepository);

    this.User = {
      get: this.userRepo.get,
      query: this.userRepo.query,
      currentUser: this.authRepo.getCurrentUser,
      update: this.userRepo.update,
      predictions: this.userRepo.predictions,
      delete: this.userRepo.delete,
      save: this.userRepo.create,
    };
    this.authStore.currentUser$.subscribe((user) => {
      this.user = user;
      /** @deprecated: use AuthStore.currentUser$ or AccountService.user instead */
      $rootScope.user = user;
    });

    this.UserSelf = this.$resource(
      '/api/accounts/users/self/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        changePassword: {
          method: 'POST',
          url: '/api/accounts/users/self/:id/change_password/',
        },
      },
    );

    this.Group = this.$resource(
      '/api/accounts/groups/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/groups/predictions/',
        },
      },
    );

    this.Permission = this.$resource(
      '/api/accounts/permissions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/permissions/predictions/',
        },
      },
    );

    this.LogEntry = this.$resource(
      '/api/core/logentries/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
      },
    );

    this.Approval = this.$resource(
      '/api/accounts/approvals/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approvals/predictions/',
        },
        approve: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/approve/',
        },
        decline: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/decline/',
        },
        reactivate: {
          method: 'GET',
          url: '/api/accounts/approvals/:id/reactivate/',
        },
        approvalResentRequest: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approvals/:id/request/',
        },
      },
    );

    this.ApprovalConfig = this.$resource(
      '/api/accounts/approval-configs/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/approval-configs/predictions/',
        },
        reactivateByConfig: {
          method: 'PATCH',
          url: '/api/accounts/approval-configs/reactivate_by_config/',
        },
        getLatestActiveByObjectType: {
          method: 'GET',
          isArray: false,
          params: { object_type: '@object_type' },
          url: '/api/accounts/approval-configs/get_latest_active_by_object_type/?object_type=:object_type',
        },
      },
    );

    this.RegionalManager = this.$resource(
      '/api/accounts/regional-managers/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/regional-managers/predictions/',
        },
        tableTotal: {
          method: 'GET',
          isArray: false,
          url: '/api/accounts/regional-managers/table_total/',
        },
        details: {
          method: 'GET',
          url: '/api/accounts/regional-managers/:id/details/',
        },
      },
    );
  }

  hasPerm(perms: string | string[]) {
    perms = ng.isArray(perms) ? perms : [perms];
    if (!perms.every((i) => this.$rootScope.user.all_perms.includes(i))) {
      throw new Error(`Permission was not defined. Perms: ${perms.join(', ')}`);
    }
    return Boolean(this.$rootScope.user.perms.filter((perm) => perms.includes(perm)).length);
  }

  logentryModal(logentry: object) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: logentryModalTemplate,
      controller: 'AccountsLogentryModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        logentry: () => logentry,
      },
    }).result;
  }

  approvalsModal(objectId: number, contentType: string) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: approvalsModalTemplate,
      controller: 'ApprovalsModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        objectId: () => {
          return objectId;
        },
        contentType: () => {
          return contentType;
        },
      },
    }).result;
  }

  approversModal(objectId: number, contentType: string, permission: string[]) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: approversModalTemplate,
      controller: 'ApproversModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        objectId: () => objectId,
        contentType: () => contentType,
        permission: () => permission,
      },
    }).result;
  }

  getLogEntries(objectId: number, model: string, appLabel: string) {
    return this.CoreService.ContentType.query({
      model: model,
      app_label: appLabel,
    }).$promise.then((data: any) => {
      const contentType = data.results.shift();
      return this.LogEntry.query({
        object_id: objectId,
        content_type: contentType?.id,
      }).$promise;
    });
  }

  approveObject(model: string, objectId: number): Promise<object> {
    return this.CoreService.getModelContentType(model).then((ct: { id: number }) => {
      return this.getObjectApproval(objectId, ct.id).then((approval: { id: number }) => {
        return this.Approval.approve({ id: approval.id });
      });
    });
  }

  declineObject(model: string, objectId: number): Promise<object> {
    return this.CoreService.getModelContentType(model).then((ct: { id: number }) => {
      return this.getObjectApproval(objectId, ct.id).then((approval: { id: number }) => {
        return this.Approval.decline({ id: approval.id });
      });
    });
  }

  getObjectApproval(objectId: number, contentType: number) {
    const params = {
      object_id: objectId,
      content_type: contentType,
      user: this.$rootScope.user.id,
      is_archived: false,
    };
    return this.getApprovals(params).then((data: any) => data.results[0]);
  }

  getApprovals(params: object) {
    return this.Approval.query(params).$promise;
  }

  approvalResentRequest(approvalId: number) {
    return this.Approval.approvalResentRequest({ id: approvalId }).$promise;
  }

  saveApproval(approval: any) {
    // rm
    if (!approval.content_type) {
      throw Error("You can't save Approval without content_type");
    }
    if (!approval.user) {
      throw Error("You can't save Approval without user");
    }
    if (!approval.object_id) {
      throw Error("You can't save Approval without object_id");
    }
    const saveFunc = approval.id ? this.Approval.update : this.Approval.save;
    return saveFunc(approval).$promise;
  }

  deleteApproval(approval: any) {
    return this.Approval.delete(approval).$promise;
  }

  getApprovalConfigList(params: object) {
    return this.ApprovalConfig.query(params).$promise;
  }

  getDefaultApprovalConfigId(objectType: any) {
    // FIX: cache me
    const requestParams: any = {
      object_type: objectType,
      page_size: 1,
      is_active: 1,
      main_approvers_list: [],
    };

    if (this.$rootScope.user.settings.DEFAULT_APPROVAL_CONFIG_FOR_MAIN_APPROVERS) {
      requestParams.main_approvers_list = [this.$rootScope.user.id];
    }

    return this.getApprovalConfigList(requestParams).then(function (data: any) {
      return (data.results.shift() || {})?.id;
    });
  }

  userModal(item: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: userModalTemplate,
      controller: 'UserModalController as vm',
      controllerAs: 'vm',
      appendTo: getModalRoot(),
      windowClass: 'modal-template',
      resolve: {
        item: () => ({ ...item }),
      },
    }).result;
  }

  reCreateApprovals(objectId: number, contentType: any) {
    // rm
    return this.Approval.reCreateApprovals({
      object_id: objectId,
      content_type: contentType,
    }).$promise;
  }

  getActualApproval(configLevel: any) {
    // rm
    const levelWithApprovals = configLevel.levels_data.filter(
      (level: any) => level.approvals_created,
    );
    const levelIndexes = levelWithApprovals.map((level: any) => {
      return level.index;
    });
    const maxIndex = Math.max(...levelIndexes);
    const actualLevel = levelWithApprovals.filter((level: any) => level.index === maxIndex)[0] || {
      approvers_data: [],
    };

    const userApproval = actualLevel.approvers_data.filter(
      (approver: any) => approver.id == this.$rootScope.user.id,
    );

    return (userApproval.length && userApproval[0]) || {};
  }

  getActualMainApproval(configLevel: any) {
    // rm
    const userApproval = (configLevel.main_approvers_data || []).filter(
      (approver: any) => approver.id == this.$rootScope.user.id,
    );
    return (userApproval.length && userApproval[0]) || {};
  }

  getApprovalsByConfig(approvableId: number, approvalConfigId: number) {
    return this.accountsRepo.getApprovalConfig(approvalConfigId).then((config) => {
      if (!config.object_type) {
        throw new Error('object_type is undefined.');
      }

      const [appLabel, modelName] = config.object_type.toLowerCase().split('.');

      return this.contentTypesRepo
        .queryContentTypes({ app_label: appLabel, model: modelName })
        .then((data) => {
          const contentType = data.results.shift();
          if (!contentType) {
            throw new Error('contentType is undefined or invalid.');
          }
          return this.accountsRepo
            .queryApprovals({ object_id: approvableId, content_type: contentType.id })
            .then((approvals) => {
              const levelApprovals: Record<string, Approval[]> = {
                main: (config.main_approvers ?? [])
                  .map((userId: number) =>
                    approvals.results.filter((approval: Approval) => approval.user === userId),
                  )
                  .reduce((acc: Approval[], arr: Approval[]) => acc.concat(arr), []),
              };

              let added: number[] = levelApprovals.main
                .filter((approval: Approval) => approval.is_archived)
                .map((approval: Approval) => approval.id);

              config.config_levels.forEach((lvl) => {
                const potentialApprovers = [...lvl.approvers];

                if (lvl.replacement_approver) {
                  const replacement = lvl.replacement_approver;
                  const alreadyIn = potentialApprovers.find(
                    (u: { id: number }) => u.id === replacement.id,
                  );
                  if (!alreadyIn) {
                    potentialApprovers.push(lvl.replacement_approver);
                  }
                }

                levelApprovals[lvl.index] = potentialApprovers
                  .map((approver) => {
                    const foundApprovals = approvals.results.filter(
                      (approval: Approval) => approval.user === approver.id,
                    );

                    const noActive = !foundApprovals.some(
                      (approval: Approval) => !approval.is_archived,
                    );

                    const isReplacedInSomeApproval = approvals.results.some(
                      (approval: Approval) =>
                        approval.replaced_user_name === approver.username && !approval.is_archived,
                    );

                    if (noActive && !isReplacedInSomeApproval) {
                      foundApprovals.push({
                        user_name: approver.username,
                        user_avatar: approver.avatar,
                        id: 0,
                        user: approver.id,
                        content_type: contentType.id,
                        object_id: approvableId,
                      } as Approval);
                    }

                    return foundApprovals;
                  })
                  .reduce((acc: Approval[], arr: Approval[]) => acc.concat(arr), [])
                  .filter(
                    (approval: Approval) => !approval.is_archived || !added.includes(approval.id),
                  );

                added = added.concat(
                  levelApprovals[lvl.index]
                    .filter((approval: Approval) => approval.is_archived)
                    .map((approval: Approval) => approval.id),
                );
              });

              return { config, levelApprovals };
            });
        });
    });
  }

  getUserApproval(
    approvableId: number,
    approvableCtId: number | null,
    approvableModelName: string,
  ) {
    if (!approvableCtId && !approvableModelName) {
      throw Error('Approvables ContentType id or ModelName must be provided');
    }

    const whenContentTypeId = approvableCtId
      ? Promise.resolve(approvableCtId)
      : this.CoreService.getModelContentType(approvableModelName.split('.')[1]).then(
          (ct: any) => ct.id,
        );

    return whenContentTypeId
      .then((ctId: string) =>
        this.getApprovals({
          object_id: approvableId,
          content_type: ctId,
          user: this.$rootScope.user.id,
          is_archived: false,
        }),
      )
      .then((data: any) => data.results);
  }

  voteApprovable(
    action: 'approve' | 'decline' | 'reactivate',
    approvableId: number,
    approvableContentTypeId: number | null,
    approvableModelName: string,
  ) {
    if (!['approve', 'decline', 'reactivate'].includes(action)) {
      throw Error('Wrong action. Can be one of [approve, decline, reactivate]');
    }
    const GtUtils = this.$injector.get<GtUtilsService>('GtUtils');
    if (action === 'reactivate') {
      return this.ApprovalConfig.reactivateByConfig({
        approvable_id: approvableId,
        approvable_content_type_id: approvableContentTypeId,
        approvable_model_name: approvableModelName,
      }).$promise.then((approval: any) => {
        notify(this.gettext('Approvals reactivated'));
        return approval;
      }, GtUtils.errorClb);
    }
    return this.getUserApproval(approvableId, approvableContentTypeId, approvableModelName).then(
      (approvals: any) => {
        if (!approvals?.length) {
          return notify(this.gettext("You can't vote for this object"), 'error');
        }

        const isApprovedAction = action === 'approve';
        const isDeclinedAction = action === 'decline';

        const allVoted = approvals.every(
          (appr: any) => (isApprovedAction && appr.approved) || (isDeclinedAction && appr.declined),
        );

        if (allVoted) {
          return notify(this.gettext('This object has been voted by you already. Skipping.'));
        }

        let ask = '';
        const anyAlreadyVoted = approvals.some((appr: any) => appr.approved || appr.declined);
        if (anyAlreadyVoted) {
          ask = this.gettext('You have voted for one (or more) of these approvals. Revote them?');
        }

        if (ask && !confirm(GtUtils.translate(ask))) {
          return;
        }

        const promises = approvals.map((approval: any) => {
          if (action === 'approve' && approval.approved) {
            return Promise.resolve();
          }
          if (action === 'decline' && approval.declined) {
            return Promise.resolve();
          }

          return this.Approval[action]({ id: approval.id }).$promise.then(
            (res: any) => {
              return res;
            },
            (err: any) => {
              GtUtils.errorClb(err);
            },
          );
        });

        return Promise.all(promises).then((results) => {
          notify(this.gettext('Approvals updated'));
          return results;
        });
      },
    );
  }
}
