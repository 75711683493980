import { BehaviorSubject, map, shareReplay, withLatestFrom } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { type EntityColumn, PageViewsStore } from '~/core/page-views';
import {
  ExportContractCreate,
  ExportContractList,
  ExportContractUpdate,
  ExportContractsListParams,
} from '~/features/deals/export-contracts/lib';
import { ExportContractsModel } from '~/features/deals/export-contracts/services/export-contracts.model';
import {
  BaseContractsListParams,
  ContractSerializer,
  ViewMode,
  tableColumnsMap,
} from '~/features/deals/shared/contracts';
import { ExportContract } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

import { ExportContractsRepository } from './export-contracts.repository';

@injectable()
export class ExportContractsListViewModel extends EntityListEditViewModel<
  ExportContract,
  ExportContractsListParams,
  ExportContractCreate,
  ExportContractList
> {
  resolveEntityRepo() {
    return container.resolve(ExportContractsRepository);
  }
  private readonly entityName = 'export-contracts-page-view';
  private readonly purposeModel = 'exportcontract';

  constructor(
    private readonly exportContractsRepo: ExportContractsRepository,
    private readonly exportContractsModel: ExportContractsModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  private readonly viewModeSubj = new BehaviorSubject<ViewMode>('table');

  readonly viewMode$ = this.viewModeSubj.asObservable();

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    withLatestFrom(this.pageParamsSubj),
    map(([allProps, pageParams]) => this.filterProperties(allProps, pageParams)),
    startWith([] as EntityColumn[]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.exportContractsRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public exportContractsCreated = async ({ entities }: { entities: ExportContractCreate[] }) => {
    await this.exportContractsModel.createExportContracts(entities);
  };

  public exportContractDeleted = async (id: number) => {
    try {
      await this.exportContractsRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public exportContractsUpdated = async ({ entities }: { entities: ExportContractUpdate[] }) => {
    try {
      await this.exportContractsModel.updateExportContracts(entities);
      notifySuccess('ExportContracts updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public getInitParams() {
    const serializer: ContractSerializer = 'table_info';
    return { page_size: 25, page: 1, serializer: serializer };
  }

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: ExportContract[]) => {
    await Promise.all(records.map(this.exportContractsRepo.update));
    this.pageParamsChanged({});
  };

  public createExportContract = async (exportContract: ExportContract) => {
    try {
      await this.exportContractsRepo.create(exportContract);
      notifySuccess('Export Contract created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  private filterProperties(props: EntityColumn[], pageParams: BaseContractsListParams) {
    const serializer = pageParams.serializer;
    const allowedCols = tableColumnsMap[serializer] ?? [];
    return props.filter((prop) => allowedCols.includes(prop.column_name));
  }
}
