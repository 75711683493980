import {
  IntermediateContractCreate,
  IntermediateContractList,
  IntermediateContractUpdate,
  IntermediateContractsListParams,
} from '~/features/deals/intermediate-contracts';
import {
  IntermediateContract,
  contractsIntermediateContractsCreate,
  contractsIntermediateContractsDestroy,
  contractsIntermediateContractsListInfoList,
  contractsIntermediateContractsPartialUpdate,
  contractsIntermediateContractsRetrieve,
  contractsIntermediateContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class IntermediateContractsRepository extends EntityRepository<
  IntermediateContract,
  IntermediateContractsListParams,
  IntermediateContractCreate,
  IntermediateContractList
> {
  get = async (id: number) => {
    const { data } = await contractsIntermediateContractsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: IntermediateContractsListParams) => {
    const { data } = await contractsIntermediateContractsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: IntermediateContractCreate) => {
    const res = await contractsIntermediateContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: IntermediateContractUpdate) => {
    const { data } = await contractsIntermediateContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsIntermediateContractsDestroy({ path: { id } });
  };

  getTotals = async (query: IntermediateContractsListParams) => {
    const { data } = await contractsIntermediateContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
