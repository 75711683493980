import template from './signings-contracts-list-table-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';
import { html } from '^/shared/utils';

export const SigningsContractsListTableView = {
  bindings: {
    contracts: '<',
    count: '<',
    filterLevel: '<',
    showFullTable: '<',
    applyFilters: '&',
    openRoleModal: '&',
  },
  template,
  controller: [
    '$rootScope',
    'gettext',
    class {
      $rootScope: GtRootScopeService;
      applyFilters: any;
      contracts: any;
      count = 0;
      filterLevel = 'signings-contracts-list-table-view';
      gettext: ng.gettext.gettextFunction;
      openRoleModal: any;
      showFullTable: any;
      tableData: any;
      tableOptions: any;
      constructor($rootScope: GtRootScopeService, gettext: ng.gettext.gettextFunction) {
        this.$rootScope = $rootScope;
        this.gettext = gettext;

        this.tableOptions = {};
        this.tableData = {};
      }

      $onInit() {
        this.contracts = this.contracts || [];
        this.count = this.count || 0;

        this.tableOptions = this.getTableOptions();
      }

      $onChanges(changes: any) {
        if (changes.contracts || changes.count) {
          this.tableData = { rows: this.contracts, count: this.count };
        }
        if (changes.showFullTable) {
          this.tableOptions = this.getTableOptions();
        }
      }

      getTableOptions() {
        const options: any = {
          tableName: 'signings-contracts',
          tableClass: 'contract-charges-table',
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          templateArgs: {
            openRoleModal: (id: number, model: any) => this.openRoleModal({ id: id, model: model }),
          },
          columnDefs: [] as any[],
          tabs: [],
          configurable: true,
        };

        options.columnDefs = [
          {
            columnName: 'counterparty',
            title: this.gettext('Counterparty'),
            class: 'th-left-align',
            cellTemplate: html`
              <!-- Left click - open role modal, right click - possibility to open buyer details in new window  -->
              <a
                ng-click="args.openRoleModal(item.buyer, 'Buyer')"
                ng-if="item.contract_type == 'sale' && item.buyer_name"
              >
                <i class="fa fa-bookmark"></i>
                <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
                {[{ item.buyer_name }]}
              </a>
              <!-- Left click - open role modal, right click - possibility to open supplier details in new window  -->
              <a
                ng-click="args.openRoleModal(item.supplier, 'Supplier')"
                ng-if="item.contract_type == 'purchase' && item.supplier_name"
              >
                <i class="fa fa-bookmark-o"></i>
                <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                {[{ item.supplier_name }]}
              </a>
            `,
          },
          {
            columnName: 'owner',
            title: this.gettext('Owner'),
            class: 'th-left-align',
            cellTemplate: html`
              <!-- Left click - open role modal, right click - possibility to open buyer details in new window  -->
              <a
                ng-click="args.openRoleModal(item.buyer, 'Owner')"
                ng-if="item.contract_type == 'purchase' && item.buyer_name"
              >
                <i class="fa fa-home"></i>
                {[{ item.buyer_name }]}
              </a>
              <!-- Left click - open role modal, right click - possibility to open supplier details in new window  -->
              <a
                ng-click="args.openRoleModal(item.supplier, 'Owner')"
                ng-if="item.contract_type == 'sale' && item.supplier_name"
              >
                <i class="fa fa-home"></i>
                {[{ item.supplier_name }]}
              </a>
            `,
          },
          {
            columnName: 'number',
            title: this.gettext('Number'),
            class: 'th-left-align',
            cellTemplate: html`
              <a ui-sref="gt.page.contract({id: item.id})" class="content-label">
                #{[{ item.contract_number }]}
              </a>
            `,
          },
          {
            columnName: 'conclusion_date',
            title: this.gettext('Create'),
            class: 'th-left-align',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="item.conclusion_date">
                  {[{ item.conclusion_date | date: 'dd.MM.yy' }]}
                </span>
              </div>
            `,
          },
        ];

        if (this.showFullTable) {
          options.columnDefs.push(
            {
              columnName: 'basis_name',
              title: this.gettext('Basis'),
              class: 'th-left-align',
              cellTemplate: html`
                <span class="tooltip" data-tip="{[{ item.basis_name }]}">
                  {[{ item.basis_name || '---' }]}
                </span>
              `,
            },
            {
              columnName: 'crop_title',
              title: this.gettext('Commodity'),
              class: 'th-left-align',
              cellTemplate: html`
                <span class="tooltip" data-tip="{[{ item.crop_title }]}">
                  {[{ item.crop_title }]}
                </span>
              `,
            },
            {
              columnName: 'volume',
              title: this.gettext('Volume'),
              class: 'th-left-align',
              cellTemplate: html`
                <span class="tooltip" data-tip="{[{ item.volume }]}">
                  {[{ item.volume || 0 | number:3 }]}
                </span>
              `,
            },
            {
              columnName: 'price',
              title: this.gettext('Price'),
              class: 'th-left-align',
              cellTemplate: html`
                <span class="tooltip" data-tip="{[{ item.price }]}">
                  {[{ item.price || 0 | number:2 }]} {[{ item.currency }]}
                </span>
              `,
            },
            {
              columnName: 'date_of_execution',
              title: this.gettext('Execution start'),
              class: 'th-left-align',
              cellTemplate: html`
                <div>
                  <span class="date-label" ng-if="item.date_of_execution">
                    {[{ item.date_of_execution | date: 'dd.MM.yy' }]}
                  </span>
                </div>
              `,
            },
            {
              columnName: 'end_of_execution',
              title: this.gettext('Execution end'),
              class: 'th-left-align',
              cellTemplate: html`
                <div>
                  <span class="date-label" ng-if="item.end_of_execution">
                    {[{ item.end_of_execution | date: 'dd.MM.yy' }]}
                  </span>
                </div>
              `,
            },
          );
        }

        options.columnDefs.push(
          {
            columnName: 'signature_stage_draft',
            title: this.gettext('Draft'),
            class: 'th-left-align',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="item.signature_stage_draft">
                  {[{ item.signature_stage_draft | date: 'dd.MM.yy' }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'signature_stage_broker_signed',
            title: this.gettext('Broker Signed'),
            class: 'th-left-align',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="item.signature_stage_broker_signed">
                  {[{ item.signature_stage_broker_signed | date: 'dd.MM.yy' }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'signature_stage_we_signed',
            title: this.gettext('Supplier Signed'),
            class: 'th-left-align',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="item.signature_stage_we_signed">
                  {[{ item.signature_stage_we_signed | date: 'dd.MM.yy' }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'signature_stage_both_signed',
            title: this.gettext('Buyer Signed'),
            class: 'th-left-align',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="item.signature_stage_both_signed">
                  {[{ item.signature_stage_both_signed | date: 'dd.MM.yy' }]}
                </span>
              </div>
            `,
          },
          {
            columnName: 'kpi_signing_success',
            title: this.gettext("Don't use for KPI"),
            cellTemplate: html`
              <span ng-if="item.kpi_signing_success"><i class="fa fa-check-circle"></i></span>
            `,
          },
          {
            columnName: 'signature_stage_additional_info',
            title: this.gettext('Notes'),
            class: 'highlighted',
            cellTemplate: html`
              <span class="tooltip" data-tip="{[{ item.signature_stage_additional_info }]}">
                {[{ item.signature_stage_additional_info | cut:true:50:'...' }]}
              </span>
            `,
          },
        );

        return options;
      }
    },
  ],
};
