import type ng from 'angular';

import type { QueryParams } from '../../core/types';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

export class CustomValuesService {
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  CustomFieldChoiceResource: any;
  CustomFieldResource: any;
  CustomValueResource: any;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $resource: ng.resource.IResourceService,
    $uibModal: ng.ui.bootstrap.IModalService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
  ) {
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.GtUtils = GtUtils;
    this.gettext = gettext;
    this.gtFilterService = gtFilterService;
    this.CustomValueResource = $resource(
      '/api/core/custom-values/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        save: { method: 'POST' },
        queryInfo: {
          method: 'GET',
          isArray: false,
          url: '/api/core/custom-values/custom-values-list/info/',
        },
        createCustomValues: {
          method: 'POST',
          url: '/api/core/custom-values/create_custom_values/',
        },
      },
    );
    this.CustomFieldResource = $resource(
      '/api/core/custom-fields/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        delete: { method: 'DELETE' },
        save: { method: 'POST' },
      },
    );
    this.CustomFieldChoiceResource = $resource(
      '/api/core/custom-field-choices/:id/',
      { id: '@id' },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        delete: { method: 'DELETE' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/core/custom-field-choices/predictions/',
        },
      },
    );
  }
  getValue(item: any) {
    let result = '';
    const field = item.field;
    const values = item;
    switch (field.field_type) {
      case 'boolean':
        if (values.value_boolean === true) {
          result = this.gettext('Yes');
        }
        if (values.value_boolean === false) {
          result = this.gettext('No');
        }
        break;
      case 'string':
        result += values.value_string;
        break;
      case 'text':
        result += values.value_text;
        break;
      case 'date':
        result = values.value_date?.toDateString() || '';
        break;
      case 'number':
        result = String(
          Math.round(values.value_number * 10 ** field.precision) / 10 ** field.precision,
        );
        break;
      case 'choice':
        result = values.field.choices.reduce((res: any, curr: any) => {
          res[curr.id] = curr.title;
          return res;
        }, {});
        result = result[values.value_choice] || '';
        break;
      case 'multiple_choices':
        result = field.choices.reduce((res: any, curr: any) => {
          res[curr.id] = `•${curr.title}`;
          return res;
        }, {});
        result = values.value_multiple_choices.map((v: any) => result[v]).filter(Boolean);
        break;
    }
    return result;
  }

  getFieldValuesList(params: QueryParams, mode: any) {
    const emptyValues: any = {
      boolean: { value_boolean: false },
      string: { value_string: '' },
      text: { value_text: '' },
      date: { value_date: new Date() },
      number: { value_number: 0 },
    };
    return this.CustomFieldResource.query(params).$promise.then((customFields: any) => {
      if (!customFields?.results?.length) {
        return {};
      }

      if (mode === 'create') {
        return {
          count: customFields.count,
          results: customFields.results.map((customField: any) => ({
            ...emptyValues[customField.field_type],
            ...params,
            field: customField,
          })),
        };
      }

      return this.CustomValueResource.query(params).$promise.then((valuesData: any) => {
        valuesData.results.forEach((item: any) => {
          item.field_id = item.field;
          item.field = customFields.results.filter((f: any) => f.id === item.field_id).pop();
          item.value = this.getValue(item);
          if (item.field.field_type === 'number') {
            const precision = item.field.precision;
            item.value_number = Math.round(item.value_number * 10 ** precision) / 10 ** precision;
          }
        });
        return {
          count: customFields.count,
          results: customFields.results.map((customField: any) => ({
            ...emptyValues[customField.field_type],
            ...params,
            ...(valuesData.results.filter((v: any) => v.field_id === customField.id).pop() || {}),
            field: customField,
          })),
        };
      });
    });
  }
  saveValue(item: any) {
    const resource = item.id ? this.CustomValueResource.update : this.CustomValueResource.save;
    return resource({ ...item, field: item.field.id }).$promise.then(
      (data: any) => data,
      this.GtUtils.errorClb,
    );
  }

  deleteValue(itemId: number) {
    return this.CustomValueResource.delete({ id: itemId }).$promise.then(
      (data: any) => data,
      this.GtUtils.errorClb,
    );
  }

  getPagesConfig() {
    return [
      {
        title: this.gettext('Custom fields'),
        state: 'custom-fields.fields',
        icon: 'fa fa-list-ul',
      },
      {
        title: this.gettext('Custom values'),
        state: 'custom-fields.values',
        icon: 'fa fa-list-ol',
      },
    ];
  }

  getCustomFieldTableColumns(params: object) {
    return this.CustomFieldResource.query(params).$promise.then((customFields: any) => {
      return customFields.results.reduce((result: any, customField: any) => {
        let cellTemplate;
        if (customField.field_type === 'date') {
          cellTemplate = `
            <span
              ng-repeat="value in item.custom_fields_data | filter: { field_id: ${customField.id}}"
            >
              <span ng-if="value.field_id === ${customField.id}"
                >{[{ value.value | date: 'yyyy-MM-dd' }]}</span
              >
            </span>
          `;
        } else {
          cellTemplate = `
            <span
              ng-repeat="value in item.custom_fields_data | filter: { field_id: ${customField.id}}"
            >
              <span ng-if="value.field_id === ${customField.id}">{[{ value.value }]}</span>
            </span>
          `;
        }
        result.push({
          columnName: `custom_field_${customField.id}_${customField.key}`,
          class: 'td-left-align',
          title: customField.title,
          cellTemplate: cellTemplate,
        });
        return result;
      }, []);
    });
  }

  getCustomFieldFilters(params: object) {
    return this.CustomFieldResource.query(params).$promise.then((customFields: any) => {
      return customFields.results
        .filter((v: any) =>
          ['boolean', 'choice', 'multiple_choices', 'date'].includes(v.field_type),
        )
        .reduce(
          (result: any, customField: any) => {
            if (customField.field_type === 'boolean') {
              result.nestedSelects.push(
                this.gtFilterService.getBoolFilter(
                  `custom_field__${customField.field_type}__${customField.id}`,
                  customField.title,
                ),
              );
            } else if (customField.field_type === 'date') {
              result.dateSelects.push({
                argument: `custom_field__${customField.field_type}__${customField.id}`,
                placeholder: customField.title,
              });
            } else {
              result.nestedMultiSelects.push({
                argument: `custom_field__${customField.field_type}__${customField.id}`,
                placeholder: customField.title,
                items: customField.choices,
              });
            }
            return result;
          },
          {
            nestedSelects: [],
            nestedMultiSelects: [],
            selects: [],
            multiSelects: [],
            dateSelects: [],
          },
        );
    });
  }

  createCustomValues(objectId: number, customValues: any) {
    return this.CustomValueResource.createCustomValues({
      object_id: objectId,
      custom_values: customValues,
    }).$promise.then((data: any) => data, this.GtUtils.errorClb);
  }
}
CustomValuesService.$inject = ['$resource', '$uibModal', 'GtUtils', 'gettext', 'gtFilterService'];
