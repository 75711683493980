import React from 'react';

import { CheckboxRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const CheckboxCellRead = ({ value, valueChanged, disabled }: CellTypeProps) => {
  return typeof value === 'boolean' ? (
    <CheckboxRead value={value} valueChanged={valueChanged} disabled={disabled} />
  ) : null;
};
