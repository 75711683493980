import React from 'react';

import type { Entity } from '~/shared/common';

import type { EntityFieldProperty, UserEntityField } from '../../lib';
import { SheetView } from '../sheet-view';
import { TableView } from '../table-view';

export const DatasetView = <
  TRecord extends Entity,
  TRecordUpdate = TRecord,
  TRecordRequest = TRecord,
>({
  view,
  pageData,
  properties,
  userProperties,
  loading,
  onEdit,
  onCreate,
  onSave,
  onDataValidation,
  onDelete,
}: {
  view: 'spreadsheet' | 'grid';
  pageData: { count: number; records: TRecord[] };
  properties: EntityFieldProperty<TRecord>[];
  userProperties: UserEntityField[];
  loading: boolean;
  onEdit: (diff: TRecordUpdate[]) => void;
  onCreate: (diff: TRecordRequest[]) => void;
  onSave: () => Promise<void>;
  onDataValidation: ({ allowSave }: { allowSave: boolean }) => void;
  onDelete: (id: number) => Promise<void>;
  editing?: boolean;
}) => {
  const viewVariants = {
    spreadsheet: (
      <SheetView<TRecord, TRecordUpdate, TRecordRequest>
        data={{ records: pageData.records, properties, userProperties: userProperties }}
        loading={loading}
        initialRecords={pageData.records}
        onEdit={onEdit}
        onCreate={onCreate}
        onSave={onSave}
        onDataValidation={onDataValidation}
        onDelete={onDelete}
      />
    ),
    grid: (
      <TableView<TRecord, TRecordUpdate>
        data={{ records: pageData.records, properties }}
        loading={loading}
        selectable
        onDelete={onDelete}
        onEdit={onEdit}
        onSave={onSave}
        editing={true}
      />
    ),
  };

  return viewVariants[view];
};
