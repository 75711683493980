import React from 'react';

import { Tag } from '~/shared/ui/kit/tag';

import type { LogisticList } from '../../lib';

export const StatusTemplate: React.FC<{ logistic: LogisticList }> = ({ logistic }) => {
  if (!logistic.volume_departed) {
    return (
      <Tag>
        <span>Loading</span>
      </Tag>
    );
  }
  if (logistic.volume_departed && !logistic.volume_received && !logistic.volume_departed_real) {
    return (
      <Tag variant={'orange'}>
        <span>At road</span>
      </Tag>
    );
  }
  if (logistic.volume_received || logistic.volume_departed_real) {
    return (
      <Tag variant={'green'}>
        <span>Delivered</span>
      </Tag>
    );
  }
  return <span />;
};
