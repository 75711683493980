import React from 'react';

import { cn } from '~/shared/lib/utils';

import type { CellRenderDef, ListColumnDef, Row } from '../types';

export const ListRow = <TRecord extends { id: number }>({
  row,
  editing,
  selectedRowIds,
  onEdit,
  onSelect,
  CellRender,
}: {
  row: Row<TRecord>;
  CellRender: React.FC<CellRenderDef<TRecord>>;
  editing: boolean;
  onEdit: (
    newValue: TRecord[keyof TRecord] | undefined,
    accessorKey: string,
    rowId: number,
  ) => void;
  onSelect?: (ids: number[]) => void;
  selectedRowIds?: number[];
}) => {
  return (
    <div className="border-stroke-main-medium flex max-h-8 min-h-8 border-b px-2 last:border-0">
      {row.getVisibleCells().map((cell) => {
        const columnDef = cell.column.columnDef as ListColumnDef<TRecord>;

        const valueChangedHandler = (value?: unknown) => {
          if (columnDef.accessorKey === '_selectRowCheckbox' && Array.isArray(value)) {
            onSelect?.(value as number[]);
          } else {
            onEdit(value as TRecord[keyof TRecord], String(columnDef.accessorKey), row.original.id);
          }
        };

        return (
          <div
            key={cell.id}
            style={{ width: cell.column.getSize() }}
            className={cn(
              'relative flex h-full w-full items-center overflow-hidden',
              selectedRowIds?.includes(row.original.id) && 'bg-background-section-extraLight',
              columnDef.accessorKey === '_selectRowCheckbox' && 'border-r-0 pl-1',
            )}
          >
            <CellRender
              value={cell.getValue() as TRecord[keyof TRecord]}
              row={row.original}
              id={row.original.id}
              dataType={columnDef.dataType}
              typeExtra=""
              cellTemplateContext={columnDef.cellTemplateContext}
              template={columnDef.cellTemplate}
              dataTypeVariant={editing ? 'write' : 'read'}
              valueChanged={valueChangedHandler}
              render={columnDef.render}
            />
          </div>
        );
      })}
    </div>
  );
};
