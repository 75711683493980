import ng from 'angular';

import { DashboardContainer } from '~/features/common/bi-dashboards';
import { CounterpartiesContainer } from '~/features/crm/counterparties/components';
import { ExportContractsContainer } from '~/features/deals/export-contracts/components/export-contracts-container/export-contracts-container';
import { IntermediateContractsContainer } from '~/features/deals/intermediate-contracts/components/intermediate-contracts-container/intermediate-contracts-container';
import { PurchaseContractsContainer } from '~/features/deals/purchase-contracts/components';
import { SaleContractsContainer } from '~/features/deals/sale-contracts/components';
import { ServicesContractsContainer } from '~/features/deals/services-contracts/components/services-contracts-container/services-contracts-container';
import { BillsOfLadingContainer } from '~/features/execution/bills-of-lading';
import { ConsignmentsContainer } from '~/features/execution/consignments';
import { LogisticContainer } from '~/features/execution/logistic/components';
import { FinanceContainer } from '~/features/finances/invoices/components/finance-container';

import { angularize } from '^/shared/utils/react';

const gtMainContainers = ng.module('gtMainContainers', []);

angularize(DashboardContainer, 'gtmDashboardContainer', gtMainContainers, {
  store: '<',
  title: '<?',
  selector: '<?',
});

angularize(CounterpartiesContainer, 'counterpartiesListContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
  onOpenCounterpartyModal: '<',
  onCloneCounterparty: '<',
  onOpenEmailModal: '<',
  onApproveCounterparty: '<',
  onOpenDocxModal: '<',
});

angularize(LogisticContainer, 'logisticListContainer', gtMainContainers, {
  viewMode: '<',
  viewModel: '<',
});
angularize(FinanceContainer, 'financeListContainer', gtMainContainers, {
  viewMode: '<',
  viewModel: '<',
});
angularize(ConsignmentsContainer, 'consignmentsListContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(BillsOfLadingContainer, 'billsOfLadingContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(SaleContractsContainer, 'saleContractsContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(PurchaseContractsContainer, 'purchaseContractsContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(ExportContractsContainer, 'exportContractsContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(IntermediateContractsContainer, 'intermediateContractsContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

angularize(ServicesContractsContainer, 'servicesContractsContainer', gtMainContainers, {
  viewModel: '<',
  viewMode: '<',
});

export const gtMainContainersModule = gtMainContainers.name;
