import React from 'react';

import {
  ArrowRightIcon,
  CopyIcon,
  DislikeIcon,
  EditIcon,
  LikeIcon,
  PreviewIcon,
  UserEditIcon,
} from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';

import type { CounterpartyList } from '../../lib/types';

export const TitleDropdown: React.FC<{
  trigger: React.ReactNode;
  isOpen: boolean;
  counterparty: CounterpartyList;
  onOpenChange: (open: boolean) => void;
  onOpenCounterpartyModal: (counterparty: CounterpartyList) => void;
  onCloneCounterparty: (counterparty: CounterpartyList) => void;
  onOpenEmailModal: (counterparty: CounterpartyList) => void;
  onApproveCounterparty: (counterparty: CounterpartyList, approve: boolean) => void;
  onOpenDocxModal: (counterparty: CounterpartyList) => void;
  id?: number;
}> = ({
  trigger,
  onOpenChange,
  isOpen,
  onOpenCounterpartyModal,
  onCloneCounterparty,
  onOpenEmailModal,
  onApproveCounterparty,
  onOpenDocxModal,
  counterparty,
}) => {
  const menuItems = [
    {
      icon: <ArrowRightIcon size={14} className="text-text-main-secondary" />,
      label: 'Details',
      href: `/#/clients/clients/${counterparty.id}`,
    },
    {
      icon: <EditIcon size={14} className="text-text-main-secondary" />,
      label: 'Edit',
      onClick: () => onOpenCounterpartyModal(counterparty),
    },
    {
      icon: <CopyIcon size={14} className="text-text-main-secondary" />,
      label: 'Clone',
      onClick: () => onCloneCounterparty(counterparty),
    },
    {
      icon: <UserEditIcon size={14} className="text-text-main-secondary" />,
      label: 'Email',
      onClick: () => onOpenEmailModal(counterparty),
    },
    {
      icon: <LikeIcon size={14} className="text-text-main-primary" />,
      label: 'Approve',
      onClick: () => onApproveCounterparty(counterparty, true),
    },
    {
      icon: <DislikeIcon size={14} className="text-text-main-primary" />,
      label: 'Decline',
      onClick: () => onApproveCounterparty(counterparty, false),
    },
    {
      icon: <PreviewIcon size={14} className="text-text-main-secondary" />,
      label: '+ Template',
      onClick: () => onOpenDocxModal(counterparty),
    },
  ];

  return (
    <DropdownMenu open={isOpen} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent className="flex w-[120px] flex-col gap-1.5 px-1 py-1">
        {menuItems.map((item) => (
          <DropdownMenuItem
            key={item.label}
            asChild={!!item.href}
            className="hover:bg-background-main-quarternary cursor-pointer p-1 text-xs transition duration-300 ease-in-out"
            onClick={item.onClick}
          >
            {item.href ? (
              <a className="flex items-center gap-2 !outline-none" href={item.href}>
                {item.icon}
                <p className="text-text-main-primary !m-0">{item.label}</p>
              </a>
            ) : (
              <span className="flex items-center gap-2">
                {item.icon}
                <p className="text-text-main-primary !m-0">{item.label}</p>
              </span>
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
