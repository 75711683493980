import type { Voyage } from '~/shared/api';
import { logisticsVoyagesList } from '~/shared/api';
import type { Paginated } from '~/shared/common';

import type { VoyageListParams } from '../types';

export class VoyageRepository {
  query = async (query: VoyageListParams): Promise<Paginated<Voyage>> => {
    const { data } = await logisticsVoyagesList({ query });
    return data;
  };
}
