import type { CellData, MatrixPrimitive } from '~/shared/lib/spreadsheet';

import { removeProtectedFields } from '../helpers';
import { fromSpreadsheetValue } from '../mappers';

export const createdDataBuilder = <T>({
  data,
  allowedFields,
}: {
  data: MatrixPrimitive<CellData>;
  allowedFields: Set<string>;
}) => {
  const result: Record<string, T> = {};

  for (const rowKey in data) {
    const row = data[rowKey];

    for (const cellKey in row) {
      const cell = row[cellKey];

      if (cell.custom && !cell.custom.id) {
        const id = rowKey;
        if (!result[id]) {
          result[id] = {} as T;
        }
        const { value } = fromSpreadsheetValue(cell);

        result[id][cell.custom.key as keyof T] = value as T[keyof T];
      }
    }
  }

  return removeProtectedFields({ data: Object.values(result), allowedFields });
};
