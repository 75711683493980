import { ClientRoleRepository } from '~/features/common/client-role';
import { injectable } from '~/shared/lib/di';

import { FinanceRepository } from './finance.repository';
import { FinanceCreate, FinanceUpdate } from '../lib';

@injectable()
export class FinanceModel {
  constructor(
    private readonly financeRepo: FinanceRepository,
    private readonly clientRoleRepo: ClientRoleRepository,
  ) {}

  createFinances = async (entities: FinanceCreate[]): Promise<void> => {
    await Promise.all(
      entities.map(async (entity) => {
        const clientRoleFrom = (
          await this.clientRoleRepo.query({ name: entity.clientrole_from_name })
        ).results[0];
        const clientRoleTo = (await this.clientRoleRepo.query({ name: entity.clientrole_to_name }))
          .results[0];

        if (clientRoleFrom) {
          entity.clientrole_from = clientRoleFrom.id;
        }
        if (clientRoleTo) {
          entity.clientrole_to = clientRoleTo.id;
        }
      }),
    );
    await Promise.all(entities.map(async (entity) => await this.financeRepo.create(entity)));
  };

  updateFinances = async (entities: FinanceUpdate[]) => {
    await Promise.all(entities.map((entity) => this.financeRepo.update(entity)));
  };
}
