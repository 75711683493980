import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { DashboardContainer, useBiDashboards } from '~/features/common/bi-dashboards';
import { GreetingCard } from '~/pages/common/start/components/greeting-card';
import { Trans } from '~/shared/lib/i18n';
import { PageLayout } from '~/shared/ui/components/page/components';
import { ChartArcs } from '~/shared/ui/icons';

export const StartPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { biDashboardsStore } = useBiDashboards();

  return (
    <PageLayout
      leftContent={
        <div className="flex items-center gap-2">
          <ChartArcs size={16} className="text-stroke-main-inverted" />
          <h4 className="text-text-main-primary text-xs font-semibold">
            <Trans>Start Page</Trans>
          </h4>
        </div>
      }
    >
      <section className="flex py-4">
        <GreetingCard name={currentUser.username} />
      </section>
      {currentUser.settings.BI_ENABLED && (
        <section className="border-stroke-main-medium bg-background-main-primary w-full border-t p-6">
          <DashboardContainer store={biDashboardsStore} />
        </section>
      )}
    </PageLayout>
  );
};
