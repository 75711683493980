import React from 'react';

import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import type {
  ConsignmentCreate,
  ConsignmentList,
  ConsignmentUpdate,
  ConsignmentsListViewModel,
} from '~/features/execution/consignments';
import { getConsignmentColumns } from '~/features/execution/consignments';
import { ConsignmentsContainerLayout } from '~/features/execution/consignments/components/consignments-container-layout';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

export const ConsignmentsContainer: React.FC<{
  viewModel: ConsignmentsListViewModel;
  viewMode: 'spreadsheet' | 'grid';
}> = ({ viewModel, viewMode }) => {
  const createdDataRef = React.useRef<ConsignmentCreate[]>([]);
  const editedDataRef = React.useRef<ConsignmentUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getConsignmentColumns(pageData.results),
      }),
    [columns, pageData],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.consignmentsUpdated({ entities: editedDataRef.current });
      await viewModel.consignmentsCreated({ entities: createdDataRef.current });
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <ConsignmentsContainerLayout>
      <DatasetView<ConsignmentList, ConsignmentUpdate, ConsignmentCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: ConsignmentUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: ConsignmentCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.consignmentDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </ConsignmentsContainerLayout>
  );
};
