import React from 'react';

import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib/types';

export const TextFieldRead: React.FC<DataTypeProps<string> & { className?: string }> = ({
  disabled,
  value,
  className,
}) => (
  <p
    className={cn('w-full truncate text-xs', disabled && 'opacity-50', className)}
    data-testid="text-field"
  >
    {value}
  </p>
);
