import type { DateArg } from 'date-fns';
import {
  add,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isSameDay,
  parse,
  startOfDay,
  startOfMonth,
  startOfYear,
  sub,
} from 'date-fns';

export {
  add as addDate,
  sub as substractDate,
  parse as parseDate,
  startOfDay,
  startOfMonth,
  startOfYear,
  endOfDay,
  endOfMonth,
  endOfYear,
  differenceInDays,
  isSameDay,
};

export const formatDate = (date: DateArg<Date>, fmt = 'YYYY-MM-DD'): string => format(date, fmt);
