import { ClientRoleRepository } from '~/features/common/client-role';
import { ExportContractsRepository } from '~/features/deals/export-contracts';
import { PlaceOfDestinationRepository } from '~/features/execution/places-of-destination';
import { VoyageRepository } from '~/features/execution/voyages';
import { injectable } from '~/shared/lib/di';

import { BillOfLadingCreate, BillOfLadingUpdate } from '../lib';
import { BillsOfLadingRepository } from './bills-of-lading.repository';

@injectable()
export class BillsOfLadingModel {
  constructor(
    private readonly billsOfLadingRepo: BillsOfLadingRepository,
    private readonly exportContractRepo: ExportContractsRepository,
    private readonly placeOfDestinationRepo: PlaceOfDestinationRepository,
    private readonly clientRoleRepo: ClientRoleRepository,
    private readonly voyageRepo: VoyageRepository,
  ) {}

  createBillsOfLading = async (entities: BillOfLadingCreate[]) => {
    await Promise.all(
      // eslint-disable-next-line sonarjs/cognitive-complexity
      entities.map(async (entity) => {
        if (entity.export_contract_number) {
          const exportContract = (
            await this.exportContractRepo.query({ contract_number: entity.export_contract_number })
          ).results[0];
          if (exportContract) {
            entity.export_contract = exportContract.id;
          }
        }

        if (entity.place_of_destination_name) {
          const placeOfDestination = (
            await this.placeOfDestinationRepo.query({ name: entity.place_of_destination_name })
          ).results[0];
          if (placeOfDestination) {
            entity.place_of_destination = placeOfDestination.id;
          }
        }

        if (entity.notify_party_name) {
          const notifyParty = (await this.clientRoleRepo.query({ name: entity.notify_party_name }))
            .results[0];
          if (notifyParty) {
            entity.notify_party = notifyParty.id;
          }
        }

        if (entity.notify_party_second_name) {
          const notifyPartySecond = (
            await this.clientRoleRepo.query({ name: entity.notify_party_second_name })
          ).results[0];
          if (notifyPartySecond) {
            entity.notify_party_second = notifyPartySecond.id;
          }
        }

        if (entity.notify_party_third_name) {
          const notifyPartyThird = (
            await this.clientRoleRepo.query({ name: entity.notify_party_third_name })
          ).results[0];
          if (notifyPartyThird) {
            entity.notify_party_third = notifyPartyThird.id;
          }
        }

        if (entity.consignee_name) {
          const consignee = (await this.clientRoleRepo.query({ name: entity.consignee_name }))
            .results[0];
          if (consignee) {
            entity.consignee = consignee.id;
          }
        }

        if (entity.voyage_name) {
          const voyage = (await this.voyageRepo.query({ voyage_name: entity.voyage_name }))
            .results[0];
          if (voyage) {
            entity.voyage = voyage.id;
          }
        }
      }),
    );

    await Promise.all(entities.map(async (entity) => await this.billsOfLadingRepo.create(entity)));
  };

  updateBillsOfLading = async (entities: BillOfLadingUpdate[]) => {
    await Promise.all(entities.map((entity) => this.billsOfLadingRepo.update(entity)));
  };
}
