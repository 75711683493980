import { contractsGeneralAgreementsList } from '~/shared/api';

import type { GeneralAgreementListParams } from '../types';

export class GeneralAgreementRepository {
  query = async (query: GeneralAgreementListParams) => {
    const { data } = await contractsGeneralAgreementsList({ query });
    return data;
  };
}
