import React from 'react';

import { EmailRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const EmailCellRead = ({ value }: CellTypeProps) => {
  return (
    <div className="px-2" data-testid="email-cell-read">
      <EmailRead value={typeof value === 'string' ? value : ''} />
    </div>
  );
};
