import React from 'react';

import { SelectRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const SelectCellRead = ({ value, cellTemplateContext, disabled }: CellTypeProps) => {
  return (
    <div className="px-2">
      <SelectRead
        value={String(value)}
        options={cellTemplateContext?.options ?? []}
        disabled={disabled}
      />
    </div>
  );
};
