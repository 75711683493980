import React from 'react';

import { cn } from '~/shared/lib/utils';
import { LockIcon, ReportsIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Tag } from '~/shared/ui/kit/tag';

export const AffiliatedTemplate: React.FC<{
  value: { exporter_names: string[]; supplier_names: string[] };
  maxLength?: number;
}> = ({ value, maxLength = 6 }) => {
  const { exporter_names: exporterNames, supplier_names: supplierNames } = value;

  return (
    <div className={cn('px-2')}>
      {supplierNames.length + exporterNames.length > maxLength ? (
        <div className={cn('flex h-full w-full items-center')}>
          <AffiliatedDropdown exporterNames={exporterNames} supplierNames={supplierNames} />
        </div>
      ) : (
        <AffiliatedList exporterNames={exporterNames} supplierNames={supplierNames} />
      )}
    </div>
  );
};

const AffiliatedDropdown: React.FC<{
  exporterNames: string[];
  supplierNames: string[];
}> = ({ exporterNames, supplierNames }) => (
  <DropdownMenu>
    <DropdownMenuTrigger className="h-full w-full outline-none">
      <Tag>{exporterNames.length + supplierNames.length}</Tag>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="flex h-full max-h-[220px] max-w-[280px] flex-col overflow-y-auto p-0">
      <div className="flex flex-wrap gap-0.5">
        {exporterNames.map((item) => (
          <div key={item} className="flex items-center p-1">
            <LockIcon size={14} />
            <p className="!m-0">{item}</p>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap gap-0.5">
        {supplierNames.map((item) => (
          <div key={item} className="flex items-center p-1">
            <ReportsIcon size={14} />
            <p className="!m-0">{item}</p>
          </div>
        ))}
      </div>
    </DropdownMenuContent>
  </DropdownMenu>
);

export const AffiliatedList: React.FC<{
  exporterNames: string[];
  supplierNames: string[];
}> = ({ exporterNames, supplierNames }) => (
  <>
    <div className="flex items-center">
      {supplierNames.map((item) => (
        <span key={item} className="flex items-center">
          <LockIcon size={14} />
          {item}
        </span>
      ))}
    </div>
    <div className="flex items-center">
      {exporterNames.map((item) => (
        <span key={item} className="flex items-center">
          <ReportsIcon size={14} />
          {item}
        </span>
      ))}
    </div>
  </>
);
