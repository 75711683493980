import type { DataDef } from '~/core/page-views/components/data-set-view';
import type { Entity } from '~/shared/common';
import type { ColumnDef } from '~/shared/ui/components/data-grid';

import type { useTableView } from './hooks/use-table-view/use-table-view';

export const tableDataBuilder = <T extends { id: number }>({
  table,
  selectedRecordIds,
  selectable,
}: {
  table: ReturnType<typeof useTableView<T>>;
  selectedRecordIds: number[];
  selectable: boolean;
}) => {
  return {
    rows: selectable
      ? table.getRowModel().rows.map((row) => ({
          ...row,
          original: {
            ...row.original,
            _selectRowCheckbox: { rowId: row.original.id, selectedRowIds: selectedRecordIds },
          },
          getVisibleCells: () => {
            const visibleCells = row.getVisibleCells();
            return visibleCells.map((cell) => ({
              ...cell,
              original: {
                ...row.original,
                _selectRowCheckbox: { rowId: row.original.id, selectedRowIds: selectedRecordIds },
              },
            }));
          },
        }))
      : table.getRowModel().rows,
    headerGroups: table.getHeaderGroups(),
  };
};

export const tableColumnsBuilder = <T extends Entity>(
  properties: DataDef<T>['properties'],
): ColumnDef<T>[] => {
  return properties.map((property) => ({
    accessorKey: (property.sourceKey as keyof T) ?? property.key,
    header: property.title,
    dataType: property.dataType,
    hidden: !property.visible,
    position: property.position,
    cellTemplateContext: property.cellTemplateContext,
    size: property.size,
    render: property.render,
    valueFormatter: property.valueFormatter,
    valueParser: property.valueParser,
    valueSetter: property.valueSetter,
    editable: property.editing,
    typeExtra: property.typeExtra,
    sticky: property.sticky,
  }));
};
