import type { BillOfLadingTotals } from '@gt/api';
import type ng from 'angular';
import type { Subscription } from 'rxjs';

import type {
  BillOfLadingList,
  BillOfLadingListParams,
} from '~/features/execution/bills-of-lading';
import { BillsOfLadingListViewModel } from '~/features/execution/bills-of-lading';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import template from './bill-of-lading-list-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { BillOfLadingService } from '^/app/execution/components/transport/bill-of-lading/bill-of-lading.service';

type BillOfLadingListQueryParams = BillOfLadingListParams & { next?: boolean };
type ViewMode = 'table' | 'spreadsheet';

export class BillOfLadingContainer implements ng.IController {
  viewModel: BillsOfLadingListViewModel;
  pageData: { count: number; results: BillOfLadingList[] } = { results: [], count: 0 };

  subs: Record<string, Subscription> = {};

  queryParams: BillOfLadingListQueryParams = { page_size: 25, page: 1 };
  initQueryParams: Partial<BillOfLadingListQueryParams> = {};
  filterLevel = 'bill-of-lading-page-view';
  billsOfLading: BillOfLadingList[] = [];
  billsOfLadingCount = 0;
  total: Partial<BillOfLadingTotals> = {};
  view: ViewMode = 'table';
  modelName = 'passport';
  selection = false;
  controlsFilterLevel = 'passports-bill-of-lading-controls';
  numberInput = false;
  invoiceCondition = 'balance';
  invoicePercent = 100;
  readonly = false;

  newBl: any;
  onUpdate?: () => void;
  savedFilterChoices: any;
  tableName?: string;
  voyage: any;
  invoiceType?: string;

  static readonly $inject = [
    '$q',
    '$scope',
    'GtUtils',
    'gtFilterService',
    'BillOfLadingService',
    'gettext',
    'DocumentsService',
    'CoreService',
    'ContractsService',
  ];

  constructor(
    private readonly $q: ng.IQService,
    private readonly $scope: ng.IScope,
    private readonly GtUtils: GtUtilsService,
    private readonly gtFilterService: GtFilterService,
    private readonly BillOfLadingService: BillOfLadingService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly DocumentsService: any,
    private readonly CoreService: CoreService,
    private readonly ContractsService: ContractsService,
  ) {
    this.viewModel = container.resolve(BillsOfLadingListViewModel);
  }

  $onInit() {
    this.tableName = this.tableName ?? this.filterLevel;
    this.queryParams = {
      page_size: this.initQueryParams.page_size ?? 25,
      page: this.initQueryParams.page ?? 1,
      ...this.initQueryParams,
    };
    this.viewModel.pageParamsChanged(this.queryParams);

    this.newBl = {
      ...this.initQueryParams,
      voyage: this.voyage ? this.voyage : this.initQueryParams.voyage,
      date: new Date(),
    };

    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.updateData();
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      this.GtUtils.overlay(loading ? 'show' : 'hide');
      this.$scope.$applyAsync();
    });

    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.total = totals;
      this.$scope.$applyAsync();
    });

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);
    this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
  }

  applyFilters(params: QueryParams) {
    this.gtFilterService.updateQueryParams(
      {
        ...this.initQueryParams,
        ...params,
      },
      this.filterLevel,
    );
  }

  updateData() {
    this.billsOfLading = this.pageData.results;
    this.billsOfLadingCount = this.pageData.count;
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: BillOfLadingListQueryParams) => {
    this.queryParams = params;
    this.viewModel.pageParamsUpdated(this.queryParams);
  };

  changeView(view: ViewMode) {
    this.view = view;
  }

  invoiceCallback() {
    notify(this.GtUtils.translate(this.gettext('BLs are invoiced')));
    this.selection = !this.selection;
    this.updateData();
    return {
      $promise: this.$q.when(),
    };
  }

  createInvoice(invoiceType: any, invoiceCondition: any, invoicePercent = 100) {
    const bills = this.billsOfLading.filter((item: any) => item.$_selected && item.id);
    if (invoicePercent !== 100) {
      bills.forEach((bill: any) => {
        bill.quantity_to_be_invoiced_incoming *= invoicePercent / 100;
        bill.quantity_to_be_invoiced_outgoing *= invoicePercent / 100;
      });
    }
    this.BillOfLadingService.createInvoice(
      bills,
      this.queryParams.passport,
      () => this.invoiceCallback(),
      invoiceType,
      invoiceCondition,
    ).then(() => notify('BLs are invoiced'), this.GtUtils.errorClb);
  }

  openDocumentModal(object: any, modelName: string) {
    return this.DocumentsService.attachDocumentModal(object, modelName).then(() =>
      this.updateData(),
    );
  }

  openBlModal(bl: any, tab: string) {
    bl = bl || this.newBl;
    return this.BillOfLadingService.billOfLadingModal(bl, {
      tab: tab,
      quantity_to_be_invoiced_incoming: bl.quantity_to_be_invoiced_incoming,
      quantity_to_be_invoiced_outgoing: bl.quantity_to_be_invoiced_outgoing,
    }).then(() => this.updateData());
  }

  updateItem(billOfLading: any) {
    this.BillOfLadingService.updateBillOfLading(billOfLading).then(
      () => this.updateData(),
      this.GtUtils.errorClb,
    );
  }

  saveItem(billOfLading: any) {
    return this.checkContractsVolumeLimit(billOfLading).then((save: any) =>
      save
        ? this.BillOfLadingService.saveBillOfLading(billOfLading).then(() => {
            this.viewModel.pageParamsUpdated(this.queryParams);
            this.onUpdate?.();
          }, this.GtUtils.errorClb)
        : false,
    );
  }

  checkContractsVolumeLimit(bl: any) {
    this.GtUtils.overlay('show');
    return this.BillOfLadingService.checkContractsVolumeLimit(
      [bl.sale_contract, bl.purchase_contract],
      bl.volume,
    )
      .then((warnings: any) =>
        warnings.length
          ? this.decideWarnings(warnings).then((result: any) => (result ? true : false))
          : true,
      )
      .catch(this.GtUtils.errorClb)
      .finally(() => this.GtUtils.overlay('hide'));
  }

  decideWarnings(warnings: any) {
    const deferred = this.$q.defer();
    let result = true;
    for (const warning of warnings) {
      if (!confirm(warning.message)) {
        result = false;
        break;
      }
    }
    deferred.resolve(result);
    return deferred.promise;
  }

  destroy(billOfLadingId: number) {
    if (!confirm(this.gettext('Are you sure that you want delete?'))) {
      return false;
    }
    return this.BillOfLadingService.deleteBillOfLading({ id: billOfLadingId }).then(
      () => this.viewModel.pageParamsUpdated(this.queryParams),
      this.GtUtils.errorClb,
    );
  }

  openDocxModal(id: number) {
    return this.DocumentsService.generateDocxModal('BillOfLading', id);
  }

  showContractsDetails(item: any) {
    if (!item) {
      return;
    }
    return this.ContractsService.Contract.predictionsDetails({
      id: item.sale_contract,
      full_title: true,
    })
      .$promise.then((data: any) => (item.saleContractDetails = data))
      .then(
        this.ContractsService.Contract.predictionsDetails({
          id: item.purchase_contract,
          full_title: true,
        }).$promise.then((data: any) => (item.purchaseContractDetails = data)),
      );
  }

  onContractSelect(item: any) {
    return this.showContractsDetails(item);
  }

  toggleSelection(invoiceType?: any) {
    this.invoiceType = invoiceType;
    this.numberInput = !this.numberInput;
    this.selection = !this.selection;
    this.$scope.$broadcast('invoicing-toggled', {
      invoiceType: this.invoiceType,
      numberInput: this.numberInput,
      selection: this.selection,
    });
    this.invoiceCondition = 'balance';
    this.invoicePercent = 100;
  }

  finishInvoicing() {
    this.createInvoice(this.invoiceType, this.invoiceCondition, this.invoicePercent);
    this.toggleSelection();
  }

  cancelInvoicing() {
    this.toggleSelection();
  }
}

export const billOfLadingListContainer = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<',
    onUpdate: '&?',
    tableName: '<?',
    voyage: '<?',
    showControl: '<?',
    hideTotals: '<?',
    readonly: '<',
  },
  template,
  controller: BillOfLadingContainer,
};
