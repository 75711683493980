import React from 'react';

import { TextFieldRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const TextFieldCellRead = ({ value, disabled }: CellTypeProps) => {
  return (
    <div className="px-2">
      {typeof value === 'string' ? <TextFieldRead value={value} disabled={disabled} /> : null}
    </div>
  );
};
