import type { PurchaseContractList } from '~/features/deals/purchase-contracts';
import type { ContractSerializer } from '~/features/deals/shared/contracts';
import { getBaseContractColumns } from '~/features/deals/shared/contracts/columns';

export const getPurchaseContractColumns = (
  contracts: PurchaseContractList[],
  serializer: ContractSerializer,
) => {
  return getBaseContractColumns(contracts, serializer);
};
