import type { SaleContractList } from '~/features/deals/sale-contracts';
import type { ContractSerializer } from '~/features/deals/shared/contracts';
import { getBaseContractColumns } from '~/features/deals/shared/contracts/columns';

export const getSaleContractColumns = (
  contracts: SaleContractList[],
  serializer: ContractSerializer,
) => {
  return getBaseContractColumns(contracts, serializer);
};
