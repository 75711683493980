import { injectable } from '~/shared/lib/di';

import { CounterpartiesRepository } from './counterparties.repository';
import { CounterpartyCreate, CounterpartyUpdate } from '../lib';

@injectable()
export class CounterpartiesModel {
  constructor(private readonly counterpartiesRepo: CounterpartiesRepository) {}

  createCounterparties = async (entities: CounterpartyCreate[]): Promise<void> => {
    await Promise.all(entities.map(async (entity) => await this.counterpartiesRepo.create(entity)));
  };

  updateCounterparties = async (entities: CounterpartyUpdate[]) => {
    await Promise.all(entities.map((entity) => this.counterpartiesRepo.update(entity)));
  };
}
