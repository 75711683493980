import type { ContractListParams } from '~/features/deals/shared/contracts';
import type { ContractBase } from '~/shared/api';
import { contractsContractsList } from '~/shared/api';
import type { Paginated } from '~/shared/common';

export class ContractsRepository {
  query = async (query: ContractListParams): Promise<Paginated<ContractBase>> => {
    const { data } = await contractsContractsList({ query });
    return data;
  };
}
