import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { CheckIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';
import { Tag } from '~/shared/ui/kit/tag';

import { messageIntent } from '../../lib/constants';
import { type DataTypeProps } from '../../lib/types';

export const SelectWrite: React.FC<
  DataTypeProps<string> & {
    isOpen?: boolean;
    cellTemplateContext: ColumnDef['cellTemplateContext'];
  }
> = ({ message, disabled, value, valueChanged, cellTemplateContext, isOpen }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredOptions = cellTemplateContext?.options?.filter((item) =>
    String(item.value).toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const valueObj = cellTemplateContext?.options?.find((option) => option.value === value);

  return (
    <div className={cn('flex h-full w-full items-center', message && messageIntent[message.type])}>
      <DropdownMenu open={isOpen}>
        <DropdownMenuTrigger className="h-full w-full outline-none">
          {value ? (
            <Tag disabled={disabled} {...valueObj?.props}>
              {value}
            </Tag>
          ) : null}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex h-full w-max flex-col p-0">
          <Input
            type="text"
            intent="transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={(e) => e.target.focus()}
            placeholder="Search"
          />
          <DropdownMenuSeparator className="bg-transparent-lighter my-0 w-full" />
          <div className="scrollbar flex max-h-[180px] flex-col gap-0.5 overflow-y-auto">
            {filteredOptions?.map(({ value: optionValue, props }) => (
              <DropdownMenuItem
                key={optionValue}
                className={cn(
                  'hover:bg-transparent-light flex cursor-pointer items-center justify-between p-1',
                  value === optionValue && 'bg-transparent-light',
                )}
                onClick={() => valueChanged?.(String(optionValue))}
              >
                <Tag {...props}>{optionValue}</Tag>
                {value === optionValue && (
                  <CheckIcon
                    size={16}
                    className="text-text-main-secondary"
                    strokeWidth="1.33"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </DropdownMenuItem>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
