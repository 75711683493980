import React from 'react';

import { dateFormat as dateFormatFn } from '~/shared/lib/date';
import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib';

const isValidDate = (date: Date | string): date is Date =>
  date instanceof Date && !isNaN(date.getTime());

export const DateRead: React.FC<DataTypeProps<Date> & { dateFormat?: string }> = ({
  disabled,
  value,
  dateFormat = 'dd/MM/yy',
}) => {
  if (!isValidDate(value)) {
    return <span></span>;
  }

  return (
    <span className={cn('text-text-main-tertiary text-xs', disabled && 'opacity-50')}>
      {dateFormatFn(new Date(value), dateFormat)}
    </span>
  );
};
