import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export class CashflowReportService {
  $http: ng.IHttpService;
  ReportsService: ReportsService;
  dates: any;
  details_template: any;
  gettext: ng.gettext.gettextFunction;
  indicators: any;
  indicators_template: any;
  mode: any;
  nextUniqueID: any;
  periodMasks: any;
  pretifyDate: any;
  results: any;
  constructor(
    $http: ng.IHttpService,
    gettext: ng.gettext.gettextFunction,
    ReportsService: ReportsService,
  ) {
    this.$http = $http;
    this.gettext = gettext;
    this.ReportsService = ReportsService;
    this.periodMasks = {
      year: 'dd.MM.yyyy',
      quarter: 'Q [QUARTER] yyyy',
      month: 'MMM yyyy',
      week: 'WW [week starts at] dd.MM.yyyy',
      day: 'dd.MM.yyyy',
      default: 'dd.MM.yyyy',
    };
    this.nextUniqueID = 1;
  }

  getReportData(params: { mode: string; [key: string]: any }) {
    const periodMask = this.periodMasks[params.mode] || this.periodMasks.default;
    this.pretifyDate = (date: Date | string) => formatDate(date, periodMask);
    this.mode = params.mode;
    return this.$http({
      method: 'GET',
      url: '/api/reports/cashflow-new-report/',
      params: params,
    }).then((response: any) => this.prepareCashflowData(response.data));
  }

  prepareCashflowData(data: any) {
    this.indicators = data.extra_list.indicators;
    this.indicators_template = data.extra_list.indicators_template;
    this.details_template = data.extra_list.details_template;
    this.dates = data.extra_list.dates.map((v: any) => ({
      title: this.pretifyDate(v.date),
      date: v.date,
      str: v.key,
      hidden: v.hidden,
    }));
    this.results = this.unpack(data.results);
    this.setRowsVisibility(1);
    return {
      results: this.results,
      dates: this.dates,
      indicators: this.indicators,
      chartsConfigs: this.results
        .filter((v: any) => v.level === 1)
        .map((currencyData: any) => ({
          currency: currencyData.currency_symbol,
          hideChart: true,
          chartConfig: this.getChartConfig(currencyData),
        })),
    };
  }

  unpack(data: any, parent: any = {}): any {
    const result: any = [];
    for (const branch of data) {
      Object.assign(branch, {
        indicators: this.deepCopy(this.indicators_template),
        objectID: this.getID(),
      });
      branch.parent = parent;
      if (branch.data?.length) {
        result.push(branch);
        const children = this.unpack(branch.data, branch);
        if (branch.data) {
          this.indicators.forEach((ind: any) =>
            this.dates.forEach((d: any) =>
              branch.data.forEach(
                (row: any) => (branch.indicators[d.str][ind] += row.indicators[d.str][ind]),
              ),
            ),
          );
        }
        result.push(...children);
      } else {
        result.push(this.setDetails(branch.parent));
        break;
      }
    }
    return result;
  }

  setDetails(branch: any) {
    const dates: any = [];
    const details = {
      parent: branch,
      parentID: branch.objectID,
      ...this.deepCopy(this.details_template),
    };

    branch.data.forEach((row: any) => {
      const strDate = formatDate(row.date, 'yyyyMMdd');
      if (!dates.includes(strDate)) {
        dates.push(strDate);
      }
      row.parentID = branch.objectID;
      row.objectID = this.getID();
      details[strDate].push(row);
      this.indicators.forEach((ind: any) => (branch.indicators[strDate][ind] += row[ind]));
    });

    branch.children = {};
    dates.forEach((date: any) => {
      branch.children[date] = details[date].map(({ objectID, direction }: any) => ({
        objectID,
        direction,
      }));
    });
    delete branch.data;
    return details;
  }

  deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  getChartConfig(data: any) {
    return {
      labels: [],
      data: {
        Incoming: this.dates.map((v: any) => data.indicators[v.str].incoming_local.toFixed(2)),
        Outgoing: this.dates.map((v: any) => data.indicators[v.str].outgoing_local.toFixed(2)),
        Total: this.dates.map((v: any) => data.indicators[v.str].total_local.toFixed(2)),
      },
      hideChart: true,
    };
  }

  setRowsVisibility(level: number) {
    this.results.forEach((v: any, i: any) => {
      v.index = i;
      v.parentIndex = v.parent.index;
      v.expand = v.show = Boolean(v.level < level);
    });
  }

  parseGroups(groupChoices: any, groupsFilter = '') {
    return this.ReportsService.parseGroupsFromString(groupChoices, groupsFilter);
  }

  getTheadConfig(groupsTitle: any, indicatorView: any) {
    const config: any = {
      tabs: [],
      columns: [
        {
          title: groupsTitle,
          columnName: 'group_data',
          predicate: 'group_data',
          group: 'groups',
          class: '',
        },
      ],
      columnSets: [{ groupTitle: this.gettext('groups'), group: 'groups' }],
    };
    if (this.dates && this.dates.length > 1) {
      if (indicatorView === 'horizontal' || indicatorView === 'mixed') {
        config.columnSets.push({
          group: 'row_totals',
          title: 'TOTALS',
          if: '$ctrl.dates.length > 1',
        });
        config.columns.push(
          {
            group: 'row_totals',
            groupTitle: 'incoming',
            title: 'incoming',
            hide: false,
            icon: 'fa-plus',
            predicate: 'incoming',
            predicateLocal: 'incoming_local',
            class: 'positive-number',
            showCheckbox: true,
          },
          {
            group: 'row_totals',
            groupTitle: 'outgoing',
            title: 'outgoing',
            hide: false,
            icon: 'fa-minus',
            predicate: 'outgoing',
            predicateLocal: 'outgoing_local',
            class: 'negative-number',
            showCheckbox: true,
          },
          {
            group: 'row_totals',
            groupTitle: 'total',
            title: 'total',
            hide: false,
            predicate: 'total',
            predicateLocal: 'total_local',
            class: 'jasmine-alert',
            showCheckbox: false,
          },
        );
      }

      if (indicatorView === 'vertical') {
        config.columnSets.push({
          group: 'row_totals',
          if: '$ctrl.dates.length > 1',
        });
        config.columns.push({
          group: 'row_totals',
          predicate: 'row_totals',
          groupTitle: 'row totals',
          title: 'row totals',
          hide: false,
          icon: 'fa-money',
        });
      }
    }

    this.dates?.forEach((date: any) => {
      if (indicatorView === 'horizontal' || indicatorView === 'mixed') {
        config.columnSets.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          icon: 'fa-calendar',
          hidden: date.hidden,
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('incoming'),
          predicate: 'incoming',
          predicateLocal: 'incoming_local',
          title: this.gettext('incoming'),
          icon: 'fa-plus',
          class: 'positive-number',
          showCheckbox: true,
          hidden: date.hidden,
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('outgoing'),
          predicate: 'outgoing',
          predicateLocal: 'outgoing_local',
          title: this.gettext('outgoing'),
          class: 'negative-number',
          icon: 'fa-minus',
          showCheckbox: true,
          hidden: date.hidden,
        });
        config.columns.push({
          group: date.str,
          groupTitle: this.gettext(date.title),
          columnName: this.gettext('total'),
          predicate: 'total',
          predicateLocal: 'total_local',
          title: this.gettext('total'),
          class: 'jasmine-alert',
          showCheckbox: false,
          hidden: date.hidden,
        });
      }
      if (indicatorView === 'vertical') {
        config.columns.push({
          group: 'months',
          groupTitle: 'months',
          columnName: this.gettext(date.str),
          title: this.gettext(date.title),
          predicate: date.str,
        });
      }
    });
    config.columnSets.forEach((item: any) => {
      item.count = config.columns.filter((v: any) => v.group === item.group).length;
    });
    return config;
  }

  getID() {
    return this.nextUniqueID++;
  }
}
CashflowReportService.$inject = ['$http', 'gettext', 'ReportsService'];
