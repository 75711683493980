import type ng from 'angular';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';

export class ClientFilterConfigService {
  $rootScope: GtRootScopeService;
  CustomValuesService: CustomValuesService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  gtFilterService: GtFilterService;
  constructor(
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    gettext: ng.gettext.gettextFunction,
    CustomValuesService: CustomValuesService,
  ) {
    this.$rootScope = $rootScope;
    this.GtUtils = GtUtils;
    this.gtFilterService = gtFilterService;
    this.gettext = gettext;
    this.CustomValuesService = CustomValuesService;
  }

  getFilterConfig(filterLevel: string) {
    const filters: any = {
      filterLevel: filterLevel,
      clicked: false,
      search: true,
      dates: true,
      useFlexDateRange: true,
      invert_filters: true,
      dateSelects: [
        {
          argument: 'create_time',
          placeholder: this.gettext('Create date'),
        },
        {
          argument: 'date_request',
          placeholder: this.gettext('Request date'),
        },
        {
          argument: 'date_received',
          placeholder: this.gettext('Receiving date'),
        },
        {
          argument: 'renewal_date',
          placeholder: this.gettext('Renewal date'),
        },
        {
          argument: 'date_confirmation',
          placeholder: this.gettext('Confirmation date'),
        },
        {
          argument: 'future_contact',
          placeholder: this.gettext('Future contact'),
        },
        {
          argument: 'updates__future_contact',
          placeholder: this.gettext('Next update'),
        },
      ],
      selects: [],
      multiSelects: [
        {
          argument: 'id_list',
          placeholder: this.gettext('counterparty'),
          resource: 'clients.Client',
          related_fk: 'id',
          related_model: 'clients.Client',
        },
        {
          argument: 'author_list',
          placeholder: this.gettext('author'),
          resource: 'auth.user',
          related_fk: 'author',
          related_model: 'clients.Client',
        },
        {
          argument: 'responsible_list',
          placeholder: this.gettext('responsible'),
          resource: 'auth.user',
          related_fk: 'responsible',
          related_model: 'clients.Client',
        },
        {
          argument: 'responsible_for_confirmation_list',
          placeholder: this.gettext('responsible for confirm'),
          resource: 'auth.user',
          related_fk: 'responsible_for_confirmation',
          related_model: 'clients.Client',
        },
        {
          argument: 'business_unit_list',
          placeholder: this.gettext('business unit'),
          resource: 'core.BusinessUnit',
          queryParams: this.GtUtils.getBUQueryParams(),
          related_fk: 'business_units',
          related_model: 'clients.Client',
        },
        {
          argument: 'related_counterparties_list',
          placeholder: this.gettext('related counterparties'),
          resource: 'clients.Client',
          related_fk: 'related_counterparties',
          related_model: 'clients.Client',
        },
        {
          argument: 'regions_list',
          placeholder: this.gettext('region'),
          resource: 'location.region',
          related_fk: 'regions',
          related_model: 'clients.Client',
        },
        {
          argument: 'country_list',
          placeholder: this.gettext('country'),
          resource: 'location.country',
          related_fk: 'country',
          related_model: 'clients.Client',
        },
        {
          argument: 'district_list',
          placeholder: this.gettext('district'),
          resource: 'location.district',
          related_fk: 'district',
          related_model: 'clients.Client',
        },
        {
          argument: 'city_list',
          placeholder: this.gettext('city'),
          resource: 'location.city',
          related_fk: 'city',
          related_model: 'clients.Client',
        },
        {
          resource: 'clients.companygroup',
          argument: 'company_group_list',
          placeholder: this.gettext('Company group'),
          related_fk: 'company_group',
          related_model: 'clients.Client',
        },
        {
          resource: 'logistics.basis',
          argument: 'basis_list',
          placeholder: this.gettext('Basis'),
          related_fk: 'client_basis_list',
          related_model: 'clients.Client',
        },
        {
          argument: 'deliverer_list',
          placeholder: this.gettext('forwarders'),
          resource: 'clients.deliverer',
          related_fk: 'roles',
          queryParams: { role_name: 'deliverer' },
          related_model: 'clients.Client',
        },
        {
          argument: 'customclientrole_list',
          placeholder: this.gettext('custom clientrole'),
          resource: 'clients.customclientrole',
        },
        {
          resource: 'clients.clienttemplate',
          argument: 'client_template_list',
          placeholder: this.gettext('Client template'),
          related_fk: 'client_template',
          related_model: 'clients.Client',
        },
        {
          argument: 'custom_status_list',
          placeholder: this.gettext('custom status'),
          resource: 'core.CustomStatus',
          related_fk: 'custom_status',
          related_model: 'clients.Client',
          queryParams: {
            content_type__model: 'client',
          },
        },
        {
          argument: 'payment_conditions_option_list',
          placeholder: this.gettext('Payment conditions'),
          resource: 'finances.PaymentCondition',
        },
      ],
      nestedSelects: [
        this.gtFilterService.getBoolFilter('is_distributor', this.gettext('Distributor')),
        this.gtFilterService.getBoolFilter('blacklisted', this.gettext('Black listed')),
        this.gtFilterService.getBoolFilter(
          'client_data_is_checked',
          this.gettext('Client data is checked'),
        ),
        this.gtFilterService.getBoolFilter(
          'bank_data_is_checked',
          this.gettext('Bank data is checked'),
        ),
        this.gtFilterService.getBoolFilter('is_resident', this.gettext('Is resident')),
        this.gtFilterService.getBoolFilter('can_confirm', this.gettext('Can confirm')),
        this.gtFilterService.getBoolFilter('with_business_unit', this.gettext('Has Business Unit')),
        this.gtFilterService.getBoolFilter('with_custom_status', this.gettext('Has Custom status')),
        this.gtFilterService.getBoolFilter('with_role', this.gettext('With roles')),
        this.gtFilterService.getBoolFilter('with_documents', this.gettext('Has documents')),
        this.gtFilterService.getBoolFilter('local_currency', this.gettext('Local currency')),
        this.gtFilterService.getBoolFilter('foreign_currency', this.gettext('Foreign currency')),
        this.gtFilterService.getBoolFilter('producer', this.gettext('Producer')),
        this.gtFilterService.getBoolFilter('agent', this.gettext('Agent')),
        this.gtFilterService.getBoolFilter('with_date_request', this.gettext('With date request')),
        this.gtFilterService.getBoolFilter(
          'with_date_received',
          this.gettext('With date received'),
        ),
        this.gtFilterService.getBoolFilter(
          'with_date_confirmation',
          this.gettext('With date confirmation'),
        ),
        {
          argument: 'wait_my_approval',
          class: '',
          items: [{ id: this.$rootScope.user.id, title: this.gettext('Yes') }],
          placeholder: this.gettext('Waiting my approval'),
        },
        this.gtFilterService.getBoolFilter(
          'overdue_future_contact',
          this.gettext('Contact date expired'),
        ),
        this.gtFilterService.getBoolFilter('today_future_contact', this.gettext('Contact today')),
        this.gtFilterService.getBoolFilter(
          'with_grain_quality_check',
          this.gettext('Has grain quality check'),
        ),
        this.gtFilterService.getBoolFilter('with_bank_details', this.gettext('Has bank details')),
        this.gtFilterService.getBoolFilter('has_contracts', this.gettext('Has contracts')),
      ],
      nestedMultiSelects: [
        {
          argument: 'status_list',
          class: 'status-filter',
          placeholder: this.gettext('Compliance status'),
          items: [
            {
              id: 'New',
              title: this.gettext('New'),
            },
            {
              id: 'Approved',
              title: this.gettext('Approved'),
            },
            {
              id: 'Approved_condition',
              title: this.gettext('Approved condition'),
            },
            {
              id: 'Re-approval',
              title: this.gettext('Re-approval'),
            },
            {
              id: 'Out_of_approval',
              title: this.gettext('Out of approval'),
            },
            {
              id: 'Pending',
              title: this.gettext('Pending'),
            },
            {
              id: 'Rejected',
              title: this.gettext('Rejected'),
            },
          ],
        },
        {
          argument: 'approval_status_list',
          placeholder: this.gettext('Approval status'),
          items: [
            { id: 'wait', title: 'Waiting for approval' },
            { id: 'approved', title: 'Approved' },
            { id: 'process', title: 'Approval in process' },
            { id: 'rejected', title: 'Rejected' },
          ],
        },
        {
          argument: 'regular_status_list',
          placeholder: this.gettext('Status'),
          items: [
            { id: 'potencial', title: 'Potencial' },
            { id: 'active', title: 'Active' },
            { id: 'inactive', title: 'Inactive' },
            { id: 'closed', title: 'Closed' },
          ],
        },
        {
          argument: 'role_name_list',
          class: 'role-filter',
          items: [
            {
              id: 'supplier',
              title: this.gettext('Supplier'),
              icon: this.GtUtils.getIcon('clients.Supplier'),
            },
            {
              id: 'exporter',
              title: this.gettext('Exporter'),
              icon: this.GtUtils.getIcon('clients.Exporter'),
            },
            {
              id: 'farm',
              title: this.gettext('Farm'),
              icon: this.GtUtils.getIcon('clients.Farm'),
            },
            {
              id: 'elevator',
              title: this.gettext('Elevator'),
              icon: this.GtUtils.getIcon('clients.Elevator'),
            },
            {
              id: 'deliverer',
              title: this.gettext('Forwarder'),
              icon: this.GtUtils.getIcon('clients.Deliverer'),
            },
            {
              id: 'bank',
              title: this.gettext('Bank'),
              icon: this.GtUtils.getIcon('clients.Bank'),
            },
            {
              id: 'owner',
              title: this.gettext('Owner'),
              icon: this.GtUtils.getIcon('clients.Owner'),
            },
            {
              id: 'surveyor',
              title: this.gettext('Surveyor'),
              icon: this.GtUtils.getIcon('clients.Surveyor'),
            },
            {
              id: 'buyer',
              title: this.gettext('Buyer'),
              icon: this.GtUtils.getIcon('clients.Buyer'),
            },
            {
              id: 'broker',
              title: this.gettext('Broker'),
              icon: this.GtUtils.getIcon('clients.Broker'),
            },
            {
              id: 'insurer',
              title: this.gettext('Insurer'),
              icon: this.GtUtils.getIcon('clients.Insurer'),
            },
            {
              id: 'other',
              title: this.gettext('Other'),
              icon: this.GtUtils.getIcon('clients.Other'),
            },
          ],
          placeholder: this.gettext('Roles'),
        },
        this.gtFilterService.getRelationFilter('relations_list'),
        {
          argument: 'size_list',
          placeholder: this.gettext('a size'),
          items: [
            { id: 'Big', title: this.gettext('Big') },
            { id: 'Medium', title: this.gettext('Medium') },
            { id: 'Small', title: this.gettext('Small') },
          ],
        },
      ],
    };

    filters.multiSelects.push(
      {
        argument: 'type_of_activities_list',
        placeholder: this.gettext('Types of activities'),
        resource: 'core.TypeOfActivity',
        related_fk: 'type_of_activities',
        related_model: 'clients.Client',
      },
      {
        argument: 'financebank_list',
        placeholder: this.gettext('Bank'),
        resource: 'finances.FinanceBank',
        related_fk: 'paymentinfo__financebank',
        related_model: 'clients.Client',
      },
      {
        argument: 'croparea_cargo_list',
        placeholder: this.gettext('Potential crops'),
        resource: 'crops.crop',
        related_fk: 'croparea__cargo',
        related_model: 'clients.Client',
      },
      {
        argument: 'indicator_cargo_list',
        placeholder: this.gettext('Indicator crops'),
        resource: 'crops.crop',
      },
      {
        argument: 'contract_cargo_list',
        placeholder: this.gettext('Contract crops'),
        resource: 'crops.crop',
      },
    );
    this.CustomValuesService.getCustomFieldFilters({
      purpose_model: 'client',
    }).then((customFieldsFilters: any) => {
      filters.multiSelects.push(...customFieldsFilters.multiSelects);
      filters.selects.push(...customFieldsFilters.selects);
      filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
      filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
    });
    return filters;
  }
}
ClientFilterConfigService.$inject = [
  '$rootScope',
  'GtUtils',
  'gtFilterService',
  'gettext',
  'CustomValuesService',
];
