import ng from 'angular';

import template from './kpiconfirm-list-container.tpl.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('kpiconfirmListContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'gtFilterService', 'AccountsService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    AccountsService: AccountsService,
  ) {
    const vm = this;

    vm.updateReport = updateReport;
    vm.users = [];
    vm.count = 0;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'kpiconfirm-list-container';
      vm.queryParams = { ...vm.initQueryParams };
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateReport();
      });
      updateReport();
    };

    ////////////////

    function updateReport() {
      AccountsService.User.query(vm.queryParams)
        .then(function (data) {
          vm.count = data.count;
          vm.users = data.results;
        })
        .catch(GtUtils.errorClb);
    }
  }
})();
