import React from 'react';

import { isEntityOption, useEntityAutocomplete } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const EntityCellWrite = ({ value, valueChanged, typeExtra }: CellTypeProps) => {
  if (!typeExtra) {
    throw new Error(
      'EntityCellWrite requires a valid typeExtra: (input: string) => Promise<EntityOption[]>',
    );
  }

  if (!isEntityOption(value)) {
    throw new Error('EntityCellWrite requires a valid value');
  }

  const EntityAutocomplete = useEntityAutocomplete(typeExtra);

  return <EntityAutocomplete value={value} valueChanged={valueChanged} />;
};
