import type { ExportContractList } from '~/features/deals/export-contracts';
import type { ContractSerializer } from '~/features/deals/shared/contracts';
import { getBaseContractColumns } from '~/features/deals/shared/contracts/columns';

export const getExportContractColumns = (
  contracts: ExportContractList[],
  serializer: ContractSerializer,
) => {
  return getBaseContractColumns(contracts, serializer);
};
