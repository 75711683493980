import { BehaviorSubject, map, shareReplay, withLatestFrom } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { type EntityColumn, PageViewsStore } from '~/core/page-views';
import {
  ServicesContractCreate,
  ServicesContractList,
  ServicesContractUpdate,
  ServicesContractsListParams,
} from '~/features/deals/services-contracts/lib';
import { ServicesContractsModel } from '~/features/deals/services-contracts/services/services-contracts.model';
import {
  BaseContractsListParams,
  ContractSerializer,
  ViewMode,
  tableColumnsMap,
} from '~/features/deals/shared/contracts';
import { ServicesContract } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

import { ServicesContractsRepository } from './services-contracts.repository';

@injectable()
export class ServicesContractsListViewModel extends EntityListEditViewModel<
  ServicesContract,
  ServicesContractsListParams,
  ServicesContractCreate,
  ServicesContractList
> {
  resolveEntityRepo() {
    return container.resolve(ServicesContractsRepository);
  }
  private readonly entityName = 'services-contracts-page-view';
  private readonly purposeModel = 'servicescontract';

  constructor(
    private readonly servicesContractsRepo: ServicesContractsRepository,
    private readonly servicesContractsModel: ServicesContractsModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  private readonly viewModeSubj = new BehaviorSubject<ViewMode>('table');

  readonly viewMode$ = this.viewModeSubj.asObservable();

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    withLatestFrom(this.pageParamsSubj),
    map(([allProps, pageParams]) => this.filterProperties(allProps, pageParams)),
    startWith([] as EntityColumn[]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.servicesContractsRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public servicesContractsCreated = async ({
    entities,
  }: {
    entities: ServicesContractCreate[];
  }) => {
    await this.servicesContractsModel.createServicesContracts(entities);
  };

  public servicesContractDeleted = async (id: number) => {
    try {
      await this.servicesContractsRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public servicesContractsUpdated = async ({
    entities,
  }: {
    entities: ServicesContractUpdate[];
  }) => {
    try {
      await this.servicesContractsModel.updateServicesContracts(entities);
      notifySuccess('ServicesContracts updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public getInitParams() {
    const serializer: ContractSerializer = 'table_info';
    return { page_size: 25, page: 1, serializer: serializer };
  }

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: ServicesContract[]) => {
    await Promise.all(records.map(this.servicesContractsRepo.update));
    this.pageParamsChanged({});
  };

  public createServicesContract = async (servicesContract: ServicesContract) => {
    try {
      await this.servicesContractsRepo.create(servicesContract);
      notifySuccess('Services Contract created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  private filterProperties(props: EntityColumn[], pageParams: BaseContractsListParams) {
    const serializer = pageParams.serializer;
    const allowedCols = tableColumnsMap[serializer] ?? [];
    return props.filter((prop) => allowedCols.includes(prop.column_name));
  }
}
