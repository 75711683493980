import type { ServicesContractList } from '~/features/deals/services-contracts';
import type { ContractSerializer } from '~/features/deals/shared/contracts';
import { getBaseContractColumns } from '~/features/deals/shared/contracts/columns';

export const getServicesContractColumns = (
  contracts: ServicesContractList[],
  serializer: ContractSerializer,
) => {
  return getBaseContractColumns(contracts, serializer);
};
