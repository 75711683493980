import type { Column } from '@tanstack/react-table';
import type { CSSProperties } from 'react';

import type { ColumnDef } from './lib/types';

export const getStickyStyles = <T>({
  column,
  isHeader,
}: {
  column: Column<T>;
  isHeader: boolean;
}): CSSProperties => {
  const isSticky = (column.columnDef as ColumnDef<T>).sticky;

  const stickyRow = isSticky ? 1 : 0;
  const stickyHeader = isSticky ? 10 : 2;

  return {
    left: isSticky ? `${column.getStart()}px` : undefined,
    position: isSticky ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isHeader ? stickyHeader : stickyRow,
  };
};
