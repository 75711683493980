import React from 'react';

import { PhoneWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const PhoneCellWrite = ({ value, valueChanged, disabled }: CellTypeProps) => {
  return (
    <PhoneWrite
      value={typeof value === 'string' ? value : ''}
      valueChanged={valueChanged}
      disabled={disabled}
    />
  );
};
