import ng from 'angular';

import { notify } from '~/shared/lib/notify';
import { formatDate } from '~/shared/lib/utils';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('deals.requests.legacy').directive('requestsTable', directive);

  function directive() {
    return {
      template: require('./requests-table.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        requests: '<',
        count: '<',
        total: '<?',
        filterLevel: '<?',
        tableName: '<?',
        onUpdate: '&?',
        mode: '@?', // template modificator,
        approve: '&',
        cancel: '&',
        createRequest: '&',
      },
    };
  }

  Controller.$inject = [
    '$state',
    '$scope',
    'GtUtils',
    '$timeout',
    'gettext',
    'ContractsService',
    'ClientsService',
    'DocumentsService',
    'gtFilterService',
    'AccountsService',
  ];

  function Controller(
    this: any,
    $state: ng.ui.IStateService,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    $timeout: ng.ITimeoutService,
    gettext: ng.gettext.gettextFunction,
    ContractsService: ContractsService,
    ClientsService: any,
    DocumentsService: any,
    gtFilterService: GtFilterService,
    AccountsService: AccountsService,
  ) {
    const vm = this;

    vm.queryParams = { serializer: 'table_info' };
    vm.tableOptions = getTableOptions();
    vm.finalApprove = finalApprove;
    vm.cancelTicket = cancelTicket;
    vm.saveRequest = saveRequest;
    vm.openRequestModal = openRequestModal;
    vm.openEmailModal = openEmailModal;
    vm.openRoleModal = openRoleModal;
    vm.openDocxModal = openDocxModal;
    vm.createTicket = createTicket;
    vm.createChildTicket = createChildTicket;
    vm.clone = clone;
    vm.prices = [];
    vm.reverse = reverse;
    vm.openContractModal = openContractModal;
    vm.approvalAction = approvalAction;
    vm.createPassport = createPassport;
    vm.createContract = createContract;
    vm.updateData = updateData;

    vm.tableData = {};

    vm.$onInit = function () {
      vm.tableOptions = getTableOptions();
      vm.filterLevel = vm.filterLevel || 'requests-table';
      vm.tableName = vm.tableName || vm.filterLevel;
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        vm.tableOptions = getTableOptions();
      });
    };

    vm.$onChanges = function (changesObj: any) {
      if (changesObj.requests || changesObj.total) {
        $timeout(function () {
          vm.tableData = {
            rows: vm.requests || [],
            count: vm.count,
            total: vm.total || [],
          };

          vm.rows = vm.tableData.rows.reduce((options: any, item: any, index: number) => {
            options[item.id] = { index: index, editable: false };
            return options;
          }, {});
        });
      }
    };

    ////////////////

    function updateData(item?: any) {
      GtUtils.overlay('show');
      return ContractsService.Request.get(
        {
          id: item.id,
          serializer: 'full',
        },
        function (data: any) {
          const approval = item.approval;
          item = data;
          item.approval = approval;
          GtUtils.overlay('hide');
        },
      ).$promise;
    }

    function openRequestModal(item: any) {
      return ContractsService.requestModal(item).then(function () {
        vm.onUpdate();
      });
    }

    function createContract(item: any) {
      if (!confirm(gettext('Do you want to create contract?'))) {
        return;
      }
      return updateData(item).then(function () {
        if (item.stage == 'prepayment') {
          return ContractsService.contractModal({
            cargo: item.cargo,
            volume: item.volume,
            regional_manager: item.regional_manager,
            business_unit: item.business_unit,
            conclusion_date: item.conclusion_date,
            contract_number:
              item.contract_type.charAt(0).toUpperCase() +
              formatDate(new Date(), 'yyyyMMdd') +
              '-' +
              item.crop_title,
          });
        }
        GtUtils.overlay('show');
        return ContractsService.Request.createContract(
          {
            id: item.id,
          },
          function (data: any) {
            GtUtils.dismissAllModals();
            GtUtils.overlay('hide');
            $state.go('gt.page.contract', { id: data.id });
          },
          (e: any) => GtUtils.errorClb(e),
        ).$promise;
      });
    }

    function createChildTicket(item: any) {
      return ContractsService.createChildTicket(item).then(vm.onUpdate);
    }

    function openEmailModal(item: any) {
      return ClientsService.sendEmailModal('Request', item.id);
    }

    function openDocxModal(item: any) {
      return DocumentsService.generateDocxModal('Request', item.id);
    }

    function createTicket(item: any) {
      return ContractsService.createTicket(item);
    }

    function clone(item: any) {
      return ContractsService.cloneRequest(item).then(vm.onUpdate);
    }

    function reverse(item: any) {
      if (!confirm(gettext('Do you want to reverse request?'))) {
        return;
      }
      return ContractsService.Request.get(
        {
          id: item.id,
          serializer: 'full',
        },
        function (data: any) {
          return ContractsService.reverseDeal(data);
        },
      ).$promise;
    }

    function finalApprove(item: any) {
      return vm.approve({ request: item });
    }

    function cancelTicket(item: any) {
      return vm.cancel({ request: item });
    }

    function openContractModal(contract: any) {
      return ContractsService.contractModal(contract);
    }

    function createPassport(contract: any) {
      return ContractsService.createPassportContract([contract]);
    }

    function connectToPassport(item: any, passportId: number) {
      return ContractsService.connectToPassport({
        id: passportId,
        deal_id: item.id,
        stage: 'request',
        type: item.contract_type,
        volume: item.volume,
      }).then(
        function (data: any) {
          updateData(data);
          notify(gettext('Passport updated'));
          item.$_passportConnectShow = !item.$_passportConnectShow;
        },
        function (error: any) {
          ng.forEach(error.data, function (value: any, key: any) {
            notify(key + ': ' + error.data[key], 'error');
          });
        },
      );
    }

    function approvalAction(action: any, item: any) {
      return AccountsService.voteApprovable(action, item.id, item.content_type, '').then(() =>
        vm.onUpdate(),
      );
    }

    function dropAllConnections(item: any) {
      if (!confirm(gettext('Do you want to delete all passport connections?'))) {
        return;
      }

      const planResource =
        (item.contract_type == 'sale' && ContractsService.PassportSalePlan) ||
        ContractsService.PassportPurchasePlan;
      return planResource.dropByRequest(
        { request_id: item.id },
        () => updateData(),
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function openRoleModal(id: number, model?: any) {
      return ClientsService.roleModal({ id: id, model_name: model });
    }

    function createServiceContract(item: any) {
      return ContractsService.createServiceContract(item);
    }

    function saveRequest(request: any) {
      GtUtils.overlay('show');
      return ContractsService.Request.update(request)
        .$promise.then(() => updateData(), GtUtils.errorClb)
        .finally(() => GtUtils.overlay('hide'));
    }

    function getTableOptions() {
      const options: any = {
        tableName: vm.tableName || vm.filterLevel || 'requests',
        filterLevel: vm.filterLevel,
        tableClass: 'request-table',
        tableAltClass: 'request-table sticky-table-first-td',
        columnDefs: [] as any[],
        tabs: [],
        showMore: true,
        changePageSize: true,
        alwaysShowColumns: ['status'],
        configurable: true,
        applyFilters: (params: { params: QueryParams }) => {
          gtFilterService.updateQueryParams(params.params, vm.filterLevel);
        },
        templateArgs: {
          clone: (id: number) => clone(id),
          openRequestModal: (item: any) => openRequestModal(item),
          saveRequest: (item: any) => saveRequest(item),
          openDocxModal: (item: any) => openDocxModal(item),
          openEmailModal: (item: any) => openEmailModal(item),
          openRoleModal: (item: any) => openRoleModal(item),
          createTicket: (item: any) => createTicket(item),
          createChildTicket: (item: any) => createChildTicket(item),
          reverse: (item: any) => reverse(item),
          finalApprove: (item: any) => finalApprove(item),
          cancelTicket: (item: any) => cancelTicket(item),
          createServiceContract: (item: any) => createServiceContract(item),
          openContractModal: (item: any) => openContractModal(item),
          createPassport: (item: any) => createPassport(item),
          createContract: (item: any) => createContract(item),
          approvalAction: (action: any, item: any) => approvalAction(action, item),
          connectToPassport: (item: any, passportId: number) => connectToPassport(item, passportId),
          dropAllConnections: (item: any) => dropAllConnections(item),
          isTicketDisabledForPassportCreate: (item: any) =>
            ContractsService.isTicketDisabledForPassportCreate(item),

          statusClass: {
            processed: 'contract_calculate',
            new: 'contract_new',
            cancelled: 'contract_cancelled',
            rejected: 'contract_cancelled',
            executed: 'contract_executed',
            approved: 'contract_processing',
            sent: 'contract_processing',
          },
        },
      };

      options.columnDefs = [
        {
          columnName: 'status',
          title: gettext('status'),
          predicate: 'contract_number',
          class: 'td-left-align',
          classExpr: 'args.statusClass[item.request_status]',
          filters: [
            {
              type: 'select',
              predicate: 'request_status',
              label: gettext('Choose status'),
              values: {
                new: gettext('New'),
                approved: gettext('Approved'),
                rejected: gettext('Rejected'),
                executed: gettext('Executed'),
              },
            },
          ],
          cellTemplate: html`
            <!-- ITEM SETTINGS BTN -->
            <div class="space-between">
              <ul class="nav navbar-nav item-settings-dropdown">
                <li>
                  <a
                    class="dropdown-toggle user-dropdown-btn"
                    ui-sref="gt.page.request({id: item.id})"
                  >
                    <span ng-if="item.stage !='indicator'">
                      <i class="fa fa-file-text-o"></i> {[{ item.contract_number | cut:true:35:'...'
                      }]}
                      <span ng-if="!item.contract_number"> {[{item.id}]} </span>
                    </span>
                    <i class="fa fa-arrow-right hover-element hide-on-bounce"></i>
                    <i class="fa fa-arrow-right fa-bounce bounce-on-hover"></i>
                  </a>
                </li>
              </ul>
              <!-- / ITEM SETTINGS BTN -->
              <!-- ITEM SETTINGS BTN -->
              <ul
                class="nav navbar-nav item-settings-dropdown actions-btn"
                ng-mouseenter="args.setHovering(true)"
                ng-mouseleave="args.setHovering(false)"
              >
                <li>
                  <a class="dropdown-toggle user-dropdown-btn" data-toggle="dropdown">
                    <i class="fa fa-ellipsis hide-on-hover"></i>
                    <i class="fa fa-ellipsis fa-beat show-on-hover"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a ui-sref="gt.page.request({id: item.id})" class="btn btn-xs col-xs-12">
                        <i class="fa fa-arrow-right"></i> <translate>Details</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        ng-disabled="item.request_status=='lost' || item.request_status=='executed' || (item.request_status=='approved' && $root.user.settings.TICKET_DENY_EDIT_AFTER_APPROVE)"
                        permission
                        permission-only="'change_request'"
                        class="btn btn-xs col-xs-12"
                        ng-click="args.openRequestModal(item)"
                      >
                        <i class="fa fa-pencil-square"></i> <translate>Edit</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        class="btn btn-xs col-xs-12"
                        ng-click="args.rows[item.id].editable = true"
                        ng-show="$root.user.settings.ENABLE_INLINE_ADD"
                        ng-disabled="item.request_status=='lost' || item.request_status=='executed' || (item.request_status=='approved' && $root.user.settings.TICKET_DENY_EDIT_AFTER_APPROVE)"
                      >
                        <i class="fa fa-pencil-square-o"></i> <translate>Quick edit</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'edit_closed_elements'"
                        class="btn btn-xs col-xs-12"
                        ng-click="args.openRequestModal(item)"
                      >
                        <i class="fa fa-user-secret"></i> <translate>Edit (admin)</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        ng-click="args.clone(item)"
                        permission
                        permission-only="'can_clone_contract'"
                        ng-if="item.id"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'clone'|translate }]}"
                      >
                        <i class="fa fa-files-o"></i> <translate>Clone</translate>
                      </a>
                    </li>
                    <li class="horizontal-divider"></li>
                    <li>
                      <a
                        permission
                        permission-only="'create_ticket_from_offer'"
                        ng-if="item.stage == 'indicator'"
                        ng-disabled="item.request_status != 'approved'"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Bite off some volume from this Indicator to create a Ticket'|translate }]}"
                        ng-click="args.createTicket(item)"
                      >
                        <i class="fa fa-chain-broken"></i> <translate>Create ticket</translate>
                      </a>
                    </li>
                    <li ng-if="item.approval_config_id">
                      <a
                        class="btn btn-xs col-xs-12"
                        ng-disabled="!item.approval_user_ids.includes($root.user.id)"
                        ng-click="args.approvalAction('approve', item);"
                      >
                        <i class="fa fa-thumbs-up"></i> <translate>Approve</translate>
                      </a>
                    </li>
                    <li ng-if="item.approval_config_id">
                      <a
                        class="btn btn-xs col-xs-12"
                        ng-disabled="!item.approval_user_ids.includes($root.user.id)"
                        ng-click="args.approvalAction('decline', item);"
                      >
                        <i class="fa fa-thumbs-down"></i> <translate>Decline</translate>
                      </a>
                    </li>
                    <li ng-if="item.approval_config_id">
                      <a
                        class="btn btn-xs col-xs-12"
                        ng-disabled="item.approval_status != 'rejected'"
                        ng-click="args.approvalAction('reactivate', item);"
                      >
                        <i class="fa fa-refresh"></i> <translate>Reactivate approving</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'approve_request'"
                        ng-if="(item.request_status == 'new' || item.request_status == 'processed') && item.stage != 'indicator'"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Approve'|translate }]}"
                        ng-click="args.finalApprove(item);"
                      >
                        <i class="fa fa-check-circle"></i> <translate>Final approve</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'cancel_request'"
                        ng-if="item.stage != 'indicator' && !item.descendants.length"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Cancel'|translate }]}"
                        ng-click="args.cancelTicket(item);"
                      >
                        <i class="fa fa-times"></i> <translate>Cancel ticket</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'add_request'"
                        ng-if="item.stage === 'ticket'"
                        ng-disabled="item.from_request"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Create Ticket from this Ticket'|translate }]}"
                        ng-click="args.createChildTicket(item);args.close()"
                      >
                        <i class="fa fa-file-text-o"></i> <translate>Create new ticket</translate>
                      </a>
                    </li>
                    <li>
                      <a
                        permission
                        permission-only="'add_contractbase'"
                        ng-if="item.stage == 'ticket' && !item.multicontract"
                        ng-disabled="item.request_status != 'approved'"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Create Contract from this Ticket. Ticket will become Executed'|translate }]}"
                        ng-click="args.createContract(item);args.close()"
                      >
                        <i class="fa fa-file-text"></i> <translate>Create contract</translate>
                      </a>
                    </li>
                    <li class="horizontal-divider"></li>
                    <li>
                      <a
                        ng-if="item.stage == 'ticket'"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Generate an email'|translate }]}"
                        ng-click="args.openEmailModal(item);"
                      >
                        <i class="fa fa-envelope"></i> <translate>Business confirmation</translate>
                      </a>
                      <a
                        class="btn btn-xs col-xs-12 tooltip"
                        ng-if="item.stage == 'ticket'"
                        data-tip="{[{ 'Generate document'|translate }]}"
                        ng-click="args.openDocxModal(item)"
                      >
                        <i class="fa fa-file" aria-hidden="true"></i>
                        <translate>Document</translate>
                        <translate>Template</translate>
                      </a>
                    </li>
                    <li class="horizontal-divider"></li>

                    <li
                      ng-if="
                      item.deal_type !== 'intermediate' &&
                      item.deal_type !== 'services' &&
                      item.deal_type !== 'export'
                  "
                    >
                      <a
                        class="dropdown-toggle user-dropdown-btn"
                        ng-click="item.DropdownPassport = !item.DropdownPassport"
                        ng-disabled="item.readonly"
                      >
                        <span class="initial-transform">
                          <i class="fa fa-exchange"></i> <translate>Allocation</translate>
                        </span>
                        <i class="fa fa-sort-desc hover-element"></i>
                      </a>
                    </li>
                    <li ng-if="item.DropdownPassport">
                      <a
                        permission
                        permission-only="'add_request'"
                        ng-disabled="args.isTicketDisabledForPassportCreate(item)"
                        ng-click="args.reverse(item)"
                        ng-if="item.id && item.stage == 'ticket'"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ item.contract_type == 'sale' && 'Purchase this' || 'Sale this' | translate }]}"
                      >
                        <i class="fa fa-exchange"></i>
                        <span ng-if="item.contract_type == 'sale'">
                          <translate>Connect to purchase</translate>
                        </span>
                        <span ng-if="item.contract_type == 'purchase'">
                          <translate>Connect to sale</translate>
                        </span>
                      </a>
                    </li>
                    <li ng-if="item.DropdownPassport">
                      <a
                        permission
                        permission-only="'add_request'"
                        ng-if="item.id && item.stage == 'ticket'"
                        ng-click="args.createPassport(item)"
                        ng-disabled="args.isTicketDisabledForPassportCreate(item)"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Create a new passport' |translate }]}"
                      >
                        + <i class="fa fa-exchange"></i> {[{ 'Create passport' |translate }]}
                      </a>
                    </li>
                    <li ng-if="item.DropdownPassport">
                      <a
                        permission
                        permission-only="'add_passport'"
                        ng-if="item.id && item.stage == 'ticket' && !item.$_passportConnectShow"
                        ng-disabled="args.isTicketDisabledForPassportCreate(item)"
                        ng-click="item.$_passportConnectShow = true;"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Connect to existing passport' |translate }]}"
                      >
                        <i class="fa fa-exchange"></i> {[{ 'Connect to passport' | translate }]}
                      </a>
                    </li>
                    <li ng-hide="!item.$_passportConnectShow">
                      <gt-resource-select
                        ng-model="item.$_passportId"
                        placeholder="'Passport'|translate"
                        resource-name="'passports.passport'"
                        allow-clear="true"
                        on-select="args.connectToPassport"
                        on-select-args="[item, item.$_passportId]"
                        query-params="args.passportConnectParams"
                      ></gt-resource-select>
                    </li>
                    <li ng-if="item.DropdownPassport">
                      <a
                        ng-if="item.id && item.stage == 'ticket'"
                        ng-click="args.dropAllConnections(item)"
                        ng-disabled="item.connections.length == 0"
                        class="btn btn-xs col-xs-12 tooltip"
                        data-tip="{[{ 'Drop all connections to passport' |translate }]}"
                      >
                        <i class="fa fa-exchange"></i> {[{ 'Drop connections' |translate }]}
                      </a>
                    </li>
                    <li>
                      <a class="btn btn-xs aligned-btn" ng-click="args.createServiceContract(item)">
                        <i class="fa fa-truck"></i> <translate>Create Service Request</translate>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!-- / ITEM SETTINGS BTN -->
            <div ng-if="args.rows[item.id].editable || !item.id" class="edit">
              <a
                class="btn btn-xs btn-success btn_success col-xs-12"
                ng-click="args.saveRequest(item); args.rows[item.id].editable = false;"
              >
                <i class="fa fa-floppy-o"></i>
              </a>
              <div class="col-xs-12"></div>
              <a class="btn btn-xs col-xs-12" ng-click="args.rows[item.id].editable=false">
                <i class="fa fa-times"></i>
              </a>
            </div>
          `,
          totalTemplate: html`
            <i class="fa-solid fa-chart-simple"></i> <translate>TOTAL</translate>
          `,
        },
        {
          columnName: 'multirequest',
          class: 'td-left-align',
          title: gettext('multirequest'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': !item.multicontract}"
              >
                <span class="label" style="margin-left: 2px" ng-if="item.multicontract">
                  <a ui-sref="gt.multiticket({id: item.multicontract })">
                    <i class="fa fa-clipboard"></i>
                    {[{ item.multicontract_number | cut:true:40:'...' || "---" }]}
                  </a>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'multirequest_conclusion_date',
          class: 'td-left-align',
          title: gettext('Conclusion date'),
          cellTemplate: html`
            <span class="bigger-label" ng-class="{'label-opacity': !item.multicontract}">
              <i class="fa fa-calendar"></i> {[{ item.contract_conclusion_date || "---" |
              date:'dd.MM.yy'}]}
            </span>
          `,
        },
        {
          columnName: 'multirequest_custom_status',
          class: 'td-left-align',
          title: gettext('Custom status'),
          cellTemplate: html`
            <span
              ng-if="item.contract_custom_status_title"
              class="label"
              ng-class="{ 'label_calculate': item.contarct_custom_status_codename == 'washout', 'label_success': item.contarct_custom_status_codename == 'new', 'label-default': item.contarct_custom_status_codename == 'cancelled', 'label_default': item.contarct_custom_status_codename == 'executed', 'label_warning': item.contarct_custom_status_codename == 'shipment' } "
            >
              {[{ item.contract_custom_status_title }]}
            </span>
          `,
        },
        {
          columnName: 'type_of_activities',
          title: gettext('type of activities'),
          class: 'td-left-align small-font-cell',
          cellTemplate: html`
            <span class="label" ng-if="item.type_of_activities_data">
              <div ng-repeat="item in item.type_of_activities_data">{[{item.title}]}</div>
            </span>
          `,
        },
        {
          columnName: 'custom_status',
          title: gettext('custom status'),
          class: 'td-left-align small-font-cell',
          cellTemplate: html`
            <span
              ng-if="item.custom_status_title"
              class="label"
              ng-class="{ 'label_calculate': item.custom_status_codename == 'washout', 'label_success': item.custom_status_codename == 'new', 'label-default': item.custom_status_codename == 'cancelled', 'label_default': item.custom_status_codename == 'executed', 'label_warning': item.custom_status_codename == 'shipment' } "
            >
              {[{ item.custom_status_title }]}
            </span>
          `,
        },
        {
          columnName: 'responsible',
          class: 'td-left-align',
          title: gettext('responsible'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.responsible"
                placeholder="'Responsible'|translate"
                resource-name="'auth.User'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="tooltip"
                  style="margin-left: 2px"
                  data-tip="{[{ 'responsible' | translate }]} - {[{ item.responsible_first_name }]} {[{ item.responsible_last_name }]}"
                >
                  <div
                    class="user-avatar"
                    ng-if="item.responsible_first_name && item.responsible_avatar.length != 0"
                  >
                    <img ng-src="/pictures/{[{item.responsible_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="item.responsible_avatar.length == 0">
                    {[{ item.responsible_first_name | cut:true:1:' ' }]}
                  </div>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'approval_status',
          class: 'td-left-align',
          title: gettext('approval status'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="label"
                  ng-if="item.approval_status != 'wait'"
                  ng-class="{'label_success': item.approval_status == 'approved', 'label_warning': item.approval_status == 'process', 'label_danger': item.approval_status == 'rejected' }"
                  style="margin-left: 2px"
                >
                  <i
                    class="fa"
                    ng-class="{'fa-check': item.approval_status == 'approved', 'fa-spin fa-spinner': item.approval_status == 'process', 'fa-times': item.approval_status == 'rejected' }"
                  ></i>
                  <translate>{[{ item.approval_status }]}</translate>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'chain',
          class: 'td-left-align',
          title: gettext('chain'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="label"
                  ng-class="{ 'label_success': item.request_status =='new', 'label_warning': item.request_status =='approved' || item.request_status == 'processed' , 'label_calculate': item.request_status =='sent', 'label_danger': item.request_status =='cancelled', 'label_default': item.request_status =='executed' }"
                  style="margin-right: 5px"
                >
                  {[{ item.request_status }]}
                </span>
                <span
                  ng-if="item.from_request"
                  class="label tooltip"
                  style="margin-left: 5px"
                  data-tip="{[{ 'This ticket was made from'|translate}]} #{[{ item.from_request_title }]}"
                >
                  <a ui-sref="gt.page.request({id: item.from_request})" class="btn-link">
                    <span> <i class="fa fa-file-o"></i> </span> {[{ item.from_request_title |
                    cut:true:30:'...' || "---" }]}
                  </a>
                </span>
                <span ng-if="item.descendants.length" class="label" style="margin-left: 2px">
                  <a
                    class="btn-link"
                    ng-if="item.stage == 'indicator'"
                    ng-repeat="desc in item.descendants"
                    style="margin-left: 5px"
                    ui-sref="gt.page.request({id: desc.id})"
                  >
                    <i class="fa fa-file-text-o"></i> {[{ desc.contract_number || desc.id |
                    cut:true:30:'...' || "---" }]}
                  </a>
                  <a
                    class="btn-link"
                    style="margin-left: 5px"
                    ng-if="item.stage == 'ticket'"
                    ng-repeat="desc in item.descendants"
                    ui-sref="gt.page.contract({id: desc.id})"
                  >
                    <i class="fa fa-file-text"></i> {[{ desc.contract_number || desc.id |
                    cut:true:30:'...' || "---" }]}
                  </a>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'parent_indicator_price',
          class: 'td-left-align',
          title: gettext('Parent indicator price'),
          cellTemplate: html`
            <span ng-if="item.parent_indicator_price">
              {[{ item.parent_indicator_price }]} {[{ item.parent_indicator_price_symbol }]}/ {[{
              $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
            <span ng-if="!item.parent_indicator_price"> --- </span>
          `,
        },
        {
          columnName: 'price_indicative',
          class: 'td-left-align',
          title: gettext('Price (with costs, without VAT)'),
          cellTemplate: html`
            <span> {[{ item.price_indicative || "---"}]} </span>
            <span class="smaller-label" ng-if="item.price_indicative">
              {[{item.price_indicative_currency_symbol || item.currency_symbol || 'USD' }]}/ {[{
              $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          `,
        },
        {
          columnName: 'price_indicative_with_vat',
          class: 'td-left-align',
          title: gettext('Price (with costs, with VAT)'),
          cellTemplate: html`
            <span> {[{ item.price_indicative_with_vat || "---"}]} </span>
            <span class="smaller-label" ng-if="item.price_indicative_with_vat">
              {[{item.price_indicative_currency_symbol || item.currency_symbol || 'USD' }]}/ {[{
              $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          `,
        },
        {
          columnName: 'price_indicative_default_currency',
          class: 'td-left-align',
          title: gettext('Price (with costs, without VAT), $'),
          cellTemplate: html`
            <span> {[{ item.price_indicative_default_currency || "---"}]} </span>
            <span class="smaller-label" ng-if="item.price_indicative_default_currency">
              $ / {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
            </span>
          `,
        },
        {
          columnName: 'passport',
          class: 'td-left-align',
          title: gettext('passport'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-repeat="conn in item.connections" style="margin-left: 2px">
                  <a class="tooltip btn-link" ui-sref="gt.page.passport({id: conn.passport })">
                    <i class="fa fa-exchange"></i> {[{conn.passport_title | cut:true:30:'...' ||
                    "---" }]}
                  </a>
                  <div class="clearfix"></div>
                </span>
                <div class="clearfix"></div>
              </span>
            </div>
          `,
        },
        {
          columnName: 'connected',
          class: 'td-left-align',
          title: gettext('Connected'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="smaller-label tooltip"
                  data-tip="{[{ 'the volume, connected to passports'|translate }]}"
                  ng-if="item.total_connected && (item.total_connected != (item.final_volume || item.volume))"
                >
                  <i class="fa fa-exchange"></i> {[{ item.total_connected || 0 | gtDecimal:2 }]}
                  <span ng-if="(item.final_volume || item.volume) - item.total_connected > 0">
                    ({[{ (item.final_volume || item.volume) - item.total_connected || 0 |
                    gtDecimal:2}]})
                  </span>
                  <span class="smaller-label">
                    {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
                <span
                  class="smaller-label tooltip"
                  data-tip="{[{ 'fully connected to passports'|translate }]}"
                  ng-if="item.total_connected && (item.total_connected == (item.final_volume || item.volume))"
                >
                  <i class="fa fa-exchange"></i> <i class="fa fa-check-circle"></i>
                </span>
              </span>
            </div>
          `,
          totalTemplate: html`
            <span ng-if="item.total.connected">
              <i class="fa fa-exchange"></i> {[{ item.total.connected || 0 | number:3 }]}
              <div class="clearfix"></div>
              <span ng-if="item.true_volume_sum - item.connected != 0">
                ({[{ item.true_volume_sum - item.connected || 0 | number:3 }]})
              </span>
            </span>
          `,
        },
        {
          columnName: 'supplier',
          title: gettext('supplier'),
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'supplier',
              label: gettext('supplier'),
              resource: 'clients.Supplier',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <!-- SUPPLIER -->
                <div class="clearfix"></div>
                <span
                  ng-if="item.supplier_name"
                  ng-class="{'label label_danger': item.supplier_blacklisted}"
                >
                  <a
                    ng-click="args.openRoleModal(item.supplier, 'Supplier')"
                    ng-if="item.contract_type =='purchase' && !item.potential_supplier_set"
                    ng-class="{'bigger-label': $root.user.settings.DEFAULT_VALUES['hide_owner']}"
                    class="btn-link"
                  >
                    <i class="fa fa-bookmark-o"></i> {[{item.supplier_name | cut:true:30:'...' ||
                    "---" }]}
                    <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                  </a>
                  <a
                    ng-click="args.openRoleModal(item.supplier, 'Owner')"
                    ng-if="item.contract_type =='sale' && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
                    class="btn-link"
                  >
                    <i class="fa fa-home"></i> {[{item.supplier_name | cut:true:30:'...' || "---"
                    }]}
                    <i class="fa fa-check-circle" ng-if="item.supplier_date_confirmation"></i>
                  </a>
                </span>
                <div class="clearfix"></div>
                <span class="exporter_set" ng-if="item.potential_supplier_set">
                  <span ng-repeat="supplier in item.potential_supplier_set">
                    <i class="fa fa-bookmark-o"></i> {[{ supplier.name | cut:true:25:' ...'}]}
                  </span>
                </span>
                <div class="clearfix"></div>
                <span
                  class="label-opacity"
                  ng-if="!item.supplier_name && !item.potential_supplier_set && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
                >
                  <i class="fa fa-bookmark-o"></i> ---
                </span>
                <div class="clearfix"></div>
                <span class="exporter_set" ng-if="item.exporter_set.length > '0'">
                  <span ng-repeat="exporter in item.exporter_set">
                    <a
                      class="smaller-label"
                      ng-class="{ 'label_danger': exporter.blacklisted}"
                      ng-click="vm.openRoleModal(exporter.id, 'Exporter')"
                    >
                      <i class="fa fa-shield"></i> {[{ exporter.name | cut:true:25:' ...'}]}
                      <i class="fa fa-check" ng-if="exporter.date_confirmation"></i>
                      <div class="clearfix"></div>
                    </a>
                  </span>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'buyer',
          title: gettext('buyer'),
          class: 'td-left-align',
          filters: [
            {
              type: 'ui-select',
              predicate: 'buyer',
              label: gettext('buyer'),
              resource: 'clients.Buyer',
            },
          ],
          cellTemplate: html`
          <div ng-if="!args.rows[item.id].editable && item.id">
          <span
            ng-if="!item.$_edit"
            ng-class="{'label-opacity': item.volume == 0}"
          >
          <span
          ng-if="item.buyer_name"
          ng-class="{'label label_danger': item.buyer_blacklisted}"
        >
          <a
            ng-click="args.openRoleModal(item.buyer, 'Buyer')"
            ng-if="item.contract_type =='sale' && !item.potential_buyer_set"
            class="btn-link"
          >
            <i class="fa fa-bookmark"></i> {[{ item.buyer_name | cut:true:30:'...' || "---" }]}
            <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
          </a>
          <a
            ng-click="args.openRoleModal(item.buyer, 'Owner')"
            ng-if="item.contract_type =='purchase' && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
            class="btn-link"
          >
            <i class="fa fa-home"></i> {[{ item.buyer_name | cut:true:30:'...' || "---" }]}
            <i class="fa fa-check-circle" ng-if="item.buyer_date_confirmation"></i>
          </a>
        </span>
        <span
          class="label-opacity"
          ng-if="!item.buyer_name && !item.potential_buyer_set  && !$root.user.settings.DEFAULT_VALUES['hide_owner']"
        >
          <i class="fa fa-bookmark"></i> ---
        </span>
        <div class="clearfix"></div>
        <span class="exporter_set" ng-if="item.potential_buyer_set">
          <span ng-repeat="buyer in item.potential_buyer_set">
            <i class="fa fa-bookmark"></i> {[{ buyer.name | cut:true:25:' ...'}]}
          </span>
        </span>
          </div>
          `,
        },
        {
          columnName: 'broker',
          title: gettext('broker'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-if="item.broker_name">
                  <i class="fa fa-briefcase"></i> {[{ item.broker_name || '---'}]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.agent_name">
                  <i class="fa fa-suitcase"></i> {[{ item.agent_name || '---'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'cargo',
          title: gettext('product'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.cargo"
                placeholder="'Product'|translate"
                resource-name="'crops.Crop'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="bigger-label"
                  ng-if="item.crop_title && !item.contractprices_data.length"
                >
                  {[{ item['crop_' + [$root.user.settings.CROP_TITLE_DISPLAY_MODE]] ||
                  item.crop_title }]}
                </span>
                <span ng-repeat="cargo in item.contractprices_data | unique:'cargo_title' || []">
                  <span class="bigger-label">{[{ cargo.cargo_title }]}</span>
                  <div class="clearfix"></div>
                </span>
                <span ng-if="item.charge_title">
                  {[{ $root.user.profile.language === 'en' && item.charge_title_eng ||
                  item.charge_title || '---'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'volume',
          title: gettext('volume'),
          class: 'td-right-align',
          predicate: 'volume',
          filters: [
            {
              type: 'text',
              predicate: 'min_volume',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_volume',
              addon: gettext('Max'),
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input
                class="form-control"
                type="number"
                ng-model="item.volume"
                placeholder="{[{ 'Volume' | translate }]}"
              />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                {[{ item.volume || 0 | gtDecimal:2 }]}
                <span class="smaller-label">
                  {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                </span>
              </span>
            </div>
          `,
          totalTemplate: html`
            <div ng-if="item.volume">
              {[{ item.volume || 0 | gtDecimal:2 }]}
              <span style="font-size: 0.8em">
                {[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'options',
          title: gettext('option'),
          hint: gettext('the actual tolerance range of the contract'),
          class: 'td-right-align',
          cellTemplate: html`
            <div ng-if="item.volume">
              <span ng-if="item.volume_options">
                <div class="clearfix"></div>
                {[{ item.volume_options || 0 | number:1}]}% - {[{ (item.volume * 1) - ((item.volume
                * 1) * item.volume_options / 100) || 0 | number:3 }]} + {[{ (item.volume * 1) +
                ((item.volume * 1) * item.volume_options / 100) || 0 | number:3 }]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'cp_approval_status',
          class: 'td-right-align',
          title: gettext('CP approval status'),
          cellTemplate: html`
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="label"
                  ng-class="{'label_success': item.counterparty_approval_status == 'approved', 'label_warning': item.counterparty_approval_status == 'process', 'label_danger': item.counterparty_approval_status == 'rejected' }"
                  style="margin-left: 2px"
                >
                  <i
                    class="fa"
                    ng-class="{'fa-check': item.counterparty_approval_status == 'approved', 'fa-spin fa-spinner': item.counterparty_approval_status == 'process', 'fa-times': item.counterparty_approval_status == 'rejected' }"
                  ></i>
                  <translate>{[{ item.counterparty_approval_status }]}</translate>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'price',
          title: gettext('price'),
          class: 'td-right-align',
          predicate: 'price_in_USD',
          hint: gettext('the value of the deal (except charges)'),
          filters: [
            {
              type: 'text',
              predicate: 'min_price',
              addon: gettext('Min'),
            },
            {
              type: 'text',
              predicate: 'max_price',
              addon: gettext('Max'),
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input
                class="form-control"
                type="number"
                ng-model="item.price"
                placeholder="{[{ 'Price' | translate }]}"
              />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span class="bigger-label" ng-if="item.price">
                  {[{ item.price || 0 | number:2 }]}
                  <span class="smaller-label"
                    >{[{ item.currency_symbol || 'USD' }]}/ {[{
                    $root.user.settings.MAIN_MEASUREMENT_UNIT }]}
                  </span>
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.measurement_price  && item.measurement_price > 0">
                  {[{ item.measurement_price }]} {[{ item.currency_symbol ||
                  $root.user.settings.DEFAULT_CURRENCY}]}/{[{ item.measurement_title }]}
                </span>
                <div class="clearfix"></div>
                <span ng-if="item.measurement_price_kg && item.measurement_price_kg > 0">
                  {[{ item.measurement_price_kg }]} {[{ item.currency_symbol ||
                  args.defaultCurrency}]}/kg
                </span>
                <div class="clearfix"></div>
                <span ng-if="!item.price">---</span>
                <div class="clearfix"></div>
                <span
                  ng-if="item.contractprices_data.length"
                  ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
                  class="smaller-label label label_default"
                >
                  <i class="fa fa-anchor"></i> {[{item.contractprices_data.length}]}
                </span>
                <div class="clearfix"></div>
                <contract-prices-info
                  ng-if="item.contractprices_data && item.contractpricesExpanded"
                  ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
                  contract-prices="item.contractprices_data"
                ></contract-prices-info>
              </span>
            </div>
          `,
          totalTemplate: html`
            <div ng-if="item.total_value">
              {[{ item.total_value || 0 | gtDecimal:2 }]}
              <span style="font-size: 0.8em"> <translate>$</translate> </span>
            </div>
          `,
        },
        {
          columnName: 'currency_exchange_rate',
          class: 'td-left-align',
          title: gettext('exchange rate'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span class="bigger-label">
                  {[{ item.currency_exchange_rate || '---' | number:6 }]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'generalagreement_status',
          class: 'td-left-align',
          title: gettext('Agreement approval status'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="!item.$_edit" class="bigger-label">
                <span
                  class="label"
                  ng-class="{'label_success': item.generalagreement_status == 'approved', 'label_warning': item.generalagreement_status == 'process', 'label_danger': item.generalagreement_status == 'rejected' }"
                >
                  <i
                    class="fa"
                    ng-class="{'fa-check': item.generalagreement_status == 'approved', 'fa-spin fa-spinner': item.generalagreement_status == 'process', 'fa-times': item.generalagreement_status == 'rejected' }"
                  ></i>
                  <translate>{[{ item.generalagreement_status }]}</translate>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'generalagreement',
          class: 'td-left-align',
          title: gettext('general agreement'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.general_agreement"
                placeholder="'General agreement'|translate"
                resource-name="'contracts.GeneralAgreement'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span class="bigger-label" ng-if="item.generalagreement_number">
                  <i class="fa fa-handshake-o"></i> {[{ item.generalagreement_number }]}
                  <span ng-if="item.generalagreement_percent_conditions">
                    {[{ item.generalagreement_percent_conditions }]} %
                  </span>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'business_unit',
          class: 'td-left-align',
          title: gettext('Business unit'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.business_unit"
                placeholder="'Business unit'|translate"
                resource-name="'core.BusinessUnit'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-if="item.business_unit_title">
                  {[{ item.business_unit_title | cut:true:30:' ...' }]}
                </span>
                <span ng-if="!item.business_unit_title"> --- </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'charges',
          class: 'td-left-align',
          title: gettext('charges'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-class="{ 'label-opacity': !item.total_charges_local}">
                  {[{ item.total_charges_local || 0 | number:2 }]}
                </span>
                <span ng-class="{ 'label-opacity': !item.total_charges}">
                  / $ {[{ item.total_charges || 0 | number:2 }]}
                </span>
              </span>
            </div>
          `,
          totalTemplate: html`
            <div ng-if="item.total_charges_local">
              <span
                style="font-size: 0.8em"
                ng-class="{ 'label-opacity': item.total_charges_local == 0 }"
              >
                {[{ item.total_charges_local || 0 | number:2 }]}
              </span>
              <span
                style="font-size: 0.8em"
                ng-class="{ 'label-opacity': item.total_charges == 0 }"
              >
                / $ {[{ item.total_charges || 0 | number:2 }]}
              </span>
            </div>
          `,
        },
        {
          columnName: 'basis',
          class: 'td-left-align',
          title: gettext('basis'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.basis"
                placeholder="'Basis'|translate"
                resource-name="'logistics.Basis'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="label label_default"
                  ng-if="item.basis_name && !item.contractprices_data.length"
                >
                  {[{ item.basis_name }]}
                </span>
                <span ng-repeat="basis in item.contractprices_data | unique:'basis_name' || []">
                  <span class="label label_default">{[{ basis.basis_name }]}</span>
                  <div class="clearfix"></div>
                </span>
                <div class="clearfix"></div>
                <span
                  class="smaller-label"
                  ng-if="item.origin_of_crop_title && $root.user.settings.DEFAULT_VALUES['field_origin_crop']"
                >
                  <i class="fa fa-level-up"></i> {[{ item.origin_of_crop_title }]}
                </span>
                <span
                  class="smaller-label"
                  ng-if="item.destination_of_crop_title && $root.user.settings.DEFAULT_VALUES['field_destination_crop']"
                >
                  <i class="fa fa-level-down"></i> {[{ item.destination_of_crop_title }]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'port',
          class: 'td-left-align',
          title: gettext('port'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-if="item.market_zone_name"
                  >MZ <i class="fa fa-anchor"></i> {[{ item.market_zone_name |
                  cut:true:30:'...'}]}</span
                >
                <div class="clearfix"></div>
                <span
                  ng-if="item.contractprices_data.length"
                  ng-click="item.contractpricesExpanded = !item.contractpricesExpanded"
                  class="smaller-label label label_default"
                >
                  <i class="fa fa-anchor"></i> {[{item.contractprices_data.length}]}
                </span>
                <div class="clearfix"></div>
                <span
                  ng-repeat="port in item.contractprices_data | unique:'port_name'"
                  ng-if="item.contractpricesExpanded"
                >
                  <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE]
                  || port.port_name | cut:true:30:'...'}]}
                  <div class="clearfix"></div>
                </span>
                <span
                  ng-if="!item.contractprices_data.length"
                  ng-repeat="port in item.port_set | unique:'name'"
                >
                  <i class="fa fa-anchor"></i> {[{ port[$root.user.settings.PORT_NAME_DISPLAY_MODE]
                  || port.name | cut:true:30:'...'}]}
                  <div class="clearfix"></div>
                </span>
                <div class="clearfix"></div>
                <!-- TERMINAL -->
                <span
                  ng-if="item.shipment_terminal_name"
                  class="tooltip btn-link"
                  data-tip="{[{ item.shipment_terminal_name }]}"
                >
                  <i class="fa fa-anchor"></i> {[{ item.shipment_terminal_name | cut:true:30:'...'
                  || "---" }]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'warehouse',
          title: gettext('warehouse'),
          class: 'td-left-align small-font-cell',
          cellTemplate: html`
            <div class="clearfix"></div>
            <a
              class="btn btn-xs col-xs-12"
              target="_blank"
              class="smaller-label"
              ng-if="item.warehouse_data.id"
              ui-sref="logistics.warehouseDetails({id: item.warehouse_data.id })"
            >
              <i class="fa fa-building-o"></i> {[{ item.warehouse_data.title |
              cut:true:30:'...'}]}({[{ item.warehouse_data.type }]})
            </a>
            <div class="clearfix"></div>
            <span
              ng-if="item.warehouses_data.length"
              ng-click="item.warehousesExpanded = !item.warehousesExpanded"
              class="smaller-label label label_default"
            >
              <i class="fa fa-building-o"></i> {[{item.warehouses_data.length}]}
            </span>
            <div class="clearfix"></div>
            <span
              ng-if="item.warehousesExpanded"
              class="smaller-label"
              ng-repeat="warehouse in item.warehouses_data | unique:'title'"
            >
              <a
                class="btn btn-xs col-xs-12"
                target="_blank"
                ui-sref="logistics.warehouseDetails({id: warehouse.id })"
                style="text-align: left"
              >
                <i class="fa fa-building-o"></i> {[{ warehouse.title | cut:true:30:'...'}]}({[{
                warehouse.type }]})
              </a>
              <div class="clearfix"></div>
            </span>
          `,
        },
        {
          columnName: 'comment',
          class: 'td-left-align',
          title: gettext('comment'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <input
                class="form-control"
                type="text"
                ng-model="item.additional_info"
                placeholder="{[{ 'Note' | translate }]}"
              />
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="tooltip"
                  data-tip="{[{item.additional_info}]}"
                  ng-if="item.additional_info"
                >
                  <i class="fa fa-comment"></i> {[{item.additional_info | cut:true:30:' ...'}]}
                </span>
                <span class="label-opacity" ng-if="!item.additional_info">
                  <i class="fa fa-comment"></i> ---
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'execution',
          class: 'td-left-align',
          title: gettext('delivery'),
          predicate: 'date_execution',
          hint: gettext('date range while the proposition is actual'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span
                  class="tooltip"
                  ng-if="item.date_of_execution"
                  ng-class="{ 'label-opacity': !item.end_of_execution, 'label_danger': ((item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 > 0 && (item.end_of_execution - $root.serverTime) / 1000 / 60 / 60 / 24 < 3) }"
                  data-tip="{[{ 'execution period' | translate }]} {[{item.date_of_execution | date:'dd MMM yy'}]} - {[{item.end_of_execution | date:'dd MMM yy'}]}"
                >
                  <i class="fa fa-calendar"></i> {[{item.date_of_execution || "---" |
                  date:'dd.MM.yy'}]}
                  <div class="clearfix"></div>
                  <i class="fa fa-calendar"></i> {[{item.end_of_execution || "---" |
                  date:'dd.MM.yy'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'sap_orders',
          title: gettext('sap orders'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id">
              <gt-resource-select
                ng-model="item.sap_orders_data"
                placeholder="'Sap orders' | translate"
                resource-name="'contracts.saporder'"
                allow-clear="true"
                on-open-close="args.setHovering(true)"
              ></gt-resource-select>
            </div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="!item.$_edit" class="bigger-label" ng-if="item.sap_orders_data">
                <span ng-repeat="sap_order in item.sap_orders_data">
                  <span class="bigger-label">{[{ sap_order.number | cut:true:30:' ...' }]}</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'costDetails',
          title: gettext('Cost details'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span ng-if="!item.$_edit" class="bigger-label">
                <span
                  ng-repeat="title in $root.user.profile.language === 'en' ? item.contractcharges_charge_title_list_eng : item.contractcharges_charge_title_list"
                >
                  <span class="bigger-label">{[{ title | cut:true:30:' ...' }]};</span>
                  <div class="clearfix"></div>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'conclusion_date',
          class: 'td-left-align',
          title: gettext('Conclusion'),
          predicate: 'conclusion_date',
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span ng-if="item.conclusion_date">
                  <i class="fa fa-calendar"></i> {[{ item.conclusion_date || "---" |
                  date:'dd.MM.yy'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'paymentEvents',
          class: 'td-left-align',
          title: gettext('Payment events'),
          cellTemplate: html`
            <span ng-if="item.prepaypayment_title">
              <translate>prepay</translate> {[{ item.prepaypayment_title }]}
            </span>
            <div class="clearfix"></div>
            <span ng-if="item.balancepayment_title">
              <translate>balance</translate> {[{ item.balancepayment_title }]}
            </span>
          `,
        },
        {
          columnName: 'creation',
          class: 'td-left-align',
          title: gettext('creation'),
          predicate: 'create_time',
          hint: gettext('time, date and user, who created this deal'),
          filters: [
            {
              type: 'daterange',
              startDateField: 'start_date',
              endDateField: 'end_date',
            },
          ],
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span class="updated_time">
                  <i class="fa fa-clock-o"></i>
                  {[{item.create_time | date:'dd.MM.yy HH:mm'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'creator',
          class: 'td-left-align',
          title: gettext('creator'),
          cellTemplate: html`
            <div ng-if="args.rows[item.id].editable || !item.id"></div>
            <div ng-if="!args.rows[item.id].editable && item.id">
                <span class="tooltip" data-tip="{[{ item.author_first_name }]}">
                  <div
                    class="user-avatar"
                    ng-if="item.author_first_name && item.author_avatar.length != 0"
                  >
                    <img ng-src="/pictures/{[{item.author_avatar}]}" />
                  </div>
                  <div class="no-user-avatar" ng-if="item.author_avatar.length == 0">
                    {[{ item.author_first_name | cut:true:1:' ' }]}
                  </div>
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'update_time',
          class: 'td-left-align',
          title: gettext('updated'),
          cellTemplate: html`
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <span class="updated_time">
                  <i class="fa fa-clock-o"></i>
                  {[{item.update_time | date:'dd.MM.yy HH:mm'}]}
                </span>
              </span>
            </div>
          `,
        },
        {
          columnName: 'editor',
          class: 'td-left-align',
          title: gettext('editor'),
          cellTemplate: html`
            <div ng-if="!args.rows[item.id].editable && item.id">
              <span
                ng-if="!item.$_edit"
                class="bigger-label"
                ng-class="{'label-opacity': item.volume == 0}"
              >
                <div
                  class="user-avatar"
                  ng-if="item.editor_first_name && item.editor_avatar.length != 0"
                >
                  <img ng-src="/pictures/{[{item.editor_avatar}]}" />
                </div>
                <div class="no-user-avatar" ng-if="item.editor_avatar.length == 0">
                  {[{ item.editor_first_name | cut:true:1:' ' }]}
                </div>
              </span>
            </div>
          `,
        },
        {
          columnName: 'total_value',
          title: gettext('Total value'),
          class: 'td-right-align',
          cellTemplate: html`
            <span class="bigger-label" ng-if="item.total_value">
              {[{ item.total_value || 0 | number:2 }]}
            </span>
          `,
        },
        {
          columnName: 'measurement',
          title: gettext('measurement'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.measurement_title"> {[{ item.measurement_title }]} </span>
          `,
        },
        {
          columnName: 'quantity_of_measurement',
          title: gettext('quantity of measurement'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-if="item.quantity"> {[{ item.quantity || 0 | gtDecimal:3 }]} </span>
          `,
        },
      ];

      return options;
    }
  }
})();
