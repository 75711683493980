import React from 'react';

import { ActionBar, type ActionBarConfig } from '~/shared/ui/components/action-bar';

export const SelectRecordToolbar: React.FC<{
  selectedRowIds: number[];
  config: ActionBarConfig[];
}> = ({ selectedRowIds, config }) => {
  return (
    <ActionBar
      title={
        <span className="text-text-main-tertiary text-xs font-medium">
          {selectedRowIds.length} selected
        </span>
      }
      config={config}
    />
  );
};
