import React from 'react';

import type { Entity } from '~/shared/common';
import { cn } from '~/shared/lib/utils';
import {
  type CellRenderDef,
  type ColumnDef,
  type DataGrid,
  type Row,
  getStickyStyles,
} from '~/shared/ui/components/data-grid';

export const RowGroup = <TRecord extends Entity>({
  row,
  CellRender,
  editing,
  onEdit,
  onSelect,
  selectedRowIds,
}: {
  row: Row<TRecord>;
  CellRender: React.FC<CellRenderDef>;
  editing: boolean;
  onEdit: React.ComponentProps<typeof DataGrid>['onEdit'];
  onSelect?: (ids: number[]) => void;
  selectedRowIds?: number[];
}) => {
  return (
    <div
      data-index={row.index}
      className="group max-height-[60px] z-[1] flex h-[32px] w-full"
      // ref={node => rowVirtualizer.measureElement(node)} - measure dynamic row height
      style={
        {
          // position: 'absolute',
          // transform: `translateY(${virtualRow.start}px)`,
        }
      }
    >
      {row.getVisibleCells().map((cell) => {
        const valueChangedHandler = (value?: unknown) => {
          if (
            (cell.column.columnDef as ColumnDef<TRecord>).accessorKey === '_selectRowCheckbox' &&
            Array.isArray(value)
          ) {
            onSelect?.(value as number[]);
          } else if ((cell.column.columnDef as ColumnDef<TRecord>).valueSetter) {
            (cell.column.columnDef as ColumnDef<TRecord>)?.valueSetter?.(
              value,
              onEdit,
              row.original.id,
            );
          } else {
            onEdit(
              value as undefined,
              String((cell.column.columnDef as ColumnDef<TRecord>).accessorKey),
              row.original.id,
            );
          }
        };

        return (
          <div
            key={cell.id}
            className={cn(
              'border-stroke-main-light !bg-background-main-primary group-hover:bg-transparent-light relative flex h-full w-full items-center overflow-hidden border-r border-b transition duration-300 ease-in-out',
              selectedRowIds?.includes(row.original.id) && 'bg-background-section-extraLight',
              (cell.column.columnDef as ColumnDef<TRecord>).accessorKey === '_selectRowCheckbox' &&
                'border-r-0 pl-1',
            )}
            style={{
              width: cell.column.getSize(),
              ...getStickyStyles({ column: cell.column, isHeader: false }),
            }}
          >
            <CellRender
              value={cell.getValue()}
              id={row.original.id}
              row={row.original}
              dataType={(cell.column.columnDef as ColumnDef<TRecord>).dataType}
              typeExtra={(cell.column.columnDef as ColumnDef<TRecord>).typeExtra ?? ''}
              cellTemplateContext={
                (cell.column.columnDef as ColumnDef<TRecord>).cellTemplateContext
              }
              dataTypeVariant={editing ? 'write' : 'read'}
              valueChanged={valueChangedHandler}
              render={(cell.column.columnDef as ColumnDef<TRecord>).render}
              disabled={!(cell.column.columnDef as ColumnDef<TRecord>).editable}
              valueFormatter={(cell.column.columnDef as ColumnDef<Entity>).valueFormatter}
              valueParser={(cell.column.columnDef as ColumnDef<Entity>).valueParser}
            />
          </div>
        );
      })}
    </div>
  );
};
