import '@univerjs/presets/lib/styles/preset-sheets-core.css';
import '@univerjs/presets/lib/styles/preset-sheets-data-validation.css';

import React from 'react';

import {
  type CellData,
  type FUniver,
  type MatrixPrimitive,
  type SpreadsheetDataDef,
  type SpreadsheetRef,
  type UnitModel,
  setNumberValidate,
  setSelectValidate,
  setTextValidate,
} from '~/shared/lib/spreadsheet';
import { isObjectsEqual } from '~/shared/lib/utils';

import { updatedDataBuilder } from './builders';
import {
  destroySpreadsheet,
  initSpreadsheet,
  setProtectedColumns,
  validateWorksheetColumns,
} from './helpers';
import { SpreadsheetSkeleton } from './spreadsheet-skeleton';

export const Spreadsheet = React.forwardRef(
  (
    {
      data,
      initialCellRecords,
      loading,
      onEdit,
      onDataValidation,
      validationBuild,
    }: {
      data: SpreadsheetDataDef;
      initialCellRecords?: MatrixPrimitive<CellData>;
      loading?: boolean;
      onEdit?: (workBookData: MatrixPrimitive<CellData>) => void;
      validationBuild?: () => void;
      onDataValidation?: ({ allowSave }: { allowSave: boolean }) => void;
    },
    ref,
  ) => {
    const spreadsheetRef = React.useRef<FUniver | null>(null);
    const workbookRef = React.useRef<UnitModel<object, number> | null>(null);
    const containerRef = React.useRef(null);
    const selectedRowsRef = React.useRef<number[]>([]);

    const [activeWorkBookData, setActiveWorkBookData] = React.useState<SpreadsheetDataDef>(data);

    const getActiveWorkBookData = React.useCallback(() => {
      const activeWorkbook = spreadsheetRef?.current?.getActiveWorkbook();
      return activeWorkbook?.save();
    }, []);

    React.useImperativeHandle(ref, () => ({
      setTextValidate: (args: Parameters<SpreadsheetRef['setTextValidate']>[0]) =>
        setTextValidate({ ...args, spreadsheetRef }),
      setSelectValidate: (args: Parameters<SpreadsheetRef['setSelectValidate']>[0]) =>
        setSelectValidate({ ...args, spreadsheetRef }),
      setNumberValidate: (args: Parameters<SpreadsheetRef['setNumberValidate']>[0]) => {
        setNumberValidate({ ...args, spreadsheetRef });
      },
      getSelectedRows: () => selectedRowsRef.current,
      setProtectedColumns: (
        ranges: {
          startRow: number;
          startColumn: number;
          endRow: number;
          endColumn: number;
        }[],
      ) => {
        if (spreadsheetRef.current) {
          setProtectedColumns({ ranges, ref: spreadsheetRef.current });
        }
      },
    }));

    React.useLayoutEffect(() => {
      if (!loading) {
        initSpreadsheet({ containerRef, spreadsheetRef, data });
        validationBuild?.();
      }

      return () => {
        destroySpreadsheet({ spreadsheetRef, workbookRef });
      };
    }, [loading, data, validationBuild]);

    spreadsheetRef.current?.getActiveWorkbook()?.onSelectionChange((selection) => {
      const [selectedRows] = selection;
      const { startRow, endRow } = selectedRows;

      selectedRowsRef.current = Object.values(initialCellRecords ?? {})
        .slice(startRow, endRow + 1)
        .map(
          (row) =>
            Object.values(row as Record<string, CellData>)[0]?.custom?.id as number | undefined,
        )
        .filter((id): id is number => typeof id === 'number');
    });

    React.useEffect(() => {
      if (containerRef.current && spreadsheetRef.current) {
        let animationFrameId: number;

        const checkWorksheetChanges = () => {
          const worksheet = spreadsheetRef.current?.getActiveWorkbook()?.getActiveSheet();
          const newWorkBookData = getActiveWorkBookData();
          const newCellData = newWorkBookData?.sheets[newWorkBookData.sheetOrder[0]].cellData;
          const cellData = data?.sheets[data.sheetOrder[0]].cellData;

          if (!isObjectsEqual(newWorkBookData, activeWorkBookData) && newCellData && cellData) {
            validateWorksheetColumns({ worksheet, onDataValidation });
            setActiveWorkBookData(newWorkBookData);
            const updatedData = updatedDataBuilder({
              newData: newCellData,
              data: initialCellRecords ?? cellData,
            });
            onEdit?.(updatedData);
          }

          animationFrameId = requestAnimationFrame(checkWorksheetChanges);
        };

        checkWorksheetChanges();

        return () => cancelAnimationFrame(animationFrameId);
      }
    }, [
      data,
      initialCellRecords,
      activeWorkBookData,
      onDataValidation,
      getActiveWorkBookData,
      onEdit,
    ]);

    return loading ? (
      <SpreadsheetSkeleton />
    ) : (
      <div
        ref={containerRef}
        data-testid="spreadsheet-container"
        className="h-[80vh] w-full overflow-hidden"
      />
    );
  },
);

Spreadsheet.displayName = 'Spreadsheet';
