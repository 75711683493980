import React from 'react';

import type { Entity } from '~/shared/common';
import { ActionView } from '~/shared/ui/components/action-bar/types';
import { DataGrid } from '~/shared/ui/components/data-grid';
import { AddFileIcon, BucketIcon } from '~/shared/ui/icons';

import { tableColumnsBuilder, tableDataBuilder } from './builders';
import type { CommonView } from '../data-set-view';
import { onEditHandler } from './helpers';
import { InlineEditToolbar } from '../inline-edit-toolbar/inline-edit-toolbar';
import { useTableView } from './hooks/use-table-view/use-table-view';
import { CellRender } from './lib/cell-render/cell-render';
import { HeaderRender } from './lib/header-render';
import { SelectRecordToolbar } from '../select-record-toolbar/select-record-toolbar';

export const TableView = <TRecord extends Entity, TRecordUpdate = TRecord>({
  data,
  editing,
  selectable,
  loading,
  onEdit,
  onDelete,
  onSave,
}: CommonView<TRecord, TRecordUpdate> & { selectable: boolean }) => {
  const editedRecordsRef = React.useRef<TRecord[]>([]);
  const [editingData, setEditingData] = React.useState<TRecord[]>(data.records);
  const [selectedRowIds, setSelectedRowIds] = React.useState<number[]>([]);

  React.useEffect(() => {
    setEditingData(data.records);
  }, [data.records]);

  const columns = React.useMemo(
    () => tableColumnsBuilder<TRecord>(data.properties),
    [data.properties],
  );

  const table = useTableView<TRecord>({
    data: editing ? editingData : data.records,
    columns,
    isRowsSelectable: selectable,
    selectedRowIds: selectedRowIds,
    recordSelected: setSelectedRowIds,
  });

  const { rows, headerGroups } = tableDataBuilder({
    table,
    selectedRecordIds: selectedRowIds,
    selectable,
  });

  const onEditAdapter = React.useCallback(
    (newValue: unknown, accessorKey: string, id: number) => {
      onEditHandler<TRecord, TRecordUpdate>({
        data: editingData,
        diff: { newValue: newValue as TRecord[keyof TRecord] | undefined, accessorKey, id },
        editedRecordsRef,
        onEdit,
        setEditingData,
      });
    },
    [editedRecordsRef, editingData, onEdit],
  );

  return (
    <>
      <DataGrid
        rows={rows}
        headerGroups={headerGroups}
        selectedRowIds={selectedRowIds}
        CellRender={CellRender}
        HeaderRender={HeaderRender}
        onEdit={onEditAdapter}
        onSelect={setSelectedRowIds}
        editing={editing}
        loading={loading}
        stickyHeaders
      />
      {!!selectedRowIds.length && !editing && (
        <SelectRecordToolbar
          selectedRowIds={selectedRowIds}
          config={[
            {
              label: {
                text: 'Delete',
                context: 'text-text-additional-danger',
              },
              view: ActionView.Button,
              key: 'delete',
              Icon: BucketIcon,
              iconContext: 'text-text-additional-danger',
              action: () => {
                selectedRowIds.forEach((id) => void onDelete(id));
                setSelectedRowIds([]);
              },
            },
          ]}
        />
      )}
      {editing && (
        <InlineEditToolbar
          selectedCount={selectedRowIds.length}
          config={[
            {
              label: {
                text: 'Delete',
                context: 'text-text-additional-danger',
              },
              view: ActionView.Button,
              key: 'delete',
              Icon: BucketIcon,
              iconContext: 'text-text-additional-danger',
              action: () => {
                selectedRowIds.forEach((id) => void onDelete(id));
                setSelectedRowIds([]);
              },
            },
            {
              label: {
                text: 'Save',
                context: 'text-text-additional-success',
              },
              Icon: AddFileIcon,
              iconContext: 'text-text-additional-success',
              view: ActionView.Button,
              key: 'save',
              action: onSave,
            },
          ]}
        />
      )}
    </>
  );
};
