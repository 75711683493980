import React from 'react';

import type { ColumnConfig } from '~/core/page-views/lib';
import { isEntityOption } from '~/shared/ui/data-types';
import type { TagVariants } from '~/shared/ui/kit/tag';
import { Tag } from '~/shared/ui/kit/tag';

import type { LogisticInfoList, LogisticList, LogisticSupplierList, LogisticTable } from './types';
import { StatusTemplate } from '../components/status-template/status';

export const getLogisticColumns = (table: LogisticTable, logistics: LogisticList[]) => {
  const logisticColumns: Record<string, ColumnConfig<LogisticInfoList>> = {
    vehicle_number: {
      key: 'vehicle_number',
      dataType: 'text',
      editing: true,
      validate: { type: 'textLength', condition: 'moreThan', value: '1' },
    },
    // select
    export_contract: {
      key: 'export_contract',
      sourceKey: 'export_contract_number',
      dataType: 'text',
      editing: true,
    },
    logistic_type: {
      key: 'logistic_type',
      dataType: 'select',
      cellTemplateContext: {
        options: [
          { value: 'main', props: { variant: 'green' } },
          { value: 'intermediate', props: { variant: 'yellow' } },
        ],
      },
      validate: {
        type: 'equalValue',
        values: ['main', 'intermediate'],
      },
      editing: true,
    },
    consignment_number: {
      key: 'consignment_number',
      dataType: 'text',
      sourceKey: 'consignment_number',
      editing: true,
    },
    // select
    custom_status: {
      key: 'custom_status',
      sourceKey: 'custom_status_title',
      dataType: 'text',
      editing: true,
      render: ({ id }) => {
        const logistic = logistics.find((l) => l.id === id) as LogisticInfoList;
        if (!logistic?.custom_status) {
          return <span />;
        }
        const variant =
          {
            new: 'green',
            washout: 'blue',
            cancelled: 'gray',
            executed: 'gray',
            shipment: 'orange',
          }[logistic.custom_status_codename] ?? 'gray';
        return (
          <Tag variant={variant as TagVariants['variant']}>{logistic.custom_status_title}</Tag>
        );
      },
    },
    voyage: {
      key: 'voyage',
      sourceKey: 'voyage_name',
      dataType: 'text',
      editing: false,
    },
    deliverer_in_port: {
      sourceKey: 'deliverer_in_port_name',
      key: 'deliverer_in_port',
      dataType: 'text',
      editing: false,
    },
    // select
    acceptance_certificate: {
      key: 'acceptance_certificate',
      sourceKey: 'acceptance_certificate_title',
      dataType: 'text',
      editing: false,
    },
    billoflading: {
      key: 'billoflading',
      dataType: 'text',
      editing: true,
    },
    status: {
      key: 'status',
      dataType: 'template',
      render: ({ id }) => {
        const logistic = logistics.find((l) => l.id == id);
        if (logistic) {
          return (
            <div>
              <StatusTemplate logistic={logistic} />
            </div>
          );
        }
        return <div />;
      },
      editing: false,
    },
    railway: {
      key: 'railway',
      dataType: 'date',
      typeExtra: 'dd.MM',
      editing: true,
    },
    // template
    export_invoice_list: {
      key: 'export_invoice_list',
      dataType: 'text',
      editing: false,
    },
    has_diff_limit: {
      key: 'has_diff_limit',
      dataType: 'template',
      editing: false,
      render: ({ value }) => {
        if (value) {
          return <Tag variant={'orange'}>Limit</Tag>;
        }
        return <span />;
      },
    },
    supplier_ten_invoice__number: {
      // template
      key: 'supplier_ten_invoice__number',
      dataType: 'text',
      editing: true,
    },
    loading_shortage: {
      key: 'loading_shortage',
      dataType: 'number',
      editing: true,
    },
    volume_departed_consignment: {
      key: 'volume_departed_consignment',
      dataType: 'number',
      editing: true,
    },
    cargo_brand: {
      key: 'cargo_brand',
      sourceKey: 'cargo_brand_name',
      dataType: 'text',
      editing: false,
    },
    supplier_nineteen_invoice__number: {
      // select
      key: 'supplier_nineteen_invoice__number',
      dataType: 'text',
      editing: true,
    },
    days_at_port: {
      key: 'days_at_port',
      dataType: 'string',
      editing: true,
    },
    discount_purchase_price: {
      key: 'discount_purchase_price',
      dataType: 'number',
      editing: true,
    },
    supplier_total_invoiced_plan: {
      key: 'supplier_total_invoiced_plan',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const item = logistics.find((l) => l.id === id) as LogisticSupplierList;
        if (
          item.supplier_total_invoiced != 0 &&
          Number(item.supplier_total_invoiced_plan ?? 0) != item.supplier_total_invoiced
        ) {
          return (
            <span>
              {Number(item.supplier_total_invoiced_plan ?? 0) -
                (item.supplier_total_invoiced ?? 0) || 0}
            </span>
          );
        }
        if (item.supplier_total_invoiced != 0) {
          return <span>{item.supplier_total_invoiced}</span>;
        }
        return <span />;
      },
    },
    sale_discount_value: {
      key: 'sale_discount_value',
      dataType: 'text',
      editing: true,
    },
    penalties: {
      // template
      key: 'penalties',
      dataType: 'number',
      editing: true,
    },
    elevator: {
      // select
      key: 'elevator',
      sourceKey: 'elevator_title',
      dataType: 'string',
      editing: true,
    },
    additional_document_two: {
      // template
      key: 'additional_document_two',
      dataType: 'string',
      editing: true,
    },
    quarantine: {
      key: 'quarantine',
      dataType: 'date',
      typeExtra: 'dd.MM',
      editing: true,
    },
    sale_trader_discount_amount: {
      key: 'sale_trader_discount_amount',
      dataType: 'number',
      editing: false,
    },
    intermediate_contract__contract_number: {
      // select
      key: 'intermediate_contract__contract_number',
      dataType: 'text',
      editing: true,
    },
    delivery: {
      key: 'delivery',
      sourceKey: 'costs',
      dataType: 'number',
      editing: true,
    },
    buyer_contract__buyer: {
      // select
      key: 'buyer_contract__buyer',
      sourceKey: 'buyer_contract_buyer_name',
      dataType: 'text',
      editing: true,
    },
    unloading_business_unit: {
      // select
      key: 'unloading_business_unit',
      sourceKey: 'unloading_business_unit_title',
      dataType: 'text',
      editing: true,
    },
    shipment_business_unit: {
      // select
      key: 'shipment_business_unit',
      sourceKey: 'shipment_business_unit_title',
      dataType: 'text',
      editing: true,
    },
    conditions_supplier: {
      // template
      key: 'conditions_supplier',
      dataType: 'text',
      editing: true,
    },
    supplier_total_invoiced: {
      key: 'supplier_total_invoiced',
      sourceKey: 'supplier_total_invoiced_plan',
      dataType: 'number',
      editing: true,
    },
    bags_quantity: {
      key: 'bags_quantity',
      dataType: 'number',
      editing: true,
    },
    volume_discounted: {
      key: 'volume_discounted',
      dataType: 'number',
      editing: true,
    },
    buyer_contract__price: {
      // template
      key: 'buyer_contract__price',
      dataType: 'text',
      editing: false,
    },
    boarding_date: {
      key: 'boarding_date',
      dataType: 'date',
      typeExtra: 'dd.MM.yy',
      editing: true,
    },
    customs_declarations_data: {
      // template
      key: 'customs_declarations_data',
      dataType: 'text',
      editing: false,
    },
    station_receiving: {
      // select
      key: 'station_receiving',
      sourceKey: 'station_receiving_name',
      dataType: 'text',
      editing: true,
    },
    volume_departed: {
      key: 'volume_departed',
      dataType: 'number',
      editing: true,
    },
    supplier_contract_supplier_name: {
      key: 'supplier_contract_supplier_name',
      sourceKey: 'supplier_name',
      dataType: 'text',
      editing: true,
    },
    exporter: {
      // select
      key: 'exporter',
      sourceKey: 'exporter_name',
      dataType: 'text',
      editing: true,
    },
    unloading_place: {
      // select
      key: 'unloading_place',
      dataType: 'select',
      editing: true,
    },
    buyer_ten_invoice__number: {
      // select
      key: 'buyer_ten_invoice__number',
      sourceKey: 'buyer_ten_number',
      dataType: 'text',
      editing: true,
    },
    sale_init_price: {
      key: 'sale_init_price',
      dataType: 'number',
      editing: false,
    },
    buyer_total_paid: {
      // template
      key: 'buyer_total_paid',
      dataType: 'text',
      editing: false,
    },
    export_contract_price: {
      key: 'export_contract_price',
      dataType: 'number',
      editing: false,
    },
    producer: {
      // select
      key: 'producer',
      sourceKey: 'producer_name',
      dataType: 'text',
      editing: true,
    },
    shipment_warehouse: {
      // select
      key: 'shipment_warehouse',
      sourceKey: 'shipment_warehouse_title',
      dataType: 'text',
      editing: true,
    },
    port__name: {
      // select
      key: 'port__name',
      sourceKey: 'port_title',
      dataType: 'text',
      editing: true,
    },
    quality: {
      // template
      key: 'quality',
      sourceKey: 'quality',
      dataType: 'text',
      editing: false,
    },
    payment: {
      // template
      key: 'payment',
      dataType: 'text',
      editing: false,
    },
    purchase_passports_data: {
      // template
      key: 'purchase_passports_data',
      dataType: 'text',
      editing: false,
    },
    cost_invoiceposition: {
      key: 'cost_invoiceposition',
      sourceKey: 'cost_invoice_number',
      dataType: 'text',
      editing: true,
    },
    buyer_total_invoiced_plan: {
      key: 'buyer_total_invoiced_plan',
      dataType: 'number',
      editing: true,
    },
    additional_info: {
      key: 'additional_info',
      dataType: 'text',
      editing: true,
    },
    supplier_to_be_invoiced: {
      key: 'supplier_to_be_invoiced',
      dataType: 'number',
      editing: true,
    },
    additional_document_three: {
      key: 'additional_document_three',
      dataType: 'date',
      typeExtra: 'dd.MM',
      editing: true,
    },
    sale_date: {
      key: 'sale_date',
      dataType: 'date',
      typeExtra: 'dd.MM.yy',
      editing: true,
    },
    purchase_total_value: {
      key: 'purchase_total_value',
      dataType: 'number',
      editing: true,
    },
    writeof_volume_balance: {
      key: 'writeof_volume_balance',
      dataType: 'number',
      editing: true,
    },
    disbursementbl: {
      // select
      key: 'disbursementbl',
      sourceKey: 'disbursementbl_number',
      dataType: 'text',
      editing: true,
    },
    receiving_date: {
      key: 'receiving_date',
      dataType: 'date',
      typeExtra: 'dd.MM.yy',
      editing: true,
    },
    bags_weight: {
      key: 'bags_weight',
      dataType: 'number',
      editing: true,
    },
    export_invoices_quantity_sum: {
      key: 'export_invoices_quantity_sum',
      dataType: 'number',
      editing: false,
    },
    buyer_to_be_invoiced_balance: {
      // template
      key: 'buyer_to_be_invoiced_balance',
      dataType: 'text',
      editing: true,
    },
    sale_total_value_discounted: {
      key: 'sale_total_value_discounted',
      dataType: 'number',
      editing: false,
    },
    transport_costs: {
      // template
      key: 'transport_costs',
      dataType: 'text',
      editing: false,
    },
    shipment_place: {
      // select
      key: 'shipment_place',
      dataType: 'text',
      editing: true,
    },
    buyer_contract__contract_number: {
      // select
      key: 'buyer_contract__contract_number',
      sourceKey: 'buyer_contract_contract_number',
      dataType: 'text',
      editing: true,
    },
    unloading_warehouse: {
      // select
      key: 'unloading_warehouse',
      sourceKey: 'unloading_warehouse_title',
      dataType: 'text',
      editing: true,
    },
    conditions_buyer: {
      // template
      key: 'conditions_buyer',
      dataType: 'text',
      editing: false,
    },
    update_time: {
      // template
      key: 'update_time',
      dataType: 'text',
      editing: false,
    },
    purchase_total_value_discounted: {
      key: 'purchase_total_value_discounted',
      dataType: 'number',
      editing: false,
    },
    volume_lost: {
      key: 'volume_lost',
      dataType: 'number',
      editing: true,
    },
    toStockReserveUnit: {
      key: 'toStockReserveUnit',
      sourceKey: 'to_stock_reserve_unit_title',
      dataType: 'text',
      editing: false,
    },
    volume_departed_real: {
      key: 'volume_departed_real',
      dataType: 'number',
      editing: true,
    },
    station: {
      // select
      key: 'station',
      sourceKey: 'station_name',
      dataType: 'text',
      editing: true,
    },
    approval_status: {
      key: 'approval_status',
      dataType: 'select',
      editing: true,
      cellTemplateContext: {
        options: [
          { value: 'wait', props: { variant: 'yellow' } },
          { value: 'rejected', props: { variant: 'red' } },
          { value: 'approved', props: { variant: 'green' } },
          { value: 'process', props: { variant: 'sky' } },
        ],
      },
      validate: {
        type: 'equalValue',
        values: ['wait', 'rejected', 'approved', 'process'],
      },
    },
    buyer_nineteen_invoice__number: {
      // select
      key: 'buyer_nineteen_invoice__number',
      sourceKey: 'buyer_nineteen_number',
      dataType: 'text',
      editing: true,
    },
    crop: {
      // select
      key: 'crop',
      sourceKey: 'cargo',
      dataType: 'entity',
      typeExtra: 'Crop',
      editing: true,
      valueFormatter: (value, row) => ({ id: value, title: row.crop_title }),
      valueSetter: (value, onEdit, rowId) => {
        if (isEntityOption(value)) {
          onEdit(value.id, 'cargo', rowId);
          onEdit(value.title, 'crop_title', rowId);
        }
      },
    },
    discount_sale_price: {
      key: 'discount_sale_price',
      dataType: 'number',
      editing: true,
    },
    volume_boarded: {
      key: 'volume_boarded',
      dataType: 'number',
      editing: true,
    },
    purchase_init_price: {
      key: 'purchase_init_price',
      dataType: 'number',
      editing: false,
    },
    buyer_invoiced_balance: {
      key: 'buyer_invoiced_balance',
      dataType: 'number',
      editing: true,
    },
    supplier_paid_balance: {
      key: 'supplier_paid_balance',
      dataType: 'number',
      editing: true,
    },
    buyer_paid_balance: {
      key: 'buyer_paid_balance',
      dataType: 'text',
      editing: true,
    },
    sale_total_value: {
      key: 'sale_total_value',
      dataType: 'text',
      editing: false,
    },
    declaration: {
      // template
      key: 'declaration',
      dataType: 'string',
      editing: false,
    },
    supplier_paid: {
      key: 'supplier_paid',
      dataType: 'number',
      editing: false,
    },
    vet: {
      key: 'vet',
      dataType: 'date',
      typeExtra: 'dd.MM',
      editing: true,
    },
    sale_trader_amount_diff: {
      key: 'sale_trader_amount_diff',
      dataType: 'number',
      editing: false,
    },
    cargo_customs_declarations_data: {
      // template
      key: 'cargo_customs_declarations_data',
      dataType: 'text',
      editing: false,
    },
    fromStockReserveUnit: {
      key: 'fromStockReserveUnit',
      sourceKey: 'from_stock_reserve_unit_title',
      dataType: 'text',
      editing: false,
    },
    receiver: {
      // select
      key: 'receiver',
      sourceKey: 'receiver_name',
      dataType: 'text',
      editing: true,
    },
    eur_one: {
      key: 'eur_one',
      sourceKey: 'eur_one_cert_file',
      dataType: 'string',
      editing: false,
    },
    buyer_invoiced: {
      key: 'buyer_invoiced',
      dataType: 'number',
      editing: false,
    },
    estimated_receiving_date: {
      key: 'estimated_receiving_date',
      typeExtra: 'dd.MM.yy',
      dataType: 'date',
      editing: false,
    },
    qualities: {
      // template
      key: 'qualities',
      dataType: 'text',
      editing: false,
    },
    loading_date: {
      key: 'loading_date',
      dataType: 'date',
      typeExtra: 'dd.MM.yy',
      editing: true,
    },
    supplier_contract__contract_number: {
      // select
      key: 'supplier_contract__contract_number',
      sourceKey: 'supplier_contract_contract_number',
      dataType: 'text',
      editing: true,
    },
    buyer_total_invoiced: {
      key: 'buyer_total_invoiced',
      dataType: 'number',
      editing: false,
    },
    sale_trader_amount: {
      key: 'sale_trader_amount',
      dataType: 'number',
      editing: false,
    },
    sale_passports_data: {
      // template
      key: 'sale_passports_data',
      dataType: 'string',
      editing: false,
    },
    volume_received: {
      key: 'volume_received',
      dataType: 'number',
      editing: true,
    },
    broker_confirmation: {
      key: 'broker_confirmation',
      dataType: 'date',
      typeExtra: 'dd.MM',
      editing: true,
    },
    volume_sale_trader: {
      key: 'volume_sale_trader',
      dataType: 'number',
      editing: false,
    },
    business_unit: {
      // select
      key: 'business_unit',
      sourceKey: 'business_unit_title',
      dataType: 'text',
      editing: true,
    },
    supplier_contract__price: {
      key: 'supplier_contract__price',
      sourceKey: 'purchase_price',
      dataType: 'number',
      editing: false,
    },
    purchase_discount_value: {
      key: 'purchase_discount_value',
      dataType: 'number',
      editing: false,
    },
    supplier_total_paid: {
      key: 'supplier_total_paid',
      dataType: 'number',
      editing: false,
    },
    supplier_to_be_invoiced_balance: {
      key: 'supplier_to_be_invoiced_balance',
      dataType: 'number',
      editing: false,
    },
    crop_year: {
      key: 'crop_year',
      dataType: 'text',
      editing: true,
    },
    sale_trader_volume_diff: {
      key: 'sale_trader_volume_diff',
      dataType: 'number',
      editing: false,
    },
    periodic_customs_declarations_data: {
      // template
      key: 'periodic_customs_declarations_data',
      dataType: 'text',
      editing: false,
    },
    supplier_invoiced_balance: {
      key: 'supplier_invoiced_balance',
      dataType: 'number',
      editing: false,
    },
    supplier_invoiced: {
      key: 'supplier_invoiced',
      dataType: 'number',
      editing: false,
    },
    forwarder: {
      // select
      key: 'forwarder',
      sourceKey: 'forwarder_name',
      dataType: 'text',
      editing: true,
    },
    additional_document_one: {
      key: 'additional_document_one',
      sourceKey: 'additional_document_one_file',
      dataType: 'string',
      editing: false,
    },
    discount_volume: {
      key: 'discount_volume',
      dataType: 'number',
      editing: true,
    },
    buyer_to_be_invoiced: {
      key: 'buyer_to_be_invoiced',
      sourceKey: 'buyer_to_be_invoiced_balance',
      dataType: 'number',
      editing: false,
    },
    buyer_paid: {
      key: 'buyer_paid',
      dataType: 'number',
      editing: false,
    },
  };

  return Object.entries(logisticColumns)
    .filter(([key]) => tableColumnsMap[table].includes(key))
    .map(([_, col]) => col);
};

export const tableColumnsMap: Record<LogisticTable, string[]> = {
  table_info: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'voyage',
    'acceptance_certificate',
    'billoflading',
    'status',
    'approval_status',
    'payment',
    'crop',
    'disbursementbl',
    'volume_departed',
    'writeof_volume_balance',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_boarded',
    'volume_lost',
    'loading_shortage',
    'loading_date',
    'receiving_date',
    'sale_date',
    'boarding_date',
    'days_at_port',
    'supplier_contract_supplier_name',
    'forwarder',
    'exporter',
    'export_contract',
    'export_contract_price',
    'buyer_contract__buyer',
    'purchase_passports_data',
    'sale_passports_data',
    'business_unit',
    'unloading_business_unit',
    'shipment_business_unit',
    'station',
    'station_receiving',
    'port__name',
    'elevator',
    'crop_year',
    'cargo_brand',
    'bags_weight',
    'bags_quantity',
    'additional_info',
    'update_time',
    'shipment_place',
    'unloading_place',
    'estimated_receiving_date',
    'deliverer_in_port',
    'intermediate_contract__contract_number',
    'producer',
    'receiver',
    'buyer_contract__contract_number',
    'supplier_contract__contract_number',
    'fromStockReserveUnit',
    'toStockReserveUnit',
    'supplier_contract__contract_number',
  ],
  table_costs: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'voyage',
    'acceptance_certificate',
    'billoflading',
    'status',
    'approval_status',
    'payment',
    'crop',
    'disbursementbl',
    'volume_departed',
    'writeof_volume_balance',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_boarded',
    'volume_lost',
    'loading_shortage',
    'loading_date',
    'receiving_date',
    'sale_date',
    'boarding_date',
    'days_at_port',
    'supplier_contract_supplier_name',
    'forwarder',
    'exporter',
    'export_contract',
    'export_contract_price',
    'buyer_contract__buyer',
    'purchase_passports_data',
    'sale_passports_data',
    'business_unit',
    'unloading_business_unit',
    'shipment_business_unit',
    'station',
    'station_receiving',
    'port__name',
    'elevator',
    'crop_year',
    'cargo_brand',
    'bags_weight',
    'bags_quantity',
    'additional_info',
    'update_time',
    'shipment_place',
    'unloading_place',
    'estimated_receiving_date',
    'deliverer_in_port',
    'intermediate_contract__contract_number',
    'producer',
    'receiver',
    'transport_costs',
    'cost_invoiceposition',
    'delivery',
    'penalties',
    'payment',
    'buyer_contract__contract_number',
    'supplier_contract__contract_number',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_buyer: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'status',
    'approval_status',
    'payment',
    'crop',
    'buyer_contract__buyer',
    'volume_departed',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_boarded',
    'volume_lost',
    'loading_shortage',
    'loading_date',
    'receiving_date',
    'supplier_contract_supplier_name',
    'exporter',
    'forwarder',
    'buyer_contract__buyer',
    'station',
    'station_receiving',
    'port__name',
    'buyer_contract__contract_number',
    'conditions_buyer',
    'buyer_nineteen_invoice__number',
    'buyer_ten_invoice__number',
    'buyer_contract__price',
    'buyer_to_be_invoiced',
    'buyer_to_be_invoiced_balance',
    'buyer_invoiced',
    'buyer_invoiced_balance',
    'buyer_paid',
    'buyer_paid_balance',
    'buyer_total_invoiced_plan',
    'buyer_total_invoiced',
    'buyer_total_paid',
    'cargo_brand',
    'bags_weight',
    'bags_quantity',
    'additional_info',
    'estimated_receiving_date',
    'intermediate_contract__contract_number',
    'producer',
    'receiver',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_supplier: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'status',
    'approval_status',
    'payment',
    'crop',
    'volume_departed',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_boarded',
    'volume_lost',
    'loading_shortage',
    'loading_date',
    'receiving_date',
    'supplier_contract_supplier_name',
    'exporter',
    'forwarder',
    'station',
    'station_receiving',
    'port__name',
    'supplier_contract__contract_number',
    'conditions_supplier',
    'supplier_nineteen_invoice__number',
    'supplier_ten_invoice__number',
    'supplier_contract__price',
    'supplier_to_be_invoiced',
    'supplier_to_be_invoiced_balance',
    'supplier_invoiced',
    'supplier_invoiced_balance',
    'supplier_paid',
    'supplier_paid_balance',
    'supplier_total_invoiced_plan',
    'supplier_total_invoiced',
    'supplier_total_paid',
    'cargo_brand',
    'bags_weight',
    'bags_quantity',
    'additional_info',
    'estimated_receiving_date',
    'intermediate_contract__contract_number',
    'producer',
    'receiver',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_certs: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'acceptance_certificate',
    'billoflading',
    'railway',
    'quality',
    'vet',
    'quarantine',
    'declaration',
    'eur_one',
    'broker_confirmation',
    'additional_document_one',
    'additional_document_two',
    'additional_document_three',
    'status',
    'payment',
    'crop',
    'disbursementbl',
    'volume_departed',
    'cargo_brand',
    'bags_weight',
    'bags_quantity',
    'additional_info',
    'update_time',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_qualities: [
    'logistic_type',
    'vehicle_number',
    'consignment_number',
    'custom_status',
    'voyage',
    'payment',
    'crop',
    'approval_status',
    'has_diff_limit',
    'qualities',
    'volume_departed',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_discounted',
    'volume_boarded',
    'discount_volume',
    'supplier_contract__price',
    'supplier_contract__contract_number',
    'discount_purchase_price',
    'purchase_init_price',
    'purchase_total_value',
    'purchase_discount_value',
    'purchase_total_value_discounted',
    'buyer_contract__price',
    'buyer_contract__contract_number',
    'discount_sale_price',
    'sale_init_price',
    'sale_total_value',
    'sale_discount_value',
    'sale_total_value_discounted',
    'volume_sale_trader',
    'sale_trader_amount',
    'sale_trader_volume_diff',
    'sale_trader_amount_diff',
    'sale_trader_discount_amount',
    'additional_info',
    'update_time',
    'deliverer_in_port',
    'export_contract_price',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_export: [
    'logistic_type',
    'shipment_warehouse',
    'unloading_warehouse',
    'vehicle_number',
    'consignment_number',
    'crop',
    'volume_boarded',
    'volume_departed',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'export_contract',
    'export_invoices_quantity_sum',
    'export_invoice_list',
    'additional_info',
    'update_time',
    'customs_declarations_data',
    'cargo_customs_declarations_data',
    'periodic_customs_declarations_data',
    'fromStockReserveUnit',
    'toStockReserveUnit',
  ],
  table_pnl: [
    'vehicle_number',
    'buyer_contract__contract_number',
    'supplier_contract__contract_number',
    'volume_departed',
    'volume_departed_consignment',
    'volume_received',
    'volume_departed_real',
    'volume_boarded',
    'supplier_contract_price',
    'buyer_contract_price',
    'simple_purchase_price',
    'simple_sale_price',
    'total_purchase_value',
    'total_sale_value',
    'transport_costs_amount',
    'invoice_positions_amount',
    'payment_amount_sum',
    'purchase_contract_cost_per_ton',
    'sale_contract_cost_per_ton',
    'passport_cost_per_ton',
    'passports_related',
    'purchase_invoice_total',
    'sale_invoice_total',
    'contract_pnl_per_auto_with_vat',
    'sale_contract_pnl_per_t_with_vat',
    'purchase_contract_pnl_per_t_with_vat',
    'execution_pnl_per_auto_with_vat',
    'sale_execution_pnl_per_t_with_vat',
    'purchase_execution_pnl_per_t_with_vat',
    'invoices_pnl_per_auto_with_vat',
    'sale_invoices_pnl_per_t_with_vat',
    'purchase_invoices_pnl_per_t_with_vat',
    'payments_pnl_per_auto_with_vat',
    'sale_payments_pnl_per_t_with_vat',
    'purchase_payments_pnl_per_t_with_vat',
  ],
};
