import React from 'react';

export const ServicesContractsContainerLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <section className="mt-2 !flex flex-col gap-3" data-testid="services-contracts-container">
      {children}
    </section>
  );
};
