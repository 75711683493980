import {
  CheckboxRead,
  DateRead,
  DateWrite,
  EmailRead,
  EmailWrite,
  EntityWrite,
  LabelRead,
  LabelWrite,
  MultiSelectRead,
  MultiSelectWrite,
  NumberRead,
  NumberWrite,
  PersonRead,
  PhoneRead,
  PhoneWrite,
  SelectRead,
  SelectWrite,
  TextFieldRead,
  TextFieldWrite,
  UrlRead,
  UrlWrite,
} from '~/shared/ui/data-types/components';

import type { CommonDataType } from './lib';
import { entityAutocompletesRegistry } from './lib';

export const useEntityAutocomplete = (entityName: string) =>
  entityAutocompletesRegistry[entityName];

export const useDataTypeControl = (
  type: CommonDataType,
  mode: 'read' | 'write',
  entity?: string,
) => {
  const commonTypes = {
    text: { read: TextFieldRead, write: TextFieldWrite },
    string: { read: TextFieldRead, write: TextFieldWrite },
    number: { read: NumberRead, write: NumberWrite },
    checkbox: { read: CheckboxRead, write: CheckboxRead },
    select: { read: SelectRead, write: SelectWrite },
    multiselect: { read: MultiSelectRead, write: MultiSelectWrite },
    url: { read: UrlRead, write: UrlWrite },
    email: { read: EmailRead, write: EmailWrite },
    phone: { read: PhoneRead, write: PhoneWrite },
    label: { read: LabelRead, write: LabelWrite },
    entity: { read: LabelRead, write: EntityWrite },
    date: { read: DateRead, write: DateWrite },
    person: { read: PersonRead, write: PersonRead },
  };

  if (entity) {
    return entityAutocompletesRegistry[entity];
  }

  return commonTypes[type][mode];
};
