import React from 'react';

import { DateRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const DateCellRead = ({ value }: CellTypeProps) => {
  return (
    <div className="px-2">
      <DateRead value={new Date(String(value))} />
    </div>
  );
};
