import type ng from 'angular';

import type { FinanceCreate, FinanceList, FinanceListTotals } from '~/features/finances';
import { FinanceListViewModel } from '~/features/finances';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';
import type { Subscription } from '~/shared/lib/state';

import template from './invoice-list-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

type ViewMode = 'table' | 'spreadsheet' | 'block' | 'list';
type InvoiceList = FinanceList & { $_selected: boolean };

class InvoiceListContainerController implements ng.IController {
  viewModel: FinanceListViewModel;
  subs: Record<string, Subscription> = {};
  pageData: { count: number; results: FinanceList[] } = { count: 0, results: [] };
  financesCount = 0;
  finances: InvoiceList[] = [];
  financesTotal: FinanceListTotals = {} as FinanceListTotals;
  newFinance: FinanceCreate & { use: string } = {} as FinanceCreate & { use: string };
  view: ViewMode = 'table';
  editing = false;

  activeFilterPresets: any;
  addButton: any;
  addInvoiceButton: any;
  addInvoiceButtonFunc: any;
  approvals: any;
  createPlanButton: any;
  filterLevel = 'invoices-list-container';
  initQueryParams: QueryParams = {};
  inlineOrderingParams: any;
  paidInvoicesListener: any;
  paymentsExportConfig: any;
  queryParams: QueryParams = {};
  quickAdd: any;
  reportConfig: any;
  reportConfigFilterLevel = 'invoices-list-container';
  savedFilterChoices: any;
  savedReportConfigs: any;
  selectToApprove: any;
  selectToDocument: any;
  selectToPay: any;
  selectToPayment: any;
  selectedFinances: any;
  showPaymentsExportButton: any;
  timelineChartItems: any;
  user: any;
  static readonly $inject = [
    '$rootScope',
    '$scope',
    '$window',
    '$filter',
    '$q',
    'gettext',
    'GtUtils',
    'FinancesService',
    'gtFilterService',
    'DocumentsService',
    'CoreService',
    'ClientsService',
    'AccountsService',
  ];

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $scope: ng.IScope,
    private readonly $window: ng.IWindowService,
    private readonly $filter: ng.IFilterService,
    private readonly $q: ng.IQService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly GtUtils: GtUtilsService,
    private readonly FinancesService: FinancesService,
    private readonly gtFilterService: GtFilterService,
    private readonly DocumentsService: any,
    private readonly CoreService: CoreService,
    private readonly ClientsService: any,
    private readonly AccountsService: AccountsService,
  ) {
    this.viewModel = container.resolve(FinanceListViewModel);

    this.quickAdd = false;
    this.selectToPayment = false;
    this.selectToDocument = false;
    this.selectToApprove = false;
    this.selectToPay = false;
    this.addButton = false;
    this.createPlanButton = null;
    this.addInvoiceButton = false;
    this.addInvoiceButtonFunc = null;
    this.showPaymentsExportButton = false;
    this.activeFilterPresets = [];
    this.inlineOrderingParams = [];
    this.selectedFinances = [];

    this.user = {};
    this.approvals = {};
    this.addInvoiceButton = true;
    this.paymentsExportConfig = '';
    this.reportConfig = null;
    this.timelineChartItems = [];
  }

  $onInit() {
    if (this.$rootScope.isDeviceMobile) {
      this.view = 'block';
    } else {
      this.view = ['list', 'block'].includes(this.$rootScope.user.profile?.invoices_view ?? '')
        ? (this.$rootScope.user.profile?.invoices_view as ViewMode)
        : ((this.initQueryParams.view ?? 'table') as ViewMode);
      delete this.initQueryParams.view;
    }
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };

    this.paymentsExportConfig = this.FinancesService.getPaymentsExportConfig();

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);

    this.$rootScope.$on(
      `gt-report-config-selected_${this.filterLevel}`,
      (event: any, config: any) => {
        this.reportConfig = config ? config.column_params : null;
      },
    );

    this.$rootScope.$on(
      `gt-report-config-created_${this.filterLevel}`,
      (event: any, config: any) => {
        this.savedReportConfigs = [...this.savedReportConfigs, config];
      },
    );

    this.$rootScope.$on(
      `gt-report-config-updated_${this.filterLevel}`,
      (event: any, config: any) => {
        const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
        this.savedReportConfigs[index] = config;
      },
    );

    this.paidInvoicesListener = this.$rootScope.$on(
      'gt-invoices-paid',
      (ev: any, finances: any) => {
        this.onPayInvoices(finances);
        this.viewModel.pageParamsChanged({});
        this.updateOrdering();
      },
    );

    this.$rootScope.$on('gt-invoices-approved', (ev: any, finances: any) => {
      this.onApproveInvoices(finances);
      this.viewModel.pageParamsChanged({});
      this.updateOrdering();
    });

    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.updateFinances();
      this.updateOrdering();
      this.$scope.$applyAsync();
    });

    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.financesTotal = totals;
      this.$scope.$applyAsync();
    });

    this.subs.editing = this.viewModel.editing$.subscribe((editing) => {
      this.editing = editing;
      this.$scope.$applyAsync();
    });

    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
    this.savedReportConfigs = this.CoreService.getSavedReportConfigs(
      this.reportConfigFilterLevel || this.filterLevel,
    );
    this.addInvoiceButtonFunc = this.addInvoiceButtonFunc || this.openFinanceModal;
  }

  $onChanges() {
    this.queryParams = {
      serializer: 'table_info',
      ...this.initQueryParams,
    };
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    this.paidInvoicesListener();
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: QueryParams) => {
    this.queryParams = params;
    this.viewModel.pageParamsUpdated({
      ...params,
      page_size: Number(params.page_size) || 25,
      page: Number(params.page) || 1,
    });
  };

  setEditing(editing: boolean) {
    this.viewModel.setEditing(editing);
  }

  changeView(view: ViewMode) {
    this.view = view;
  }

  updateOrdering() {
    this.inlineOrderingParams = [
      { title: 'Issuance date', value: 'date' },
      { title: 'Date of receiving', value: 'date_of_receiving' },
      { title: 'Date of payment (plan)', value: 'date_of_execution' },
    ];
  }

  getIcon(resourceName: string) {
    return this.GtUtils.getIcon(resourceName);
  }

  applyFilters(params: QueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }

  saveSelectedFinances() {
    return this.finances.filter((finance) => finance.$_selected);
  }

  updateFinances() {
    this.GtUtils.overlay('show');
    this.newFinance = {
      condition: 'prepay',
      use: 'cargo',
      ...this.queryParams,
    };
    if (this.queryParams.next) {
      this.finances = this.finances.concat(this.pageData.results as InvoiceList[]);
      delete this.queryParams.next;
    } else {
      this.finances = this.pageData.results as InvoiceList[];
      this.setSelectedFinancesFromState();
    }
    this.timelineChartItems = this.pageData.results
      .filter((item) => item.date || item.date_of_execution)
      .map((item) => ({
        id: item.id.toString(),
        start: item.date || item.date_of_execution,
        end: item.date_of_execution ?? item.date,
        name: item.number.toString(),
        popupHtml: `From: ${String(item.clientrole_from_name)}<br />
        To: ${String(item.clientrole_to_name)}<br />
        <span><a href="#/payment/${String(item.id)}/"
        target="_blank">
        Details <i class="fa fa-arrow-right"></i></a></span>`,
      }));
    this.financesCount = this.pageData.count;
  }

  updateInvoicesDates(data: any[]) {
    this.GtUtils.overlay('show');
    let chain = this.$q.when();

    data.forEach((item: { id: { toString: () => any }; start: any; end: any }) => {
      const finance = this.finances.find(
        (f: { id: { toString: () => any } }) => f.id.toString() === item.id.toString(),
      );

      if (finance) {
        const financeToUpdate = { ...finance, date: item.start, date_of_execution: item.end };

        chain = chain.then(() => this.FinancesService.updateInvoice(financeToUpdate));
      }
    });

    chain.then(() => {
      this.GtUtils.overlay('hide');
      this.viewModel.pageParamsChanged({});
    });
  }

  setSelectedFinancesFromState() {
    this.finances.forEach((finance: any) => {
      finance.$_selected = this.selectedFinances.includes(finance);
    });
  }

  createPaymentPlan(finances: any, paymentPlanId: number) {
    this.selectToPayment = false;
    if (!finances) {
      return;
    }
    if (!finances.length) {
      notify(this.GtUtils.translate(this.gettext('Empty finances list')), 'warning');
      return;
    }

    const paymentPlan = { id: paymentPlanId, finances: [] as any[] };

    this.finances = this.updateFinancesFromSelectedList(finances, this.selectedFinances);

    this.finances
      .filter((finance: any) => finance.$_selected && finance.$_inputedNumber != 0)
      .forEach((finance: any) => {
        paymentPlan.finances.push({
          finance: finance.id,
          value: finance.$_inputedNumber,
          finance_number: finance.number,
          to_pay: finance.to_pay,
          additional_info: finance.additional_info,
          clientrole_from_name: finance.clientrole_from_name,
          clientrole_from_role: finance.clientrole_from_role,
          clientrole_from: finance.clientrole_from,
          clientrole_to_name: finance.clientrole_to_name,
          clientrole_to_role: finance.clientrole_to_role,
          clientrole_to: finance.clientrole_to,
          isOverpaid: finance.fact_amount > finance.amount,
          currency_symbol: finance.currency_symbol,
          invoicepositions:
            finance.invoicepositions_set?.map((position: any) => {
              return {
                quantity: position.quantity_sum,
                subuses: position.subuses,
                use: position.use,
              };
            }) || [],
        });
      });

    this.selectedFinances = [];

    return this.FinancesService.paymentPlanModal(paymentPlan).then(() =>
      this.viewModel.pageParamsChanged({}),
    );
  }

  updateFinancesFromSelectedList(finances: any, selectedFinances: any) {
    selectedFinances.forEach((selectedFinance: any) => {
      const financeExists = finances.find((finance: any) => finance.id === selectedFinance.id);

      if (!financeExists) {
        finances.push(selectedFinance);
      }
    });

    return finances;
  }

  createDocument(finances: any) {
    this.selectToDocument = false;
    if (!finances.length) {
      return notify(this.GtUtils.translate(this.gettext('Empty finances list')), 'warning');
    }
    const objectId = finances?.[0];
    return this.DocumentsService.generateDocxModal('Finance', objectId, {
      objects_id: finances,
    });
  }

  openFinanceModal = (finance: any, data: any) => {
    return this.FinancesService.financeModal(finance, data).then((data: any) => {
      if (this.selectToPay || this.selectToPayment || this.selectToApprove) {
        if (data !== 'cancel') {
          this.viewModel.pageParamsChanged({});
        }
      } else {
        this.viewModel.pageParamsChanged({});
      }
    });
  };

  openDocumentModal(finance: any) {
    return this.DocumentsService.documentListModal({
      content_type: finance.content_type,
      object_id: finance.id,
    }).then(() => this.viewModel.pageParamsChanged({}));
  }

  openPaymentPlanModal(paymentPlan: any) {
    return this.FinancesService.paymentPlanModal(paymentPlan);
  }

  openDocxModal(finance: any) {
    return this.DocumentsService.generateDocxModal('Finance', finance.id);
  }

  openRoleModal(roleId: number, roleModel: any) {
    return this.ClientsService.roleModal({ id: roleId, model_name: roleModel });
  }

  clone(finance: any) {
    if (!confirm(this.gettext('Do you want to clone this Invoice?'))) {
      return false;
    }
    return this.FinancesService.cloneInvoice(finance).then(() =>
      this.viewModel.pageParamsChanged({}),
    );
  }

  isGreen(finance: any) {
    if (finance.invoice_position_sum == 0 && finance.invoiced_logistics == 0) {
      return true;
    }

    return (
      finance.invoice_position_sum <= finance.invoiced_logistics + 0.05 &&
      finance.invoice_position_sum >= finance.invoiced_logistics - 0.05
    );
  }

  addTableOptions(options: any) {
    this.activeFilterPresets = options.activeFilterPresets;
  }

  cancelSelection() {
    this.selectToPayment = false;
    this.selectToDocument = false;
    this.selectToApprove = false;
    this.selectToPay = false;
  }

  isCreatePaymentPlanShown() {
    return (
      this.financesCount &&
      this.createPlanButton &&
      this.view === 'table' &&
      this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_paymentplans
    );
  }

  onApproveInvoices(finances: any) {
    this.cancelSelection();
    finances.forEach((finance: any) => {
      this.AccountsService.voteApprovable('approve', finance.id, finance.content_type, '');
    });
  }

  onPayInvoices(finances = [] as any[]) {
    this.cancelSelection();

    finances.forEach((finance) => {
      if (finance.currency_symbol === 'USD') {
        this.payInvoice({
          amount: finance.$_inputedNumber,
          date: new Date(),
          finance: finance.id,
        });
      } else {
        this.FinancesService.getLatestExchangeObject(finance.currency)
          .then((exchange: any) => {
            const financeToCreate = {
              amount: finance.$_inputedNumber,
              date: new Date(),
              finance: finance.id,
              currency_exchange: exchange.id,
            };

            this.payInvoice(financeToCreate);
          })
          .catch((error: any) => {
            this.GtUtils.errorClb(error);
          });
      }
    });
  }

  payInvoice(finance: any) {
    this.FinancesService.Payment.save(finance)
      .$promise.then(() => {
        notify(this.gettext('Payment successfully created'));
      })
      .catch((error: any) => {
        this.GtUtils.errorClb(error);
      });
  }
}

export const invoicesListContainer: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    quickAdd: '=?',
    addButton: '<?',
    createPlanButton: '@?',
    addInvoiceButton: '<?',
    addInvoiceButtonFunc: '&?',
    showPaymentsExportButton: '@?',
    view: '<?',
    simplifiedView: '<?',
    showTotals: '<?',
    readonly: '<',
    reportConfigFilterLevel: '<?',
  },
  template,
  controller: InvoiceListContainerController,
};
