import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';
export class LoyalityProgramDataService {
  $http: ng.IHttpService;
  $q: ng.IQService;
  prevData: any;
  prevParams: any;
  constructor($http: ng.IHttpService, $q: ng.IQService) {
    this.$http = $http;
    this.$q = $q;

    this.prevParams = {};
    this.prevData = [];
  }

  fetchPremiumData(
    obj: any,
    dateFrom: Date | string,
    dateTo: Date | string,
    bonusPercentage: number,
  ) {
    if (!dateFrom || !dateTo) {
      return this.$q.when();
    }

    const fromDate = typeof dateFrom === 'string' ? new Date(dateFrom) : dateFrom;
    const toDate = typeof dateTo === 'string' ? new Date(dateTo) : dateTo;

    const monthTo = formatDate(new Date(toDate.getFullYear(), toDate.getMonth(), 1), 'dd.MM.yyyy');
    const monthFrom = formatDate(
      new Date(fromDate.getFullYear(), fromDate.getMonth(), 1),
      'dd.MM.yyyy',
    );

    const params: {
      contract_option: string;
      price: number;
      volume: number;
      cargo: string;
      premium_bonus_percentage: number;
      month?: string;
      month_from?: string;
      month_to?: string;
    } = {
      contract_option: obj.contract_option,
      price: obj.price,
      volume: obj.volume,
      cargo: obj.cargo,
      premium_bonus_percentage: bonusPercentage,
    };
    if (monthFrom == monthTo) {
      params.month = monthFrom;
    } else {
      params.month_from = monthFrom;
      params.month_to = monthTo;
    }

    if (!this.paramsChanged(params)) {
      return this.$q.when().then(() => this.prevData);
    }

    return this.$http
      .get('/api/contracts/contract-option-group-monthes/get_premium_program_data/', {
        params: params,
      })
      .then((response: any) => {
        this.prevData = response.data;
        return response.data;
      });
  }

  paramsChanged(params: object) {
    if (JSON.stringify(this.prevParams) == JSON.stringify(params)) {
      return false;
    } else {
      this.prevParams = { ...params };
      return true;
    }
  }
}
LoyalityProgramDataService.$inject = ['$http', '$q'];
