import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').directive('passportsPassportItemTr', directive);

  function directive() {
    return {
      template: require('./passport-item-tr.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        passport: '<',
        mode: '<?', // 'plan' or 'fact'
        onUpdate: '&?',
        useValuesWithVat: '<?',
        currency: '<?',
        columnVisibility: '<?',
        onMenuHover: '&?',
        openContractModal: '&?',
        hideTickets: '<',
        hideContracts: '<',
        hideExecution: '<',
        hideInvoices: '<',
        hideCosts: '<',
        hidePayments: '<',
        customFieldsTableOptions: '<?',
      },
    };
  }

  Controller.$inject = [
    'ContractsService',
    'LogisticsService',
    'DocumentsService',
    'AccountsService',
    'GtUtils',
    'gettext',
  ];

  function Controller(
    this: any,
    ContractsService: ContractsService,
    LogisticsService: LogisticsService,
    DocumentsService: any,
    AccountsService: AccountsService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.user = AccountsService.user;
    vm.expanded = false;
    vm.openPassportModal = openPassportModal;
    vm.tab = 'info';
    vm.mode = vm.mode || 'table_fact';
    vm.subtab = 'list';
    vm.sum = sum;
    vm.currency = vm.currency || 'local'; // can be: 'usd' || 'local'
    vm.convert = convert;
    vm.db_convert = dbConvert;
    vm.curSign = curSign;
    vm.createContractFromTickets = createContractFromTickets;
    vm.diffDays = diffDays;
    vm.createVoyage = createVoyage;
    vm.openDocxModal = openDocxModal;
    vm.approvalAction = approvalAction;
    vm.createMarginControl = createMarginControl;
    vm.getIcon = GtUtils.getIcon;
    vm.customFieldsTableOptions = vm.customFieldsTableOptions || {};

    function openPassportModal(passport: any) {
      return ContractsService.passportModal(passport).then(function (data: any) {
        if (!data || data == 'cancel') {
          return;
        }
        vm.onUpdate();
      });
    }

    function diffDays(firstDate: any, secondDate: any) {
      if (!firstDate || !secondDate) {
        return '--';
      }
      return Math.round(
        Math.abs((firstDate.getTime() - secondDate.getTime()) / (24 * 60 * 60 * 1000)),
      ).toString();
    }

    function sum(operands: any) {
      let result = 0;
      ng.forEach(operands, function (operand: any) {
        result += parseFloat(operand);
      });
      return result;
    }

    function convert(value: any) {
      value = parseFloat(value);
      return (vm.currency == 'usd' && value) || value * (vm.passport.exchange_rate || 1);
    }

    function dbConvert(usd: any, local: any) {
      if (vm.currency && vm.currency.toLowerCase() == 'usd') {
        return usd;
      } else {
        return local;
      }
    }

    function curSign() {
      return vm.currency == 'usd' ? '$' : vm.passport?.results.currency_symbol;
    }

    function createContractFromTickets() {
      GtUtils.overlay('show');
      return ContractsService.executePassport(vm.passport).then(
        () => {
          GtUtils.overlay('hide');
          vm.onUpdate();
        },
        (data: any) => GtUtils.errorClb(data),
      );
    }

    function createVoyage() {
      return LogisticsService.voyageModal().then(function (data: any) {
        if (!data || data == 'cancel' || !data.id) {
          return;
        }
        vm.passport.voyage = data.id;
        return ContractsService.Passport.update(vm.passport, function (data: any) {
          notify(gettext('Passport updated'));
          vm.passport = data;
          vm.onUpdate();
        }).$promise;
      });
    }

    function openDocxModal(passport: any) {
      return DocumentsService.generateDocxModal('Passport', passport.id);
    }

    function approvalAction(action: any) {
      return AccountsService.voteApprovable(
        action,
        vm.passport.id,
        vm.passport.content_type,
        '',
      ).then(() => vm.onUpdate());
    }

    function createMarginControl() {
      ContractsService.MarginControl.save({
        passport: vm.passport.id,
        custom_status: vm.passport.custom_status,
        margin_selector: vm.passport.margin_selector,
        ticket_margin_t: vm.passport.plans_margin_per_t || 0,
        contract_margin_t: vm.passport.facts_margin_per_t || 0,
        execution_margin_t: vm.passport.facts_transport_margin_per_t || 0,
        invoice_margin_t: vm.passport.facts_invoice_margin_per_t || 0,
        payment_margin_t: vm.passport.facts_payment_margin_per_t || 0,
        ticket_margin: vm.passport.plans_margin_with_vat || 0,
        contract_margin: vm.passport.facts_margin_with_vat || 0,
        contract_analytical_margin: vm.passport.facts_margin_analytical_with_vat || 0,
        execution_margin: vm.passport.facts_transport_margin_with_vat || 0,
        invoice_margin: vm.passport.invoices_margin_with_vat || 0,
        payment_margin: vm.passport.payments_margin_with_vat || 0,
        derivative_margin: vm.passport.derivativeMargin || 0,
        ticket_analytical_margin: vm.passport.plans_margin_analytical_with_vat || 0,
      }).$promise.then(
        () => {
          vm.onUpdate();
          notify(gettext('Margin control created'));
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }
  }
})();
