import {
  accountsUsersCreate,
  accountsUsersDestroy,
  accountsUsersList,
  accountsUsersPartialUpdate,
  accountsUsersPredictionsRetrieve,
  accountsUsersRetrieve,
} from '~/shared/api';
import { type Entity, EntityRepository, type Paginated } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

import type { User, UserCreate, UserQueryParams } from '../lib/types';

@singleton()
export class UserRepository extends EntityRepository<User, UserQueryParams, UserCreate> {
  get = async (id: number): Promise<User> => {
    const { data } = await accountsUsersRetrieve({ path: { id } });
    return data;
  };
  query = async (params: UserQueryParams): Promise<Paginated<User>> => {
    const { data } = await accountsUsersList({ query: params });
    return data;
  };
  search = async (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (entity: UserCreate): Promise<User> => {
    const { data } = await accountsUsersCreate({ body: entity });
    return data;
  };
  update = async (entity: Partial<UserCreate> & Entity): Promise<User> => {
    const { data } = await accountsUsersPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };
  delete = async (id: number): Promise<void> => {
    await accountsUsersDestroy({ path: { id } });
  };
  predictions = async (params: UserQueryParams) => {
    const { data } = await accountsUsersPredictionsRetrieve({ query: params });
    return data;
  };
}
