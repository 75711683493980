import React from 'react';

import type { CellRenderDef } from '~/shared/ui/components/data-grid';

import { DynamicReadCell } from './dynamic-read-cell';
import { DynamicWriteCell } from './dynamic-write-cell';

export const CellRender = ({
  value,
  row,
  valueChanged,
  id,
  cellTemplateContext,
  dataTypeVariant,
  dataType,
  typeExtra,
  render,
  disabled,
  valueFormatter,
  valueParser,
}: CellRenderDef) => {
  const valueFormatted = React.useMemo(
    () => (valueFormatter ? valueFormatter(value, row) : value),
    [valueFormatter, value, row],
  );

  const valueChangedHandler = React.useCallback(
    (value: unknown) => {
      const returnVal = valueParser ? valueParser(value, row) : value;
      valueChanged(returnVal);
    },
    [valueParser, valueChanged, row],
  );
  if (dataTypeVariant === 'read') {
    return (
      <DynamicReadCell
        id={id}
        value={valueFormatted}
        row={row}
        dataType={dataType}
        cellTemplateContext={cellTemplateContext}
        valueChanged={valueChangedHandler}
        typeExtra={typeExtra}
        render={render}
      />
    );
  } else {
    return (
      <DynamicWriteCell
        render={render}
        value={valueFormatted}
        row={row}
        dataType={dataType}
        rowId={id}
        valueChanged={valueChangedHandler}
        cellTemplateContext={cellTemplateContext}
        typeExtra={typeExtra}
        disabled={disabled}
      />
    );
  }
};
