import React from 'react';

import { Skeleton } from '~/shared/ui/kit/skeleton';

export const Loading: React.FC<{ recordsCount: number; rowsCount: number }> = ({
  recordsCount,
  rowsCount,
}) => {
  return (
    <div>
      {Array.from({ length: rowsCount }).map((_, rowIdx) => (
        <div key={`row-${rowIdx + recordsCount}`} className="flex">
          {Array.from({ length: recordsCount }).map((_, cellIdx) => (
            <div
              key={`row-${rowIdx}-cell-${cellIdx}`}
              className="border-stroke-main-light flex h-8 w-[150px] items-center justify-center border-b"
            >
              <Skeleton width={140} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
