import { formatDate } from '~/shared/lib/utils';

import template from './consolidated-crop-position-table.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const ConsolidatedCropPositionTable = {
  bindings: {
    cropData: '<',
    onUpdate: '&',
  },
  template,
  controller: [
    'GtUtils',
    'ConsolidatedCropPositionService',
    'ContractsService',
    class {
      ConsolidatedCropPositionService: any;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      cropData?: {
        months: string[];
        basisReports: {
          months: { showContractTypes: boolean; contract_types: string[] }[];
          inventory: {
            showPorts: boolean;
            showOnRoadPorts: boolean;
            inventories: { showTerminals: boolean }[];
          };
        }[];
        paperReports: {
          months: { showContractTypes: boolean; contract_types: string[] }[];
        }[];
      };
      cropDataKeys: any;
      cropDataMonthIndexes: any;
      isContractCollapsed: any;
      isContractTypeCollapsed: any;
      isPortCollapsed: any;
      onUpdate: any;
      constructor(
        GtUtils: GtUtilsService,
        ConsolidatedCropPositionService: any,
        ContractsService: ContractsService,
      ) {
        this.GtUtils = GtUtils;
        this.ConsolidatedCropPositionService = ConsolidatedCropPositionService;
        this.ContractsService = ContractsService;
        this.cropDataKeys = [];

        this.isContractTypeCollapsed = false;
        this.isPortCollapsed = false;
        this.isContractCollapsed = false;
      }
      $onInit() {
        this.cropDataKeys = this.cropData?.months;
        this.cropDataMonthIndexes = this.cropDataKeys.map((month: any, index: number) => index);
      }

      getMonthName(number: number) {
        const date = new Date();
        date.setMonth(number - 1);
        return formatDate(date, 'MMMM');
      }

      openContractModal(contract: any) {
        this.ContractsService.contractModal({ id: contract.contract_id })
          .then((data: any) => {
            if (!data || data == 'cancel') {
              return;
            }
            return this.onUpdate();
          })
          .catch(this.GtUtils.errorClb);
      }

      toggleContractTypes() {
        this.isContractTypeCollapsed = !this.isContractTypeCollapsed;
        this.cropData?.basisReports.forEach((basisReport) => {
          Object.values(basisReport.months).forEach(
            (monthReport) => (monthReport.showContractTypes = this.isContractTypeCollapsed),
          );
        });
        this.cropData?.paperReports.forEach((paperReport) => {
          Object.values(paperReport.months).forEach(
            (monthReport) => (monthReport.showContractTypes = this.isContractTypeCollapsed),
          );
        });
      }

      togglePorts() {
        this.isPortCollapsed = !this.isPortCollapsed;
        this.cropData?.basisReports.forEach((basisReport) => {
          Object.values(basisReport.months).forEach((monthReport) => {
            monthReport.showContractTypes = this.isPortCollapsed;
            monthReport.contract_types.forEach(
              (contractType: any) => (contractType.showPorts = this.isPortCollapsed),
            );
          });
          basisReport.inventory.showPorts = this.isPortCollapsed;
          basisReport.inventory.inventories.forEach((warehouseGroupReport: any) => {
            warehouseGroupReport.showTerminals = this.isPortCollapsed;
          });
        });
      }

      toggleContracts() {
        this.isContractCollapsed = !this.isContractCollapsed;
        this.cropData?.basisReports.forEach((basisReport) => {
          Object.values(basisReport.months).forEach((monthReport) => {
            monthReport.showContractTypes = this.isContractCollapsed;
            monthReport.contract_types.forEach((contractType: any) => {
              contractType.showPorts = this.isContractCollapsed;
              contractType.ports.forEach(
                (portReport: any) => (portReport.showContracts = this.isContractCollapsed),
              );
            });
          });
          basisReport.inventory.showPorts = this.isContractCollapsed;
          basisReport.inventory.showOnRoadPorts = this.isContractCollapsed;
          basisReport.inventory.inventories.forEach((warehouseGroupReport: any) => {
            warehouseGroupReport.showTerminals = this.isContractCollapsed;
            warehouseGroupReport.showOnRoadTerminals = this.isContractCollapsed;
            warehouseGroupReport.data.forEach((warehouseReport: any) => {
              warehouseReport.showContracts = this.isContractCollapsed;
              warehouseReport.showOnRoadContracts = this.isContractCollapsed;
            });
          });
        });
        this.cropData?.paperReports.forEach((paperReport) => {
          Object.values(paperReport.months).forEach((monthReport) => {
            monthReport.showContractTypes = this.isContractCollapsed;
            monthReport.contract_types.forEach((contractType: any) => {
              contractType.showContracts = this.isContractCollapsed;
            });
          });
        });
      }
    },
  ],
};
