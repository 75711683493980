import { logisticsBasisesList } from '~/shared/api';

import type { BasisListParams } from '../types';

export class BasisRepository {
  query = async (query: BasisListParams) => {
    const { data } = await logisticsBasisesList({ query });
    return data;
  };
}
