import { coreCustomStatusesList } from '~/shared/api';

import type { CustomStatusListParams } from '../types';

export class CustomStatusRepository {
  query = async (query: CustomStatusListParams) => {
    const { data } = await coreCustomStatusesList({ query });
    return data;
  };
}
