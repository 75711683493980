import { BusinessUnitRepository } from '~/features/common/business-units';
import { ClientRoleRepository } from '~/features/common/client-role';
import { CommodityRepository } from '~/features/common/commodity';
import { CountryRepository } from '~/features/common/countries';
import { CustomStatusRepository } from '~/features/common/custom-statuses';
import { MeasurementRepository } from '~/features/common/measurements';
import { BasisRepository } from '~/features/deals/basises';
import { ExportContractsRepository } from '~/features/deals/export-contracts/services/export-contracts.repository';
import { GeneralAgreementRepository } from '~/features/deals/general-agreements';
import { populateContractEntities } from '~/features/deals/shared/contracts';
import { WarehouseRepository } from '~/features/execution/warehouses';
import { injectable } from '~/shared/lib/di';

import { ExportContractCreate, ExportContractUpdate } from '../lib';

@injectable()
export class ExportContractsModel {
  constructor(
    private readonly exportContractsRepo: ExportContractsRepository,
    private readonly commodityRepo: CommodityRepository,
    private readonly clientRoleRepo: ClientRoleRepository,
    private readonly warehouseRepo: WarehouseRepository,
    private readonly businessUnitRepo: BusinessUnitRepository,
    private readonly basisRepo: BasisRepository,
    private readonly countryRepo: CountryRepository,
    private readonly measurementRepo: MeasurementRepository,
    private readonly customStatusRepo: CustomStatusRepository,
    private readonly generalAgreementRepo: GeneralAgreementRepository,
  ) {}

  createExportContracts = async (entities: ExportContractCreate[]) => {
    await Promise.all(
      entities.map((entity) =>
        populateContractEntities(entity, {
          commodityRepo: this.commodityRepo,
          clientRoleRepo: this.clientRoleRepo,
          warehouseRepo: this.warehouseRepo,
          businessUnitRepo: this.businessUnitRepo,
          basisRepo: this.basisRepo,
          countryRepo: this.countryRepo,
          measurementRepo: this.measurementRepo,
          customStatusRepo: this.customStatusRepo,
          generalAgreementRepo: this.generalAgreementRepo,
        }),
      ),
    );
    await Promise.all(
      entities.map(async (entity) => await this.exportContractsRepo.create(entity)),
    );
  };

  updateExportContracts = async (entities: ExportContractUpdate[]) => {
    await Promise.all(entities.map((entity) => this.exportContractsRepo.update(entity)));
  };
}
