import type ng from 'angular';

import { formatDate, startOfDay } from '~/shared/lib/utils';

import type { QueryParams } from '^/app/core/types';

export class StockExchangesService {
  $resource: ng.resource.IResourceService;
  DerivativeAccountResource: any;
  MeasurementCoefficientResource: any;
  MeasurementResource: any;
  OperationResource: any;
  StockExchangeResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor($resource: ng.resource.IResourceService, gettext: ng.gettext.gettextFunction) {
    this.$resource = $resource;
    this.gettext = gettext;

    this.StockExchangeResource = this.$resource(
      '/api/stockexchanges/stock-exchanges/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/stock-exchanges/predictions/',
        },
      },
    );

    this.DerivativeAccountResource = this.$resource(
      '/api/stockexchanges/derivative-accounts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/derivative-accounts/predictions/',
        },
        accountsBalance: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/account-balance/',
        },
        accountsBalanceByMonth: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/account-balance-by-month/',
        },
      },
    );

    this.OperationResource = this.$resource(
      '/api/stockexchanges/operations/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/operations/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/operations/export_column_names/',
        },
      },
    );

    this.MeasurementResource = this.$resource(
      '/api/stockexchanges/measurements/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/stockexchanges/measurements/predictions/',
        },
      },
    );
    this.MeasurementCoefficientResource = this.$resource(
      '/api/stockexchanges/measurement-commodity/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }

  getVolumeByMeasurementQuantity(measurementId: number, cargoId: number, quantity: any) {
    return this.MeasurementCoefficientResource.get({
      measurement: measurementId,
      commodity: cargoId,
    }).$promise.then((data: any) => {
      const measurementCoefficient = data.results[0].coefficient;
      return Number((quantity / measurementCoefficient).toFixed(4)) || 0;
    });
  }

  getPriceByMeasurementPrice(measurementId: number, cargoId: number, price: any) {
    return this.MeasurementCoefficientResource.get({
      measurement: measurementId,
      commodity: cargoId,
    }).$promise.then((data: any) => {
      const measurementCoefficient = data.results[0].coefficient;
      return Number((price * measurementCoefficient).toFixed(4)) || 0;
    });
  }

  getPriceByMeasurementPriceKg(
    measurementId: number,
    cargoId: number,
    priceKg: any,
    volume: any,
    quantity: any,
  ) {
    return this.MeasurementCoefficientResource.get({
      measurement: measurementId,
      commodity: cargoId,
    }).$promise.then((data: any) => {
      const measurementCoefficient = data.results[0].coefficient;
      const measurementPrice = Number(((priceKg / measurementCoefficient) * 1000).toFixed(4)) || 0;
      const price = Number(((measurementPrice * quantity) / volume).toFixed(4)) || 0;
      return { measurementPrice, price };
    });
  }

  getAccountBalance(queryParams: QueryParams) {
    return this.DerivativeAccountResource.accountsBalance(queryParams).$promise.then(
      (response: any) => this.prepareReportData(response.results),
    );
  }

  getAccountBalanceByMonth(queryParams: QueryParams) {
    return this.DerivativeAccountResource.accountsBalanceByMonth(queryParams).$promise.then(
      (response: any) => {
        return response.results;
      },
    );
  }

  prepareReportData(rawData: any) {
    return {
      accounts: rawData,
    };
  }

  getDateRange(startArg: string | Date, endArg: string | Date) {
    const startDate = startOfDay(startArg);
    const endDate = startOfDay(endArg);
    startDate.setHours(0);
    endDate.setHours(0);
    let start = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const end = new Date(endDate.getTime());
    const dateRange: any = [];
    while (start <= end) {
      dateRange.push({
        obj: start,
        shortKey: formatDate(start, 'yyyyMM'),
        yearKey: formatDate(start, 'yyyy'),
        monthKey: formatDate(start, 'MM'),
        title: formatDate(start, 'MMM yyyy'),
      });
      const newDate = start.setMonth(start.getMonth() + 1);
      start = new Date(newDate);
    }
    return dateRange;
  }
}
StockExchangesService.$inject = ['$resource', 'gettext'];
