import React from 'react';

import { PhoneRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const PhoneCellRead = ({ value }: CellTypeProps) => {
  return (
    <div className="px-2">{typeof value === 'string' ? <PhoneRead value={value} /> : null}</div>
  );
};
