import React from 'react';

import type { ColumnDef, EditableOption } from '~/shared/ui/components/data-grid';
import { MultiSelectRecords } from '~/shared/ui/data-types/components/multi-select-read/multi-select-records';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import type { TagVariants } from '~/shared/ui/kit/tag';
import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const MultiSelectRead: React.FC<
  DataTypeProps<string[]> & {
    cellTemplateContext?: ColumnDef['cellTemplateContext'];
    options: EditableOption<TagVariants>[];
  }
> = ({ disabled, value, cellTemplateContext }) => {
  return (
    <div className="flex items-center gap-1">
      {value ? (
        <div className="flex gap-1">
          {value.length > 2 ? (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="flex gap-2">
                  {value.slice(0, 2).map((item) => (
                    <Tag key={item} disabled={disabled} {...cellTemplateContext?.props}>
                      {item}
                    </Tag>
                  ))}
                  <Tag variant="gray">+{value.length - 2}</Tag>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[200px] p-2">
                <MultiSelectRecords
                  value={value}
                  disabled={disabled}
                  cellTemplateContext={cellTemplateContext}
                  className="flex-wrap"
                />
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <MultiSelectRecords
              value={value}
              disabled={disabled}
              cellTemplateContext={cellTemplateContext}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
