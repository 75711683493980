export const removeProtectedFields = <T>({
  data,
  allowedFields,
}: {
  data: T[];
  allowedFields: Set<string>;
}) => {
  return data.map((item) => {
    const record = item as Record<string, unknown>;
    const filteredItem = {} as T;

    Object.keys(record).forEach((key) => {
      if (allowedFields.has(key)) {
        filteredItem[key as keyof T] = record[key] as T[keyof T];
      }
    });
    return filteredItem;
  });
};
