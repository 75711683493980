import type { Transition } from '@uirouter/angularjs';
import ng from 'angular';

import { ThemeStore } from '~/core/theme/services';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';
import { formatDate } from '~/shared/lib/utils';

import type { CoreService } from '../core.service';
import type { GtRootScopeService } from '../types';
import type { PageService } from './components/gt-page/gt-page.srv';
import type { GtUtilsService } from './gt-utils/gt-utils.srv';
import type { AccountsService } from '../../accounts/accounts.service';

import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('core.legacy')
    .run(legacyRunFunction)
    .config(legacyConfigFunction)
    .config(configFunction);

  legacyRunFunction.$inject = [
    '$rootScope',
    '$state',
    '$transitions',
    '$locale',
    '$anchorScroll',
    '$http',
    '$cookies',
    '$location',
    '$stateParams',
    '$urlRouter',
    '$injector',
    '$document',
    'PermPermissionStore',
    'gettext',
  ];

  function legacyRunFunction(
    $rootScope: GtRootScopeService,
    $state: ng.ui.IStateService,
    $transitions: any,
    $locale: ng.ILocaleService,
    $anchorScroll: ng.IAnchorScrollService,
    $http: ng.IHttpService,
    $cookies: ng.cookies.ICookiesService,
    $location: ng.ILocationService,
    $stateParams: ng.ui.IStateParamsService,
    $urlRouter: ng.ui.IUrlRouterService,
    $injector: ng.auto.IInjectorService,
    $document: ng.IDocumentService,
    PermPermissionStore: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const CoreService = $injector.get<CoreService>('CoreService');
    const HelpButtonsService = $injector.get<any>('HelpButtonsService');
    const GtUtils = $injector.get<GtUtilsService>('GtUtils');
    const PageService = $injector.get<PageService>('PageService');
    const themeStore = container.resolve(ThemeStore);

    $locale.NUMBER_FORMATS.GROUP_SEP = ' ';
    // fix date toJSON issue with timezone
    Date.prototype.toJSON = function () {
      return formatDate(this, "yyyy-MM-dd'T'HH:mm:ss");
    };

    // fill caches
    const modelsForCounters: any = [
      'logistics.voyage',
      'logistics.billoflading',
      'logistics.shipmentplan',
      'logistics.logistic',
      'logistics.transport',
      'logistics.vessel',

      'contracts.indicator',
      'contracts.ticket',
      'contracts.request',
      'contracts.purchasecontract',
      'contracts.salecontract',
      'contracts.multicontract',

      'documents.document',

      'clients.client',
      'clients.clientupdate',
      'clients.person',

      'auth.user',
      'passports.passport',

      'finances.finance',
      'finances.payment',
      'finances.bankaccount',
    ];

    GtUtils.getCounters(modelsForCounters).catch(GtUtils.errorClb);

    function isBrowserHeadlessMode() {
      return navigator.webdriver;
    }

    function isDeviceMobile() {
      if (/Android|iPhone/i.test(navigator.userAgent)) {
        return true;
      }
    }

    $rootScope.gtUtils = GtUtils;

    $rootScope.$on('hb-config-updated', function () {
      $rootScope.hbConfig = HelpButtonsService.getConfig();
    });

    $rootScope.$on('page-config-updated', function (ev: any, config: any) {
      $rootScope.hbConfig = config;
      $rootScope.hbConfig.filter = config.filters;
    });

    $transitions.onSuccess({}, function () {
      if ($state.current.data?.pageTitle) {
        PageService.setPageTitle($state.current.data.pageTitle);
      }
      const hbConfig = HelpButtonsService.getConfig();
      if (!hbConfig.filter) {
        return;
      }
      hbConfig.filter.clicked = false;
    });

    $rootScope.$on('$stateChangePermissionDenied', function () {
      notify(gettext('Access denied!'), 'error');
      $state.go('gt.old-page.home');
    });

    $transitions.onBefore({}, (transition: Transition) => {
      if (transition.options().source == 'sref' && transition.from().name != 'gt.page.passport') {
        $location.search({});
        $anchorScroll();
      }
    });

    // set the CSRF token here
    if ($http.defaults.headers) {
      $http.defaults.headers.post['X-CSRFToken'] = $cookies.get('csrftoken');
    }
    $http.defaults.xsrfCookieName = 'csrftoken';
    $http.defaults.xsrfHeaderName = 'X-CSRFToken';

    const user = $injector.get<AccountsService>('AccountsService').user;
    if (!user) {
      throw new Error('User is not defined');
    }
    $rootScope.serverTime = new Date(user.server_time);
    $rootScope.serverTimeZone = user.server_time_zone;
    themeStore.modeChanged(user.profile?.color == 'dark' ? 'dark' : 'light');
    themeStore.setFont(user.profile?.font ?? '');
    CoreService.setLocale(user.profile?.language ?? 'en', user.profile?.day_of_week ?? 1);
    CoreService.getSiteSettings().then((settings: any) => {
      $rootScope.clientSettings = {
        logo_dark: settings.logo_dark,
        logo_light: settings.logo_light,
        theme: user.profile?.color == 'dark' ? 'dark' : 'light',
        host: GtUtils.getCurrentHost(),
      };
    });

    if (isBrowserHeadlessMode()) {
      ng.element($document.find('body'))[0].style.height = 'auto';
      $rootScope.isWithoutNavbar = true;
    }

    if (isDeviceMobile()) {
      $rootScope.isDeviceMobile = true;
    }

    applyWindowsStyles(user);

    PermPermissionStore.defineManyPermissions(user.all_perms, function (perm: any) {
      return user.perms.includes(perm);
    });
    $urlRouter.sync();
    $urlRouter.listen();

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const original = $location.path;
    let unlisten: any = null;

    ($location as any).path = function (path: any, reload: any) {
      if (reload === false) {
        const lastRoute = $stateParams.current;
        if (unlisten) {
          unlisten();
        }

        unlisten = $rootScope.$on('$locationChangeSuccess', function () {
          $stateParams.current = lastRoute;
          unlisten();
          unlisten = null;
        });
      }
      return original.apply($location, [path]);
    };
  }

  function applyWindowsStyles(data: any) {
    if (/Windows/i.test(navigator.userAgent)) {
      (document.body.style as any).webkitFontSmoothing = 'antialiased';
      (document.body.style as any).mozOsxFontSmoothing = 'grayscale';

      if (data.profile.color === 'light') {
        document.body.style.webkitTextStroke = '0.2px #000';
      }
    }
  }

  legacyConfigFunction.$inject = [
    '$urlRouterProvider',
    '$resourceProvider',
    '$interpolateProvider',
    '$httpProvider',
  ];

  function legacyConfigFunction(
    $urlRouterProvider: any,
    $resourceProvider: any,
    $interpolateProvider: any,
    $httpProvider: any,
  ) {
    $interpolateProvider.startSymbol('{[{').endSymbol('}]}');
    $resourceProvider.defaults.stripTrailingSlashes = false;
    $urlRouterProvider.deferIntercept();
    $urlRouterProvider.otherwise(function ($injector: ng.auto.IInjectorService) {
      const $state = $injector.get<ng.ui.IStateService>('$state');
      $state.go('gt.old-page.home');
    });
    $httpProvider.interceptors.push('AuthHttpInterceptor');
  }

  configFunction.$inject = [
    '$locationProvider',
    '$qProvider',
    '$stateProvider',
    'formlyConfigProvider',
    'gettext',
  ];
  function configFunction(
    $locationProvider: any,
    $qProvider: any,
    $stateProvider: ng.ui.IStateProvider,
    formlyConfig: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    $locationProvider.hashPrefix('');
    $qProvider.errorOnUnhandledRejections(false);
    $stateProvider
      .state('gt', {
        template: require('./common/gt-wrapper/gt-wrapper.tpl.html?raw'),
        controller: 'GtWrapperController as vm',
      })
      .state('gt.page', {
        component: 'gtPage',
      })
      .state('gt.old-page', {
        template: require('./common/page/page.tpl.html?raw'),
        controller: 'PageController as vm',
      })
      .state('gt.old-page.home', {
        url: '/',
        component: 'startPage',
        data: {
          pageTitle: gettext('Dashboard'),
        },
      })
      .state('settings', {
        parent: 'gt.page',
        url: '/settings',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('settings.lists', {
        url: '/lists',
        component: 'settingsListsPageView',
      })
      .state('settings.organization', {
        url: '/organization',
        component: 'settingsOrganizationPageView',
        data: {
          pageTitle: gettext('Settings'),
          permissions: {
            only: 'view_settings',
          },
        },
      })
      .state('settings.personal', {
        url: '/personal',
        component: 'settingsPersonalPageView',
        data: {
          pageTitle: gettext('Settings'),
          permissions: {
            only: 'view_settings',
          },
        },
      })
      .state('settings.parsing', {
        url: '/parsing',
        component: 'settingsParsingPage',
        data: {
          pageTitle: gettext('Settings'),
          permissions: {
            only: 'view_settings',
          },
        },
      })
      .state('documentation', {
        parent: 'gt.page',
        url: '/documentation',
        component: 'documentationPageView',
        data: {
          pageTitle: gettext('Documentation'),
        },
      });

    // wrappers
    formlyConfig.setWrapper({
      name: 'gt-panel',
      template: html`<div class="col-sm-12 modal-form-block">
        <div class="col-sm-12 ul-inline-row">
          <label
            class="contract-description-label"
            ng-if="options.templateOptions.label"
            style="margin-bottom: 10px; text-transform: capitalize;"
          >
            {[{ options.templateOptions.label | translate }]}
          </label>
          <formly-transclude></formly-transclude>
          <div class="clearfix"></div>
        </div>
      </div>`,
      overwriteOk: true,
    });
    formlyConfig.setWrapper({
      name: 'gt-label',
      template: html`<div
        ng-hide="to.hide"
        class="form-group col-xs-12 row {[{to.wrapperclass}]}"
        ng-class="{ 'not-empty': options.value() && options.value().length !=0, 'has-error': to.required && (!options.value() || options.value().length ==0)}"
      >
        <div class="row" style="margin: 0 !important">
          <label
            for="{[{id}]}"
            class="col-sm-12 col-md-5 control-label-wrapper control-label {[{to.labelSrOnly ? 'sr-only' : ''}]}"
            ng-if="to.label"
          >
            <span>{[{to.label | translate | cut:true:37:' ' }]}</span>
            <span
              class="tooltip-modal"
              ng-if="to.hint && $rootScope.user.profile.education_mode == 'enabled'"
              data-tip="{[{ to.hint | translate }]}"
            >
              <i class="fa fa-question-circle"></i>
            </span>
          </label>
          <div
            class="col-sm-12 col-md-7 input-wrapper {[{'gt_test_wrapper_' + options.key}]}"
            style="padding-right: 0; padding-left: 0px"
          >
            <formly-transclude></formly-transclude>
            <div class="clearfix"></div>
            <span
              ng-if="to.required && (!options.value() || options.value().length ==0)"
              class="help-inline"
            >
              <i class="fa fa-exclamation-circle fa-bounce"></i> <translate>Required</translate>
            </span>
          </div>
          <span
            ng-if="to.help && $rootScope.user.profile.education_mode == 'enabled'"
            class="help-inline-hint"
            ><i class="fa fa-info-circle"></i> {[{ to.help }]}
          </span>
        </div>
      </div>`,
    });
    formlyConfig.setWrapper({
      name: 'gt-input-wrapper',
      template: html`<div
        class="form-group col-xs-12 {[{to.wrapperclass}]}"
        ng-class="{ 'not-empty': options.value() && options.value().length !=0 }"
        ng-hide="to.hide"
      >
        <div class="row" style="margin: 0 !important; display: flex;">
          <label
            for="{[{id}]}"
            class="col-sm-12 col-md-5 control-label-wrapper control-label {[{to.labelSrOnly ? 'sr-only' : ''}]}"
            ng-if="to.label"
          >
            <span>{[{to.label | translate | cut:true:37:' ' }]}</span>
          </label>
          <span
            class="tooltip-modal"
            ng-if="to.hint && $root.user.profile.education_mode == 'enabled'"
            data-tip="{[{ to.hint | translate }]}"
          >
            <i class="fa fa-question-circle"></i>
          </span>
          <div class="col-sm-12 col-md-7 input-wrapper" style="padding-right: 0; padding-left: 0px">
            <div class="input-group {[{'gt_test_wrapper_' + options.key}]}">
              <formly-transclude></formly-transclude>
              <span ng-if="to.addon" class="input-group-addon"> {[{ to.addon | translate }]} </span>
            </div>
            <div class="clearfix"></div>
            <span ng-if="to.required && options.formControl.$invalid" class="help-inline">
              <i class="fa fa-exclamation-circle fa-bounce"></i> <translate>Required</translate>
            </span>
          </div>
        </div>
        <div class="clearfix"></div>
        <span ng-if="to.help" class="help-inline-hint"
          ><i class="fa fa-info-circle"></i> {[{ to.help }]}
        </span>
      </div>`,
    });
    formlyConfig.setWrapper({
      name: 'gt-has-error',
      template: html`<div
        ng-hide="to.hide"
        ng-class="{ 'formly-full-width': to.position != 'half', 'formly-half-width': to.position == 'half' }"
      >
        <div
          class="form-group"
          ng-class="{'has-error': showError || (to.required && options.formControl.$invalid)} || options.validation.messages.length"
        >
          <formly-transclude></formly-transclude>
          <div
            class="error-msg"
            ng-message="{{::name}}"
            ng-repeat="(name, message) in options.validation.messages"
          >
            {[{message(options.formControl.$viewValue, options.formControl.$modelValue, this)}]}
          </div>
        </div>
      </div>`,
    });
    // types
    formlyConfig.setType({
      name: 'gt-input',
      template: html`<input
          class="form-control {[{'gt_test_field_' + options.key}]}"
          ng-model="model[options.key]"
          ng-model-options="{ debounce: 500 }"
          ng-class="to.className"
          ng-disabled="to.viewOnly"
          placeholder="{[{ to.placeholder | translate | cut:true:10:'' }]}"
          ng-if="to.type === 'number'"
          gt-clear-input
        />
        <input
          class="form-control {[{'gt_test_field_' + options.key}]}"
          ng-model="model[options.key]"
          ng-model-options="{ debounce: 500 }"
          ng-class="to.className"
          ng-disabled="to.viewOnly"
          placeholder="{[{ to.placeholder | translate | cut:true:10:'' }]}"
          ng-if="to.type !== 'number'"
        />
        <span
          class="tooltip copy-clipboard-btn"
          ng-click="copyToClipboard(options, model)"
          data-tip="{[{ 'Copy to clipboard' | translate }]}"
        >
          <i class="fa fa-copy"></i>
        </span>`,
      wrapper: ['gt-input-wrapper', 'gt-has-error'],
      controller: [
        '$scope',
        'GtUtils',
        function ($scope: ng.IScope, GtUtils: GtUtilsService) {
          ($scope as any).copyToClipboard = function (options: any, model: any) {
            GtUtils.copyToClipboard(model[options.key]);
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-multiselect',

      template: html`<select
          multiple="true"
          ng-model="model[options.key]"
          class="form-control"
          ng-disabled="to.disabled"
          size="14"
        >
          <option ng-repeat="option in to.options" value="{[{ option[to.valueProp] }]}">
            {[{ option[to.labelProp] | translate }]}
          </option>
        </select>
        <div
          ng-if="to.addFunc"
          permission
          permission-only="to.addPerms"
          class="form-add-btn-fullwidth"
        >
          <a class="btn add-btn" ng-click="to.addFunc()" target="_blank"
            >+ <i class="fa" ng-class="to.addIcon || 'fa-bookmark-o'"></i
          ></a>
        </div>`,
      wrapper: ['gt-label', 'gt-has-error'],
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'title',
          options: [],
          addPerms: '',
        },
      },
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          if (!($scope as any).to.resource) {
            return;
          }
          ($scope as any).to.resource.query(function (res: any) {
            ($scope as any).to.options = res.results;
          });
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-ui-select',
      wrapper: ['gt-label', 'gt-has-error'],

      template: html`<span class="input-group-addon-search"><i class="fa fa-search"></i></span>
        <div class="select-with-btn">
          <div ng-if="to.title">
            <span class="object-title">{[{ to.title }]}</span>
            <span class="clear-title-btn" ng-click="to.clear(options, model)">
              <i class="fa fa-times"></i>
            </span>
            <span
              class="tooltip copy-clipboard-btn"
              ng-click="copyToClipboard()"
              data-tip="{[{ 'Copy to clipboard' | translate }]}"
            >
              <i class="fa fa-copy"></i>
            </span>
          </div>
          <gt-resource-select
            ng-if="!to.title"
            ng-model="model[options.key]"
            placeholder="Select"
            resource-name="to.resource"
            on-select="(to.onSelect)"
            on-search="to.onSearch(event)"
            query-params="to.queryParams"
            allow-clear="to.allowClear"
            gt-disabled="to.disabled"
            class="{[{'gt_test_field_' + options.key}]}"
          ></gt-resource-select>
        </div>
        <div
          ng-if="to.addFunc && !to.title"
          class="form-add-btn"
          permission
          permission-only="to.addPerms"
        >
          <a class="btn add-btn" ng-click="to.callAddFunc(options)"
            >+ <i class="fa" ng-class="to.addIcon"></i
          ></a>
        </div>`,
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'title',
          refreshDelay: 500,
          addPerms: '',
          allowClear: true,
          options: [],
          callAddFunc: function (options: any) {
            const to = this;
            const result = (to as any).addFunc();

            if (!result) {
              return;
            }

            Promise.resolve(result)
              .then(function (data: any) {
                if (!data || data == 'cancel') {
                  return;
                }
                options.value(data.id);
              })
              .catch((error: Error) => console.error(error));
          },
          clear: function (options: any, model: any) {
            delete options.templateOptions.title;
            delete model[options.key];
          },
        },
      },
      controller: [
        '$scope',
        'GtUtils',
        function ($scope: ng.IScope, GtUtils: GtUtilsService) {
          ($scope as any).copyToClipboard = () => GtUtils.copyToClipboard(($scope as any).to.title);
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-ui-multiselect',
      wrapper: ['gt-label', 'gt-has-error'],
      template: html`<span class="input-group-addon-search"><i class="fa fa-search"></i></span>
        <div class="select-with-btn">
          <gt-resource-multiselect
            ng-model="model[options.key]"
            placeholder="Select"
            resource-name="to.resource"
            query-params="to.queryParams"
            get-query-params="to.getQueryParams"
            update-on-event="to.updateOnEvent"
            allow-clear="to.allowClear"
            gt-disabled="to.disabled"
            class="{[{'gt_test_field_' + options.key}]}"
          ></gt-resource-multiselect>
        </div>
        <!-- ng-model directive used for validation - 'required' message in this case -->
        <select ng-model="model[options.key]" multiple style="display: none"></select>
        <div ng-if="to.addFunc" class="form-add-btn" permission permission-only="to.addPerms">
          <a class="btn add-btn" ng-click="to.callAddFunc(options)"
            >+ <i class="fa" ng-class="to.addIcon"></i
          ></a>
        </div>`,
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'title',
          refreshDelay: 500,
          addPerms: '',
          allowClear: true,
          options: [],
          callAddFunc: function (options: any) {
            const to = this;
            const result = (to as any).addFunc();

            if (!result) {
              return;
            }

            Promise.resolve(result)
              .then(function (data: any) {
                if (!data || data == 'cancel') {
                  return;
                }
                options.value().push(data.id);
              })
              .catch((error: Error) => console.error(error));
          },
        },
      },
    });
    formlyConfig.setType({
      name: 'gt-select',
      template: html`<select
        ng-model="model[options.key]"
        class="form-control {[{'gt_test_field_' + options.key}]}"
        ng-options="obj[to.valueProp] as obj[to.labelProp] | translate for obj in to.options"
        ng-disabled="to.disabled"
        ng-change="to.onSelected && to.onSelected($event)"
      ></select>`,
      wrapper: ['gt-label', 'gt-has-error'],
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'title',
          options: [],
        },
      },
    });
    formlyConfig.setType({
      name: 'gt-checkbox',
      extends: 'checkbox',
      wrapper: ['gt-label', 'gt-has-error'],
      template: html`<input
        type="checkbox"
        class="formly-field-checkbox {[{'gt_test_field_' + options.key}]}"
        ng-model="model[options.key]"
        ng-disabled="to.disabled"
      />`,
    });
    formlyConfig.setType({
      name: 'gt-file',
      extends: 'input',
      wrapper: ['gt-label', 'gt-has-error'],
      template: html`<div class="user-avatar ng-binding ng-scope" ng-if="$rootScope.user.avatar">
          <img ng-src="/pictures/{[{model[options.key]}]}" /> {[{ model[options.key] }]}
        </div>
        <div class="clearfix"></div>
        <label for="id_file"
          ><i class="fa fa-folder-open"></i> <translate>Choose the file on computer</translate>
        </label>
        <input
          id="id_file"
          type="file"
          ngf-select="changeInput($files)"
          ng-model="model[options.key]"
        />
        <p class="help-block">
          <i class="fa fa-database"></i> <translate>single document size up to 50 mb</translate>
        </p>`,
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).changeInput = (files: any) => {
            ($scope as any).model.files = files;
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-textarea',
      template: html`<i class="tooltip" data-tip="{[{ to.tooltip }]}"></i>
        <textarea
          adjust-height
          placeholder="{[{to.placeholder | translate}]}"
          class="form-control"
          ng-model="model[options.key]"
          oninput="this.style.height = ''; this.style.height = this.scrollHeight + 'px'"
          onclick="this.style.height = ''; this.style.height = this.scrollHeight + 'px'"
          ng-change="to.onBlur(model[options.key])"
          ng-class="to.className"
          ng-model-options="{ debounce: 500 }"
        ></textarea>
        <span class="copy-clipboard-btn" ng-click="copyToClipboard(options, model)">
          <i class="fa fa-copy"></i>
        </span>`,
      extends: 'textarea',
      wrapper: ['gt-label', 'gt-has-error'],
      defaultOptions: {
        templateOptions: {
          onBlurFunc: undefined,
        },
      },
      controller: [
        '$scope',
        'GtUtils',
        function ($scope: ng.IScope, GtUtils: GtUtilsService) {
          ($scope as any).copyToClipboard = function (options: any, model: any) {
            GtUtils.copyToClipboard(model[options.key]);
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-choice-list',
      template: html`<contract-price-widget
        init-query-params="{ contract: to.contractId }"
        contract-prices="model[options.key]"
        on-change="(changeContract)"
        fields-config="to.fieldsConfig"
        contract-type="to.contractType"
        contract-general-agreement="to.contractGeneralAgreement"
        init-contract="{
          volume: to.contractVolume,
          cargo: to.contractCargo,
          currency: to.contractCurrency,
          deal_type: to.contractDealType,
          contractOption: to.contractOption,
          premiumBonusPercentage: to.premiumBonusPercentage,
        }"
      ></contract-price-widget>`,
      wrapper: ['gt-label', 'gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).changeContract = function (contract: any) {
            ($scope as any).model.basis = contract.basis;
            ($scope as any).model.cargo = contract.cargo;
            ($scope as any).model.ports = contract.ports;
            ($scope as any).model.volume = contract.volume || 0;
            ($scope as any).model.date_of_execution = contract.date_of_execution;
            ($scope as any).model.end_of_execution = contract.end_of_execution;
            ($scope as any).model.derivative = contract.derivative;
            ($scope as any).model.price =
              (($scope as any).model.id === contract.id &&
                contract.price !== undefined &&
                contract.price) ||
              ($scope as any).model.price;
            ($scope as any).model.measurement = contract.measurement;
            ($scope as any).model.price_premium = contract.price_premium || 0;
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-consignee-list',
      template: html`<contracts-consignee-widget
        data="model[options.key]"
        contract="model"
        on-change="changeConsignee"
      ></contracts-consignee-widget>`,
      wrapper: ['gt-label', 'gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).changeConsignee = function (data: any) {
            ($scope as any).model.contract_consignees = data;
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-voyage-few-commodity',
      template: html`<voyage-few-commodity-widget
        data="model[options.key]"
        on-change="(changeCommodity)"
      ></voyage-few-commodity-widget>`,
      wrapper: ['gt-label', 'gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).changeCommodity = function (data: any) {
            ($scope as any).model.few_commodity = data;
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-list-derivatives',
      template: html`<contracts-derivatives-widget
        data="model[options.key]"
        on-change="changeDerivatives"
      ></contracts-derivatives-widget>`,
      wrapper: ['gt-label', 'gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).changeDerivatives = function (data: any) {
            ($scope as any).model.derivatives = data;
          };
        },
      ],
    });
    formlyConfig.setType({
      name: 'gt-date-select',
      template: html`<gt-date-select
        use-watch="to.useWatch"
        date-model="model[options.key]"
        allow-clear="to.allowClear"
        required="to.required"
        start-view="to.startView"
        min-view="to.minView"
        min-date="to.minDate"
        max-date="to.maxDate"
        on-selected="to.onSelectedWrapper($event)"
        disabled="to.disabled"
      ></gt-date-select>`,
      wrapper: ['gt-label', 'gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).to.showRequiredError =
            ($scope as any).to.required && !($scope as any).model[($scope as any).options.key];
          ($scope as any).model[($scope as any).options.key] =
            ($scope as any).model[($scope as any).options.key] ||
            ($scope as any).options.defaultValue;
        },
      ],
      defaultOptions: {
        templateOptions: {
          onSelected: undefined,
          useWatch: undefined,
          allowClear: true,
          startView: 'day',
          minView: 'day',
          showRequiredError: false,
          onSelectedWrapper: function (event: any) {
            this.showRequiredError = !event.date;
            (this.onSelected as ((event: any) => void) | undefined)?.(event);
          },
          options: [],
        },
      },
    });
    formlyConfig.setType({
      name: 'gt-date',
      defaultOptions: {
        templateOptions: {
          onSelected: undefined,
          useWatch: undefined,
        },
      },
      template: html`<gt-date-select
        on-selected="to.onSelected($event)"
        use-watch="to.useWatch"
        date-model="model[options.key]"
        allow-clear="to.allowClear"
        required="to.required"
      ></gt-date-select>`,
      wrapper: ['gt-label', 'gt-has-error'],
    });
    formlyConfig.setType({
      name: 'gt-documents',
      defaultOptions: {
        templateOptions: {
          showRequiredError: true,
        },
      },
      template: html`
        <documents-list-table-view
          documents="model[options.key]"
          count="model[options.key].length"
          delete-event-source="to.deleteEventSource"
          mode="to.mode"
        ></documents-list-table-view>
        <input
          required="to.required"
          id="idFile"
          type="file"
          name="document"
          accept=".pdf, .xls, .xlsx, .doc, .docx, .p7s"
          ngf-select="to.onFileSelect($files); fileSelectHandler();"
        />
        <span ng-if="to.showRequiredError" class="help-inline">
          <i class="fa fa-exclamation-circle"></i> <translate>Required</translate>
        </span>
      `,
      wrapper: ['gt-has-error'],
      controller: [
        '$scope',
        function ($scope: ng.IScope) {
          ($scope as any).form.$invalid = !($scope as any).model[($scope as any).options.key]
            ?.length;
          ($scope as any).to.showRequiredError =
            ($scope as any).to.required &&
            !($scope as any).model[($scope as any).options.key]?.length;

          $scope.$watch(
            () => JSON.stringify(($scope as any).model),
            (cur: any, prev: any) => {
              if (cur !== prev) {
                ($scope as any).form.$invalid =
                  ($scope as any).model[($scope as any).options.templateOptions.connectedField] ===
                    false && !($scope as any).model[($scope as any).options.key]?.length;
              }
            },
          );

          $scope.$watch(
            () => ($scope as any).model[($scope as any).options.key]?.length,
            (cur: any, prev: any) => {
              if (cur !== prev) {
                ($scope as any).to.showRequiredError = !cur;

                if (cur === 0) {
                  ($scope as any).form.$invalid = true;
                } else {
                  ($scope as any).form.$invalid = false;
                }
              }
            },
          );
        },
      ],
    });
    formlyConfig.extras.fieldTransform.push(function (fields: any) {
      return fields.map(function (field: any) {
        if (!field.key) {
          return field;
        }
        field.validation = field.validation || {};
        field.validation.messages = field.validation.messages || {};
        field.validation.messages.server = function (viewValue: any, modelValue: any, scope: any) {
          if (!scope.model.errors) {
            return;
          }
          const errors = Array.isArray(scope.model.errors)
            ? scope.model.errors
            : Object.values(scope.model.errors);
          let message = '';
          errors.forEach(function (err: any) {
            if (err[scope.options.key]) {
              message = err[scope.options.key][0];
            }
          });
          return message;
        };
        return field;
      });
    });
  }
})();
