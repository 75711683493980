import type ng from 'angular';

import template from './bill-of-lading-page-view.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '^/app/core/types';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

export const billOfLadingPageView = {
  bindings: {
    filterLevel: '<?',
    queryParams: '<?',
  },
  template,
  controller: [
    'PageService',
    'gtFilterService',
    'LogisticsService',
    'gettext',
    'GtUtils',
    'CustomValuesService',
    class BillOfLadingPageView {
      CustomValuesService: CustomValuesService;
      GtUtils: GtUtilsService;
      LogisticsService: LogisticsService;
      PageService: PageService;
      filterLevel = 'bill-of-lading-page-view';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      queryParams: QueryParams = {};
      constructor(
        PageService: PageService,
        gtFilterService: GtFilterService,
        LogisticsService: LogisticsService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        CustomValuesService: CustomValuesService,
      ) {
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.LogisticsService = LogisticsService;
        this.CustomValuesService = CustomValuesService;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const pageConfig: any = {
          class: 'page-header-main-logistics-tab',
          buttons: [
            new this.PageService.buttons.Filters(),
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(),
          ],
          pages: this.LogisticsService.getPagesConfig(),
          filters: {
            filterLevel: this.filterLevel,
            clicked: false,
            search: true,
            dates: true,
            invert_filters: true,
            dateSelects: [
              {
                argument: 'date',
                placeholder: this.gettext('Date'),
              },
              {
                argument: 'create_time',
                placeholder: this.gettext('Create date'),
              },
            ],
            nestedSelects: [
              this.gtFilterService.getBoolFilter('has_docs', this.gettext('Has docs')),
              this.gtFilterService.getBoolFilter('has_loi', this.gettext('Has LOI')),
              this.gtFilterService.getBoolFilter(
                'has_incoming_invoice',
                this.gettext('Has incoming invoice'),
              ),
              this.gtFilterService.getBoolFilter(
                'has_outgoing_invoice',
                this.gettext('Has outgoing invoice'),
              ),
            ],
            selects: [],
            multiSelects: [
              {
                argument: 'passport_list',
                placeholder: this.gettext('passport'),
                resource: 'passports.passport',
                related_fk: 'passport',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'voyage_list',
                placeholder: this.gettext('voyage'),
                resource: 'logistics.Voyage',
                related_fk: 'voyage',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'sale_contract_list',
                placeholder: this.gettext('sale contract'),
                resource: 'contracts.SaleContract',
                related_fk: 'sale_contract',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'purchase_contract_list',
                placeholder: this.gettext('purchase contract'),
                resource: 'contracts.PurchaseContract',
                related_fk: 'purchase_contract',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'sale_contract_basis_list',
                placeholder: this.gettext('sale contract basis'),
                resource: 'logistics.Basis',
                related_fk: 'sale_contract__basis',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'purchase_contract_basis_list',
                placeholder: this.gettext('purchase contract basis'),
                resource: 'logistics.Basis',
                related_fk: 'purchase_contract__basis',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'sale_contract_cargo_list',
                placeholder: this.gettext('sale contract commodity'),
                resource: 'crops.Crop',
                related_fk: 'sale_contract__cargo',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'sale_contract_buyer_list',
                placeholder: this.gettext('sale contract buyer'),
                resource: 'clients.Buyer',
                related_fk: 'sale_contract__buyer',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'sale_contract_supplier_list',
                placeholder: this.gettext('sale contract supplier'),
                resource: 'clients.Owner',
                related_fk: 'sale_contract__supplier',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'purchase_contract_buyer_list',
                placeholder: this.gettext('purchase contract buyer'),
                resource: 'clients.Owner',
                related_fk: 'purchase_contract__buyer',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'purchase_contract_supplier_list',
                placeholder: this.gettext('purchase contract supplier'),
                resource: 'clients.Supplier',
                related_fk: 'purchase_contract__supplier',
                related_model: 'logistics.BillOfLading',
              },
              {
                argument: 'export_contract_list',
                placeholder: this.gettext('export contract'),
                resource: 'contracts.ExportContract',
              },
              {
                argument: 'business_unit_list',
                placeholder: this.gettext('business unit'),
                resource: 'core.BusinessUnit',
                getQueryParams: this.GtUtils.getBUQueryParams,
              },
              {
                argument: 'author_list',
                placeholder: this.gettext('Author'),
                resource: 'auth.User',
                related_fk: 'author',
                related_model: 'logistics.BillOfLading',
              },
            ],
            nestedMultiSelects: [
              {
                argument: 'incoming_inv_status_list',
                placeholder: this.gettext('Incoming invoice status'),
                items: [
                  {
                    id: 'new',
                    title: this.gettext('New'),
                  },
                  {
                    id: 'process',
                    title: this.gettext('Process'),
                  },
                  {
                    id: 'paid',
                    title: this.gettext('Paid'),
                  },
                  {
                    id: 'canceled',
                    title: this.gettext('Canceled'),
                  },
                ],
              },
              {
                argument: 'outgoing_inv_status_list',
                placeholder: this.gettext('Outgoing invoice status'),
                items: [
                  {
                    id: 'new',
                    title: this.gettext('New'),
                  },
                  {
                    id: 'process',
                    title: this.gettext('Process'),
                  },
                  {
                    id: 'paid',
                    title: this.gettext('Paid'),
                  },
                  {
                    id: 'canceled',
                    title: this.gettext('Canceled'),
                  },
                ],
              },
              {
                argument: 'purchase_contract_deal_type_list',
                placeholder: this.gettext('Purchase contract deal type'),
                items: [
                  {
                    id: 'spot',
                    title: this.gettext('Spot'),
                  },
                  {
                    id: 'forward',
                    title: this.gettext('Forward'),
                  },
                  {
                    id: 'brokerage',
                    title: this.gettext('Brokerage'),
                  },
                ],
              },
              {
                argument: 'sale_contract_deal_type_list',
                placeholder: this.gettext('Sale contract deal type'),
                items: [
                  {
                    id: 'spot',
                    title: this.gettext('Spot'),
                  },
                  {
                    id: 'forward',
                    title: this.gettext('Forward'),
                  },
                  {
                    id: 'brokerage',
                    title: this.gettext('Brokerage'),
                  },
                ],
              },
              {
                argument: 'purchase_contract_status_list',
                placeholder: this.gettext('Purchase contract status'),
                items: [
                  { id: 'washout', title: this.gettext('Washout') },
                  { id: 'new', title: this.gettext('New') },
                  { id: 'delivery_period', title: this.gettext('Delivery period') },
                  { id: 'shipment', title: this.gettext('Shipment') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'executed', title: this.gettext('Executed') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
              {
                argument: 'sale_contract_status_list',
                placeholder: this.gettext('Sale contract status'),
                items: [
                  { id: 'washout', title: this.gettext('Washout') },
                  { id: 'new', title: this.gettext('New') },
                  { id: 'delivery_period', title: this.gettext('Delivery period') },
                  { id: 'shipment', title: this.gettext('Shipment') },
                  { id: 'cargo_executed', title: this.gettext('Cargo executed') },
                  { id: 'executed', title: this.gettext('Executed') },
                  { id: 'approved', title: this.gettext('Approved') },
                  { id: 'cancelled', title: this.gettext('Cancelled') },
                ],
              },
            ],
          },
        };
        this.CustomValuesService.getCustomFieldFilters({
          purpose_model: 'billoflading',
        }).then((customFieldsFilters: any) => {
          pageConfig.filters.multiSelects.push(...customFieldsFilters.multiSelects);
          pageConfig.filters.selects.push(...customFieldsFilters.selects);
          pageConfig.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
          pageConfig.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
          pageConfig.filters.dateSelects.push(...customFieldsFilters.dateSelects);
        });
        return pageConfig;
      }
    },
  ],
};
