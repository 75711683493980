import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './contract-charges-list-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const ContractChargesListContainer = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    showGrouppedTotals: '<?',
    showCommodity: '<?',
    readOnly: '<?',
    invoicingParams: '<?',
    predictionsQueryParams: '<?',
    objectStage: '<?',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    'gettext',
    'GtUtils',
    'ContractsService',
    'gtFilterService',
    'CoreService',
    'DocumentsService',
    'FinancesService',
    'MulticontractService',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      ContractsService: ContractsService;
      CoreService: CoreService;
      DocumentsService: any;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      MulticontractService: MulticontractService;
      activeFilterPresets: any;
      consolidatedInvoiceCreation: any;
      contractChargesData: any;
      count = 0;
      creating: any;
      filterLevel: string;
      financePositions: any;
      gettext: ng.gettext.gettextFunction;
      grouppedData: any;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      invoceCreationStep: any;
      invoiceType: any;
      invoicing: any;
      invoicingParams: any;
      newCharge: any;
      newFinance: any;
      objectStage: any;
      predictionsQueryParams: QueryParams = {};
      queryParams: QueryParams & { contract?: { id: number } };
      readOnly: any;
      reportConfig: any;
      savedFilterChoices: any;
      savedReportConfigs: any;
      showCommodity: any;
      showFinancingCalcButton: any;
      showGrouppedTotals: any;
      tableName?: string;
      totals: any;
      selection: boolean;
      selectedContractCharges: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        gettext: ng.gettext.gettextFunction,
        GtUtils: GtUtilsService,
        ContractsService: ContractsService,
        gtFilterService: GtFilterService,
        CoreService: CoreService,
        DocumentsService: any,
        FinancesService: FinancesService,
        MulticontractService: MulticontractService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.gettext = gettext;
        this.GtUtils = GtUtils;
        this.ContractsService = ContractsService;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.DocumentsService = DocumentsService;
        this.FinancesService = FinancesService;
        this.MulticontractService = MulticontractService;

        this.initQueryParams = {};
        this.invoicingParams = {};
        this.queryParams = {};

        this.contractChargesData = [];
        this.grouppedData = [];
        this.filterLevel = '';
        this.newCharge = {};

        this.invoicing = false;
        this.invoiceType = '';
        this.newFinance = {};
        this.financePositions = [];

        this.savedFilterChoices = [];
        this.showFinancingCalcButton = false;
        this.showGrouppedTotals = false;
        this.showCommodity = false;
        this.creating = false;

        this.invoceCreationStep = 0;
        this.activeFilterPresets = [];

        this.predictionsQueryParams = {};
        this.objectStage = undefined;
        this.reportConfig = null;
        this.selection = false;
      }

      $onInit() {
        this.filterLevel = this.filterLevel || 'contract-charges-list-container';
        this.tableName = this.tableName ?? this.filterLevel;
        this.queryParams = { ...this.initQueryParams, ordering: '-update', is_by_default: 0 };
        this.updateNewCharge();

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateContractChargesData();
        });

        this.$rootScope.$on(
          'gt-report-config-selected_' + this.filterLevel,
          (ev: any, config: any) => {
            this.reportConfig = config ? config.column_params : null;
          },
        );
        this.$rootScope.$on(
          'gt-report-config-created_' + this.filterLevel,
          (ev: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );
        this.$rootScope.$on(
          'gt-report-config-updated_' + this.filterLevel,
          (ev: any, config: any) => {
            const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
            this.savedReportConfigs[index] = config;
          },
        );

        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
      }

      updateContractChangeStatuses() {
        this.GtUtils.overlay('show');
        return this.ContractsService.ContractCharge.setStatusClosed({}, () => {
          this.updateContractChargesData();
          this.GtUtils.overlay('hide');
        });
      }

      updateContractChargesData() {
        this.GtUtils.overlay('show');
        this.selection = false;
        return this.ContractsService.ContractCharge.queryInfo(this.queryParams, (data: any) => {
          this.count = data.totals.row_count;
          this.grouppedData = data.totals.groupped_data;
          this.totals = data.totals;
          if (this.queryParams.next) {
            this.contractChargesData = this.contractChargesData.concat(data.results);
            delete this.queryParams.next;
          } else if (this.readOnly) {
            this.contractChargesData = [...data.results];
          } else {
            this.contractChargesData = [this.newCharge, ...data.results];
          }
          this.creating = false;
        }).$promise.then(() => {
          this.GtUtils.overlay('hide');
        });
      }

      applyFilters(params: QueryParams) {
        this.gtFilterService.updateQueryParams(
          { ...params, ...this.initQueryParams },
          this.filterLevel,
        );
      }

      resetFilters() {
        return this.gtFilterService.setQueryParams(this.initQueryParams, this.filterLevel);
      }

      openDocumentModal(object: any) {
        return this.DocumentsService.attachDocumentModal(object, 'contractcharge').then(() =>
          this.updateContractChargesData(),
        );
      }

      openContractChargeModal(charge: any) {
        this.updateNewCharge();
        charge = charge || this.newCharge;
        charge.volume = charge.volume || this.initQueryParams.customs_declaration_volume;
        charge.predictionsQueryParams = this.predictionsQueryParams;
        return this.ContractsService.contractChargeModal(charge).then(() => {
          this.updateContractChargesData();
        });
      }

      startStopInvoicing() {
        if (this.invoceCreationStep) {
          this.invoceCreationStep = 0;
          this.newFinance = {};
          this.financePositions = [];
          this.refreshCheckboxes();
          return;
        }
        this.setNextInvoiceCreationStep();
      }

      setNextInvoiceCreationStep() {
        if (this.invoceCreationStep === 2 && this.invoiceType) {
          this.invoceCreationStep = 3;
          this.updateNewFinance();
        } else if (
          this.invoceCreationStep === 1 &&
          this.queryParams.clientrole &&
          this.queryParams.currency
        ) {
          this.invoceCreationStep = 2;
        } else if (this.invoceCreationStep === 0) {
          this.invoceCreationStep = 1;
        }
        this.refreshCheckboxes();
      }

      selectInvoiceType(invoiceType: any) {
        this.invoiceType = invoiceType;
        this.setNextInvoiceCreationStep();
        this.refreshCheckboxes();
      }

      refreshCheckboxes() {
        this.contractChargesData.forEach((v: any) => {
          if (this.invoceCreationStep < 3 || !v.not_invoiced) {
            v._showCheckbox = v._selected = false;
          } else {
            v._showCheckbox = true;
          }
        });
      }

      selectAllChecboxes(value: any) {
        this.contractChargesData.forEach((v: any) => (v._selected = v._showCheckbox && value));
        if (this.invoceCreationStep) {
          this.updateFinancePostitions();
        }
      }

      createInvoice(item: any) {
        const newFinance = item?.id ? this.getNewFinanceByCharge(item) : this.newFinance;
        const financePositions = item?.id
          ? [this.getInviocePositionByContractCharge(item)]
          : this.financePositions;
        if (!financePositions.length) {
          return notify(this.gettext('Select any of item'), 'error');
        }

        return this.FinancesService.financeModal(newFinance, {
          financePositions: financePositions,
        }).then((data: any) => {
          if (data || data !== 'cancel') {
            this.newFinance = {};
            this.startStopInvoicing();
            this.resetFilters();
          }
        });
      }

      getNewFinanceByCharge(item: any) {
        const defaultOwner = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
        const contractOwner = item.contract_owner_id || defaultOwner;
        const invoiceType = item.is_gain ? 'outgoing' : 'incoming';
        const isBroker = item.deal_type === 'brokerage';

        const clientFrom = invoiceType === 'incoming' ? item.clientrole : contractOwner;
        const clientTo = invoiceType === 'incoming' ? contractOwner : item.clientrole;
        const brokerageClientFrom = invoiceType === 'incoming' ? item.clientrole : contractOwner;
        const brokerageClientTo = invoiceType === 'incoming' ? contractOwner : item.clientrole;

        return {
          invoice_type: invoiceType,
          currency: item.currency,
          currency_exchange: item.currency_exchange,
          clientrole_from: isBroker ? brokerageClientFrom : clientFrom,
          clientrole_to: isBroker ? brokerageClientTo : clientTo,
          business_unit: this.invoicingParams.business_unit,
        };
      }

      updateNewFinance() {
        const defaultOwner = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
        const clientFrom =
          this.invoiceType === 'incoming' ? this.queryParams.clientrole : defaultOwner;
        const clientTo =
          this.invoiceType === 'incoming' ? defaultOwner : this.queryParams.clientrole;

        this.newFinance = {
          invoice_type: this.invoiceType,
          amount: 0,
          volume: 0,
          currency: this.queryParams.currency,
          clientrole_from: clientFrom,
          clientrole_to: clientTo,
          business_unit: this.invoicingParams.business_unit,
        };
      }

      updateFinancePostitions() {
        this.financePositions = this.contractChargesData
          .filter((row: any) => row._selected)
          .reduce(
            (result: any, row: any) => [...result, this.getInviocePositionByContractCharge(row)],
            [],
          );
        this.newFinance.full_amount = this.newFinance.amount = this.financePositions.reduce(
          (res: any, row: any) => res + row.amount,
          0,
        );
      }

      updateNewCharge() {
        this.newCharge = {
          ...this.initQueryParams,
          status: 'forecast',
          price: 0,
          price_t: 0,
          percentage: 0,
          _selected: false,
          contract: this.queryParams.contract,
          passport: this.queryParams.passport,
          customs_declaration: this.queryParams.customs_declaration,
          currency: this.FinancesService.getDefaultCurrency().id,
        };
      }

      getInviocePositionByContractCharge(contractCharge: any) {
        let quantity = 1;
        let pricePerPiece = Math.round(contractCharge.not_invoiced * 100) / 100;

        if (contractCharge.price_t) {
          quantity = contractCharge.not_invoiced / contractCharge.price_t;
          pricePerPiece = contractCharge.price_t;
        }

        return {
          use: contractCharge.is_gain ? 'gains' : 'costs',
          amount: Math.round(contractCharge.not_invoiced * 100) / 100,
          vat_option: contractCharge.vat_option,
          vat_value: contractCharge.vat_value,
          quantity: Math.round(quantity * 1000) / 1000,
          price_per_piece: pricePerPiece,
          contract: contractCharge.contract,
          contractcharge: contractCharge.id,
          charge: contractCharge.charge,
          passport: contractCharge.passport,
          logistic: contractCharge.logistic,
          customs_declaration: contractCharge.customs_declaration,
          contracts: contractCharge.contracts || [],
          date: contractCharge.date,
        };
      }

      checkChargeType(charge: any) {
        if (!this.queryParams.contract) {
          return;
        }
        this.FinancesService.Charge.get({ id: charge.charge }).$promise.then(
          (data: any) => (charge.showFinancingCalcButton = data.tariff_type === 'financing'),
          this.GtUtils.errorClb,
        );
      }

      calcFinancingPrices(charge: any) {
        return this.ContractsService.ContractCharge.getFinancingData(
          {
            charge: charge.charge,
            contract: charge.contract || this.queryParams.contract?.id,
          },
          (data: any) => {
            if (data?.data.length) {
              charge.price = data.data[0].price;
              charge.price_t = data.data[0].price_t;
              charge.currency = data.data[0].currency;
            } else {
              notify(this.gettext('no data'), 'error');
            }
          },
          this.GtUtils.errorClb,
        );
      }

      calculateChargePrice(charge: any, perVolume: any, isPriceChanged: any) {
        Object.assign(
          charge,
          this.ContractsService.calcContractChargePrice(charge, perVolume, isPriceChanged),
        );
      }

      saveCharge(charge: any) {
        charge.volume = charge.volume || 0;
        charge.currency_exchange = charge.currency_exchange ?? null;

        if (charge.id) {
          charge.contracts = charge.contracts || [];
          return this.ContractsService.ContractCharge.update(charge).$promise.then(() => {
            this.updateContractChargesData();
            this.updateNewCharge();
          }, this.GtUtils.errorClb).$promise;
        }
        return this.ContractsService.checkChargeAndConfirmNotUnique(charge).then(
          (checkResult: any) => {
            if (checkResult === true) {
              return this.ContractsService.ContractCharge.save(charge).$promise.then(() => {
                this.updateContractChargesData();
                this.updateNewCharge();
              }, this.GtUtils.errorClb);
            } else {
              return this.GtUtils.errorClb({ data: ['charge was not created', checkResult] });
            }
          },
        ).$promise;
      }

      saveMultiContractCharge(charge: any) {
        if (!charge.multicontract || charge.id) {
          return null;
        }
        this.GtUtils.overlay('show');
        return this.MulticontractService.getTableData({ id: charge.multicontract }).then(
          (data: any) => {
            const multicontract = data.results[0];
            const contractCharges = this.ContractsService.createMultiContractCharge(
              charge,
              multicontract,
            );
            return Promise.all(
              contractCharges.map((newCharge: any) => this.saveCharge(newCharge)),
            ).then(() => {
              this.GtUtils.overlay('hide');
            });
          },
        );
      }

      deleteCharge(charge: any) {
        if (!confirm(this.gettext('Are you sure that you want delete Contract Charge?'))) {
          return false;
        }
        return this.ContractsService.ContractCharge.delete({ id: charge.id }).$promise.then(() => {
          this.updateContractChargesData();
          this.updateNewCharge();
        }, this.GtUtils.errorClb).$promise;
      }

      createChargesByDefault() {
        if (!confirm(this.gettext('Are you sure that you want to create Contract Charges?'))) {
          return false;
        }

        this.creating = true;
        return this.ContractsService.ContractCharge.createChargesByDefault({
          contract: this.queryParams.contract,
        }).$promise.then(() => {
          this.updateContractChargesData();
          this.updateNewCharge();
        }, this.GtUtils.errorClb).$promise;
      }

      canAddCharge() {
        if (this.objectStage === 'contract') {
          return this.$rootScope.user.perms.includes('can_add_contract_charge');
        }
        if (this.objectStage === 'ticket') {
          return this.$rootScope.user.perms.includes('can_add_ticket_charge');
        }
        return true;
      }

      finishConsolidatedInvoiceCreation() {
        this.consolidatedInvoiceCreation = false;
        return this.updateContractChargesData();
      }

      toogleSelectMode(flag: boolean) {
        this.selection = flag;
      }

      onCheckboxClick() {
        this.selectedContractCharges = this.contractChargesData.filter(
          (cost: any) => cost.$_selected,
        );
      }
    },
  ],
};
