import type { InvoicePurpose, LogisticTable } from '.';

export const invoicingConfig: Record<
  InvoicePurpose,
  {
    allowedTables: LogisticTable[];
    defaultTable: LogisticTable;
    contractType: 'sale' | 'purchase';
  }
> = {
  incoming: {
    allowedTables: ['table_supplier', 'table_qualities', 'table_certs'],
    defaultTable: 'table_supplier',
    contractType: 'purchase',
  },
  outgoing: {
    allowedTables: ['table_buyer', 'table_qualities'],
    defaultTable: 'table_buyer',
    contractType: 'sale',
  },
  export: {
    allowedTables: ['table_export', 'table_qualities'],
    defaultTable: 'table_export',
    contractType: 'purchase',
  },
  consolidated_costs: {
    allowedTables: ['table_costs'],
    defaultTable: 'table_costs',
    contractType: 'purchase',
  },
  costs: {
    allowedTables: ['table_buyer', 'table_supplier', 'table_qualities'],
    defaultTable: 'table_supplier',
    contractType: 'purchase',
  },
};
