import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import {
  Counterparty,
  CounterpartyCreate,
  CounterpartyList,
  CounterpartyListParams,
  CounterpartyUpdate,
} from '~/features/crm/counterparties/lib';
import { CounterpartiesRepository } from '~/features/crm/counterparties/services';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

import { CounterpartiesModel } from './counterparties.model';

@injectable()
export class CounterpartiesListViewModel extends EntityListEditViewModel<
  Counterparty,
  CounterpartyListParams,
  CounterpartyCreate,
  CounterpartyList
> {
  resolveEntityRepo() {
    return container.resolve(CounterpartiesRepository);
  }
  private readonly entityName = 'clients';
  private readonly purposeModel = 'client';

  constructor(
    private readonly counterpartiesRepo: CounterpartiesRepository,
    private readonly counterpartiesModel: CounterpartiesModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public counterpartiesCreated = async ({ entities }: { entities: CounterpartyCreate[] }) => {
    await this.counterpartiesModel.createCounterparties(entities);
  };

  public counterpartyDeleted = async (id: number) => {
    try {
      await this.counterpartiesRepo.delete(id);
    } catch (err) {
      errorHandler(err);
      console.error(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public counterpartiesUpdated = async ({ entities }: { entities: CounterpartyUpdate[] }) => {
    try {
      await this.counterpartiesModel.updateCounterparties(entities);
      notifySuccess('Counterparties updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: Counterparty[]) => {
    await Promise.all(records.map(this.counterpartiesRepo.update));
    this.pageParamsChanged({});
  };

  public updateRegularStatus = async () => {
    try {
      await this.counterpartiesRepo.updateRegularStatus();
      notifySuccess('Status updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public createCounterparty = async (counterparty: Counterparty) => {
    try {
      await this.counterpartiesRepo.create(counterparty);
      notifySuccess('Counterparty created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };
}
