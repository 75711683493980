export const allRecordsSelected = <TRecord extends { id: number }>({
  data,
  selectedRecordIds,
  onSelect,
}: {
  data: TRecord[];
  selectedRecordIds: number[];
  onSelect?: (ids: number[]) => void;
}) => {
  const allSelected = selectedRecordIds.length === data.length;
  if (allSelected) {
    onSelect?.([]);
  } else {
    onSelect?.(data.map((item) => item.id));
  }
};
