import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './multicontract-modal.html?raw';
import type { MulticontractFormFieldsService } from '../../multicontract-form-fields.service';
import type { MulticontractService } from '../../multicontract.service';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const MulticontractModal = {
  bindings: {
    item: '<',
    data: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$window',
    '$state',
    'GtUtils',
    'gettext',
    'FormFieldParamsService',
    'MulticontractService',
    'AccountsService',
    'CoreService',
    'ContractsService',
    'ClientsService',
    'DocumentsService',
    'MulticontractFormFieldsService',
    'CustomValuesService',
    class {
      $formValid = true;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      $window: ng.IWindowService;
      AccountsService: AccountsService;
      ClientsService: any;
      ContractsService: ContractsService;
      CoreService: CoreService;
      CustomValuesService: CustomValuesService;
      DocumentsService: any;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      MulticontractFormFieldsService: MulticontractFormFieldsService;
      MulticontractService: MulticontractService;
      businessUnit: any;
      customValues: any;
      data: any;
      fields: any;
      fieldsTemplateOptions: any;
      form: any;
      formNamePrefix: any;
      gettext: ng.gettext.gettextFunction;
      goToDetails: any;
      item: any;
      logEntries: any;
      logEntriesCount = 0;
      modalInstance: any;
      multicontract: any;
      positions: any;
      positionsFormValid = true;
      requiredFields: any;
      savedFiles: any;
      stayAfterSave: any;
      tab: string;
      tempId: number;
      unwatchBusinessUnit: any;
      unwatchContractType: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $window: ng.IWindowService,
        $state: ng.ui.IStateService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        FormFieldParamsService: FormFieldParamsService,
        MulticontractService: MulticontractService,
        AccountsService: AccountsService,
        CoreService: CoreService,
        ContractsService: ContractsService,
        ClientsService: any,
        DocumentsService: any,
        MulticontractFormFieldsService: MulticontractFormFieldsService,
        CustomValuesService: CustomValuesService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$window = $window;
        this.$state = $state;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.FormFieldParamsService = FormFieldParamsService;
        this.MulticontractService = MulticontractService;
        this.AccountsService = AccountsService;
        this.CoreService = CoreService;
        this.ContractsService = ContractsService;
        this.ClientsService = ClientsService;
        this.DocumentsService = DocumentsService;
        this.MulticontractFormFieldsService = MulticontractFormFieldsService;
        this.CustomValuesService = CustomValuesService;
        this.form = undefined;
        this.fields = [];
        this.tab = 'edit';
        this.formNamePrefix = '';
        this.stayAfterSave = false;
        this.goToDetails = false;
        this.businessUnit = undefined;
        this.savedFiles = [];
        this.tempId = 1;
        this.fieldsTemplateOptions = [];
        this.positions = [];
        this.requiredFields = [];
      }

      $onInit() {
        this.multicontract = this.item || { positions: [], contract_type: 'sale' };

        if (!this.multicontract.documents) {
          this.multicontract.documents = [];
        }
        this.data = this.data || this.multicontract;
        this.GtUtils.overlay('show');
        this.initData()
          .then(() => {
            this.GtUtils.overlay('hide');
            this.setWatchers();
          })
          .catch((e) => this.GtUtils.errorClb(e));
      }
      setWatchers() {
        this.$rootScope.$on(
          'documents-list__remove-from-multicontract-modal',
          (event: any, fileToRemove: any) => this.removeDocument(event, fileToRemove),
        );
        this.$rootScope.$on('buisness-unit-changed', () => this.businessUnitChanged());
        this.$rootScope.$on('on-file-selected', (event: any, file: any) => this.onFileSelect(file));
        this.$rootScope.$on('generate-number', () => this.getNumber());
        this.$rootScope.$on('copy-number-to-positions', () => this.copyNumberToPositions());
        this.unwatchContractType = this.$scope.$watch(
          '$ctrl.multicontract.contract_type',
          (newVal: any, oldVal: any) => this.changePositionsContractType(newVal, oldVal),
        );
        this.unwatchBusinessUnit = this.$scope.$watch(
          '$ctrl.multicontract.business_unit',
          (newVal: any, oldVal: any) => this.clearApprovalConfig(newVal, oldVal),
        );

        this.$scope.$on(
          `custom-values-updated__multi${this.multicontract.stage}`,
          (event: any, data: any) => {
            this.customValues = data;
          },
        );
        this.$scope.$watch(
          () => this.multicontract,
          () => {
            this.$formValid = !this.fieldsTemplateOptions
              .filter((f: any) => f.required)
              .some((f: any) => !this.isValueFilled(this.multicontract[f.key]));
          },
          true,
        );
        this.$scope.$watchCollection(
          () => this.multicontract,
          () => {
            this.$formValid = !this.fieldsTemplateOptions
              .filter((f: any) => f.required)
              .some((f: any) => !this.isValueFilled(this.multicontract[f.key]));
            this.form.$setValidity('multicontractValid', this.$formValid);
          },
        );
        this.$scope.$watch(
          '$ctrl.multicontract.responsible',
          (newVal: number | undefined, oldVal: number | undefined) => {
            if (newVal !== oldVal) {
              this.applyResponsibleToPositions();
              this.setResponsibleWorkStatus();
            }
          },
        );
        this.$scope.$watch(
          '$ctrl.multicontract.responsible_for_signing',
          (newVal: number | undefined, oldVal: number | undefined) => {
            if (newVal !== oldVal) {
              this.setResponsibleForSigningWorkStatus();
            }
          },
        );
      }
      isValueFilled(value: any) {
        return (
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && (Boolean(value) || value === false || value === 0))
        );
      }

      $onDestroy() {
        this.unwatchContractType();
        this.unwatchBusinessUnit();
      }
      updateResponsibleForPosition(positionId: any, responsible: any) {
        const position = this.positions.find((pos: any) => pos.id === positionId);
        if (position) {
          position.responsible = responsible;
          position.isResponsibleManuallySet = true;
        }
      }

      updateConclusionDateRange() {
        this.ContractsService.getConclusionDateRange(this.multicontract).then((dataRange: any) => {
          this.multicontract.conclusionDateRange = dataRange;
          return this.updateFields();
        });
      }
      postUpdate() {
        this.positions = this.setValuesForPositions([...this.multicontract.positions]);
        this.businessUnit = this.multicontract.business_unit;
        this.formNamePrefix = this.MulticontractService.getFormNamePrefix(this.data);
        delete this.multicontract.positions;
        this.updateFields();
      }
      initData() {
        let chain = Promise.resolve();
        if (this.multicontract.id) {
          chain = chain.then(() => this.updateMulticontractData());
        } else {
          chain = chain.then(() => this.updateConclusionDateRange());
        }
        chain.then(() => this.postUpdate()).catch((e) => this.GtUtils.errorClb(e));
        if (this.multicontract.id && !this.multicontract.id) {
          chain = chain.then(() => this.updateDocumentsData());
          chain = chain.then(() => this.updateLogEntries());
        }
        return chain;
      }
      updateDocumentsData() {
        const { content_type: contentType, id: objectId } = this.multicontract;
        return this.DocumentsService.queryDocuments({
          content_type: contentType,
          object_id: objectId,
        }).then(({ results }: any) => (this.multicontract.documents = results || []));
      }
      updateMulticontractData() {
        this.GtUtils.overlay('show');
        return this.MulticontractService.getModalData({ id: this.multicontract.id }).then(
          (data: any) => {
            this.GtUtils.overlay('hide');
            this.multicontract = data;
          },
        );
      }
      updateData() {
        this.GtUtils.overlay('show');
        return this.MulticontractService.getModalData({ id: this.multicontract.id })
          .then((data: any) => {
            this.multicontract = data;
            this.positions = this.setValuesForPositions([...this.multicontract.positions]);
            this.businessUnit = this.multicontract.business_unit;
            this.formNamePrefix = this.MulticontractService.getFormNamePrefix(data);
            delete this.multicontract.positions;
            this.updateFields();
            this.updateLogEntries();
            this.GtUtils.overlay('hide');
          })
          .then(() => {
            const { content_type: contentType, id: objectId } = this.multicontract;
            return this.DocumentsService.queryDocuments({
              content_type: contentType,
              object_id: objectId,
            });
          })
          .then(({ results }: any) => (this.multicontract.documents = results || []));
      }

      getPermissionName(params: object, permType: any) {
        return this.MulticontractService.getPermissionName(params, permType);
      }

      setValuesForPositions(positions: any) {
        positions.forEach((position: any) => {
          position.deal_type = this.multicontract.use_type;
          position.stage = this.multicontract.stage;
          position._show_derivatives = Boolean(position?.derivatives?.length);
          position._show_contractprice = Boolean(position?.contract_prices?.length);
        });
        return positions;
      }

      changePositionsContractType(newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          this.positions.forEach((position: any) => {
            position.contract_type = this.multicontract.contract_type;
          });
        }
      }

      clearApprovalConfig(newVal: any, oldVal: any) {
        if (newVal !== oldVal) {
          this.multicontract.approval_config = null;
        }
      }

      _error(error: any) {
        this.GtUtils.errorClb(error);
        this.multicontract.errors = error.data;
      }

      getNumber() {
        return this.MulticontractService.getContractNumber(this.multicontract, this.positions).then(
          (data: any) => {
            this.multicontract.number = data.contract_number;
          },
          (error: any) => this._error(error),
        );
      }

      copyNumberToPositions() {
        this.positions.map(
          (position: any) => (position.contract_number = this.multicontract.number),
        );
      }

      updateLogEntries() {
        return this.AccountsService.LogEntry.query(
          {
            object_id: this.multicontract.id,
            content_type: this.multicontract.content_type,
          },
          (data: any) => {
            this.logEntries = data.results;
            this.logEntriesCount = data.count;
          },
        );
      }

      updateDocuments() {
        this.DocumentsService.saveDocuments(
          this.savedFiles.map(({ tempId, file }: any) => {
            const documentOptions = this.multicontract.documents.filter(
              (doc: any) => doc.tempId === tempId,
            );
            if (documentOptions.length) {
              file.basis_doc = documentOptions[0].basis_doc;
            }
            return file;
          }),
          this.multicontract.id,
          this.multicontract.content_type,
        );
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        this.modalInstance.close(data || 'close');
      }

      businessUnitChanged() {
        this.businessUnit = this.multicontract.business_unit;
        this.updateFields();
      }

      destroy() {
        return this.MulticontractService.delete(this.multicontract).then(
          (data: any) => {
            notify(this.gettext('Multicontract deleted.'));
            this.modalInstance.close(data || 'close');
          },
          (error: any) => {
            this.multicontract.errors = error.data;
            this.GtUtils.errorClb(error);
          },
        );
      }

      save() {
        this.GtUtils.overlay('show');
        return this.MulticontractService.bulkSave(this.multicontract, this.positions).then(
          (data: any) => {
            notify(this.gettext('Multicontract saved.'));
            this.GtUtils.overlay('hide');

            if (!this.multicontract.id) {
              this.CustomValuesService.createCustomValues(data.item.id, this.customValues);
            }

            if (this.stayAfterSave) {
              this.multicontract.id = data.item.id;
              return this.updateData().then(() => this.updateFields());
            } else if (this.goToDetails && !this.multicontract.id) {
              const stageState =
                this.multicontract.stage === 'ticket' ? 'gt.multiticket' : 'gt.multicontract';
              this.modalInstance.close(data || 'close');
              this.$state.go(stageState, { id: data.item.id });
            } else {
              this.modalInstance.close(data || 'close');
            }
            this.updateDocuments();
          },
          (error: any) => {
            this.multicontract.errors = error.data;
            this.GtUtils.errorClb(error);
          },
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(
          this.MulticontractFormFieldsService.getMulticontractFormConfig(
            this.multicontract,
            this.positions,
            this.formNamePrefix,
          ),
          this.businessUnit,
        )
          .then((fields: any) => {
            this.fields = fields;
            this.fieldsTemplateOptions = this.getFlatFields(fields);
            this.requiredFields = this.fieldsTemplateOptions.filter((f: any) => f.required);
          })
          .catch(this.GtUtils.errorClb);
      }
      getFlatFields(fields: any) {
        return this.FormFieldParamsService.getFlatFields({ fieldsDef: fields }).map(
          (field: any) => {
            const res = field.templateOptions;
            res.key = field.key;
            return res;
          },
        );
      }
      applyResponsibleToPositions() {
        this.positions.forEach((position: any) => {
          if (!position.isResponsibleManuallySet) {
            position.responsible = this.multicontract.responsible;
          }
        });
      }

      setResponsibleWorkStatus = () => {
        this.AccountsService.User.get(this.multicontract.responsible)
          .then((userDetails) => {
            this.multicontract.responsibleWorkStatus = userDetails.profile.work_status;
          })
          .catch(this.GtUtils.errorClb);
      };

      setResponsibleForSigningWorkStatus = () => {
        this.AccountsService.User.get(this.multicontract.responsible_for_signing)
          .then((userDetails) => {
            this.multicontract.responsibleForSigningWorkStatus = userDetails.profile.work_status;
          })
          .catch(this.GtUtils.errorClb);
      };

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(
          this.MulticontractFormFieldsService.getMulticontractFormConfig(
            this.multicontract,
            this.positions,
            this.formNamePrefix,
          ),
        ).then(() => this.updateFields());
      }

      formValidChange() {
        this.positionsFormValid = this.positions.every((position: any) => position.$formValid);
      }

      updatePositions(positions: any) {
        this.positions = positions;
      }

      updateTotals(data: any) {
        this.multicontract.positions_volume_sum = data.positions_volume_sum;
        this.multicontract.positions_final_volume_sum = data.positions_final_volume_sum;
      }

      openPositionModal(position: any) {
        if (position.stage === 'ticket') {
          return this.ContractsService.requestModal(position).then(() => {
            this.updateData();
          });
        }
        return this.ContractsService.contractModal(position).then(() => {
          this.updateData();
        });
      }

      onFileSelect(file: any) {
        const [{ name, type }] = file;
        const fileId = this.tempId;
        this.savedFiles.push({ fileId, file });
        this.multicontract.documents.push({
          fileId,
          name,
          type,
          class: this.DocumentsService.getFileIcoClass(name),
        });
        this.tempId++;
      }

      removeDocument(event: any, fileToRemove: any) {
        if (fileToRemove.tempId) {
          this.savedFiles = this.savedFiles.filter(
            ({ tempId }: any) => tempId !== fileToRemove.tempId,
          );
          this.multicontract.documents = this.multicontract.documents.filter(
            ({ tempId }: any) => tempId !== fileToRemove.tempId,
          );
        } else {
          this.CoreService.confirmDeletionModal(this.DocumentsService.Document, fileToRemove.id)
            .then((data: any) => {
              if (data === 'delete') {
                notify(this.gettext('Doc removed'));
                this.multicontract.documents = this.multicontract.documents.filter(
                  ({ id }: any) => id !== fileToRemove.id,
                );
              }
            })
            .catch((error: any) => {
              this.GtUtils.errorClb(error);
              this.multicontract.documents.push(fileToRemove);
            });
        }
      }
    },
  ],
};
