import ng from 'angular';

import { formatDate, substractDate } from '~/shared/lib/utils';

import { analyticalPlanFactPnlReport } from './analytical-plan-fact-pnl-report/analytical-plan-fact-pnl-report.module';
import { approvalsReport } from './approvals-report/approvals-report.module';
import { basicMetrics } from './basic-metrics-page/basic-metrics.module';
import { budgets } from './budgets/budgets.module';
import { cashflow } from './cashflow/cashflow.module';
import { cashflowReport } from './cashflow-report/cashflow-report.module';
import { certificateDates } from './certificate-dates/certificate-dates.module';
import { ChartsBlock } from './charts-block/charts-block.component';
import { clientInvoicePaymentReconciliationReport } from './client-invoice-payment-reconciliation-report/client-invoice-payment-reconciliation-report.module';
import { clientLogisticPaymentReconciliationReport } from './client-logistic-payment-reconciliation-report/client-logistic-payment-reconciliation-report.module';
import { consolidatedContractPnl } from './consolidated-contract-pnl/consolidated-contract-pnl.module';
import { consolidatedCropPosition } from './consolidated-crop-position/consolidated-crop-position.module';
import { consolidatedPnl } from './consolidated-pnl/consolidated-pnl.module';
import { contractStatus } from './contract-status/contract-status.module';
import { costsReport } from './costs-report/costs-report.module';
import { costsStatistics } from './costs-statistics/costs-statistics.module';
import { creditReport } from './credit-report/credit-report.module';
import { debitCredit } from './debit-credit/debit-credit.module';
import { freightPosition } from './freight-position/freight-position.module';
import { invoiceRiskReport } from './invoice-risk-report/invoice-risk-report.module';
import { reportsLegacyModule } from './legacy';
import { LineupContractsTable } from './lineup-contracts-table/lineup-contracts-table.component';
import { LineupRequestsTable } from './lineup-requests-table/lineup-requests-table.component';
import { passportPnl } from './passport-pnl/passport-pnl.module';
import { physicalPosition } from './physical-position/physical-position.module';
import { pnlMtm } from './pnl-mtm/pnl-mtm.module';
import { purcahsePlan } from './purchase-plan/purchase-plan.module';
import { quantitativeMetrics } from './quantitative-metrics-page/quantitative-metrics.module';
import { salePlanReport } from './sale-plan-report/sale-plan-report.module';
import { signing } from './signing/signing.module';
import { statisticsReport } from './statistics-report/statistics-report.module';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
const reportsConfig = [
  '$stateProvider',
  'gettext',
  ($stateProvider: ng.ui.IStateProvider, gettext: ng.gettext.gettextFunction) => {
    $stateProvider
      .state('reports', {
        parent: 'gt.page',
        url: '/reports',
        abstract: true,
        template: '<ui-view/>',
      })
      .state('reports.balance', {
        url: '/balance',
        component: 'balancePage',
        data: {
          pageTitle: gettext('Balance'),
          permissions: {
            only: 'view_balance',
          },
        },
      })
      .state('reports.consolidatedCropPosition', {
        url: '/consolidated-crop-position',
        component: 'consolidatedCropPositionPage',
        data: {
          pageTitle: gettext('Consolidated crop position'),
          permissions: {
            only: 'view_position',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'crop-position-page-view';
          },
        },
      })
      .state('reports.statistics', {
        url: '/statistics/?contract_id_list?contract_type?title',
        component: 'statisticsReportPage',
        data: {
          pageTitle: gettext('Statistics'),
        },
        resolve: {
          filterLevel: () => {
            return 'statistics-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('statistics-report-page-view');
            },
          ],
        },
      })
      .state('reports.statisticsCosts', {
        url: '/statistics/costs/',
        component: 'costsStatisticsPage',
        data: {
          pageTitle: gettext('Costs report'),
        },
        resolve: {
          filterLevel: () => {
            return 'costs-statistics-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('costs-statistics-page-view');
            },
          ],
        },
      })
      .state('reports.approvalsReport', {
        url: '/approvals/',
        component: 'approvalsReportPage',
        data: {
          pageTitle: gettext('Approvals report'),
        },
        resolve: {
          filterLevel: () => {
            return 'approvals-report-page-view';
          },
          initQueryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('approvals-report-page-view');
              queryParams.date_predicate = 'create_time';
              queryParams.start_date = formatDate(
                substractDate(new Date(), { days: 365 }),
                'dd.MM.yyyy',
              );
              queryParams.end_date = formatDate(new Date(), 'dd.MM.yyyy');
              return queryParams;
            },
          ],
        },
      })
      .state('reports.purchasePlanReport', {
        url: '/purchase-plan',
        component: 'purchasePlanPage',
        data: {
          pageTitle: gettext('Purchase plan'),
          permissions: {
            only: 'view_purchaseplanreport',
          },
        },
      })
      .state('reports.cashflow', {
        url: '/cashflow',
        component: 'cashflowPage',
        data: {
          pageTitle: gettext('Cashflow'),
          permissions: {
            only: 'view_cashflow',
          },
        },
      })
      .state('reports.cashflowReport', {
        url: '/cashflow-report',
        component: 'cashflowReportPage',
        data: {
          pageTitle: gettext('new Cashflow'),
          permissions: {
            only: 'view_cashflow',
          },
        },
        resolve: {
          variant: () => {
            return 'split-tables';
          },
        },
      })
      .state('reports.cashflowBalanceReport', {
        url: '/cashflow-balance-report',
        component: 'cashflowReportPage',
        data: {
          pageTitle: gettext('new Cashflow'),
          permissions: {
            only: 'view_cashflow',
          },
        },
        resolve: {
          variant: () => {
            return 'joined-tables';
          },
        },
      })
      .state('reports.debitCredit', {
        url: '/debit-credit',
        component: 'debitCreditPage',
        data: {
          pageTitle: gettext('Debit Credit'),
          permissions: {
            only: 'view_reports',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'debit-credit-page-view';
          },
          initQueryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('debit-credit-page-view');
            },
          ],
        },
      })
      .state('reports.contractStatus', {
        url: '/contract-status/',
        component: 'contractStatusPage',
        data: {
          pageTitle: gettext('Contract Status'),
          permissions: {
            only: 'view_reports',
          },
        },
      })
      .state('reports.certificateDates', {
        url: '/certificate-dates',
        component: 'certificateDatesReportPage',
        data: {
          pageTitle: gettext('Certificate Dates'),
          permissions: {
            only: 'view_certificate_dates',
          },
        },
      })
      .state('pl', {
        parent: 'reports',
        url: '/pl',
        abstract: true,
        template: '<ui-view>',
      })
      .state('pl.passport', {
        url: '/passport',
        component: 'passportPnlPage',
        data: {
          pageTitle: gettext('Passports Profit and Loss'),
          permissions: {
            only: 'view_pl_passport',
          },
        },
      })
      .state('position.mtm', {
        url: '/mtm',
        component: 'pnlMtmPage',
        data: {
          pageTitle: gettext('Mark to Market'),
          permissions: {
            only: 'view_pl_mtm',
          },
        },
        resolve: {
          filterLevel: () => {
            return 'pnl-mtm-page-view';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              const queryParams = PageService.syncUrlFilter('pnl-mtm-page-view');
              queryParams.status_list = [
                'new',
                'shipment',
                'executed',
                'delivery_period',
                'cargo_executed',
              ];
              queryParams.with_internal_chain = 0;
              return queryParams;
            },
          ],
        },
      })
      .state('pl.consolidated', {
        url: '/consolidated',
        component: 'consolidatedPnlPage',
        data: {
          pageTitle: gettext('Consolidated PnL'),
        },
        resolve: {
          filterLevel: () => {
            return 'consolidated-pnl-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('consolidated-pnl-page');
            },
          ],
        },
      })
      .state('pl.analyticalPlanFactPnlReport', {
        url: '/analytical-plan-fact-pnl-report',
        component: 'analyticalPlanFactPnlReportPage',
        data: {
          pageTitle: gettext('Analytical-Plan-Fact PnL'),
        },
        resolve: {
          filterLevel: () => {
            return 'analytical-plan-fact-pnl-report-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('analytical-plan-fact-pnl-report-page');
            },
          ],
        },
      })
      .state('pl.consolidatedContract', {
        url: '/consolidated-contract',
        component: 'consolidatedContractPnlPage',
        data: {
          pageTitle: gettext('Consolidated Contract PnL'),
        },
        resolve: {
          filterLevel: () => {
            return 'consolidated-contract-pnl-page';
          },
          queryParams: [
            'PageService',
            function (PageService: PageService) {
              return PageService.syncUrlFilter('consolidated-contract-pnl-page');
            },
          ],
        },
      })
      .state('dashboard', {
        parent: 'reports',
        url: '/dashboard',
        abstract: true,
        template: '<ui-view>',
      })
      .state('dashboard.quantitativeMetrics', {
        url: '/quantitative-metrics',
        component: 'quantitativeMetricsPage',
        data: {
          pageTitle: gettext('Quantitative metrics'),
        },
      })
      .state('dashboard.basicMetrics', {
        url: '/basic-metrics',
        component: 'basicMetricsPage',
        data: {
          pageTitle: gettext('Basic metrics'),
        },
      });
  },
];

export const reportsModule = ng
  .module('reports', [
    reportsLegacyModule,
    consolidatedCropPosition,
    purcahsePlan,
    cashflow,
    cashflowReport,
    statisticsReport,
    costsStatistics,
    approvalsReport,
    debitCredit,
    contractStatus,
    certificateDates,
    signing,
    passportPnl,
    pnlMtm,
    consolidatedPnl,
    analyticalPlanFactPnlReport,
    consolidatedContractPnl,
    budgets,
    physicalPosition,
    quantitativeMetrics,
    basicMetrics,
    salePlanReport,
    freightPosition,
    creditReport,
    invoiceRiskReport,
    clientLogisticPaymentReconciliationReport,
    clientInvoicePaymentReconciliationReport,
    costsReport,
  ])
  .component('chartsBlock', ChartsBlock)
  .component('lineupContractsTable', LineupContractsTable)
  .component('lineupRequestsTable', LineupRequestsTable)
  .config(reportsConfig).name;
