import { BehaviorSubject, shareReplay, startWith, switchMap, tap } from '~/shared/lib/state';

import type { EntityOption } from '../ui/data-types';

export type Paginated<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export type Entity = {
  id: number;
};

export type EntityPageParams = {
  page_size?: number;
  page?: number;
};

export abstract class EntityRepository<
  TEntity extends Entity,
  TParams extends EntityPageParams,
  TEntityRequest = TEntity,
  TEntityInfoList = TEntity,
> {
  abstract get(id: number): Promise<TEntity>;
  abstract query(params: TParams): Promise<Paginated<TEntityInfoList>>;
  abstract search(input: string, id?: number): Promise<EntityOption[]>;
  abstract create(entity: TEntityRequest): Promise<TEntity>;
  abstract update(entity: Partial<TEntityRequest>): Promise<TEntity>;
  abstract delete(id: number): Promise<void>;
}

export abstract class EntityListViewModel<
  TEntity extends Entity,
  TParams extends EntityPageParams,
  TEntityRequest = TEntity,
  TEntityInfoList = TEntity,
> {
  private readonly entityRepo;
  protected getInitParams() {
    return { page_size: 25, page: 1 } as TParams;
  }

  protected readonly pageParamsSubj = new BehaviorSubject<TParams>(this.getInitParams());
  private readonly loadingSubj = new BehaviorSubject(false);

  readonly pageData$ = this.pageParamsSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((params) => this.entityRepo.query(params)),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
    startWith({ count: 0, results: [] }),
  );
  readonly pageParams$ = this.pageParamsSubj.asObservable();
  readonly loading$ = this.loadingSubj.asObservable();

  constructor() {
    this.entityRepo = this.resolveEntityRepo();
  }

  protected abstract resolveEntityRepo(): EntityRepository<
    TEntity,
    TParams,
    TEntityRequest,
    TEntityInfoList
  >;

  public pageParamsChanged = (params: Partial<TParams> = {}) => {
    this.pageParamsSubj.next({ ...this.pageParamsSubj.value, ...params });
  };

  public pageParamsUpdated = (params: TParams) => {
    this.pageParamsSubj.next(params);
  };

  public loadingChanged = (loading: boolean) => {
    this.loadingSubj.next(loading);
  };
}

export abstract class EntityListEditViewModel<
  TEntity extends Entity,
  TParams extends EntityPageParams,
  TEntityRequest = TEntity,
  TEntityInfoList = TEntity,
> extends EntityListViewModel<TEntity, TParams, TEntityRequest, TEntityInfoList> {
  private readonly editingSubj = new BehaviorSubject<boolean>(false);
  readonly editing$ = this.editingSubj.asObservable();

  public setEditing = (editing: boolean) => {
    this.editingSubj.next(editing);
  };
}
