import type ng from 'angular';

import type { GtUtilsService } from '../legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '../types';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class GtSidebarListService {
  $injector: ng.auto.IInjectorService;
  $q: ng.IQService;
  AccountsService: AccountsService;
  ClientsService: any;
  FinancesService: FinancesService;
  GtUtils: GtUtilsService;
  constructor($q: ng.IQService, $injector: ng.auto.IInjectorService) {
    this.$q = $q;
    this.$injector = $injector;

    this.GtUtils = $injector.get<GtUtilsService>('GtUtils');
    this.FinancesService = $injector.get<any>('FinancesService');
    this.AccountsService = $injector.get<AccountsService>('AccountsService');
    this.ClientsService = $injector.get<any>('ClientsService');
  }

  _getEndpoint(modelName: string) {
    switch (modelName) {
      case 'contracts.salecontract':
        return this.$injector.get<any>('ContractsService').SaleContract.queryLight;
      case 'contracts.purchasecontract':
        return this.$injector.get<any>('ContractsService').PurchaseContract.queryLight;
      case 'contracts.request':
        return this.$injector.get<any>('ContractsService').Request.query;
      case 'finances.finance':
        return this.FinancesService.Finance.query;
      case 'passports.passport':
        return this.$injector.get<any>('ContractsService').Passport.query;
      case 'logistic.logistic':
        return this.$injector.get<any>('LogisticsService').Logistic.queryLight;
      case 'auth.user':
        return this.AccountsService.User.query;
      case 'clients.client':
        return this.ClientsService.Client.sidebar;
      default:
        console.error(`Can not match model name ${modelName} to any endpoints;`);
    }
  }

  _getFormattedName(item: any, modelName: string) {
    let status;
    switch (modelName) {
      case 'contracts.salecontract':
      case 'contracts.purchasecontract':
        return {
          title: `${item.contract_number || 'ID ' + item.id}`,
          id: item.id,
          status: item.status,
        };
      case 'contracts.request':
        if (item.request_status === 'approved' || item.request_status === 'processed') {
          status = 'processing';
        } else if (item.request_status === 'new') {
          status = 'new';
        } else if (item.request_status === 'rejected' || item.request_status === 'cancelled') {
          status = 'cancelled';
        } else if (item.request_status === 'executed' || item.request_status == 'sent') {
          status = 'executed';
        }
        return {
          title: `${item.contract_number || 'ID ' + item.id}`,
          id: item.id,
          status: status,
        };
      case 'finances.finance':
        if (item.status === 'process') {
          status = 'processing';
        } else if (item.status === 'new') {
          status = 'new';
        } else if (item.status === 'paid') {
          status = 'executed';
        }
        return { title: item.number, id: item.id, status: status };
      case 'passports.passport':
        return { title: item.title, id: item.id, status: item.status.toLowerCase() };
      case 'logistic.logistic':
        return { title: item.vehicle_number, id: item.id, status: item.approval_status };
      case 'auth.user':
        return {
          title: `${item.first_name} ${item.last_name}`,
          id: item.id,
          status: item.is_active ? 'new' : 'cancelled',
        };
      case 'clients.client':
        if (item.status === 'Approved' || item.status === 'Approved_condition') {
          status = 'new';
        } else if (item.status === 'Pending' || item.status === 'Re-approval') {
          status = 'processing';
        } else if (item.status === 'Rejected') {
          status = 'cancelled';
        } else if (item.status === 'New') {
          status = 'executed';
        }
        return {
          title: item.name,
          id: item.id,
          status: status,
        };
      default:
        console.error(`Can not match model name ${modelName} to any name representations;`);
    }
  }

  _getParams(modelName: string) {
    switch (modelName) {
      case 'contracts.request':
        return { fields: ['id', 'contract_number', 'request_status'], stage: 'ticket' };
      case 'finances.finance':
        return { fields: ['id', 'number', 'status'] };
      case 'passports.passport':
        return { fields: ['id', 'title', 'status'] };
      case 'auth.user':
        return { fields: ['id', 'first_name', 'last_name', 'is_active'] };
      default:
        return {};
    }
  }

  getData(modelName: string, queryParams: QueryParams, currentInstance: any, callback: any) {
    modelName = modelName.toLowerCase();
    const params = Object.assign(this._getParams(modelName), queryParams);
    const listEndpoint = this._getEndpoint(modelName);
    if (!listEndpoint) {
      return;
    }
    return listEndpoint(params)
      .$promise.then((data: any) => {
        data.results = data.results.map((item: any) => this._getFormattedName(item, modelName));
        const instanceInList = data.results.some((item: any) => item.id == currentInstance.id);
        if (!instanceInList) {
          data.results.unshift(this._getFormattedName(currentInstance, modelName));
        }
        return data;
      })
      .then(callback);
  }
}
GtSidebarListService.$inject = ['$q', '$injector'];
