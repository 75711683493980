import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './invoice-billing.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const InvoiceBillingContainer = {
  bindings: {
    invoicing: '<',
    selectedLogistics: '<',
    invoicePurpose: '<',
    contractType: '<',
    onParamsChanged: '&',
    selectAll: '&',
    onFinish: '&',
  },

  template,

  controller: [
    '$scope',
    '$rootScope',
    '$timeout',
    '$q',
    'FinancesService',
    'ContractsService',
    'GtUtils',
    'gettext',
    'LogisticsService',
    'LogisticsInvoicingService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $timeout: ng.ITimeoutService;
      ContractsService: ContractsService;
      FinancesService: FinancesService;
      GtUtils: GtUtilsService;
      LogisticsInvoicingService: any;
      LogisticsService: LogisticsService;
      amountsData: any;
      assignment: any;
      assignmentOptions: any;
      contactFieldName = '';
      contractType: any;
      divideInvoiceAmountForContracts: any;
      divideInvoiceAmountForLogisticCommodity: any;
      divideInvoiceAmountForPassports: any;
      gettext: ng.gettext.gettextFunction;
      groupedInvoices: any;
      invoiceAmount: any;
      invoiceCondition: any;
      invoiceConditionChoices: any;
      invoiceGroupByCounterparty: any;
      invoiceGroupByTerminal: any;
      invoiceLoader: any;
      invoicePurpose: any;
      invoiceVolumeDefaults: any;
      invoicingConditions: any;
      invoicingDisabled: any;
      invoicingVolume: any;
      invoicingVolumeChoices: any;
      maintUnit: any;
      newFinance: any;
      onFinish: any;
      onParamsChanged: any;
      percent: any;
      progress: any;
      resource: any;
      selectAll: any;
      selectAllChecked: any;
      selectedDate: any;
      selectedLogistics: any;
      showLimitBlock: any;
      volumeLimit: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $timeout: ng.ITimeoutService,
        $q: ng.IQService,
        FinancesService: FinancesService,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        LogisticsService: LogisticsService,
        LogisticsInvoicingService: any,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$q = $q;
        this.FinancesService = FinancesService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.LogisticsService = LogisticsService;
        this.LogisticsInvoicingService = LogisticsInvoicingService;

        this.invoiceConditionChoices = [
          { id: 'prepay', title: this.gettext('Prepay') },
          { id: 'balance', title: this.gettext('Balance') },
          { id: 'total', title: this.gettext('Total') },
        ];
        this.invoiceGroupByTerminal = false;
        this.invoiceGroupByCounterparty = false;
        this.invoicingDisabled = false;
        this.selectAllChecked = false;
        this.newFinance = {};
        this.amountsData = [];
        this.invoiceCondition = 'prepay';
        this.invoicingVolume = undefined;
        this.invoicePurpose = undefined;
        this.resource = undefined;
        this.invoiceAmount = 0;
        this.divideInvoiceAmountForContracts = false;
        this.divideInvoiceAmountForPassports = false;
        this.showLimitBlock = false;
        this.volumeLimit = 0;
        this.groupedInvoices = [];
        this.invoiceLoader = {
          loading: false,
          max: null,
          current: null,
        };
        this.selectedDate = new Date();
        this.assignment = 'commercial';
        this.assignmentOptions = [
          { value: 'commercial', name: this.gettext('Commercial Invoice') },
          { value: 'proforma', name: this.gettext('Proforma Invoice') },
          { value: 'debit', name: this.gettext('Debit Note') },
          { value: 'credit', name: this.gettext('Credit Note') },
        ];

        this.maintUnit = undefined;
      }
      _notifyError(msg: any) {
        return notify(msg, 'error');
      }
      $onInit() {
        this.initInvoiceCondition();
        this.resource = this.LogisticsService.Logistic;
        this.maintUnit = this.$rootScope.user.settings.MAIN_MEASUREMENT_UNIT;

        this.invoiceVolumeDefaults = {
          incoming:
            this.$rootScope.user.settings.DEFAULT_VALUES
              .default_logistics_volume_for_prepaid_invoice_purchase,
          outgoing:
            this.$rootScope.user.settings.DEFAULT_VALUES
              .default_logistics_volume_for_prepaid_invoice_sale,
          export:
            this.$rootScope.user.settings.DEFAULT_VALUES
              .default_logistics_volume_for_prepaid_invoice_export,
          costs: 'volume_received',
        };
        this.invoicingVolumeChoices = [
          { key: 'volume_received', title: this.gettext('Volume received') },
          { key: 'volume_departed', title: this.gettext('Volume departed') },
          {
            key: 'volume_departed_consignment',
            title: this.gettext('Volume departed consignment'),
          },
          { key: 'volume_departed_real', title: this.gettext('Volume departed real') },
          { key: 'volume_boarded', title: this.gettext('Volume boarded') },
        ];

        this.invoicingVolume = this.invoiceVolumeDefaults[this.invoicePurpose];
        this.contactFieldName = (
          {
            export: 'export_contract',
            incoming: 'supplier_contract',
            outgoing: 'buyer_contract',
          } as any
        )[this.invoicePurpose];
      }
      refreshCheckboxes() {
        this.onParamsChanged({
          changedFields: {
            invoiceCondition: this.invoiceCondition,
            invoicingVolume: this.invoicingVolume,
          },
        });
        this.updateNewFinance();
      }

      $onChanges(changes: any) {
        if (changes.invoicePurpose) {
          this.initInvoiceCondition();
        }
        if (changes.selectedLogistics) {
          this.updateNewFinance();
        }
      }
      initInvoiceCondition() {
        if (['costs', 'export'].includes(this.invoicePurpose)) {
          this.invoiceCondition = 'total';
          return;
        }
        this.invoiceCondition = 'prepay';
      }

      callSelectAll() {
        this.$timeout(() => {
          this.selectAll({ checked: this.selectAllChecked });
        });
      }
      updateNewFinance() {
        if (this.selectedLogistics.length === 0) {
          this.amountsData = {
            amount_volume: 0,
            total_amount_volume: 0,
            amount: 0,
            amount_balance: 0,
            amount_plan: 0,
          };
          return null;
        }
        if (this.contactFieldName) {
          this.updateNewFinanceBusinessUnit();
        }

        this.invoicingConditions = parseFloat(this.invoicingConditions);
        if (this.invoicingConditions === 0) {
          this._notifyError(this.gettext('-Payment conditions value must be 1-100'));
          return false;
        }

        const divideTypeMap: any = {
          contract: this.divideInvoiceAmountForContracts,
          passport: this.divideInvoiceAmountForPassports,
          commodity: this.divideInvoiceAmountForLogisticCommodity,
        };

        const divideType =
          Object.keys(divideTypeMap).find((key) => divideTypeMap[key]) ?? undefined;

        if (this.invoicePurpose === 'costs' || this.invoicePurpose === 'export') {
          return this.LogisticsInvoicingService.createInvoiceObject({
            invoice_purpose: this.invoicePurpose,
            contract_type: this.contractType,
            logistic_ids: this.selectedLogistics.map((logistic: any) => logistic.id),
            logistics: this.selectedLogistics.map((logistic: any) => {
              return {
                id: logistic.id,
                quantity_to_be_invoiced: logistic.quantity_to_be_invoiced || 0,
              };
            }),
            volume_field: this.invoicingVolume,
            amount_for_devide: this.invoiceAmount,
            divide_type: divideType,
          }).then(
            (data: any) => {
              this.newFinance = data.invoice;
              this.newFinance.clientrole_from =
                this.newFinance.clientrole_from || this.getForwarder();
              this.amountsData = {
                amount_volume: data.invoice.volume || 0,
                total_amount_volume: data.invoice.volume || 0,
                amount: data.invoice.amount || 0,
                amount_balance: data.invoice.amount || 0,
                amount_plan: data.invoice.amount || 0,
              };
            },
            (err: any) => {
              this.GtUtils.errorClb(err);
            },
          );
        }

        const maxDate = this.getMaxDate();
        if (maxDate) {
          this.newFinance.date_of_certification = maxDate;
          this.newFinance.date_of_execution = this.GtUtils.addBusinessDays(maxDate, 3);
        }

        this.GtUtils.overlay('show');
        return this.resource
          .calcAmounts({
            logistics: this.selectedLogistics.map((logistic: any) => logistic.id),
            invoice_condition: this.invoiceCondition,
            invoice_type: this.invoicePurpose,
            invoice_conditions:
              this.invoiceCondition === 'prepay' ? this.invoicingConditions : undefined,
            invoice_field: this.invoiceCondition === 'prepay' ? this.invoicingVolume : undefined,
            group_by_terminal: this.invoiceGroupByTerminal,
            group_by_counterparty: this.invoiceGroupByCounterparty,
          })
          .$promise.then((invoices: any) => {
            this.GtUtils.overlay('hide');
            let localCount = 0;
            if (this.invoiceGroupByCounterparty) {
              this.groupedInvoices = invoices.map((invoice: any) => ({
                invoice: {
                  ...this.processNewFinanceData(invoice, localCount),
                  invoice_type: this.invoicePurpose,
                },

                positionSet: invoice.position_set,
              }));
              localCount += 1;
              this.calculateAmounts(invoices);
            } else {
              this.newFinance = this.processNewFinanceData(invoices[0], localCount);
              this.calculateAmounts(invoices[0]);
            }
          })
          .catch((err: any) => {
            this.GtUtils.errorClb(err);
          });
      }
      updateNewFinanceBusinessUnit() {
        this.ContractsService.Contract.query({
          id_list: this.selectedLogistics
            .map((item: any) => item[this.contactFieldName])
            .filter((item: any) => Boolean(item)),
        }).$promise.then((data: any) => {
          const buList: any = [
            ...new Set(
              data.results
                .map((contract: any) => contract.business_unit)
                .filter((v: any) => Boolean(v)),
            ),
          ];
          if (buList.length === 1) {
            this.newFinance.business_unit = buList[0];
          } else {
            this.newFinance.business_unit = undefined;
          }
        });
      }

      newGroupedInvoice(finance: any) {
        return {
          ...finance.invoice,
          assignment: this.assignment,
          date: this.selectedDate,
          date_of_execution: this.GtUtils.addDaysToDate(
            this.selectedDate,
            this.$rootScope.user.settings.DEFAULT_VALUES.invoice_payment_days,
          ),
        };
      }

      createInvoice() {
        if (this.invoicePurpose === 'costs' || this.invoicePurpose === 'export') {
          return this.createCostExportInvoice();
        }
        return this.createCommodityInvoice();
      }
      createCostExportInvoice() {
        const financePositions = this.newFinance.position_set;
        delete this.newFinance.position_set;

        return this.FinancesService.financeModal(this.newFinance, {
          financePositions: financePositions,
          isExportInvoice: this.invoicePurpose === 'export',
          isInvoiceFromLogistics: true,
        }).then(() => this.close());
      }
      createCommodityInvoice() {
        return this.FinancesService.financeModal(this.newFinance, {
          financePositions: this.getFinancePositions(this.amountsData.position_set || []),
          logisticsData: this.getLogisticsData(
            this.selectedLogistics.map((logistic: any) => logistic.id),
          ),
          logistics: this.selectedLogistics.map((logistic: any) => logistic.id),
          isInvoiceFromLogistics: true,
          invoicePurpose: this.invoicePurpose,
        }).then(() => this.close());
      }
      initLoader() {
        this.invoiceLoader.loading = true;
        this.invoiceLoader.current = 0;
        this.invoiceLoader.max = this.groupedInvoices.length;
        this.progress = `${this.invoiceLoader.current} / ${this.invoiceLoader.max} [ ${this.percent}% ]`;
      }
      countInvoiceLoading() {
        this.invoiceLoader.current++;
        if (this.invoiceLoader.max <= this.invoiceLoader.current) {
          this.close();
        }
        this.percent = Math.round(this.invoiceLoader.current / this.invoiceLoader.max);
        this.progress = `${this.invoiceLoader.current} / ${this.invoiceLoader.max} [ ${this.percent}% ]`;
      }
      createGroupedInvoices() {
        if (!this.invoiceGroupByCounterparty) {
          return;
        }
        this.initLoader();
        const promises = this.groupedInvoices.map((invoice: any) =>
          this.createGroupedInvoice(invoice),
        );
        return this.$q.all(promises).then(() => this.close());
      }
      createGroupedInvoice(finance: any) {
        const logistics = finance.invoice.logistics;
        delete finance.invoice.logistics;

        let invoicePositions = finance.positionSet || [];

        if (this.invoicePurpose !== 'costs') {
          invoicePositions = this.getFinancePositions(invoicePositions);
        }
        this.getSides(
          invoicePositions
            .filter((item: any) => item.contract && item.use === 'cargo')
            .map((item: any) => item.contract),
        )
          .then((sides: any) => {
            let when = this.$q.when();
            if (sides) {
              when = this.FinancesService.Finance.bulkCreateOrUpdatePositions({
                invoice: {
                  ...this.newGroupedInvoice(finance),
                  ...sides,
                },
                invoice_positions: invoicePositions,
                logistics: logistics,
                logistics_data: this.getLogisticsData(logistics),
              }).$promise.then(() => notify(this.gettext('Invoice was successfully created')));
            }
            return when;
          })
          .catch(this.GtUtils.errorClb)
          .finally(() => this.countInvoiceLoading());
      }
      getSides(invoicePositionsIds: any) {
        return this.ContractsService.Contract.uniqueCounterparties({
          ids: invoicePositionsIds,
        }).$promise.then((result: any) => {
          if (result.unique_counterparties.length === 1) {
            return {
              clientrole_from: result.unique_counterparties[0].supplier_id,
              clientrole_to: result.unique_counterparties[0].buyer_id,
            };
          }
          if (result.unique_counterparties.length) {
            this._notifyError(this.gettext('Counterparties in contracts is not unique'));
          }
        });
      }

      getLogisticsData(logistics: any) {
        return {
          item_ids: logistics,
          update_data: {
            invoice_field: this.invoicingVolume,
            invoice_condition: this.invoiceCondition,
            payment_conditions: this.invoicingConditions,
            invoicing_type: this.invoicePurpose,
          },
        };
      }
      getFinancePositions(positions: any) {
        return positions.map((position: any) => {
          let positionAmount = 0;
          let positionPriceT = 0;
          let quantityTotal = 0;
          let newPosition: any = {};
          const quantity =
            position.use === 'costs' || position.use === 'gains' ? 1 : position.invoiced_volume;

          if (this.invoiceCondition === 'total') {
            positionAmount = position.total_invoiced_plan;
            positionPriceT = position.group_price;
            quantityTotal = position.total_invoiced_volume;
          } else if (this.invoiceCondition === 'balance') {
            positionAmount = position.to_be_invoiced_balance;
            positionPriceT = position.group_price;
            quantityTotal = position.invoiced_volume;
          } else if (this.invoiceCondition === 'prepay') {
            positionAmount = position.to_be_invoiced;
            positionPriceT = position.group_price;
            quantityTotal = position.total_invoiced_volume;
          }

          newPosition = {
            use: position.use,
            amount: positionAmount,
            price_per_piece: positionPriceT,
            quantity: quantity,
            quantity_total: quantityTotal,
            contract: position.contract_id,
            crop: position.group_cargo_id,
            terminal: position.terminal_id,
            terminal_name: position.terminal_name,
            vat_option: position.vat_option,
            vat_value: position.vat_value,
            _charge_id: position.charge_id,
          };
          if (position.logistic_cost) {
            newPosition.logistic_cost = position.logistic_cost;
          }
          if (this.invoicePurpose != this.newFinance.invoice_type) {
            newPosition.amount = Math.abs(newPosition.amount);
            newPosition.price_per_piece = Math.abs(newPosition.price_per_piece);
          }

          return newPosition;
        });
      }
      calculateAmounts(data: any) {
        if (!this.invoiceGroupByCounterparty) {
          this.amountsData = data;
          return;
        }

        const initialAmounts = { amount: 0, amount_volume: 0, total_amount_volume: 0 };

        this.amountsData = data.reduce(
          (totals: any, invoice: any) => ({
            amount: totals.amount + (invoice.amount || 0),
            amount_volume: totals.amount_volume + (invoice.amount_volume || 0),
            total_amount_volume: totals.total_amount_volume + (invoice.total_amount_volume || 0),
          }),
          initialAmounts,
        );
      }

      processNewFinanceData(data: any, localcount: number) {
        const finance: any = {
          full_amount: 0,
          full_volume: 0,
          volume: 0,
          amount: 0,
          condition: this.invoiceCondition !== 'total' ? this.invoiceCondition : 'balance',
        };

        const conditionsMap: any = {
          balance: ['amount_balance', 'amount_volume'],
          total: ['amount_plan', 'total_amount_volume'],
          prepay: ['amount', 'amount_volume'],
        };

        [finance.full_amount, finance.full_volume] = conditionsMap[this.invoiceCondition].map(
          (item: any) => data[item],
        );

        if (this.invoicePurpose === 'outgoing' || this.invoicePurpose === 'incoming') {
          finance.invoice_type = this.invoicePurpose;
        }

        if (finance.invoice_type === 'outgoing' && finance.full_amount < 0) {
          finance.invoice_type = 'incoming';
        } else if (finance.invoice_type === 'incoming' && finance.full_amount < 0) {
          finance.invoice_type = 'outgoing';
        }

        finance.volume = Math.round(finance.full_volume * 1000) / 1000;
        finance.amount = Math.abs(Math.round(finance.full_amount * 100) / 100);
        finance.full_amount = finance.amount;

        finance.payment_conditions =
          data.payment_conditions || Math.round((this.invoicingConditions || 100) * 100) / 100;

        if (this.invoiceGroupByCounterparty) {
          const {
            supplier_abbreviation: abbreviation,
            commodity_short_title: commodity,
            invoice_by_today_count: count,
          } = data;
          const fullDate = new Date();
          const date = `${fullDate.getUTCDate()}${
            fullDate.getUTCMonth() + 1
          }${fullDate.getUTCFullYear()}`;
          const invoiceNumber = count + localcount;
          finance.number = `${abbreviation || ''}/${commodity || ''}/${date}/${
            invoiceNumber || ''
          }`;
          finance.logistics = data.logistic_id_list;
          finance.assignment = 'commercial';
          finance.business_unit = data.business_unit_id;
        }

        if (data.position_set) {
          const contractCurrencies = this.GtUtils.listUnique(
            (data.position_set || []).map((ip: any) => ip.currency_id),
          );

          const contractCurrencyExchanges = this.GtUtils.listUnique(
            (data.position_set || []).map((ip: any) => ip.currency_exchange_id),
          );

          if (contractCurrencies.length !== 1) {
            this._notifyError(this.gettext('Contracts should have same currency.'));
            this.invoicingDisabled = true;
            finance.currency = undefined;
            finance.currency_exchange = undefined;
          } else {
            finance.currency = contractCurrencies[0];
            finance.currency_exchange = contractCurrencyExchanges[0];
            this.invoicingDisabled = false;
          }
        }
        return finance;
      }

      getMaxDate() {
        let maxDate: any;
        this.selectedLogistics.forEach((logistic: any) => {
          if (
            logistic.vet &&
            logistic.quarantine &&
            logistic.broker_confirmation &&
            logistic.quality &&
            logistic.declaration &&
            logistic.railway
          ) {
            const dates: any = [
              logistic.vet,
              logistic.quarantine,
              logistic.broker_confirmation,
              logistic.quality,
              logistic.declaration,
              logistic.railway,
            ];
            if (maxDate) {
              dates.push(maxDate);
            }
            maxDate = new Date(Math.max.apply(null, dates));
          }
        });
        return maxDate;
      }

      getForwarder() {
        if (this.selectedLogistics.length <= 0) {
          return null;
        }
        const uniqueForwarderList = this.GtUtils.listUnique(
          (this.selectedLogistics || []).map((item: any) => item.forwarder),
        );

        if (uniqueForwarderList.length !== 1) {
          this._notifyError(this.gettext('Selected more than one forwarder.'));
          return null;
        } else {
          return uniqueForwarderList[0];
        }
      }

      close() {
        this.invoiceLoader = {};
        return this.onFinish();
      }
      cancelInvoicing() {
        this.refreshCheckboxes();
        this.divideInvoiceAmountForContracts = false;
        this.divideInvoiceAmountForPassports = false;
        this.divideInvoiceAmountForLogisticCommodity = false;
        this.selectAllChecked = false;
      }

      onDivideByContractsChange() {
        this.refreshCheckboxes();
        this.selectAllChecked = false;
        this.divideInvoiceAmountForPassports = false;
      }

      onDivideByPassportsChange() {
        this.refreshCheckboxes();
        this.selectAllChecked = false;
        this.divideInvoiceAmountForContracts = false;
      }

      onDivideByLogisticCommodityChange() {
        this.refreshCheckboxes();
        this.selectAllChecked = false;
        this.callSelectAll();
      }

      broadcastInvoicingVolumeUpdated() {
        if (this.invoicePurpose === 'export' && this.invoicingVolume !== undefined) {
          this.$rootScope.$broadcast('invoicingVolume-changed', this.invoicingVolume);
        }
      }
    },
  ],
};
