import React from 'react';

import { Avatar } from '~/shared/ui/kit/avatar';

import type { DataTypeProps } from '../../lib';

export const PersonRead: React.FC<
  DataTypeProps<{ firstName: string; lastName: string; avatarSrc?: string }>
> = ({ value }) => {
  return (
    <div className="flex items-center gap-1">
      <Avatar
        src={value.avatarSrc ?? ''}
        fallback={value.firstName.charAt(0)}
        alt={value.firstName.charAt(0)}
      />
      <div>
        {value.firstName} {value.lastName}
      </div>
    </div>
  );
};
