import type { Entity } from '~/shared/common';
import type { MessageIntent } from '~/shared/lib/types';

export type DataTypeProps<T> = {
  disabled?: boolean;
  value: T;
  valueChanged?: (value: T | undefined) => void;
  ref?: React.Ref<HTMLInputElement>;
  message?: {
    type: MessageIntent;
    text?: string;
  };
};
export type EntityOption = Entity & { title: string };

export const isEntityOption = (value: unknown): value is EntityOption => {
  return typeof value === 'object' && value !== null && 'id' in value && 'title' in value;
};
