import { replaceUnderscoresWithSpaces } from '~/shared/lib/utils';

import type { ColumnConfig, EntityColumn, EntityFieldProperty } from './types';

export const entityColumnsToPropertiesBuilder = <T = unknown>({
  columns,
  columnsConfig: columnConfig,
}: {
  columns: EntityColumn[];
  columnsConfig: ColumnConfig<T>[];
}): EntityFieldProperty<T>[] => {
  return columns
    .filter((property) => columnConfig.some((column) => column.key === property.column_name))
    .map((property) => {
      const column = columnConfig.find((col) => col.key === property.column_name);

      return {
        key: property.column_name,
        title: replaceUnderscoresWithSpaces({ input: property.column_name, capitalize: true }),
        visible: property.visible ?? true,
        position: property.index ?? 0,
        userTitle: property.new_title ?? '',
        columnsSetConfigId: property.columnssetconfig_id,
        id: property.id,
        tableName: property.table_name,
        users: property.users,
        sourceKey: column?.sourceKey,
        dataType: column?.dataType ?? 'text',
        validate: column?.validate,
        cellTemplateContext: column?.cellTemplateContext ?? {},
        size: column?.size ?? 200,
        render: column?.render,
        valueFormatter: column?.valueFormatter,
        valueParser: column?.valueParser,
        valueSetter: column?.valueSetter,
        editing: column?.editing ?? false,
        typeExtra: column?.typeExtra,
        sticky: column?.sticky ?? false,
      };
    });
};
