import template from './gt-sidebar-list.html?raw';
import type { GtFilterService } from '../../legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '../../legacy/gt-utils/gt-utils.srv';
import type { QueryParams } from '../../types';

import type { HelpButtonsService } from '^/app/core/legacy/help-buttons/help-buttons.srv';

export const GtSidebarList = {
  bindings: {
    config: '<',
  },
  template,
  controller: [
    '$scope',
    'gtFilterService',
    'HelpButtonsService',
    'GtUtils',
    'GtSidebarListService',
    class {
      $scope: ng.IScope;
      GtSidebarListService: any;
      GtUtils: GtUtilsService;
      HelpButtonsService: HelpButtonsService;
      config: any;
      filterLevel = '';
      gtFilterService: GtFilterService;
      icon: any;
      instance: any;
      items: any;
      itemsCount = 0;
      modelName = '';
      queryParams: QueryParams = {};
      subscription: any;
      verboseName?: string;
      constructor(
        $scope: ng.IScope,
        gtFilterService: GtFilterService,
        HelpButtonsService: HelpButtonsService,
        GtUtils: GtUtilsService,
        GtSidebarListService: any,
      ) {
        this.$scope = $scope;
        this.gtFilterService = gtFilterService;
        this.HelpButtonsService = HelpButtonsService;
        this.GtUtils = GtUtils;
        this.GtSidebarListService = GtSidebarListService;

        this.items = [];
      }

      $onInit() {
        this.$scope.$on('sidebar-list-toggle-hide', (_: any, newValue: any) => {
          this.config.sidebar.is_open = newValue;
        });
        this.subscription = this.$scope.$on(
          'gt-filter-updated_' + this.filterLevel,
          (_: any, data: any) => {
            this.queryParams = data;
            if (this.modelName) {
              this.updateData();
            }
          },
        );
      }

      $onChanges(changes: any) {
        if (changes.config && this.config.sidebar) {
          let needsUpdate = false;
          if (
            this.modelName !== this.config.sidebar.modelName ||
            this.filterLevel !== this.config.sidebar.filterLevel
          ) {
            needsUpdate = true;
          }
          this.modelName = this.config.sidebar.modelName;
          this.filterLevel = this.config.sidebar.filterLevel;
          this.instance = this.config.sidebar.instance;
          this.verboseName = this.config.sidebar.verboseName;
          this.subscription?.();
          this.subscription = this.$scope.$on(
            'gt-filter-updated_' + this.filterLevel,
            (_: any, data: any) => {
              this.queryParams = data;
              if (this.modelName) {
                this.updateData();
              }
            },
          );
          if (needsUpdate) {
            this.gtFilterService.setQueryParams(
              this.gtFilterService.getQueryParams(this.filterLevel),
              this.filterLevel,
            );
            this.icon = this.getIcon(this.modelName);
          }
        }
      }

      getIcon(modelName: string) {
        if (modelName) {
          return this.GtUtils.getIcon(modelName);
        }
      }

      toggleHidden(isOpen: any) {
        this.$scope.$emit('sidebar-list-toggle-hide', isOpen);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.GtSidebarListService.getData(
          this.modelName,
          this.queryParams,
          this.instance,
          (data: any) => {
            this.items = data.results;
            this.itemsCount = data.count;
            this.GtUtils.overlay('hide');
          },
        );
      }

      toggleFilter() {
        if (this.config.filters.clicked) {
          this.gtFilterService.updateQueryParams({}, this.filterLevel);
        }
        this.config.filters.clicked = !this.config.filters.clicked;
        this.config.filters.filterLevel = this.filterLevel;
        this.config.filter = this.config.filters;
        this.HelpButtonsService.setConfig(this.config, this.filterLevel);
      }

      goToDetails(id: number) {
        this.GtUtils.goToDetails(this.modelName, id);
      }
    },
  ],
};
