import React from 'react';

import { SelectWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const SelectCellWrite = ({
  value,
  cellTemplateContext,
  valueChanged,
  disabled,
}: CellTypeProps) => {
  return (
    <div className="px-2">
      <SelectWrite
        value={String(value)}
        cellTemplateContext={cellTemplateContext}
        valueChanged={valueChanged}
        disabled={disabled}
      />
    </div>
  );
};
