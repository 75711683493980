import type { Entity } from '~/shared/common';

export const onEditHandler = <TRecord extends Entity, TRecordUpdate = TRecord>({
  data,
  diff,
  editedRecordsRef,
  onEdit,
  setEditingData,
}: {
  diff: {
    newValue: TRecord[keyof TRecord] | undefined;
    accessorKey: string;
    id: number;
  };
  data: TRecord[];
  editedRecordsRef: React.MutableRefObject<Partial<TRecord>[]>;
  setEditingData: (data: TRecord[]) => void;
  onEdit: (updatedRecords: TRecordUpdate[]) => void;
}) => {
  const { newValue, accessorKey, id } = diff;

  const updatedValue = {
    [accessorKey]: newValue,
    id: id,
  } as TRecord;

  const existingRecordIndex = editedRecordsRef.current.findIndex(
    (record) => record.id === updatedValue.id,
  );

  const updatedRecords =
    existingRecordIndex !== -1
      ? editedRecordsRef.current.map((record, index) =>
          index === existingRecordIndex ? { ...record, ...updatedValue } : record,
        )
      : [...editedRecordsRef.current, updatedValue];

  editedRecordsRef.current = updatedRecords;

  const existingDataIndex = data.findIndex((record) => record.id === updatedValue.id);

  const updatedEditingData =
    existingDataIndex !== -1
      ? data.map((record, index) =>
          index === existingDataIndex ? { ...record, ...updatedValue } : record,
        )
      : [...data, updatedValue];

  setEditingData(updatedEditingData);
  onEdit(editedRecordsRef.current as unknown as TRecordUpdate[]);
};
