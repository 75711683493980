import type { EntityFieldProperty } from '~/core/page-views/lib';
import type { SpreadsheetRef } from '~/shared/lib/spreadsheet';

type ValidationHandler = (
  index: number,
  validate: NonNullable<EntityFieldProperty['validate']>,
) => void;

export const columnValidationBuilder = <T = unknown>({
  properties,
  recordsLength,
  ref,
  rowCount,
}: {
  properties: EntityFieldProperty<T>[];
  recordsLength: number;
  ref: SpreadsheetRef;
  rowCount: number;
}) => {
  properties
    .map((property, index) => ({ ...property, index }))
    .filter((property) => property.editing === false)
    .forEach(({ index }) => {
      ref.setProtectedColumns([
        { startRow: 1, startColumn: index, endRow: rowCount, endColumn: index },
      ]);
    });

  const validationHandlers = getValidationHandlers({ ref, recordsLength });

  properties
    .map((property, index) => ({ ...property, index }))
    .filter((property) => property.validate)
    .forEach(({ index, validate }) => {
      if (validate?.type && validationHandlers[validate.type]) {
        validationHandlers[validate.type](index, validate);
      }
    });
};

const getValidationHandlers = ({
  ref,
  recordsLength,
}: {
  ref: SpreadsheetRef;
  recordsLength: number;
}) => {
  const validationHandlers: Record<string, ValidationHandler> = {
    equalValue: (index, validate) => {
      if ('values' in validate) {
        ref.setSelectValidate({
          range: [1, index, recordsLength, 1],
          options: validate.values,
        });
      }
    },
    equalValues: (index, validate) => {
      if ('values' in validate) {
        ref.setSelectValidate({
          range: [1, index, recordsLength, 1],
          options: validate.values,
          allowMultiple: true,
        });
      }
    },
    textLength: (index, validate) => {
      if (
        'condition' in validate &&
        'value' in validate &&
        (validate.condition === 'lessThan' || validate.condition === 'moreThan') &&
        typeof validate.value === 'string'
      ) {
        ref.setTextValidate({
          range: [1, index, recordsLength, 1],
          condition: validate.condition,
          value: validate.value,
        });
      }
    },
    number: (index, validate) => {
      if (
        'condition' in validate &&
        'value' in validate &&
        (validate.condition === 'lessThan' || validate.condition === 'moreThan') &&
        typeof validate.value === 'number'
      ) {
        ref.setNumberValidate({
          range: [1, index, recordsLength, 1],
          condition: validate.condition,
          value: validate.value,
        });
      }
    },
  };

  return validationHandlers;
};
