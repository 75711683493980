import type ng from 'angular';
import type { Subscription } from 'rxjs';

import { LogisticListViewModel } from '~/features/execution/logistic';
import type {
  ConnectionMode,
  InvoicePurpose,
  InvoicingVolume,
  LogisticCreate,
  LogisticList,
  LogisticListTotals,
  LogisticTable,
  LogisticUpdate,
} from '~/features/execution/logistic/lib';
import { tableColumnsMap } from '~/features/execution/logistic/lib/columns';
import { container } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifyError } from '~/shared/lib/notify';

import template from './logistics-container.html?raw';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { LogisticsService } from '^/app/execution/legacy/logistics.srv';

type LogisticsQueryParams = QueryParams & {
  serializer: LogisticTable;
};

type ViewMode = 'table' | 'spreadsheet' | 'grid';

class LogisticsContainerController implements ng.IController {
  viewModel: LogisticListViewModel;
  editing = false;
  pageData: { count: number; results: LogisticList[] } = { results: [], count: 0 };
  totals: {
    totals: LogisticListTotals;
    mainTotals: LogisticListTotals;
    intermediateTotals: LogisticListTotals;
  } = {
    totals: {} as LogisticListTotals,
    mainTotals: {} as LogisticListTotals,
    intermediateTotals: {} as LogisticListTotals,
  };

  logistics: LogisticList[] = [];
  logisticsCount = 0;
  logisticsIntermediateTotals: LogisticListTotals = {} as LogisticListTotals;
  logisticsMainTotals: LogisticListTotals = {} as LogisticListTotals;
  logisticsTotal: LogisticListTotals = {} as LogisticListTotals;
  selectedLogistics: LogisticList[] = [];
  selectedTotals: LogisticListTotals = {} as LogisticListTotals;
  invoicePurpose?: InvoicePurpose;
  selectableItems: number[] = [];

  connectionMode?: ConnectionMode;
  contractType?: 'sale' | 'purchase';

  subs: Record<string, Subscription> = {};

  loading = false;
  filterLevel = 'logistics-container';
  tableName: LogisticTable = 'table_info';
  initQueryParams: Partial<LogisticsQueryParams> = {};
  showAllOnInvoicing = false;
  sertDocument?: object;
  canConnectToDbl = false;
  onConnectionToDbl?: (_p: { logisticIds: number[]; logistic_type: string }) => Promise<void>;
  newLogisticInitData?: LogisticCreate;
  readonly = false;
  logisticsExportConfig:
    | 'logistics-export-config-table_qualities'
    | 'logistics-export-config-table_info';
  newLogistic?: LogisticCreate;
  reportConfig?: object[];
  logisticsQueryParams: LogisticsQueryParams = { serializer: 'table_info' };
  queryParams: LogisticsQueryParams = { serializer: 'table_info' };
  view: ViewMode = 'table';
  showTotals: boolean;

  certificateTabTitles = tableColumnsMap.table_certs;

  static readonly $inject = [
    '$scope',
    '$rootScope',
    'gettext',
    'LogisticsService',
    'gtFilterService',
    'GtUtils',
    'AccountsService',
  ];
  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly LogisticsService: LogisticsService,
    private readonly gtFilterService: GtFilterService,
    private readonly GtUtils: GtUtilsService,
    private readonly AccountsService: AccountsService,
  ) {
    this.logisticsExportConfig = this.AccountsService.user?.settings.DEFAULT_LOGISTICS_WITH_QUALITY
      ? 'logistics-export-config-table_qualities'
      : 'logistics-export-config-table_info';
    this.showTotals = this.AccountsService.user?.settings.SHOW_TABLES_TOTAL ?? false;
    this.viewModel = container.resolve(LogisticListViewModel);
  }

  $onInit() {
    this.queryParams = {
      serializer: this.AccountsService.user?.settings.DEFAULT_LOGISTICS_WITH_QUALITY
        ? 'table_qualities'
        : 'table_info',
      page_size: 25,
      ...this.initQueryParams,
    };
    if (this.newLogisticInitData) {
      this.newLogistic = { ...this.newLogisticInitData };
    }
    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.logisticUpdated();
      this.$scope.$applyAsync();
    });
    this.subs.table = this.viewModel.table$.subscribe((table) => {
      this.tableName = table;
      this.$scope.$applyAsync();
    });
    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.totals = totals;
      this.logisticUpdated();
      this.$scope.$applyAsync();
    });
    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      if (loading != this.loading || !loading) {
        this.loading = loading;
        this.GtUtils.overlay(loading ? 'show' : 'hide');
        this.$scope.$applyAsync();
      }
    });
    this.subs.invoicePurpose = this.viewModel.invoicePurpose$.subscribe((purpose) => {
      this.invoicePurpose = purpose;
      this.$scope.$applyAsync();
    });
    this.subs.selectableItems = this.viewModel.selectableItems$.subscribe((selectable) => {
      this.selectableItems = selectable;
      this.$scope.$applyAsync();
    });
    this.subs.selectedItems = this.viewModel.selectedItems$.subscribe((selected) => {
      this.selectedLogistics = this.logistics.filter((l) => selected.includes(l.id));
      this.$scope.$applyAsync();
    });
    this.subs.connectionMode = this.viewModel.connectionMode$.subscribe((mode) => {
      this.connectionMode = mode;
      this.$scope.$applyAsync();
    });
    this.subs.contractType = this.viewModel.invoicingContractType$.subscribe((contractType) => {
      this.contractType = contractType;
      this.$scope.$applyAsync();
    });
    this.subs.editing = this.viewModel.editing$.subscribe((editing) => {
      this.editing = editing;
      this.$scope.$applyAsync();
    });

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.GtUtils.overlay('hide');

    /** @deprecated: use callbacl subscription after refactoring core-inline-report-config-view */
    this.$rootScope.$on(
      `gt-report-config-selected_${this.filterLevel}`,
      (_ev, config?: { column_params: object[] }) => {
        this.reportConfig = config ? config.column_params : undefined;
      },
    );

    this.$rootScope.$on('invoicingVolume-changed', (_ev, invoicingVolume: InvoicingVolume) => {
      this.viewModel.invoicingVolumeChanged(invoicingVolume);
    });
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: LogisticsQueryParams) => {
    this.queryParams = params;
    this.viewModel.tableChanged(this.queryParams.serializer);
    this.viewModel.pageParamsUpdated({
      page: params.page as number,
      page_size: params.page_size as number,
      ...params,
    });
  };

  setEditing(editing: boolean) {
    this.viewModel.setEditing(editing);
  }

  changeView(view: ViewMode) {
    this.view = view;
  }

  connectionModeChanged(editMode: ConnectionMode) {
    this.viewModel.connectionModeChanged(editMode);
  }

  toggleLogisticSelection(id: number) {
    this.viewModel.toggleItemSelection(id);
  }

  selectAll(checked: boolean) {
    this.viewModel.selectedItemsChanged(checked ? this.selectableItems : []);
  }

  resetEditMode(updated: boolean) {
    this.viewModel.resetConnectionModeAndInvoicing(updated);
  }

  startInvoicing(purpose: InvoicePurpose) {
    this.viewModel.invoicePurposeChanged(purpose);
  }

  connectToDbl() {
    const selectedLogisticsIds = this.selectedLogistics.map((l) => l.id);
    if (!selectedLogisticsIds.length) {
      notifyError('No one logistic selected');
    }
    if (this.onConnectionToDbl) {
      this.onConnectionToDbl({ logisticIds: selectedLogisticsIds, logistic_type: 'Logistic' })
        .then(() => this.resetEditMode(true))
        .catch(errorHandler);
    }
  }

  logisticUpdated() {
    if (this.queryParams.next) {
      this.logistics = this.logistics.concat(this.pageData.results);
      delete this.queryParams.next;
    } else {
      this.logistics = this.pageData.results;
    }
    this.logisticsCount = this.pageData.count;
    this.logisticsTotal = this.totals.totals;
    this.logisticsIntermediateTotals = this.totals.intermediateTotals;
    this.logisticsMainTotals = this.totals.mainTotals;
    this.updateLogisticExportConfig();
  }

  async applyQualityDiscount() {
    if (!confirm(this.gettext('Do you want to update logistics by discount?'))) {
      return Promise.resolve(false);
    }
    await this.viewModel.applyDiscount();
  }

  async applyCheckLimit() {
    if (!confirm(this.gettext('Do you want to update status of logistics with limits?'))) {
      return Promise.resolve(false);
    }
    await this.viewModel.updateApprovalStatus();
  }

  async setPurchasePrice() {
    if (
      !confirm(this.gettext('Do you want to update purchase price of logistics from indicator?'))
    ) {
      return Promise.resolve(false);
    }
    await this.viewModel.recalculatePurchasePrice();
  }

  async updateLogistic(logistic: LogisticUpdate) {
    await this.viewModel.logisticsUpdated([logistic]);
  }

  updateLogisticExportConfig() {
    this.logisticsQueryParams = { ...this.queryParams };
    delete this.logisticsQueryParams.page_size;
    this.gtFilterService.setQueryParams(this.logisticsQueryParams, this.logisticsExportConfig);
  }

  openLogisticsDocumentModal() {
    return this.LogisticsService.logisticsDocumentsModal(this.queryParams);
  }

  openLogisticModal() {
    return this.LogisticsService.logisticModal(this.newLogistic).then(() => {
      this.viewModel.tableChanged(this.queryParams.serializer);
      this.viewModel.pageParamsUpdated({
        page: this.queryParams.page as number,
        page_size: this.queryParams.page_size as number,
        ...this.queryParams,
      });
    });
  }

  applyFilters(params: LogisticsQueryParams) {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  }
}

export const logisticsContainer: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    showAllOnInvoicing: '<?',
    sertDocument: '<?',
    canConnectToDbl: '<?',
    onConnectionToDbl: '&?',
    newLogisticInitData: '<?',
    readonly: '<?',
  },
  template,
  controller: LogisticsContainerController,
};
