import type ng from 'angular';

import { notify } from '~/shared/lib/notify';
import { differenceInDays } from '~/shared/lib/utils';

import template from './loan-modal.html?raw';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export const LoanModal = {
  bindings: {
    loan: '<',
    modalInstance: '<',
  },
  template,
  controller: [
    '$scope',
    '$rootScope',
    '$state',
    '$q',
    'gettext',
    'LoanService',
    'FormFieldParamsService',
    'GtUtils',
    'ReassignmentService',
    'FinancesService',
    'ClientsService',
    'gtFilterService',
    class {
      $q: ng.IQService;
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      $state: ng.ui.IStateService;
      ClientsService: any;
      FinancesService: FinancesService;
      FormFieldParamsService: FormFieldParamsService;
      GtUtils: GtUtilsService;
      LoanService: any;
      ReassignmentService: any;
      calcCollateralAmount: any;
      calcInterestAmount: any;
      calcInterestRate: any;
      fields: any;
      form: any;
      getNumber: any;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      loan: any;
      modalInstance: any;
      updating: any;
      constructor(
        $scope: ng.IScope,
        $rootScope: GtRootScopeService,
        $state: ng.ui.IStateService,
        $q: ng.IQService,
        gettext: ng.gettext.gettextFunction,
        LoanService: any,
        FormFieldParamsService: FormFieldParamsService,
        GtUtils: GtUtilsService,
        ReassignmentService: any,
        FinancesService: FinancesService,
        ClientsService: any,
        gtFilterService: GtFilterService,
      ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$q = $q;
        this.gettext = gettext;
        this.ReassignmentService = ReassignmentService;
        this.FinancesService = FinancesService;
        this.ClientsService = ClientsService;
        this.LoanService = LoanService;
        this.FormFieldParamsService = FormFieldParamsService;
        this.gtFilterService = gtFilterService;
        this.GtUtils = GtUtils;

        this.updating = undefined;
        this.form = undefined;
        this.fields = undefined;
        this.loan = undefined;
        this.getNumber = () => {
          if (!this.loan.number && this.loan.auto_name && this.loan.lender) {
            this.LoanService.getNumber({ lender: this.loan.lender }).then(
              (data: any) => (this.loan.number = data.number),
            );
          }
        };
        this.calcInterestRate = (field: any, newValue: any, oldValue: any, scope: any) => {
          if (newValue) {
            scope.model.interest_rate =
              Math.round((scope.model.margin + scope.model.libor) * 100) / 100;
          }
        };

        this.calcInterestAmount = (field: any, newValue: any, oldValue: any, scope: any) => {
          let diffDays;
          const maturityDate = scope.model.maturity_date || scope.model.plan_maturity_date;
          const startDate = scope.model.start_date || scope.model.plan_start_date;
          if (maturityDate && startDate) {
            diffDays = differenceInDays(maturityDate, startDate);
          }
          if (newValue && diffDays) {
            const interestAmount =
              ((scope.model.amount * scope.model.interest_rate) / scope.model.base_days) * diffDays;
            scope.model.interest_amount = Math.round(interestAmount * 100) / 100;
          }
        };

        this.calcCollateralAmount = (field: any, newValue: any, oldValue: any, scope: any) => {
          if (newValue) {
            const collateralAmount = (scope.model.amount * scope.model.collateral) / 100;
            scope.model.collateral_amount = Math.round(collateralAmount * 100) / 100;
          }
        };
      }

      $onInit() {
        this.updating = true;
        this.form = undefined;
        this.fields = undefined;
        this.loan = this.loan || {};

        let promise;
        if (this.loan.id) {
          promise = this.updateData();
        } else {
          promise = this.$q.when();
        }

        promise.then(() => {
          this.updateFields();
          this.updating = false;
          this.$scope.$watch(() => this.loan.auto_name, this.getNumber);
          this.$scope.$watch(() => this.loan.lender, this.getNumber);
        });
      }

      openFieldsConfigModal() {
        this.FormFieldParamsService.fieldsConfigModal(this.getFormConfig()).then(() =>
          this.updateFields(),
        );
      }

      updateFields() {
        this.FormFieldParamsService.getFields(this.getFormConfig())
          .then((fields: any) => (this.fields = fields))
          .catch(this.GtUtils.errorClb);
      }

      updateData() {
        return this.LoanService.getLoanModalData({ id: this.loan.id }).then((data: any) => {
          this.loan = data;
          this.updating = false;
        });
      }

      close(data: any, silent: any) {
        if (!silent && !confirm(this.gettext('Close modal?'))) {
          return;
        }
        return this.modalInstance.close(data || 'cancel');
      }

      save(stayAfterSave: any) {
        this.form.$invalid = true;
        this.LoanService.saveLoan(this.loan)
          .then((data: any) => {
            if (stayAfterSave) {
              this.loan = data;
              this.updateData();
            } else {
              this.close('saved', true);
            }
            notify(this.gettext('Loan saved'));
          })
          .catch(this.GtUtils.errorClb);
      }

      destroy() {
        if (!confirm(this.gettext('Are you sure you want to delete loan?'))) {
          return;
        }
        return this.LoanService.destroy({ id: this.loan.id })
          .then(() => {
            this.close('deleted', true);
            this.$state.go('finances.loansPage');
          })
          .catch(this.GtUtils.errorClb);
      }

      getFormConfig() {
        const col1: any = {
          className: 'form-group-container col-md-4 col-xs-12',
          fieldGroup: [] as any[],
        };
        const col2: any = {
          className: 'form-group-container col-md-4 col-xs-12',
          fieldGroup: [] as any[],
        };

        const col3: any = {
          className: 'form-group-container col-md-4 col-xs-12',
          fieldGroup: [] as any[],
        };

        col1.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('GENERAL INFO'),
          },
          fieldGroup: [
            {
              key: 'number',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Loan number'),
                placeholder: this.gettext('Type loan number'),
                addon: this.gettext('#'),
                required: true,
              },
            },
            {
              key: 'auto_name',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Generate loan name'),
                hint: this.gettext('Check this if you want loan name to be auto generated'),
              },
              hideExpression: (viewValue: any, modelValue: any, scope: any) => scope.model.id,
            },
            {
              key: 'lender',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Lender'),
                placeholder: this.gettext('Lender'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick a lender that will be bound to this loan'),
                required: true,
              },
            },
            {
              key: 'borrower',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Borrower'),
                placeholder: this.gettext('Borrower'),
                addPerms: ['add_client'],
                addIcon: this.GtUtils.getIcon('clients.Client'),
                addFunc: () => {
                  this.ClientsService.clientModal();
                },
                resource: 'clients.ClientRole',
                hint: this.gettext('Pick a borrower that will be bound to this loan'),
                required: true,
              },
            },
            {
              key: 'bank_account',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext("Owner's bank account"),
                placeholder: this.gettext("Owner's bank account"),
                resource: 'finances.BankAccount',
                hint: this.gettext('Pick a bank account'),
              },
            },
            {
              key: 'general_agreement',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Loan agreement'),
                placeholder: this.gettext('Loan agreement'),
                resource: 'contracts.GeneralAgreement',
                hint: this.gettext('Pick a loan agreement'),
              },
            },
            {
              key: 'business_units',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Business units'),
                placeholder: this.gettext('Business Units'),
                resource: 'core.BusinessUnit',
              },
            },
            {
              key: 'amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                placeholder: this.gettext('Amount'),
                type: 'number',
                required: true,
              },
              watcher: {
                listener: (field: any, newValue: any, oldValue: any, scope: any) => {
                  this.calcCollateralAmount(field, newValue, oldValue, scope);
                  this.calcInterestAmount(field, newValue, oldValue, scope);
                },
              },
            },
            {
              key: 'currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency'),
                placeholder: this.gettext('Currency'),
                resource: 'finances.Currency',
                hint: this.gettext('Pick a currency'),
                required: true,
              },
            },
            {
              key: 'currency_exchange',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency exchange'),
                resource: 'finances.CurrencyExchange',
              },
            },
            {
              key: 'status',
              type: 'gt-select',
              defaultValue: 'forecast',
              templateOptions: {
                label: this.gettext('Status'),
                placeholder: this.gettext('Choose status'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('Forecast'), value: 'forecast' },
                  { name: this.gettext('Processing'), value: 'processing' },
                  { name: this.gettext('Done'), value: 'done' },
                  { name: this.gettext('Cancelled'), value: 'cancelled' },
                ],
              },
            },
          ],
        });

        col2.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('CONDITIONS'),
          },
          fieldGroup: [
            {
              key: 'payment_condition',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Payment condition'),
                resource: 'finances.PaymentCondition',
                queryParams: () => {
                  return { is_active: 1 };
                },
              },
            },
            {
              key: 'interest_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Interest rate'),
                placeholder: this.gettext('Interest rate'),
                type: 'number',
                disabled: true,
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'margin',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Margin'),
                placeholder: this.gettext('Margin'),
                type: 'number',
                required: true,
              },
              watcher: {
                listener: this.calcInterestRate,
              },
            },
            {
              key: 'libor',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Libor'),
                placeholder: this.gettext('Libor'),
                type: 'number',
              },
              watcher: {
                listener: this.calcInterestRate,
              },
            },
            {
              key: 'base_days',
              type: 'gt-select',
              defaultValue: 360,
              templateOptions: {
                label: this.gettext('Base, days'),
                placeholder: this.gettext('Choose status'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: 366, value: 366 },
                  { name: 365, value: 365 },
                  { name: 360, value: 360 },
                ],
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'interest_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Interest amount'),
                placeholder: this.gettext('Interest amount'),
                type: 'number',
                disabled: true,
              },
            },
            {
              key: 'related_fees',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Related fees'),
                placeholder: this.gettext('Related fees'),
                type: 'number',
              },
            },
            {
              key: 'notes',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Notes'),
                placeholder: this.gettext('Notes'),
                type: 'textarea',
              },
            },
            {
              key: 'collateral',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Collateral'),
                placeholder: this.gettext('Collateral'),
                type: 'number',
                required: true,
              },
              watcher: {
                listener: this.calcCollateralAmount,
              },
            },
            {
              key: 'collateral_amount',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Collateral amount'),
                placeholder: this.gettext('Collateral amount'),
                type: 'number',
                disabled: true,
              },
            },
            {
              key: 'finance_purpose',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Fin purpose'),
                resource: 'finances.FinancePurpose',
              },
            },
            {
              key: 'passport',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Related deal'),
                resource: 'passports.Passport',
                queryParams: {
                  status: '',
                },
              },
            },
            {
              key: 'destination',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Destination'),
                resource: 'location.Country',
              },
            },
          ],
        });

        col3.fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('DATES'),
          },
          fieldGroup: [
            {
              key: 'plan_start_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Start date (plan)'),
                placeholder: this.gettext('date'),
                type: 'date',
                required: true,
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'start_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Start date'),
                placeholder: this.gettext('date'),
                type: 'date',
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'plan_maturity_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Maturity date (plan)'),
                placeholder: this.gettext('date'),
                type: 'date',
                required: true,
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'maturity_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Maturity date'),
                placeholder: this.gettext('date'),
                type: 'date',
              },
              watcher: {
                listener: this.calcInterestAmount,
              },
            },
            {
              key: 'plan_interest_payment_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Interest payment date (plan)'),
                placeholder: this.gettext('date'),
                type: 'date',
                required: true,
              },
            },
            {
              key: 'interest_payment_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Interest payment date'),
                placeholder: this.gettext('date'),
                type: 'date',
              },
            },
          ],
        });

        return {
          formName: 'loan-edit-modal',
          fieldsDef: [col1, col2, col3],
        };
      }
    },
  ],
};
