import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { EditableOption } from '~/shared/ui/components/data-grid';
import { Checkbox } from '~/shared/ui/kit/checkbox';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';
import { Tag, type TagComponent } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const MultiSelectWriteContent: React.FC<
  DataTypeProps<string[]> & {
    isOpen?: boolean;
    options: EditableOption[];
    contentClassName?: string;
    context?: Omit<TagComponent, 'children'>;
  }
> = ({ value, context, options, valueChanged, contentClassName }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  // const filteredOptions = options.filter((item) =>
  //   String(item.value).toLowerCase().includes(searchTerm.toLowerCase()),
  // );

  return (
    <DropdownMenuContent className={cn('w-[160px] p-0', contentClassName)}>
      <Input
        type="text"
        intent="transparent"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onBlur={(e) => e.target.focus()}
        placeholder="Search"
      />
      <DropdownMenuSeparator className="bg-transparent-lighter my-0 w-full" />
      <div className="scrollbar flex max-h-[200px] flex-col gap-2 overflow-y-auto p-2">
        {options.length ? (
          options.map((item) => (
            <DropdownMenuItem
              key={item.value}
              className="flex items-center gap-2"
              onSelect={(event) => {
                event.preventDefault();
                valueChanged?.(
                  value?.some((v) => v === item.value)
                    ? value?.filter((v) => v !== item.value)
                    : [...(value ?? []), item.value],
                );
              }}
            >
              <Checkbox size="small" checked={value?.some((v) => v === item.value)} />
              <Tag {...item.props} {...context}>
                {item.value}
              </Tag>
            </DropdownMenuItem>
          ))
        ) : (
          <span className="text-xs">No Data</span>
        )}
      </div>
    </DropdownMenuContent>
  );
};
