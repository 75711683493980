import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  forkJoin,
  from,
  map,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from '~/shared/lib/state';

import { LogisticModel } from './logistic.model';
import { LogisticRepository } from './logistic.repository';
import {
  ConnectionMode,
  InvoicePurpose,
  InvoicingVolume,
  Logistic,
  LogisticCreate,
  LogisticList,
  LogisticListParams,
  LogisticListTotals,
  LogisticTable,
  LogisticUpdate,
  invoicingConfig,
  tableColumnsMap,
} from '../lib';

@injectable()
export class LogisticListViewModel extends EntityListEditViewModel<
  Logistic,
  Omit<LogisticListParams, 'page' | 'page_size'> & { page: number; page_size: number },
  LogisticCreate,
  LogisticList
> {
  resolveEntityRepo() {
    return container.resolve(LogisticRepository);
  }
  private readonly entityName = 'logistics-container';
  private readonly purposeModel = 'logistic';

  private readonly tableSubj = new BehaviorSubject<LogisticTable>('table_info');
  private readonly selectedItemsSubj = new BehaviorSubject<number[]>([]);

  private readonly connectionModeSubj = new BehaviorSubject<ConnectionMode | undefined>(undefined);
  private readonly invoicePurposeSubj = new BehaviorSubject<InvoicePurpose | undefined>(undefined);
  private readonly invoicingVolumeSubj = new BehaviorSubject<InvoicingVolume | undefined>(
    undefined,
  );

  constructor(
    private readonly logisticRepo: LogisticRepository,
    private readonly logisticModel: LogisticModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) =>
      from(propertiesPromise).pipe(
        withLatestFrom(this.tableSubj),
        map(([properties, table]) =>
          properties.filter((prop) => tableColumnsMap[table].includes(prop.column_name)),
        ),
      ),
    ),
    startWith([]),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  readonly table$ = this.tableSubj.asObservable();
  readonly selectedItems$ = this.selectedItemsSubj.asObservable();
  readonly invoicePurpose$ = this.invoicePurposeSubj.asObservable();
  readonly connectionMode$ = this.connectionModeSubj.asObservable();
  readonly invoicingContractType$ = this.invoicePurposeSubj.pipe(
    map((purpose) => (purpose ? invoicingConfig[purpose].contractType : undefined)),
  );

  readonly totals$ = this.pageParams$.pipe(
    tap(() => this.loadingChanged(true)),
    switchMap((params) =>
      forkJoin([
        this.logisticRepo.getListTotals(params),
        this.logisticRepo.getListTotals({ ...params, logistic_type: 'main' }),
        this.logisticRepo.getListTotals({ ...params, logistic_type: 'intermediate' }),
      ]).pipe(
        map(([totals, mainTotals, intermediateTotals]) => ({
          totals,
          mainTotals,
          intermediateTotals,
        })),
      ),
    ),
    tap(() => this.loadingChanged(false)),
    startWith({
      totals: {} as LogisticListTotals,
      mainTotals: {} as LogisticListTotals,
      intermediateTotals: {} as LogisticListTotals,
    }),
  );

  readonly selectedItemsTotals$ = this.selectedItems$.pipe(
    tap(() => this.loadingChanged(true)),
    switchMap((selectedItems) =>
      this.logisticModel.getLogisticsTotals({ id_list: selectedItems.map(String) }),
    ),
    tap(() => this.loadingChanged(false)),
  );

  readonly selectableItems$ = combineLatest([
    this.invoicePurpose$,
    this.invoicingVolumeSubj,
    this.connectionModeSubj,
    this.pageData$,
  ]).pipe(
    map(([invoicePurpose, invoicingVolume, connectionMode, { results }]) =>
      results
        .filter((r) =>
          this.logisticModel.isLogisticSelectable(
            r,
            invoicePurpose,
            invoicingVolume,
            connectionMode,
          ),
        )
        .map((r) => r.id),
    ),
  );

  public tableChanged(table: LogisticTable) {
    this.tableSubj.next(table);
    this.logisticRepo.setDatasources(table);
    this.logisticModel.changeTable(table);
  }

  public connectionModeChanged(mode?: ConnectionMode) {
    this.connectionModeSubj.next(mode);
  }

  public invoicePurposeChanged(purpose?: InvoicePurpose) {
    if (purpose && !invoicingConfig[purpose].allowedTables.includes(this.tableSubj.getValue())) {
      this.tableChanged(invoicingConfig[purpose].defaultTable);
      this.pageParamsChanged({});
    }
    this.invoicePurposeSubj.next(purpose);
  }

  public invoicingVolumeChanged(invoicingVolume?: InvoicingVolume) {
    this.invoicingVolumeSubj.next(invoicingVolume);
  }

  public selectedItemsChanged(selected: number[]) {
    this.selectedItemsSubj.next(selected);
  }

  public toggleItemSelection(id: number) {
    const currentSelection = this.selectedItemsSubj.getValue();

    if (currentSelection.includes(id)) {
      this.selectedItemsSubj.next(currentSelection.filter((selectedId) => selectedId !== id));
    } else {
      this.selectedItemsSubj.next([...currentSelection, id]);
    }
  }

  public resetConnectionModeAndInvoicing(updated: boolean) {
    this.invoicePurposeChanged();
    this.invoicingVolumeChanged();
    this.connectionModeChanged();
    if (updated) {
      this.pageParamsChanged({});
    }
  }

  public logisticsCreated = async (dtos: LogisticCreate[]) => {
    try {
      await this.logisticModel.createLogistics(dtos);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public logisticDeleted = async (id: number) => {
    try {
      await this.logisticRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public logisticsUpdated = async (dtos: LogisticUpdate[]) => {
    try {
      await this.logisticModel.updateLogistics(dtos);
      notifySuccess('Logistic updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public recalculatePurchasePrice = async () => {
    this.loadingChanged(true);
    try {
      await this.logisticRepo.setPurchasePriceFromIndicator();
      notifySuccess('Purchase price updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  public applyDiscount = async () => {
    this.loadingChanged(true);
    try {
      await this.logisticRepo.setDiscountByQuality();
      notifySuccess('Discount updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  public updateApprovalStatus = async () => {
    this.loadingChanged(true);
    try {
      await this.logisticRepo.setApprovalStatusProcess();
      notifySuccess('Approval status updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.loadingChanged(false);
      this.pageParamsChanged({});
    }
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }
}
