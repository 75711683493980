import React from 'react';

import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import type {
  PurchaseContractCreate,
  PurchaseContractList,
  PurchaseContractUpdate,
} from '~/features/deals/purchase-contracts';
import { getPurchaseContractColumns } from '~/features/deals/purchase-contracts';
import { PurchaseContractsContainerLayout } from '~/features/deals/purchase-contracts/components/purchase-contracts-container/purchase-contracts-container-layout';
import type { PurchaseContractsListViewModel } from '~/features/deals/purchase-contracts/services/purchase-contracts-list.view';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

export const PurchaseContractsContainer: React.FC<{
  viewMode: 'spreadsheet' | 'grid';
  viewModel: PurchaseContractsListViewModel;
}> = ({ viewMode, viewModel }) => {
  const createdDataRef = React.useRef<PurchaseContractCreate[]>([]);
  const editedDataRef = React.useRef<PurchaseContractUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getPurchaseContractColumns(pageData.results, pageParams.serializer),
      }),
    [columns, pageParams.serializer, pageData.results],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.purchaseContractsCreated({ entities: createdDataRef.current });
      await viewModel.purchaseContractsUpdated({ entities: editedDataRef.current });
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <PurchaseContractsContainerLayout>
      <DatasetView<PurchaseContractList, PurchaseContractUpdate, PurchaseContractCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: PurchaseContractUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: PurchaseContractCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.purchaseContractDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </PurchaseContractsContainerLayout>
  );
};
