import { CaretSortIcon } from '@radix-ui/react-icons';
import React from 'react';

import type { ListOption, MessageIntent } from '~/shared/lib/types';
import { cn } from '~/shared/lib/utils';
import { messageIntent } from '~/shared/ui/data-types/lib/constants';
import { CheckIcon, ResetIcon } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '~/shared/ui/kit/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/shared/ui/kit/popover';

export const Autocomplete: React.FC<{
  value?: ListOption;
  options: ListOption[];
  onSearch: (input: string) => void;
  valueChanged?: (value: ListOption) => void;
  disabled?: boolean;
  loading?: boolean;
  placeholder?: string;
  emptyMessage?: string;
  message?: {
    type: MessageIntent;
    text?: string;
  };
  onOpenChange?: (isOpen: boolean) => void;
}> = ({
  value,
  options,
  onSearch,
  valueChanged,
  disabled = false,
  loading = false,
  placeholder = 'Search...',
  emptyMessage = 'Not found',
  message,
  onOpenChange,
}) => {
  const [open, setOpen] = React.useState(false);

  const onSelectHandler = (currentValue: string) => {
    if (disabled) {
      return;
    }
    const option = options.find((o) => o.label === currentValue);
    if (option) {
      valueChanged?.(option);
      setOpen(false);
    }
  };

  const onOpenChangeHandler = React.useCallback(
    (isOpen: boolean) => {
      setOpen(isOpen);
      onOpenChange?.(isOpen);
    },
    [onOpenChange],
  );

  return (
    <div className={cn(message && messageIntent[message.type])}>
      {message && <p className="text-xs font-medium">{message.text}</p>}
      <Popover open={open} onOpenChange={onOpenChangeHandler}>
        <PopoverTrigger asChild>
          <Button variant="default" aria-expanded={open} disabled={disabled}>
            <div className="flex w-[200px] items-center justify-between">
              <p className="text-text-main-primary text-xs">{value?.label}</p>
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput onValueChange={onSearch} placeholder={placeholder} className="h-9" />
            <CommandList>
              {loading ? (
                <div className="flex items-center justify-center py-2">
                  <ResetIcon className="text-text-main-primary h-5 w-5 animate-spin" />
                </div>
              ) : (
                <>
                  <CommandEmpty>{emptyMessage}</CommandEmpty>
                  <CommandGroup>
                    {options.map((opt) => (
                      <CommandItem
                        key={opt.value}
                        value={opt.label}
                        onSelect={onSelectHandler}
                        aria-selected={value?.value === opt.value}
                        className="hover:bg-transparent-light flex justify-between rounded-md px-2 py-2 pl-2 text-xs"
                      >
                        {opt.label}
                        {value?.value === opt.value && <CheckIcon />}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
