import React from 'react';

import { DateWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const DateCellWrite = ({ value, valueChanged, disabled, typeExtra }: CellTypeProps) => {
  if (value !== null && !(typeof value == 'string' || value instanceof Date)) {
    throw new Error(`Invalid date type: expected string or null, but got ${typeof value}`);
  }
  return (
    <DateWrite
      value={value ? new Date(value) : null}
      valueChanged={valueChanged}
      disabled={disabled}
      dateFormat={typeExtra}
    />
  );
};
