import { coreColumnParamsList, coreCustomFieldsList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import type {
  EntityColumn,
  EntityFieldsDTO,
  UserEntityField,
  UserEntityParams,
} from '../lib/types';

@singleton()
export class EntityFieldsRepository {
  async getEntityProperties(dto: EntityFieldsDTO): Promise<EntityColumn[]> {
    const { data } = await coreColumnParamsList({
      query: { table_name: dto.entityName, has_columnssetconfig: '0' },
    });
    return data;
  }

  async getEntityUserProperties(params: UserEntityParams): Promise<UserEntityField[]> {
    const { data } = await coreCustomFieldsList({ query: params });
    return data.results;
  }
}
