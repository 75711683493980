import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { IconComponent } from '~/shared/ui/icons';

import type { DataTypeProps } from '../../lib';

export const EntityRead: React.FC<
  DataTypeProps<{ id?: number; Icon?: IconComponent; title: string }>
> = ({ disabled, value }) => {
  return (
    <div
      className={cn(
        'hover:bg-transparent-light flex gap-2 transition duration-300 ease-in-out',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      <p className="text-text-main-primary text-xs">{value?.title}</p>
    </div>
  );
};
