import React from 'react';

import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

import { CounterpartiesContainerLayout } from './counterparties-container-layout';
import {
  type CounterpartyCreate,
  type CounterpartyList,
  type CounterpartyUpdate,
  counterpartiesBuilder,
  getCounterpartyColumns,
} from '../../lib';
import type { CounterpartiesListViewModel } from '../../services';

export const CounterpartiesContainer: React.FC<{
  viewModel: CounterpartiesListViewModel;
  viewMode: 'spreadsheet' | 'grid';
  onOpenCounterpartyModal: (counterparty: CounterpartyList) => void;
  onCloneCounterparty: (counterparty: CounterpartyList) => void;
  onOpenEmailModal: (counterparty: CounterpartyList) => void;
  onApproveCounterparty: (counterparty: CounterpartyList, approve: boolean) => void;
  onOpenDocxModal: (counterparty: CounterpartyList) => void;
}> = ({
  viewModel,
  viewMode,
  onOpenCounterpartyModal,
  onCloneCounterparty,
  onOpenEmailModal,
  onApproveCounterparty,
  onOpenDocxModal,
}) => {
  const createdDataRef = React.useRef<CounterpartyCreate[]>([]);
  const editedDataRef = React.useRef<CounterpartyUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);

  const counterparties = React.useMemo(
    () => counterpartiesBuilder(pageData.results),
    [pageData.results],
  );

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getCounterpartyColumns({
          onOpenCounterpartyModal,
          onCloneCounterparty,
          onOpenEmailModal,
          onApproveCounterparty,
          onOpenDocxModal,
          counterparties: pageData.results,
        }),
      }),
    [
      columns,
      pageData.results,
      onOpenCounterpartyModal,
      onCloneCounterparty,
      onOpenEmailModal,
      onApproveCounterparty,
      onOpenDocxModal,
    ],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.counterpartiesCreated({ entities: createdDataRef.current });
      await viewModel.counterpartiesUpdated({ entities: editedDataRef.current });
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <CounterpartiesContainerLayout>
      <DatasetView<CounterpartyList, CounterpartyUpdate, CounterpartyCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: counterparties }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: CounterpartyUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: CounterpartyCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.counterpartyDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </CounterpartiesContainerLayout>
  );
};
