import {
  BillOfLadingCreate,
  BillOfLadingList,
  BillOfLadingListParams,
  BillOfLadingUpdate,
} from '~/features/execution/bills-of-lading';
import {
  BillOfLading,
  logisticsBillsOfLadingCreate,
  logisticsBillsOfLadingDestroy,
  logisticsBillsOfLadingListInfoList,
  logisticsBillsOfLadingListTotalsRetrieve,
  logisticsBillsOfLadingPartialUpdate,
  logisticsBillsOfLadingRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class BillsOfLadingRepository extends EntityRepository<
  BillOfLading,
  BillOfLadingListParams,
  BillOfLadingCreate,
  BillOfLadingList
> {
  get = async (id: number) => {
    const { data } = await logisticsBillsOfLadingRetrieve({ path: { id } });
    return data;
  };

  query = async (query: BillOfLadingListParams) => {
    const { data } = await logisticsBillsOfLadingListInfoList({ query });
    return data;
  };

  search = async (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: BillOfLadingCreate) => {
    const res = await logisticsBillsOfLadingCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: BillOfLadingUpdate) => {
    const { data } = await logisticsBillsOfLadingPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await logisticsBillsOfLadingDestroy({ path: { id } });
  };

  getTotals = async (query: BillOfLadingListParams) => {
    const { data } = await logisticsBillsOfLadingListTotalsRetrieve({ query });
    return data;
  };
}
