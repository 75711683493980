import { logisticsPlacesOfDestinationList } from '~/shared/api';

import type { PlaceOfDestinationListParams } from '../types';

export class PlaceOfDestinationRepository {
  query = async (query: PlaceOfDestinationListParams) => {
    const { data } = await logisticsPlacesOfDestinationList({ query });
    return data;
  };
}
