import {
  LogisticRequest,
  logisticsLogisticsCreate,
  logisticsLogisticsDestroy,
  logisticsLogisticsListBuyerList,
  logisticsLogisticsListCertsList,
  logisticsLogisticsListCostsList,
  logisticsLogisticsListExportList,
  logisticsLogisticsListInfoList,
  logisticsLogisticsListPnlList,
  logisticsLogisticsListQualitiesList,
  logisticsLogisticsListSupplierList,
  logisticsLogisticsPartialUpdate,
  logisticsLogisticsRetrieve,
  logisticsLogisticsSetApprovalStatusProcessPartialUpdate,
  logisticsLogisticsSetDiscountByQualityPartialUpdate,
  logisticsLogisticsSetPurchasePriceFromIndicatorPartialUpdate,
  logisticsLogisticsTotalsInfoRetrieve,
  logisticsLogisticsTotalsPnlRetrieve,
  logisticsLogisticsTotalsPurchaseRetrieve,
  logisticsLogisticsTotalsSaleRetrieve,
} from '~/shared/api';
import { EntityRepository, Paginated } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

import {
  Logistic,
  LogisticCreate,
  LogisticList,
  LogisticListParams,
  LogisticTable,
  LogisticUpdate,
} from '../lib';

export const listDatasourceMap = {
  table_info: logisticsLogisticsListInfoList,
  table_costs: logisticsLogisticsListCostsList,
  table_buyer: logisticsLogisticsListBuyerList,
  table_supplier: logisticsLogisticsListSupplierList,
  table_certs: logisticsLogisticsListCertsList,
  table_qualities: logisticsLogisticsListQualitiesList,
  table_export: logisticsLogisticsListExportList,
  table_pnl: logisticsLogisticsListPnlList,
  default: logisticsLogisticsListInfoList,
};

export const listTotalsDatasourceMap = {
  table_info: logisticsLogisticsTotalsInfoRetrieve,
  table_buyer: logisticsLogisticsTotalsSaleRetrieve,
  table_supplier: logisticsLogisticsTotalsPurchaseRetrieve,
  table_pnl: logisticsLogisticsTotalsPnlRetrieve,
  default: logisticsLogisticsTotalsInfoRetrieve,
};

@singleton()
export class LogisticRepository extends EntityRepository<
  Logistic,
  LogisticListParams,
  LogisticCreate,
  LogisticList
> {
  listDatasource: (typeof listDatasourceMap)[keyof typeof listDatasourceMap] =
    listDatasourceMap.default;
  listTotalsDatasource: (typeof listTotalsDatasourceMap)[keyof typeof listTotalsDatasourceMap] =
    listTotalsDatasourceMap.default;

  setDatasources(dsKey: LogisticTable) {
    this.listDatasource = listDatasourceMap[dsKey] || listDatasourceMap.default;
    this.listTotalsDatasource =
      dsKey in listTotalsDatasourceMap
        ? listTotalsDatasourceMap[dsKey as keyof typeof listTotalsDatasourceMap]
        : listTotalsDatasourceMap.default;
  }

  search = async (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  get = async (id: number): Promise<Logistic> => {
    const { data } = await logisticsLogisticsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: LogisticListParams): Promise<Paginated<LogisticList>> => {
    const { data } = await this.listDatasource({ query });
    return { count: data.count, results: data.results };
  };

  create = async (dto: LogisticCreate): Promise<Logistic> => {
    const res = await logisticsLogisticsCreate({
      body: dto as LogisticRequest,
    });
    return res.data;
  };

  update = async (entity: LogisticUpdate): Promise<Logistic> => {
    const { data } = await logisticsLogisticsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await logisticsLogisticsDestroy({ path: { id } });
  };

  getListTotals = async (query: LogisticListParams) => {
    const { data } = await this.listTotalsDatasource({ query });
    return data;
  };

  setPurchasePriceFromIndicator = async (idList?: number[]) => {
    try {
      await logisticsLogisticsSetPurchasePriceFromIndicatorPartialUpdate({
        body: { id_list: idList },
      });
    } catch (err) {
      throw new Error('Failed to set puchase price from indicator', { cause: err });
    }
  };

  setDiscountByQuality = async (idList?: number[]) => {
    try {
      await logisticsLogisticsSetDiscountByQualityPartialUpdate({
        body: { id_list: idList },
      });
    } catch (err) {
      throw new Error('Failed to set discount by quality', { cause: err });
    }
  };

  setApprovalStatusProcess = async (idList?: number[]) => {
    try {
      await logisticsLogisticsSetApprovalStatusProcessPartialUpdate({
        body: { id_list: idList },
      });
    } catch (err) {
      throw new Error('Failed to set approval status process', { cause: err });
    }
  };
}
