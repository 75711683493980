import React from 'react';

import type { CurrentUser } from '~/core/auth/lib';
import type { ColumnConfig } from '~/core/page-views';
import { Tag } from '~/shared/ui/kit/tag';

import type { FinanceList } from './types';

export const getFinanceColumns = (finances: FinanceList[], user: CurrentUser): ColumnConfig[] => [
  { key: 'number', dataType: 'text', editing: true },
  {
    key: 'invoice_type',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'incoming', props: { variant: 'green' } },
        { value: 'outgoing', props: { variant: 'yellow' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['incoming', 'outgoing'],
    },
  },
  {
    key: 'counterparty_from',
    sourceKey: 'clientrole_from_name',
    dataType: 'text',
    editing: true,
  },
  { key: 'counterparty_to', sourceKey: 'clientrole_to_name', dataType: 'text', editing: true },
  {
    key: 'uses',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'cargo', props: { variant: 'blue' } },
        { value: 'costs', props: { variant: 'red' } },
        { value: 'gains', props: { variant: 'yellow' } },
        { value: 'expenses', props: { variant: 'orange' } },
        { value: 'washout', props: { variant: 'turquoise' } },
        { value: 'credit', props: { variant: 'cyan' } },
        { value: 'other', props: { variant: 'gray' } },
      ],
    },
  },
  {
    key: 'status',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'paid', props: { variant: 'green' } },
        { value: 'process', props: { variant: 'yellow' } },
        { value: 'canceled', props: { variant: 'red' } },
        { value: 'new', props: { variant: 'blue' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['paid', 'process', 'canceled', 'new'],
    },
  },
  { key: 'condition', dataType: 'text', editing: true },
  { key: 'date_of_issuance', dataType: 'text', editing: true },
  {
    key: 'contract_counterparty',
    dataType: 'template',
    render: ({ id }) => {
      const finance = finances.find((f) => f.id === id);

      if (!finance?.contract_clients_data?.length) {
        return <span />;
      }

      return (
        <>
          {finance.contract_clients_data.map((item) => (
            <span key={item.client_id}>
              {item.client_name}
              <div className="clear-both block"></div>
            </span>
          ))}
        </>
      );
    },
    editing: false,
  },
  { key: 'company_group_owner_title', dataType: 'text', editing: false },
  { key: 'company_group_counterparty_title', dataType: 'text', editing: false },
  {
    key: 'assignment',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'commercial', props: { variant: 'green' } },
        { value: 'proforma', props: { variant: 'orange' } },
        { value: 'debit', props: { variant: 'yellow' } },
        { value: 'credit', props: { variant: 'blue' } },
        { value: 'reserve', props: { variant: 'red' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['commercial', 'proforma', 'debit', 'credit', 'reserve'],
    },
  },
  { key: 'related_invoice_number', dataType: 'text', editing: true },
  { key: 'date_of_withdrawal', dataType: 'date', editing: true, typeExtra: 'yy/MM/dd' },
  {
    key: 'received',
    dataType: 'date',
    editing: true,
    sourceKey: 'date_of_receiving',
    typeExtra: 'yy/MM/dd',
  },
  {
    key: 'paid_plan',
    dataType: 'date',
    editing: true,
    sourceKey: 'date_of_execution',
    typeExtra: 'yy/MM/dd',
  },
  {
    key: 'payment_delay',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const finance = finances.find((f) => f.id === id);
      if (!finance) {
        return <div />;
      }

      return (
        <div>
          {finance.paid_diff_days != undefined && (
            <Tag variant={finance.paid_diff_days < 0 ? 'red' : 'green'}>
              {finance.paid_diff_days}
            </Tag>
          )}
          {finance.paid_diff_coef != undefined && <span>AVG delay {finance.paid_diff_coef}</span>}
        </div>
      );
    },
  },
  {
    key: 'paid_max_payments_date',
    dataType: 'text',
    editing: false,
    sourceKey: 'max_payments_date',
  },
  { key: 'amount', dataType: 'number', editing: true },
  { key: 'amount_usd', dataType: 'number', editing: true },
  { key: 'currency_rate', dataType: 'number', editing: false },
  { key: 'payments_currency_exchange_rate', dataType: 'number', editing: false },
  { key: 'volume', dataType: 'number', editing: true },
  { key: 'full_invoiced_volume', dataType: 'number', editing: false },
  { key: 'interest_rate', dataType: 'number', editing: true },
  {
    key: 'interest_rate_delay',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const finance = finances.find((f) => f.id == id);
      if (!finance || !(finance.amount && finance.interest_rate && finance.paid_diff_coef)) {
        return <span />;
      }
      return (
        <span>
          {((finance.amount * (finance.interest_rate / 100)) / 360) * finance.paid_diff_coef}
        </span>
      );
    },
  },
  {
    key: 'discount',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const finance = finances.find((f) => f.id === id);
      if (!finance) {
        return <div />;
      }

      return (
        <div>
          {finance.discount_mt !== undefined && finance.discount_mt > 0 && (
            <span>
              {finance.discount_mt} {finance.currency_symbol} /{' '}
              {user.settings.MAIN_MEASUREMENT_UNIT}
            </span>
          )}
          {finance.discount_amount !== undefined && finance.discount_amount > 0 && (
            <span>
              {finance.discount_amount} {finance.currency_symbol}
            </span>
          )}
          {finance.discount !== 100 && <span>{finance.discount} %</span>}
        </div>
      );
    },
  },
  { key: 'payment_conditions', dataType: 'number', editing: true },
  { key: 'paid_sum_payments_amount', dataType: 'text', editing: true },
  { key: 'paid_sum_payments_amount_USD', dataType: 'number', editing: false },
  { key: 'offsets_sum_amount', dataType: 'number', editing: true },
  { key: 'offsets_sum_default_currency_amount', dataType: 'number', editing: false },
  { key: 'payments_sum_amount', dataType: 'text', editing: true },
  {
    key: 'payments_sum_default_currency_amount',
    sourceKey: 'payment_default_currency_amount',
    dataType: 'number',
    editing: true,
  },
  { key: 'to_pay', dataType: 'number', editing: true },
  { key: 'logistics_total_value', dataType: 'number', editing: false },
  { key: 'invoiced_logistics', dataType: 'number', editing: false },
  { key: 'counterparty', dataType: 'text', editing: false },
  { key: 'side_from', dataType: 'text', editing: false, sourceKey: 'clientrole_from_name' },
  {
    key: 'side_from_status',
    dataType: 'text',
    editing: false,
    sourceKey: 'client_from_approval_status',
  },
  { key: 'side_to', dataType: 'text', editing: false, sourceKey: 'clientrole_to_name' },
  {
    key: 'side_to_status',
    dataType: 'text',
    editing: false,
    sourceKey: 'client_to_approval_status',
  },
  { key: 'quantity_total_amount_sum', dataType: 'number', editing: false },
  { key: 'position_passports', dataType: 'text', editing: false },
  { key: 'position_passports_vessels', dataType: 'text', editing: false },
  { key: 'contract', dataType: 'text', editing: false },
  { key: 'contract_payment_conditions', dataType: 'text', editing: false },
  { key: 'bank', dataType: 'text', editing: true },
  { key: 'counterpaty_payment_info_bank_name', dataType: 'text', editing: false },
  {
    key: 'owner_bank_account',
    dataType: 'text',
    editing: false,
    sourceKey: 'owner_bank_account_name',
  },
  { key: 'account', dataType: 'text', editing: false, sourceKey: 'finance_account_title' },
  { key: 'subuses', dataType: 'text', editing: false },
  { key: 'documents', dataType: 'number', editing: false, sourceKey: 'document_count' },
  { key: 'business_unit', dataType: 'text', editing: false, sourceKey: 'business_unit_title' },
  { key: 'responsible', dataType: 'text', editing: false, sourceKey: 'responsible_first_name' },
  {
    key: 'responsible_for_signing',
    dataType: 'text',
    editing: false,
    sourceKey: 'responsible_for_signing_first_name',
  },
  { key: 'payment_plans', dataType: 'text', editing: false },
  { key: 'additional_info', dataType: 'text', editing: true },
  { key: 'delivery_hyperlink', dataType: 'text', editing: true },
  { key: 'editor', dataType: 'text', editing: false, sourceKey: 'editor_first_name' },
  { key: 'counterparty_payment_iban', dataType: 'text', editing: true },
  { key: 'bank_name_iban', dataType: 'text', editing: true },
  { key: 'payment_bank_name', dataType: 'text', editing: false },
  { key: 'customs_declaration', dataType: 'text', editing: false },
  { key: 'bills_of_lading', dataType: 'text', editing: false },
  { key: 'daily_fines_rate', dataType: 'number', editing: true, sourceKey: 'daily_fines_rate' },
  { key: 'fines_days_count', dataType: 'text', editing: false },
  { key: 'fines_invoicing', dataType: 'text', editing: false },
  { key: 'fines_amount', dataType: 'number', editing: true },
  { key: 'fines_amount_default', dataType: 'text', editing: false },
  { key: 'amount_not_reserved', dataType: 'number', editing: false },
  { key: 'payment_conditions_option_title', dataType: 'text', editing: false },
  { key: 'tse', dataType: 'text', editing: false },
];

// need to do templae or select
// discount
// paid_sum_payments_amount_USD
/// offsets_sum_default_currency_amount
// counterparty
// position_passports
// position_passports_vessels
// contract
// contract_payment_conditions
// subuses
// documents
// business_unit
// responsible
// responsible_for_signing
/// payment_plans
// payment_bank_name
// customs_declaration
// bills_of_lading
// fines_days_count
