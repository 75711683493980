import React from 'react';

import type { Entity } from '~/shared/common';

import { ListRow } from './components/list-row';
import { Loading } from './components/loading';
import type { CellRenderDef, Row } from './types';

export const DataList = <TRecord extends Entity>({
  rows,
  CellRender,
  editing,
  onEdit,
  loading,
  onSelect,
  selectedRowIds,
}: {
  rows: Row<TRecord>[];
  selectedRowIds?: number[];
  editing: boolean;
  CellRender: React.FC<CellRenderDef<TRecord>>;
  onEdit: (
    newValue: TRecord[keyof TRecord] | undefined,
    accessorKey: string,
    rowId: number,
  ) => void;
  onSelect?: (ids: number[]) => void;
  loading?: boolean;
}) => {
  return (
    <div className="scrollbar relative max-h-[89vh] w-full overflow-auto text-xs">
      <div className="border-stroke-main-medium bg-background-main-secondary inline-grid rounded-md border">
        {loading ? (
          <Loading recordsCount={20} rowsCount={20} />
        ) : (
          rows.map((row) => (
            <ListRow
              key={row.id}
              row={row}
              editing={editing}
              selectedRowIds={selectedRowIds}
              onEdit={onEdit}
              onSelect={onSelect}
              CellRender={CellRender}
            />
          ))
        )}
      </div>
    </div>
  );
};
