import { formatDate } from '~/shared/lib/utils';

import template from './cashflow-page.html?raw';

import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ReportsService } from '^/app/reports/legacy/reports.srv';

export const CashflowPage = {
  bindings: {},
  template,
  controller: [
    '$rootScope',
    'PageService',
    'gtFilterService',
    'ReportsService',
    'CashflowService',
    class {
      $rootScope: GtRootScopeService;
      CashFlowService: any;
      gtFilterService: GtFilterService;
      PageService: PageService;
      ReportsService: ReportsService;
      filterLevel: string;
      modes: any;
      queryParams: QueryParams = {};
      recreateReports: any;
      constructor(
        $rootScope: GtRootScopeService,
        PageService: PageService,
        gtFilterService: GtFilterService,
        ReportsService: ReportsService,
        CashflowService: any,
      ) {
        this.$rootScope = $rootScope;
        this.PageService = PageService;
        this.gtFilterService = gtFilterService;
        this.ReportsService = ReportsService;
        this.CashFlowService = CashflowService;

        this.filterLevel = 'cashflow-page-view';
        this.modes = ['day', 'week', 'month'];
        this.recreateReports = false;
        this.queryParams = {};
      }

      $onInit() {
        this.queryParams = {
          mode: 'month',
          start_date: formatDate(this.$rootScope.user.settings.CASHFLOW_START_DATE, 'dd.MM.yyyy'),
          end_date: formatDate(
            new Date(
              parseInt(this.$rootScope.user.settings.DEFAULT_VALUES.crop_year, 10),
              new Date().getMonth() + 1,
              0,
            ),
            'dd.MM.yyyy',
          ),
        };
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        return {
          class: 'page-header-main-clients-tab',
          buttons: [
            new this.PageService.buttons.Print(),
            new this.PageService.buttons.Refresh(this.filterLevel),
          ],
          pages: this.ReportsService.getPagesConfig(),
        };
      }

      applyFilter() {
        this.gtFilterService.updateQueryParams(this.queryParams, this.filterLevel);
      }

      callRecreateModal() {
        this.CashFlowService.openCashFlowModal({
          date_from: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          date_to: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        }).then(() => this.applyFilter());
      }
    },
  ],
};
