import { shareReplay } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import {
  ConsignmentCreate,
  ConsignmentList,
  ConsignmentListParams,
  ConsignmentUpdate,
} from '~/features/execution/consignments';
import { ConsignmentsModel } from '~/features/execution/consignments/services/consignments.model';
import { ConsignmentsRepository } from '~/features/execution/consignments/services/consignments.repository';
import { Consignment } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

@injectable()
export class ConsignmentsListViewModel extends EntityListEditViewModel<
  Consignment,
  Omit<ConsignmentListParams, 'page' | 'page_size'> & { page: number; page_size: number },
  ConsignmentCreate,
  ConsignmentList
> {
  resolveEntityRepo() {
    return container.resolve(ConsignmentsRepository);
  }
  private readonly entityName = 'consignments-page';
  private readonly purposeModel = 'consignment';

  constructor(
    private readonly consignmentsRepo: ConsignmentsRepository,
    private readonly consignmentsModel: ConsignmentsModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.consignmentsRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public consignmentsCreated = async ({ entities }: { entities: ConsignmentCreate[] }) => {
    await this.consignmentsModel.createConsignments(entities);
  };

  public consignmentDeleted = async (id: number) => {
    try {
      await this.consignmentsRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public consignmentsUpdated = async ({ entities }: { entities: ConsignmentUpdate[] }) => {
    try {
      await this.consignmentsModel.updateConsignments(entities);
      notifySuccess('Consignments updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: Consignment[]) => {
    await Promise.all(records.map(this.consignmentsRepo.update));
    this.pageParamsChanged({});
  };

  public createConsignment = async (consignment: Consignment) => {
    try {
      await this.consignmentsRepo.create(consignment);
      notifySuccess('Consignment created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };
}
