import type { CounterpartyList } from './types';

export const counterpartiesBuilder = (counterparties: CounterpartyList[]) => {
  return counterparties.map((counterparty) => ({
    ...counterparty,
    author: {
      firstName: counterparty.author_first_name,
      lastName: counterparty.author_last_name,
      avatarSrc: counterparty.author_avatar,
    },
    editor: {
      firstName: counterparty.editor_first_name,
      lastName: counterparty.editor_last_name,
      avatarSrc: counterparty.editor_avatar,
    },
    affiliated: {
      supplier_names: counterparty.supplier_names,
      exporter_names: counterparty.supplier_names,
    },
  }));
};
