import type ng from 'angular';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

export class DisbursementBlService {
  $resource: ng.resource.IResourceService;
  CropsService: any;
  DisbursementBlResource: any;
  FinancesService: FinancesService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    FinancesService: FinancesService,
    CropsService: any,
  ) {
    this.$resource = $resource;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.FinancesService = FinancesService;
    this.CropsService = CropsService;
    this.DisbursementBlResource = $resource(
      '/api/finances/disbursementbl/:id/',
      {
        id: '@id',
      },
      {
        listDetailed: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursement-bill-of-lading/list/detailed/',
        },
        totalsDetailed: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursement-bill-of-lading/totals/detailed/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursementbl/predictions/',
        },
        getPositionFromDbl: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/position-from-dbl/:id/',
        },
      },
    );
  }

  getTableData(params: object) {
    return this.DisbursementBlResource.listDetailed(params).$promise;
  }

  getTotalsData(params: object) {
    return this.DisbursementBlResource.totalsDetailed(params).$promise;
  }

  getPositionFromDbl(params: object) {
    return this.DisbursementBlResource.getPositionFromDbl(params).$promise;
  }

  checkCreateDisbursementBLAbility(consignments: any) {
    if (!consignments || consignments.length === 0) {
      return Promise.resolve({ errors: ['No consignments provided'] });
    }
    const duplicationChecks = {
      'Selected multiple warehouses': consignments.reduce((res: any, item: any) => {
        res[item.warehouse_key] = item.warehouse_name;
        return res;
      }, {}),
      'Selected multiple commodities': consignments.reduce((res: any, item: any) => {
        res[item.commodity_id] = item.commodity_title;
        return res;
      }, {}),
      'Selected multiple dates': consignments.reduce((res: any, item: any) => {
        res[item.date.toISOString().split('T')[0]] = item.date.toISOString().split('T')[0];
        return res;
      }, {}),
    };
    const errors: any = Object.fromEntries(
      Object.entries(duplicationChecks)
        .filter((entry) => Object.keys(entry[1]).length > 1)
        .map((entry) => [entry[0], Object.values(entry[1])]),
    );
    consignments
      .filter((item: any) => item.$_inputedNumber < 0)
      .forEach((item: any) => {
        errors[`Consignment #${item.number} (${item.id})`] =
          `Volume should be positive: ${item.$_inputedNumber}`;
      });
    consignments
      .filter((item: any) => item.$_inputedNumber > item.volume_to_connect_dbl)
      .forEach((item: any) => {
        errors[`Consignment #${item.number} (${item.id})`] =
          `Exceeding the available volume: ${item.$_inputedNumber} > ${item.volume_to_connect_dbl}`;
      });
    if (!Object.keys(errors).length) {
      return Promise.resolve({});
    }
    return Promise.resolve({ errors: errors });
  }
  createDisbursementBL(consignments: any) {
    const firstConsignment = consignments[0];

    const currencyQuery = this.FinancesService.Currency.query({
      search: firstConsignment.contract_currency,
    }).$promise;

    const cropQuery = this.CropsService.Crop.query({
      title: firstConsignment.commodity_title,
    }).$promise;

    const promises: any = [currencyQuery, cropQuery];

    if (firstConsignment.contract_currency !== this.FinancesService.getDefaultCurrency().symbol) {
      const currencyExchangeQuery = this.FinancesService.CurrencyExchange.query({
        search: firstConsignment.contract_currency,
      }).$promise;
      promises.push(currencyExchangeQuery);
    }

    return Promise.all(promises)
      .then((results) => {
        const [currencyData, cropData, currencyExchangeData] = results;
        const totalVolume = consignments.reduce(
          (acc: any, consignment: any) =>
            acc + (consignment.$_inputedNumber || consignment.volume_to_connect_dbl),
          0,
        );
        const disbursementbl: any = {
          id: null,
          number: firstConsignment.number,
          price: firstConsignment.contract_price_with_vat,
          volume: totalVolume,
          date: firstConsignment.date,
          currency: currencyData.results[0]?.id,
          cargo: cropData.results[0]?.id,
          type_of_activities: firstConsignment.contract_type_of_activities_ids,
          sap_orders: firstConsignment.contract_sap_orders_ids,
          contract_option: firstConsignment.contract_option,
          contract_vat_option: firstConsignment.contract_vat_option,
          contract_vat_value: firstConsignment.contract_vat_value,
        };

        if (currencyExchangeData) {
          disbursementbl.currency_exchange = currencyExchangeData.results[0]?.id;
        }

        if (firstConsignment.contract_type === 'sale') {
          disbursementbl.sale_contract = firstConsignment.contract;
          disbursementbl.dbl_type = 'sale';
        } else if (firstConsignment.contract_type === 'purchase') {
          disbursementbl.purchase_contract = firstConsignment.contract;
          disbursementbl.dbl_type = 'purchase';
        }

        disbursementbl.volume = totalVolume;

        disbursementbl.disbursementbl_consignments = consignments.map((consignment: any) => ({
          consignment: consignment.id,
          volume_connected: consignment.$_inputedNumber || disbursementbl.volume,
          consignment_number: consignment.number,
        }));

        return this.FinancesService.disbursementblModal(disbursementbl);
      })
      .catch((error) => {
        alert(`Error creating disbursementBL: ${error}`);
      });
  }
  createDisbursementBLAfterCheck(consignments: any) {
    return this.checkCreateDisbursementBLAbility(consignments).then((resultData: any) => {
      if (resultData.errors) {
        return this.GtUtils.errorClb({ data: resultData.errors });
      }
      return this.createDisbursementBL(consignments);
    });
  }
}

DisbursementBlService.$inject = [
  '$resource',
  'gettext',
  'GtUtils',
  'FinancesService',
  'CropsService',
];
