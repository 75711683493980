import React from 'react';

import type { ColumnConfig } from '~/core/page-views/lib';
import type { BillOfLadingList } from '~/features/execution/bills-of-lading';

export const getBillOfLadingColumns = (billsOfLading: BillOfLadingList[]): ColumnConfig[] => [
  {
    key: 'number',
    dataType: 'string',
    editing: true,
    validate: { type: 'textLength', condition: 'moreThan', value: '0' },
  },
  { key: 'date', dataType: 'date', editing: true, typeExtra: 'd-M-y' },
  { key: 'container_number', sourceKey: 'container_number', dataType: 'number', editing: true },
  { key: 'volume', dataType: 'number', editing: true },
  { key: 'unloadingVolume', sourceKey: 'unloading_volume', dataType: 'number', editing: true },
  { key: 'lossesVolume', sourceKey: 'losses_volume', dataType: 'number', editing: false },
  { key: 'purchase_contract_number', dataType: 'string', editing: false },
  {
    key: 'quantity_invoiced_incoming',
    sourceKey: 'quantity_to_be_invoiced_incoming',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'quantity_invoiced_outgoing',
    sourceKey: 'quantity_to_be_invoiced_outgoing',
    dataType: 'number',
    editing: false,
  },
  { key: 'margin', dataType: 'number', editing: false },
  { key: 'sale_contract_status', dataType: 'string', editing: false },
  { key: 'purchase_contract_status', dataType: 'string', editing: false },
  { key: 'sale_contract_true_volume', dataType: 'number', editing: false },
  { key: 'amount_bl', dataType: 'number', editing: false },
  { key: 'purchase_amount_bl', dataType: 'number', editing: false },
  { key: 'amount_bl_110_percents', dataType: 'number', editing: false },
  { key: 'additional_info', dataType: 'text', editing: true },
  {
    key: 'exportCtrBusinessUnit',
    sourceKey: 'export_ctr_businessunit_title',
    dataType: 'string',
    editing: false,
  },
  {
    key: 'purchaseCtrBusinessUnit',
    sourceKey: 'purchase_ctr_businessunit_title',
    dataType: 'string',
    editing: false,
  },
  {
    key: 'saleCtrBusinessUnit',
    sourceKey: 'sale_ctr_businessunit_title',
    dataType: 'string',
    editing: false,
  },
  {
    key: 'sale_execution_period',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const billOfLading = billsOfLading.find((bl) => bl.id === id);
      if (!billOfLading?.sale_contract_date_of_execution) {
        return <div />;
      }

      const { sale_contract_date_of_execution, sale_contract_end_of_execution } = billOfLading;

      const startDate = sale_contract_date_of_execution || '---';
      const endDate = sale_contract_end_of_execution || '---';

      const tooltipText = `${startDate} - ${endDate}`;

      return (
        <span className="tooltip smaller-label" data-tip={tooltipText}>
          <i className="fa fa-calendar" />
          {startDate}
          <div className="clear-both block"></div>
          <i className="fa fa-calendar" />
          {endDate}
        </span>
      );
    },
  },
  {
    key: 'logistics_purchase_contract_data',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const billOfLading = billsOfLading.find((bl) => bl.id === id);
      if (!billOfLading) {
        return <div />;
      }

      const contracts = billOfLading.logistics_purchase_contract_data || [];

      return (
        <>
          {contracts
            .filter((contract) => contract.volume_received_sum)
            .map((contract, index) => (
              <span key={contract.id || index}>
                <a href={`#/contract/${contract.id}`}>
                  <i className="fa fa-file-text-o" /> {contract.contract_number} -{' '}
                  {Number(contract.volume_received_sum).toFixed(3)} (
                  {Number(contract.true_volume).toFixed(3)})<div className="clear-both block"></div>
                </a>
              </span>
            ))}
        </>
      );
    },
  },
  {
    key: 'logistics_purchase_contracts_numbers',
    sourceKey: 'logistics_purchase_contract_data',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const billOfLading = billsOfLading.find((bl) => bl.id === id);
      if (!billOfLading?.logistics_purchase_contract_data) {
        return <div />;
      }

      const contracts = billOfLading.logistics_purchase_contract_data;

      return (
        <>
          {contracts.map((contract, index) => {
            if (!contract.contract_number) {
              return null;
            }
            return (
              <span key={contract.id || index}>
                <a href={`#/contract/${contract.id}`} className="btn-link">
                  <i className="fa fa-file-text-o"></i> {contract.contract_number}
                </a>
                <div className="clear-both block"></div>
              </span>
            );
          })}
        </>
      );
    },
  },
  {
    key: 'incoming_invoices',
    sourceKey: 'invoices',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const item = billsOfLading.find((bl) => bl.id === id);
      if (!item?.invoices) {
        return <div />;
      }

      return (
        <>
          {item.invoices
            .filter((invoice) => invoice.invoice_type === 'incoming')
            .map((invoice) => (
              <span key={invoice.id}>
                <a
                  href={`#/payment/${invoice.id}/?tab=positions`}
                  className="btn-link"
                  style={{ marginLeft: '2px' }}
                >
                  <i className="fa fa-credit-card-alt"></i> {invoice.number} {invoice.status}
                </a>
                <div className="clear-both block"></div>
              </span>
            ))}
        </>
      );
    },
  },
  {
    key: 'outgoing_invoices',
    sourceKey: 'invoices',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const item = billsOfLading.find((bl) => bl.id === id);
      if (!item?.invoices) {
        return <div />;
      }

      return (
        <>
          {item.invoices
            .filter((invoice) => invoice.invoice_type === 'outgoing')
            .map((invoice) => (
              <span key={invoice.id}>
                <a
                  href={`#/payment/${invoice.id}/?tab=positions`}
                  className="btn-link"
                  style={{ marginLeft: '2px' }}
                >
                  <i className="fa fa-credit-card-alt"></i> {invoice.number} {invoice.status}
                </a>
                <div className="clear-both block"></div>
              </span>
            ))}
        </>
      );
    },
  },
  {
    key: 'costs_invoices',
    sourceKey: 'cost_invoices_data',
    dataType: 'template',
    editing: false,
    render: ({ id }) => {
      const item = billsOfLading.find((bl) => bl.id === id);
      if (!item?.cost_invoices_data) {
        return <div />;
      }

      return (
        <>
          {item.cost_invoices_data.map((invoice) => (
            <span key={invoice.id}>
              <a
                href={`#/payment/${invoice.id}/?tab=positions`}
                className="btn-link"
                style={{ marginLeft: '2px' }}
              >
                <i className="fa fa-credit-card-alt"></i> {invoice.number} {invoice.status}
              </a>
              <div className="clear-both block"></div>
            </span>
          ))}
        </>
      );
    },
  },
  { key: 'cargo', sourceKey: 'sale_contract_commodity_title', dataType: 'text', editing: false },
  { key: 'carrier', dataType: 'text', editing: true },
  { key: 'loading_port', sourceKey: 'port_loading_name', dataType: 'text', editing: false },
  { key: 'destination_port', sourceKey: 'port_destination_name', dataType: 'text', editing: false },
  { key: 'export_contract', sourceKey: 'export_contract_number', dataType: 'text', editing: true },
  { key: 'place', sourceKey: 'place_of_destination_name', dataType: 'text', editing: true },
  { key: 'notify_party', sourceKey: 'notify_party_name', dataType: 'text', editing: true },
  {
    key: 'notify_party_second',
    sourceKey: 'notify_party_second_name',
    dataType: 'text',
    editing: true,
  },
  {
    key: 'notify_party_third',
    sourceKey: 'notify_party_third_name',
    dataType: 'text',
    editing: true,
  },
  { key: 'consignee', sourceKey: 'consignee_name', dataType: 'text', editing: true },
  { key: 'vessel_name', sourceKey: 'voyage_name', dataType: 'text', editing: true },
];
