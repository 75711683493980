import React from 'react';

import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import type {
  BillOfLadingCreate,
  BillOfLadingList,
  BillOfLadingUpdate,
  BillsOfLadingListViewModel,
} from '~/features/execution/bills-of-lading';
import { getBillOfLadingColumns } from '~/features/execution/bills-of-lading/';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

import { BillsOfLadingContainerLayout } from './bills-of-lading-container-layout';

export const BillsOfLadingContainer: React.FC<{
  viewModel: BillsOfLadingListViewModel;
  viewMode: 'spreadsheet' | 'grid';
}> = ({ viewModel, viewMode }) => {
  const createdDataRef = React.useRef<BillOfLadingCreate[]>([]);
  const editedDataRef = React.useRef<BillOfLadingUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getBillOfLadingColumns(pageData.results),
      }),
    [columns, pageData],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.billsOfLadingUpdated({ entities: editedDataRef.current });
      await viewModel.billsOfLadingCreated({ entities: createdDataRef.current });
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <BillsOfLadingContainerLayout>
      <DatasetView<BillOfLadingList, BillOfLadingUpdate, BillOfLadingCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: BillOfLadingUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: BillOfLadingCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.billOfLadingDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </BillsOfLadingContainerLayout>
  );
};
