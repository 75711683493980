import {
  DataValidationErrorStyle,
  DataValidationOperator,
  DataValidationType,
  FUniver,
} from '@univerjs/presets';

export const setNumberValidate = ({
  range,
  condition,
  value,
  spreadsheetRef,
}: {
  range: [number, number, number, number];
  condition: 'lessThan' | 'moreThan';
  value: number;
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
}) => {
  const workbook = spreadsheetRef?.current?.getActiveWorkbook();
  const worksheet = workbook?.getActiveSheet();
  const sheetRange = worksheet?.getRange(...range);

  const dataValidationBuilder = FUniver.newDataValidation();
  const dataValidation = dataValidationBuilder.build();
  dataValidation.setCriteria(DataValidationType.TEXT_LENGTH, [
    condition === 'lessThan'
      ? DataValidationOperator.LESS_THAN
      : DataValidationOperator.GREATER_THAN,
    String(value),
    String(value),
  ]);

  dataValidationBuilder.setAllowBlank(false);

  dataValidation.setOptions({
    errorStyle: DataValidationErrorStyle.WARNING,
  });

  sheetRange?.setDataValidation(dataValidation);
};

export const setTextValidate = ({
  range,
  condition,
  value,
  spreadsheetRef,
}: {
  range: [number, number, number, number];
  condition: 'lessThan' | 'moreThan';
  value: string;
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
}) => {
  const workbook = spreadsheetRef?.current?.getActiveWorkbook();
  const worksheet = workbook?.getActiveSheet();
  const sheetRange = worksheet?.getRange(...range);

  const dataValidationBuilder = FUniver.newDataValidation();
  const dataValidation = dataValidationBuilder.build();
  dataValidation.setCriteria(DataValidationType.TEXT_LENGTH, [
    condition === 'lessThan'
      ? DataValidationOperator.LESS_THAN
      : DataValidationOperator.GREATER_THAN,
    value,
    value,
  ]);
  dataValidationBuilder.setAllowBlank(false);

  dataValidation.setOptions({
    errorStyle: DataValidationErrorStyle.WARNING,
  });

  sheetRange?.setDataValidation(dataValidation);
};

export const setSelectValidate = ({
  range,
  options,
  spreadsheetRef,
  allowMultiple = false,
}: {
  range: [number, number, number, number];
  options: string[];
  allowMultiple?: boolean;
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
}) => {
  const workbook = spreadsheetRef?.current?.getActiveWorkbook();
  const worksheet = workbook?.getActiveSheet();
  const sheetRange = worksheet?.getRange(...range);

  const dataValidationBuilder = FUniver.newDataValidation();
  const dataValidation = dataValidationBuilder.requireValueInList(options, allowMultiple).build();

  dataValidationBuilder.setAllowBlank(false);

  sheetRange?.setDataValidation(dataValidation);
};
