import ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';

(function () {
  'use strict';
  ng.module('reports.legacy').component('lineupContainer', {
    bindings: {
      filterLevel: '<?',
      initQueryParams: '<?',
      contractQuickAdd: '<?',
    },
    template: require('./lineup-container.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    'gettext',
    '$scope',
    'ReportsService',
    'GtUtils',
    'gtFilterService',
    'CoreService',
  ];

  function Controller(
    this: any,
    gettext: ng.gettext.gettextFunction,
    $scope: ng.IScope,
    ReportsService: any,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    CoreService: CoreService,
  ) {
    const vm = this;
    vm.filterLevel = vm.filterLevel || 'lineup-container';
    vm.passports = [];
    vm.passportsTotal = {};
    vm.passportsCount = 0;
    vm.queryParams = {};
    vm.initQueryParams = vm.initQueryParams || {};
    vm.openLineupModal = openLineupModal;
    vm.changeSubTab = changeSubTab;
    vm.updateData = updateData;
    vm.applyFilters = applyFilters;
    vm.clearFilters = clearFilters;
    vm.contractQuickAdd = false;
    vm.dateFilterOptions = [
      {
        predicate: 'passport_bl_date',
        title: gettext('Date of execution'),
      },
      {
        predicate: 'bl_max_date',
        title: gettext('BL max date'),
      },
      {
        predicate: 'update_time',
        title: gettext('Update time'),
      },
      {
        predicate: 'conclusion_date',
        title: gettext('Conclusion date'),
      },
      {
        predicate: 'business_date',
        title: gettext('business date'),
      },
    ];
    vm.shortLongFilterOptions = [
      {
        value: '1',
        title: gettext('Short'),
      },
      {
        value: '0',
        title: gettext('Long'),
      },
    ];
    vm.passportStatusOptions = [
      {
        value: 'Request',
        title: gettext('New'),
      },
      {
        value: 'Approved',
        title: gettext('Approved'),
      },
      {
        value: 'Processing',
        title: gettext('Processing'),
      },
      {
        value: 'cargo_partial_executed',
        title: gettext('Cargo partial executed'),
      },
      {
        value: 'cargo_executed',
        title: gettext('Cargo executed'),
      },
      {
        value: 'Executed',
        title: gettext('Executed'),
      },
      {
        value: 'Cancelled',
        title: gettext('Cancelled'),
      },
    ];

    vm.voyageStatusOptions = [
      {
        value: 'New',
        title: gettext('New'),
      },
      {
        value: 'pol_under_way',
        title: gettext('POL under way'),
      },
      {
        value: 'at_loading_port',
        title: gettext('At loading port'),
      },
      {
        value: 'Loading',
        title: gettext('Loading'),
      },
      {
        value: 'Loaded',
        title: gettext('Loaded'),
      },
      {
        value: 'pod_under_way',
        title: gettext('POD under way'),
      },
      {
        value: 'at_discharging_port',
        title: gettext('At discharging port'),
      },
      {
        value: 'Discharging',
        title: gettext('Discharging'),
      },
      {
        value: 'Discharged',
        title: gettext('Discharged'),
      },
      {
        value: 'Executed',
        title: gettext('Executed'),
      },
      {
        value: 'Washout',
        title: gettext('Washout'),
      },
      {
        value: 'Cancelled',
        title: gettext('Cancelled'),
      },
    ];

    vm.orderingOptions = [
      {
        predicate: 'bl_ordering_date',
        title: gettext('BL/ETA ASC'),
      },
      {
        predicate: '-bl_ordering_date',
        title: gettext('BL/ETA DESC'),
      },
      {
        predicate: 'sale_fact_volume_sum',
        title: gettext('SALE ASC'),
      },
      {
        predicate: '-sale_fact_volume_sum',
        title: gettext('SALE DESC'),
      },
      {
        predicate: 'purchase_fact_volume_sum',
        title: gettext('PURCHASE ASC'),
      },
      {
        predicate: '-purchase_fact_volume_sum',
        title: gettext('PURCHASE DESC'),
      },
      {
        predicate: 'latest_margin_conrol_create_time',
        title: gettext('Margin control ASC'),
      },
      {
        predicate: '-latest_margin_conrol_create_time',
        title: gettext('Margin control DESC'),
      },
    ];

    vm.$onInit = function () {
      vm.subtab = gtFilterService.getQueryParams(vm.filterLevel).subtab ?? 'lineup';
      vm.cargo_list = gtFilterService.getQueryParams(vm.filterLevel).cargo_list;
      setDefaultQueryParams();
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        if (data.start_date === undefined && data.end_date === undefined) {
          return setDefaultQueryParams();
        }
        vm.queryParams = data;
        if (data.subtab) {
          vm.subtab = data.subtab;
        }
        updateData();
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
    };

    ////////////////

    function setDefaultQueryParams() {
      vm.queryParams = Object.assign(vm.initQueryParams, {
        page_size: 20,
        ordering: '-bl_ordering_date',
        start_date: formatDate(new Date(new Date().getFullYear(), 0, 1), 'dd.MM.yyyy'),
        end_date: formatDate(new Date(new Date().getFullYear() + 1, 11, 31), 'dd.MM.yyyy'),
        date_predicate: 'passport_bl_date',
        subtab: vm.subtab,
        cargo_list: vm.cargo_list,
      });
      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
    }

    function clearFilters() {
      setDefaultQueryParams();
      applyFilters();
    }

    function updateData() {
      let query = 'query';
      GtUtils.overlay('show');
      if (vm.subtab === 'pnl') {
        query = 'pnl';
      }
      ReportsService.Lineup[query](vm.queryParams, function (passData: any) {
        vm.passports = passData.results;
        vm.passportsCount = passData.count;
        vm.passportsTotal = passData.totals;
        GtUtils.overlay('hide');
      });
    }

    function applyFilters() {
      if (
        vm.queryParams.not_equal_margin_with_contolled === '1' ||
        vm.queryParams.not_equal_margin_with_contolled === '0'
      ) {
        GtUtils.overlay('show');
        ReportsService.LineupPnlLight.query(vm.queryParams, function (passData: any) {
          vm.queryParams.passport_id_list = passData.results.map((passport: any) => passport.id);
          gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
          GtUtils.overlay('hide');
        });
      } else {
        gtFilterService.setQueryParams(
          { ...vm.queryParams, passport_id_list: undefined },
          vm.filterLevel,
        );
      }
    }

    function openLineupModal() {
      ReportsService.lineupModal().then(updateData);
    }

    function changeSubTab(subtab: string) {
      vm.subtab = subtab;
      vm.queryParams.subtab = subtab;
      applyFilters();
    }
  }
})();
