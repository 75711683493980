import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { InputProps } from '~/shared/ui/kit/input';
import { Input } from '~/shared/ui/kit/input';

import { type DataTypeProps, messageIntent } from '../../lib';

export const UrlWrite: React.FC<DataTypeProps<string> & InputProps> = ({
  value,
  disabled,
  valueChanged,
  message,
  ...props
}) => {
  return (
    <div className={cn(message && messageIntent[message.type])}>
      <Input
        intent="transparent"
        defaultValue={value}
        onBlur={(e) => valueChanged?.(e.target.value)}
        disabled={disabled}
        fullWidth
        {...props}
      />
    </div>
  );
};
