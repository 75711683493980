import type { CellData, CustomData, MatrixPrimitive } from '~/shared/lib/spreadsheet';

export const updatedDataBuilder = ({
  newData,
  data,
}: {
  newData: MatrixPrimitive<CellData>;
  data: MatrixPrimitive<CellData>;
}): MatrixPrimitive<CellData> => {
  const result: MatrixPrimitive<CellData> = {};
  const dataLength = Object.keys(data).length;

  for (const rowKey in newData) {
    const workbookRow = newData[rowKey];
    const initialRow = data[rowKey] || undefined;
    const cellModel = data[1];

    const updatedRow = updateDataRow({
      workbookRow,
      initialRow,
      cellModel,
      rowKey: Number(rowKey),
      dataLength,
    });
    if (updatedRow) {
      result[rowKey] = updatedRow;
    }
  }

  return result;
};

const updateDataRow = ({
  workbookRow,
  initialRow,
  cellModel,
  rowKey,
  dataLength,
}: {
  workbookRow: MatrixPrimitive<CellData>[number];
  initialRow?: MatrixPrimitive<CellData>[number];
  cellModel: MatrixPrimitive<CellData>[number];
  rowKey: number;
  dataLength: number;
}) => {
  const updatedRow: MatrixPrimitive<CellData>[number] = {};

  for (const cellKey in workbookRow) {
    const workBookCell = workbookRow[cellKey];
    const key = (cellModel[cellKey].custom as CustomData)?.key;
    const dataType = (cellModel[cellKey].custom as CustomData)?.dataType;

    if (initialRow) {
      const initialCell = initialRow[cellKey];

      if (workBookCell.v !== initialCell.v && workBookCell.custom?.id === initialCell.custom?.id) {
        updatedRow[cellKey] = {
          ...initialCell,
          v: workBookCell.v,
        };
      } else if (workBookCell.custom?.id !== initialCell.custom?.id) {
        updatedRow[cellKey] = {
          custom: {
            ...initialCell.custom,
            key,
            dataType,
          },
          v: workBookCell.v,
        };
      }
    } else {
      updatedRow[cellKey] = {
        custom: {
          ...workBookCell.custom,
          id: rowKey >= dataLength ? undefined : (workBookCell.custom as CustomData)?.id,
          key,
          dataType,
        },
        v: workBookCell.v,
      };
    }
  }

  return Object.keys(updatedRow).length > 0 ? updatedRow : undefined;
};
