import ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import type { GtFilterService } from '../gt-filter/gt-filter.srv';

(function () {
  'use strict';
  ng.module('core.legacy').directive('gtTheadFilter', directive);

  function directive() {
    return {
      template: require('./gt-thead-filter.tpl.html?raw'),
      bindToController: true,
      controller: Controller,
      controllerAs: 'vm',
      scope: {
        filters: '<',
        filterLevel: '<?',
        applyFilters: '&?',
      },
    };
  }

  Controller.$inject = ['$scope', '$timeout', 'gtFilterService'];
  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.queryParams = {};
    vm.initQueryParams = {};
    vm.apply = apply;
    vm.getDatePickerConfig = getDatePickerConfig;
    vm.clearDate = clearDate;

    activate();

    ////////////////

    vm.$onChanges = function (changes: any) {
      if (changes.filterLevel) {
        vm.initQueryParams = gtFilterService.getQueryParams(vm.filterLevel);
      }
    };

    function activate() {
      if (vm.filterLevel) {
        vm.queryParams = gtFilterService.getQueryParams(vm.filterLevel);
        $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
          vm.queryParams = data;
        });
      }
    }

    function apply(event?: any) {
      if (event?.which && event.which != 13) {
        return;
      }
      vm.queryParams.page = undefined;
      const params = { ...vm.queryParams };
      for (const key in vm.queryParams) {
        if (
          vm.queryParams[key] == undefined ||
          (Array.isArray(vm.queryParams[key]) && !vm.queryParams[key].length)
        ) {
          params[key] = vm.initQueryParams[key];
        }
      }
      if (vm.applyFilters) {
        vm.applyFilters({ params });
      } else if (vm.filterLevel) {
        gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      }
    }
    function clearDate(filter: any) {
      vm.queryParams[filter.startDateField] = null;
      vm.queryParams[filter.endDateField] = null;
      filter.datePicker = { ...filter.datePicker, startDate: null, endDate: null };
      apply();
    }

    function getDatePickerConfig(filter: any) {
      if (filter.datePicker?.config) {
        return filter.datePicker.config;
      }
      const config = gtFilterService.getDateRangePickerConfig();
      config.eventHandlers = {
        'apply.daterangepicker': () => {
          return $timeout(() => {
            vm.queryParams.date_predicate = filter.datePredicate;
            vm.queryParams[filter.startDateField] = formatDate(
              filter.datePicker.startDate,
              'dd.MM.yyyy',
            );
            vm.queryParams[filter.endDateField] = formatDate(
              filter.datePicker.endDate,
              'dd.MM.yyyy',
            );
            apply();
          });
        },
        'cancel.daterangepicker': () => {
          vm.queryParams[filter.startDateField] = null;
          vm.queryParams[filter.endDateField] = null;
          vm.queryParams.date_predicate = null;
          filter.datePicker = {
            ...filter.datePicker,
            startDate: null,
            endDate: null,
            datePredicate: null,
          };
          apply();
        },
      };
      filter.datePicker = filter.datePicker || {
        startDate: null,
        endDate: null,
      };
      filter.datePicker.config = config;
      return config;
    }
  }
})();
