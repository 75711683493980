import { contractsRequestsRetrieve } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

@singleton()
export class RequestsRepository {
  getRequest = async (id: number) => {
    const { data } = await contractsRequestsRetrieve({ path: { id } });
    return data;
  };
}
