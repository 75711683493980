import type {
  Entity,
  EntityAutocomplete,
  EntityPageParams,
  EntityRepository,
} from '~/shared/common';
import { entityAutocompleteWithSearch } from '~/shared/common/components/entity-autocomplete/with-repo';

export type CommonDataType =
  | 'multiselect'
  | 'number'
  | 'select'
  | 'string'
  | 'phone'
  | 'checkbox'
  | 'text'
  | 'label'
  | 'url'
  | 'email'
  | 'entity'
  | 'date'
  | 'person';

export const entityAutocompletesRegistry: Record<string, typeof EntityAutocomplete> = {};

export const registerEntityAutocomplete = (
  entityName: string,
  search: EntityRepository<Entity, EntityPageParams>['search'],
) => {
  if (entityAutocompletesRegistry[entityName]) {
    throw new Error(`Autocomplete "${entityName}" is already registered.`);
  }

  entityAutocompletesRegistry[entityName] = entityAutocompleteWithSearch(search)();
};
