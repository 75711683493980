import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { type LogisticsService } from '^/app/execution/legacy/logistics.srv';

(function () {
  'use strict';

  ng.module('common.crops.legacy').controller('CropsQualitiesEditController', Controller);

  Controller.$inject = [
    '$uibModalInstance',
    'GtUtils',
    'ContractsService',
    'LogisticsService',
    'qualities',
    'planId',
    'cropId',
    'contractId',
    'logisticId',
    'consignmentId',
    'commodityIdList',
    'cropAreaId',
    'CropsService',
    'ClientsService',
    'gettext',
  ];

  function Controller(
    this: any,
    $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    LogisticsService: LogisticsService,
    qualities: any,
    planId: number,
    cropId: number,
    contractId: number,
    logisticId: number,
    consignmentId: number,
    commodityIdList: any,
    cropAreaId: number,
    CropsService: any,
    ClientsService: any,
    gettext: ng.gettext.gettextFunction,
  ) {
    const vm = this;

    vm.qualities = qualities;
    vm.close = close;
    vm.save = save;
    vm.errors = {};
    vm.planQualities = null;
    vm.cropQualities = null;
    vm.findQuality = findQuality;
    vm.nullSensitiveComparator = nullSensitiveComparator;
    vm.refreshCheckboxes = refreshCheckboxes;
    vm.createCharge = createCharge;
    vm.deleteQuality = deleteQuality;
    vm.createButton = false;
    vm.hovering = false;
    vm.setHovering = setHovering;
    vm.contractId = contractId;
    vm.logisticId = logisticId;
    vm.consignmentId = consignmentId;
    vm.commodityIdList = commodityIdList;
    vm.cropAreaId = cropAreaId;
    vm.qualityService = ContractsService.ContractQuality;

    activate();

    /////////////

    function activate() {
      vm.qualities = vm.qualities || [];
      vm.commodityIdList = vm.commodityIdList || [];
      vm.cropAreaId = vm.cropAreaId || [];
      if (logisticId) {
        vm.qualityService = LogisticsService.LogisticQuality;
      }
      if (consignmentId) {
        vm.qualityService = CropsService.ConsignmentQuality;
      }
      if (cropAreaId) {
        vm.qualityService = ClientsService.CropAreaQuality;
      }
      if (!vm.qualities.length) {
        return updateQualities();
      }
      if (!vm.cropQualities) {
        return updateCropQualities();
      }
    }

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function updatePlanQualities() {
      vm.qualityService.query({ contract: planId }, function (data: any) {
        vm.planQualities = data.results;
      });
    }

    function updateCropQualities() {
      CropsService.CropQuality.query(
        { cargo_list: [cropId, ...vm.commodityIdList] },
        function (data: any) {
          vm.cropQualities = data.results;
        },
      );
    }

    function close(data: any, silent: any) {
      if (!silent && !confirm(gettext('Close modal?'))) {
        return;
      }
      $uibModalInstance.close(data || 'cancel');
    }

    function save() {
      vm.qualityService.bulkCreateOrUpdate(
        vm.qualities,
        function (data: any) {
          vm.qualities = data.results;
          close(vm.qualities, true);
        },
        (e: any) => GtUtils.errorClb(e),
      );
    }

    function findQuality(title: any, list: any) {
      if (!title || !list) {
        return { value: 0 };
      }
      return list.filter((quality: any) => quality.title === title).shift() || { value: '--' };
    }

    function nullSensitiveComparator(previousEntry: any, nextEntry: any) {
      if (previousEntry.value === undefined) {
        return -1;
      }
      if (nextEntry.value === undefined) {
        return 1;
      }
      return previousEntry < nextEntry ? -1 : 1;
    }

    function refreshCheckboxes() {
      vm.createButton = !vm.createButton;
      if (vm.createButton) {
        vm.qualities.forEach(function (item: any) {
          if (item.contract_charge == null) {
            item._showCheckbox = true;
          }
        });
      }
    }

    function createCharge() {
      vm.qualities.unshift({
        contract: vm.contractId,
        logistic: vm.logisticId,
        consignment: vm.consignmentId,
        crop_area: vm.cropAreaId,
        value: 0,
      });
      vm.createButton = false;
    }

    function updateQualities() {
      if (planId) {
        updatePlanQualities();
      }
      if (cropId) {
        updateCropQualities();
      }
      if (contractId) {
        updateContractQualities();
      }
      if (logisticId) {
        updateLogisticQualities();
      }
      if (consignmentId) {
        updateConsignmentQualities();
      }
      if (cropAreaId) {
        updateCropAreaQualities();
      }
    }

    function updateContractQualities() {
      vm.qualityService.query({ contract: vm.contractId }, function (data: any) {
        vm.qualities = data.results;
      });
    }

    function updateLogisticQualities() {
      return LogisticsService.LogisticQuality.query(
        { logistic: vm.logisticId },
        function (data: any) {
          vm.qualities = data.results;
        },
      );
    }

    function updateConsignmentQualities() {
      CropsService.ConsignmentQuality.query(
        { consignment: vm.consignmentId },
        function (data: any) {
          vm.qualities = data.results;
        },
      );
    }

    function updateCropAreaQualities() {
      vm.qualityService.query({ crop_area: vm.cropAreaId }).$promise.then((data: any) => {
        vm.qualities = data.results;
      });
    }

    function deleteQuality(quality: any) {
      if (!confirm(gettext('Delete quality?'))) {
        return false;
      }
      if (!quality.id) {
        vm.qualities.splice(vm.qualities.indexOf(quality), 1);
        return;
      }
      GtUtils.overlay('show');
      vm.qualityService.delete({ id: quality.id }, function () {
        updateQualities();
        GtUtils.overlay('hide');
        notify(gettext('Quality deleted'));
      });
    }
  }
})();
