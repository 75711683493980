import { ContentType, coreContentTypesList } from '~/shared/api';
import { Paginated } from '~/shared/common';
import { singleton } from '~/shared/lib/di';

import { ContentTypesParams } from '../lib/types';

@singleton()
export class ContentTypesRepository {
  queryContentTypes = async (params: ContentTypesParams): Promise<Paginated<ContentType>> => {
    const { data } = await coreContentTypesList({ query: params });
    return data;
  };
}
