import type ng from 'angular';

import type { Request } from '~/features/deals/requests';
import type { Paginated } from '~/shared/common';
import { notify } from '~/shared/lib/notify';

import template from './requests-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { CoreUtils } from '^/app/core/core.utils';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

type RequestsContainerQueryParams = QueryParams & {
  contract_type: Request['contract_type'];
  deal_type: Request['deal_type'];
  serializer: string;
  stage: Request['stage'];
};

class RequestsContainerController implements ng.IController {
  initQueryParams?: Partial<RequestsContainerQueryParams>;
  filterLevel = 'requests-container';
  tableName = '';
  tableExpanded = false;
  total = 0;
  onCreate?: () => void;
  onUpdate?: (data: { requests: Partial<Request>[] }) => void;
  suppressNotifications = false;
  view: 'list' | 'block' | 'table' = 'table';
  hideButton = false;
  simplifiedView = false;

  queryParams: RequestsContainerQueryParams = {
    contract_type: 'sale',
    deal_type: 'spot',
    serializer: 'table_info',
    stage: 'ticket',
  };
  resourceClass: 'indicator' | 'request' = 'request';
  savedFilterInitQueryParams: Partial<RequestsContainerQueryParams> = {};
  requests: Request[] | Partial<Request>[] = [];
  newRequest: Partial<Request> & { $_edit?: boolean } = {};
  newRequestTemplate: Partial<Request> = {};
  requestsCount = 0;
  quickAdd = false;
  onQuickAdd?: () => void;
  savedFilterChoices: any;
  creatingMultiTicket = false;

  static readonly $inject = [
    '$rootScope',
    '$scope',
    'gtFilterService',
    'ContractsService',
    'FinancesService',
    'GtUtils',
    'gettext',
    'CoreUtils',
    'CoreService',
    'ClientsService',
    'MulticontractService',
  ];
  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $scope: ng.IScope,
    private readonly gtFilterService: GtFilterService,
    private readonly ContractsService: ContractsService,
    private readonly FinancesService: FinancesService,
    private readonly GtUtils: GtUtilsService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly CoreUtils: CoreUtils,
    private readonly CoreService: CoreService,
    private readonly ClientsService: any,
    private readonly MulticontractService: MulticontractService,
  ) {}

  $onInit() {
    if (this.$rootScope.isDeviceMobile) {
      this.view = 'block';
    } else {
      this.view =
        (['list', 'block'].includes(this.$rootScope.user.profile?.tickets_view ?? '')
          ? this.$rootScope.user.profile?.tickets_view
          : this.view) ?? 'block';
    }

    this.queryParams = { ...this.queryParams, ...this.initQueryParams };

    this.savedFilterInitQueryParams = {
      stage: this.queryParams.stage,
      serializer: this.queryParams.serializer,
      contract_type: this.queryParams.contract_type,
    };
    this.newRequest = { ...this.queryParams };
    this.onQuickAdd = this.onCreate ?? this.updateData;

    this.tableName = this.tableName || this.queryParams.contract_type + '-requests-container';
    this.resourceClass = this.queryParams.stage === 'indicator' ? 'indicator' : 'request';

    this.tableExpanded = this.tableExpanded || false;

    this.$scope.$on(
      'gt-filter-updated_' + this.filterLevel,
      (_ev: unknown, data: RequestsContainerQueryParams) => {
        this.queryParams = data;
        this.resourceClass = this.queryParams.stage === 'indicator' ? 'indicator' : 'request';
        this.updateData();
      },
    );
    this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
    this.buildNewRequest().catch(this.GtUtils.errorClb);
    this.CoreService.getSavedFilterChoices(this.filterLevel)
      .then((data) => (this.savedFilterChoices = data))
      .catch(this.GtUtils.errorClb);
  }

  $onChanges = (changes: ng.IOnChangesObject) => {
    if ([changes.tableExpanded, this.tableExpanded].every(Boolean)) {
      this.updateData();
    }
  };

  buildNewRequest = (origin: Partial<Request> = {}): Promise<Request> => {
    this.newRequest = { ...this.queryParams, ...origin };
    this.newRequest.crop_year =
      this.newRequest.crop_year ?? this.$rootScope.user.settings.DEFAULT_VALUES.crop_year;
    this.newRequest.responsible = this.$rootScope.user.id;
    const promises: any = [];
    if (this.newRequest.contract_type === 'sale') {
      this.newRequest.basis =
        this.newRequest.basis ?? this.$rootScope.user.settings.DEFAULT_VALUES.basis_sale;
      this.newRequest.supplier = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
      if (this.initQueryParams?.client) {
        promises.push(
          this.ClientsService.Buyer.predictions(
            { client: this.initQueryParams.client },
            (data: any) => {
              this.newRequest.buyer = data.results.length && data.results[0].id;
            },
          ).$promise,
        );
      }
    } else {
      this.newRequest.basis =
        this.newRequest.basis ?? this.$rootScope.user.settings.DEFAULT_VALUES.basis_purchase;
      this.newRequest.buyer = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
      if (this.initQueryParams?.client) {
        promises.push(
          this.ClientsService.Supplier.predictions(
            { client: this.initQueryParams.client },
            (data: any) => {
              this.newRequest.supplier = data.results.length && data.results[0].id;
            },
          ).$promise,
        );
      }
    }

    promises.push(
      this.CoreService.getDefaultBuId(this.newRequest).then(
        (data: any) => (this.newRequest.business_unit = data),
      ),
    );

    promises.push(
      this.FinancesService.Currency.query(
        { search: this.$rootScope.user.settings.DEFAULT_CURRENCY },
        (data: any) => (this.newRequest.currency = data.results[0].id),
      ).$promise,
    );

    return Promise.all(promises).then(() => {
      this.newRequest = { ...this.queryParams, ...this.newRequest, ...origin };
      if (this.newRequest.deal_type === 'services') {
        this.newRequest.contract_type = 'purchase';
      }
      return this.newRequest as Request;
    });
  };

  updateData = (onlyUpdate = false, origin?: any) => {
    const resourceUrl = this.queryParams.serializer === 'table_info' ? 'queryInfo' : 'query';
    if (!onlyUpdate) {
      this.buildNewRequest(origin)
        .then((data) => (this.newRequestTemplate = data))
        .catch(this.GtUtils.errorClb);
    }
    this.GtUtils.overlay('show');
    const params = { ...this.queryParams };
    params.serializer = '';

    const resource =
      this.queryParams.stage === 'indicator'
        ? this.ContractsService.Indicator
        : this.ContractsService.Request;
    resource[resourceUrl](this.queryParams, (data: Paginated<Request>) => {
      if (this.queryParams.next) {
        this.requests = this.requests.concat(data.results);
        delete this.queryParams.next;
      } else {
        this.requests = data.results;
      }
      this.newRequest = { $_edit: true, ...this.newRequestTemplate, ...this.newRequest };
      if (this.quickAdd && this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
        this.requests.unshift(this.newRequest as Request);
      }

      this.onUpdate?.({ requests: this.requests });
      this.requestsCount = data.count;

      resource.totalInfo(this.queryParams, (data: number) => {
        this.total = data;
      });

      this.GtUtils.overlay('hide');
    });
  };

  approve(request: Request) {
    this.ContractsService.approveDeal(request).then(this.updateData);
  }

  cancel(request: Request) {
    this.ContractsService.cancelDeal(request).then(this.updateData);
  }

  openRequestModal(request: Request) {
    this.ContractsService.requestModal(request).then(this.updateData);
  }

  openRequestModalQuick(request: Request) {
    this.ContractsService.requestModalQuick(request).then(this.updateData);
  }

  openContractModal(contract: Request) {
    this.ContractsService.contractModal(contract).then(this.updateData);
  }

  toggleInlineAdd() {
    if (this.$rootScope.user.settings.ENABLE_INLINE_ADD) {
      if (this.quickAdd) {
        this.requests.shift();
      } else {
        this.newRequest = { $_edit: true, ...this.newRequestTemplate, ...this.newRequest };
        this.requests.unshift(this.newRequest as Request);
      }
      this.requests = [...this.requests];
    }
    this.quickAdd = !this.quickAdd;
  }

  createRequest(request: Request) {
    this.GtUtils.overlay('show');
    return this.ContractsService.Request.save(
      request,
      () => {
        notify(this.gettext(this.GtUtils.translate('Request created')));
        this.updateData(false, request);
        this.GtUtils.overlay('hide');
      },
      (error: Error) => {
        this.GtUtils.errorClb(error);
        this.GtUtils.overlay('hide');
      },
    );
  }

  saveRequest(request: Request) {
    this.GtUtils.overlay('show');
    return this.ContractsService.Request.update(request)
      .$promise.then(() => this.updateData(), this.GtUtils.errorClb)
      .finally(() => this.GtUtils.overlay('hide'));
  }

  deleteRequest(request: Request) {
    const msg = this.gettext('Are you sure that you want to delete?');
    if (!confirm(msg)) {
      return;
    }
    this.requests.splice(this.requests.indexOf(request), 1);
    this.updateData(false);
  }

  cloneRequest(request: Request) {
    this.GtUtils.overlay('show');
    return this.ContractsService.cloneRequest(request)
      .then(() => this.updateData(), this.GtUtils.errorClb)
      .finally(() => this.GtUtils.overlay('hide'));
  }

  createTicketFromIndicator(indicator: Request) {
    return this.ContractsService.createTicket(indicator.id);
  }

  refreshShowCheckboxCreateTicket() {
    this.requests.forEach((item: any) => {
      item.$_selected = false;
      if (item.unconnected_ticket_volume <= 0) {
        item.$_showCheckbox = false;
      } else {
        item.$_showCheckbox = this.creatingMultiTicket;
        item.$_inputedNumber = item.unconnected_ticket_volume;
      }
    });
  }

  startCreatingMultiTicket() {
    this.creatingMultiTicket = true;
    this.refreshShowCheckboxCreateTicket();
  }

  stopCreatingMultiTicket() {
    this.creatingMultiTicket = false;
  }

  createMultiTicket() {
    const selectedIndicators = this.requests.filter((indicator: any) => {
      return indicator.$_selected;
    });

    if (!selectedIndicators.length) {
      notify(this.gettext('Please select at least one object'), 'warning');
      return false;
    }

    this.GtUtils.overlay('show');
    const fieldsForClear: any = [
      'id',
      'conclusion_date',
      'number',
      'contract_number',
      'multicontract',
      'status',
      'approval_status',
      'stage',
    ];
    const newPositions: any = [];
    const firstIndicator = selectedIndicators[0];
    const indicatorsMap = selectedIndicators.reduce((map: any, indicator: any) => {
      map[indicator.id] = indicator;
      return map;
    }, {});

    this.ContractsService.Request.query(
      {
        id_list: selectedIndicators.map((indicator: any) => indicator.id),
        serializer: 'modal',
      },
      (data: any) => {
        data.results.forEach((item: any) => {
          item.from_request = item.id;
          item.stage = 'ticket';
          item.conclusion_date = new Date();
          item.request_status = 'new';
          item.responsible = this.$rootScope.user.id;
          item.volume = indicatorsMap[item.id].$_inputedNumber;

          newPositions.push(this.CoreUtils.cleanBeforeClone({ ...item }, fieldsForClear));
        });

        const multiTicket = {
          ...this.CoreUtils.cleanBeforeClone({ ...firstIndicator }, fieldsForClear),
          stage: 'ticket',
          use_type: 'commodity',
          positions: newPositions,
        };
        this.GtUtils.overlay('hide');

        return this.MulticontractService.multicontractModal(multiTicket, { ...multiTicket }).then(
          () => {
            this.stopCreatingMultiTicket();
            this.updateData();
          },
        );
      },
    );
  }
}

export const requestsContainerComponent: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    tableName: '<?',
    tableExpanded: '<?',
    total: '<?',
    onCreate: '&?',
    onUpdate: '&?',
    suppressNotifications: '<?',
    view: '<?',
    hideButton: '<?',
    simplifiedView: '<?',
  },
  template,
  controller: RequestsContainerController,
};
