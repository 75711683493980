import * as ToolbarPrimitive from '@radix-ui/react-toolbar';
import React from 'react';

import { cn } from '~/shared/lib/utils';

export const Toolbar = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <ToolbarPrimitive.Root
    ref={ref}
    className={cn(
      'border-stroke-main-medium bg-transparent-secondary !flex w-full min-w-max items-center gap-2 rounded-md border p-2 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.04),2px_4px_16px_0px_rgba(0,0,0,0.12)] backdrop-blur-[20px]',
      className,
    )}
    {...props}
  >
    {children}
  </ToolbarPrimitive.Root>
));
Toolbar.displayName = 'Toolbar';

export const ToolbarButton = ToolbarPrimitive.Button;

export const ToolbarSeparator = React.forwardRef<
  React.ElementRef<typeof ToolbarPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof ToolbarPrimitive.Separator>
>(({ className, children, ...props }, ref) => (
  <ToolbarPrimitive.Separator
    ref={ref}
    className={cn('bg-transparent-light h-full w-[1px]', className)}
    {...props}
  >
    {children}
  </ToolbarPrimitive.Separator>
));
ToolbarSeparator.displayName = 'ToolbarSeparator';

export const ToolbarLink = ToolbarPrimitive.Link;

export const ToolbarToggleGroup = ToolbarPrimitive.ToolbarToggleGroup;

export const ToolbarToggleItem = ToolbarPrimitive.ToolbarToggleItem;
