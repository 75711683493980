import ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';

  ng.module('finances.legacy').component('disbursementblTable', {
    bindings: {
      queryParams: '<',
      disbursementblList: '<',
      showConnected: '<?',
      filterLevel: '<?',
      tableName: '<?',
      onConnect: '<?',
      contractPaymentConditions: '<?',
      contractCounterpartyBusinessReference: '<?',
      totals: '<',
      count: '<',
      save: '&',
      saveAll: '&?',
      hideDocsCols: '<?',
      readonly: '<',
    },
    template: require('./disbursementbl-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$scope',
    '$timeout',
    '$rootScope',
    'GtUtils',
    'gettext',
    'FinancesService',
    'ReassignmentService',
    'DocumentsService',
  ];

  function Controller(
    this: any,
    $scope: ng.IScope,
    $timeout: ng.ITimeoutService,
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gettext: ng.gettext.gettextFunction,
    FinancesService: FinancesService,
    ReassignmentService: any,
    DocumentsService: any,
  ) {
    const vm = this;
    vm.updateData = updateData;
    vm.remove = remove;
    vm.clone = clone;
    vm.setEditAll = setEditAll;
    vm.openDisbursementBLModal = openDisbursementBLModal;
    vm.refreshCheckboxes = refreshCheckboxes;
    vm.selectAll = selectAll;
    vm.onCheck = updateNewFinance;
    vm.createInvoice = createInvoice;
    vm.createReassignmenting = createReassignmenting;
    vm.openDocxModal = openDocxModal;
    vm.quickEditMode = false;
    vm.tableData = {};
    vm.invoiceCondition = 'balance';
    vm.invoicePercent = 100;

    vm.$onInit = function () {
      vm.filterLevel = vm.filterLevel || 'disbursementbl-list-table-view';
      vm.tableName = vm.tableName || vm.filterLevel;
      vm.contractId = vm.queryParams.purchase_contract || vm.queryParams.sale_contract;
      vm.multiContractId =
        vm.queryParams.purchase_multicontract || vm.queryParams.sale_multicontract;
      vm.contractType = vm.queryParams.contract_type || 'purchase';
      vm.contractCargo = vm.queryParams.contract_cargo;
      if (vm.contractType == 'sale') {
        vm.clientroleFrom = vm.queryParams.supplier;
        vm.clientroleTo = vm.queryParams.buyer;
      } else {
        vm.clientroleFrom = vm.queryParams.buyer;
        vm.clientroleTo = vm.queryParams.supplier;
      }
      vm.amountSource =
        {
          not_use: 'amount_to_connect',
          dbl_currency: 'amount_dbl_currency_to_connect',
        }[$rootScope.user.settings.DBL_BALANCE_INVOICE_AMOUNT_CURRENCY] ?? 'amount_to_connect';
      vm.tableOptions = getTableOptions();
    };

    vm.$onChanges = function (changes: any) {
      if (changes.disbursementblList || changes.count || changes.totals) {
        vm.tableData = { rows: vm.disbursementblList, count: vm.count, total: vm.totals };
      }
    };

    function updateData(params: Record<string, string> = {}) {
      $scope.$emit('disbursementbl-list__update', params);
      vm.invoicing = false;
      vm.creatingReassignment = false;
      vm.selectAllChecked = false;
    }

    function remove(disbursementbl: any) {
      const msg = gettext('Are you sure that you want delete this disbursement bill of lading?');
      if (!confirm(msg)) {
        return;
      }
      $scope.$emit('disbursementbl-list__remove', disbursementbl);
    }

    function openDocxModal(dbl: any) {
      return DocumentsService.generateDocxModal('DisbursementBL', dbl.id);
    }

    function openDisbursementBLModal(disbursementbl: any) {
      disbursementbl = { ...vm.queryParams, ...(disbursementbl || {}) };
      const extraData = { contractId: vm.contractId, contractType: vm.contractType };

      const localCurrency = GtUtils.getLocalCurrency();
      if (vm.queryParams.contract_currency == localCurrency) {
        disbursementbl.price = vm.queryParams.price;
      }
      return FinancesService.disbursementblModal(disbursementbl, extraData).then(updateData);
    }

    function clone(instanceId: number) {
      return FinancesService.cloneDisbursementBL(instanceId).then(updateData);
    }

    function refreshCheckboxes() {
      const _showCheckbox = vm.invoicing || vm.creatingReassignment;
      vm.disbursementblList.forEach(function (item: any) {
        item._showCheckbox = _showCheckbox;
        if (!item._showCheckbox) {
          return;
        }
        if (item.financeoffset_id || item[vm.amountSource] <= 0) {
          item._showCheckbox = false;
        }
      });
    }

    function selectAll() {
      vm.disbursementblList.forEach(function (disbursementbl: any) {
        if (!disbursementbl._showCheckbox) {
          return;
        }
        $timeout(() => (disbursementbl._selected = vm.selectAllChecked));
      });
      updateNewFinance();
    }

    function updateNewFinance() {
      if (!vm.invoicing) {
        return;
      }
      const selectedDbls = vm.disbursementblList.filter((item: any) => item._selected);
      const invoiceType = vm.contractType == 'sale' ? 'outgoing' : 'incoming';
      const earliestDate = selectedDbls.reduce(
        (min: any, p: any) => (p.date < min ? p.date : min),
        vm.disbursementblList[0].date,
      );
      vm.newFinance = {
        invoice_type: invoiceType,
        amount: 0,
        volume: 0,
        cargo: vm.contractCargo,
        contract: vm.contractId,
        date: selectedDbls.length == 1 ? selectedDbls[0].date : earliestDate,
        currency:
          $rootScope.user.settings.CURRENCY_OBJECTS[$rootScope.user.settings.LOCAL_CURRENCY].id,
        currency_exchange: getOldestCurrencyExchangeFromDblList(selectedDbls),
        condition: vm.invoiceCondition,
        clientrole_from: vm.clientroleFrom,
        clientrole_to: vm.clientroleTo,
        payment_conditions: vm.invoicePercent,
        counterparty_business_reference: vm.contractCounterpartyBusinessReference,
        responsible: vm.queryParams.responsible,
        responsible_for_signing: vm.queryParams.responsible_for_signing,
      };
    }

    function getOldestCurrencyExchangeFromDblList(selectedDbls: any) {
      if (!selectedDbls.length) {
        return;
      }
      const oldestDbl = selectedDbls.reduce((prev: any, current: any) => {
        return new Date(prev.date) < new Date(current.date) ? prev : current;
      });
      return oldestDbl.currency_exchange;
    }

    function createInvoice() {
      const financePositions: any = [];
      if (!vm.invoiceCondition) {
        return;
      }
      const customRound = (val: any, signs: any) => Math.round(10 ** signs * val) / 10 ** signs;
      vm.disbursementblList
        .filter((item: any) => item._selected)
        .forEach(function (item: any) {
          const invoicePosition = {
            quantity: Number(
              ((item[vm.amountSource] / item.amount_local_with_vat) * item.volume).toFixed(6),
            ),
            quantity_total: item.volume,
            amount_correction: item.amount_correction,
            disbursement_bl_volume: item.volume,
            contract: item.dbl_type === 'purchase' ? item.purchase_contract : item.sale_contract,
            use: 'cargo',
            vat_option: item.contract_vat_option,
            vat_value: item.contract_vat_value,
            crop: item.cargo,
            _max_amount: customRound(item.quantity_total * item.price_per_piece, 2),
            _disbursement_bl_id: item.id,
            price_per_piece: item.price,
          };
          const volumePercent = vm.invoiceCondition === 'balance' ? 100 : vm.invoicePercent;
          if (vm.invoiceCondition === 'prepay') {
            invoicePosition.quantity = Number(
              customRound((item.volume * volumePercent) / 100, 6).toFixed(6),
            );
          }

          financePositions.push(invoicePosition);
        });
      const data = { financePositions: financePositions };
      FinancesService.financeModal({ ...vm.newFinance, condition: vm.invoiceCondition }, data).then(
        function (data: any) {
          if (!data || data === 'cancel') {
            return;
          }
          vm.newFinance = {};
          vm.invoicing = false;
          vm.updateData();
        },
      );
    }

    function createReassignmenting() {
      const reassignmentPositions: any = [];
      const reassignment: {
        owner?: number;
        contract?: number;
        reassignment_rate?: number;
      } = {};
      if (vm.queryParams.contract_type === 'sale') {
        reassignment.owner = vm.queryParams.supplier;
      } else {
        reassignment.owner = vm.queryParams.buyer;
      }
      reassignment.contract = vm.contractId;
      setReassignmentAverageRate(reassignment);

      const selectedDisbursmentBL = vm.disbursementblList.filter((item: any) => item._selected);
      selectedDisbursmentBL.forEach(function (item: any) {
        reassignmentPositions.push({
          number: item.number,
          date: item.date,
          disbursement_bl_amount: item.amount_local_with_vat,
          disbursement_bl_amount_without_vat: item.amount_local_without_vat,
          disbursementbl_contract_vat_option: item.contract_vat_option,
          disbursementbl_contract_vat_value: item.contract_vat_value,
          reassignment_amount: item.amount_to_connect,
          reassignment_position_balance: item.amount_local_with_vat,
          disbursement_bl: item.id,
        });
      });

      if (!reassignmentPositions.length) {
        notify(gettext('Please select at least one Disbursement Bill of Lading'), 'warning');
        return false;
      }

      setPositionBalance(reassignmentPositions).then(function () {
        const data = { reassignmentPositions: reassignmentPositions };
        ReassignmentService.reassignmentModal(reassignment, data).then(function (data: any) {
          if (!data || data === 'cancel') {
            return;
          }
          vm.creatingReassignment = false;
          vm.updateData();
        });
      });
    }

    function setReassignmentAverageRate(reassignment: any) {
      const params = vm.multiContractId
        ? { multicontract_id: vm.multiContractId }
        : { contract_id: vm.contractId };
      return ReassignmentService.getAverageRate(params)
        .then((data: any) => {
          reassignment.reassignment_rate = parseFloat(data.average_rate) || 0;
        })
        .catch(() => {
          notify(gettext('Failed to fetch reassignment rate'), 'error');
        });
    }

    function setPositionBalance(reassignmentPositions: any) {
      return FinancesService.DisbursementBL.reassignmentPositionBalance({
        disbursement_bl_ids: reassignmentPositions.map((item: any) => item.disbursement_bl),
      }).$promise.then((data: any) => {
        reassignmentPositionBalanceMapper(reassignmentPositions, data);
      });
    }

    function setEditAll(val: any) {
      vm.quickEditMode = val;
      vm.disbursementblList.forEach((i: any) => (i.edit = val));
      if (!val) {
        updateData();
      }
    }

    function reassignmentPositionBalanceMapper(reassignmentPositions: any, dbls: any) {
      dbls.forEach((dbl: any) => {
        reassignmentPositions.forEach((position: any) => {
          if (position.disbursement_bl === dbl.disbursementbl_id) {
            position.reassignment_position_balance = dbl.reassignment_position_balance;
          }
        });
      });
    }

    function getTableOptions() {
      const options: any = {
        tableName: vm.tableName,
        tableClass: 'request-table',
        filterLevel: vm.filterLevel,
        applyFilters: vm.applyFilters,
        templateArgs: {
          editAllMode: vm.quickEditMode,
          openDisbursementBLModal: (item: any) => openDisbursementBLModal(item),
          onConnect: (item: any) => vm.onConnect(item),
          onCheck: () => vm.onCheck(),
          selectAll: () => selectAll(),
          openDocxModal: (item: any) => openDocxModal(item),
          setEditAll: () => setEditAll(false),
          clone: (item: any) => clone(item),
          remove: (item: any) => remove(item),
          save: (item: any) => vm.save(item),
        },

        columnDefs: [] as any[],
        configurable: true,
      };

      options.columnDefs = [
        {
          columnName: 'name',
          title: gettext('name'),
          class: 'wrap-text',
          cellTemplate: html`
            <div style="display: flex; align-items: center;">
              <input
                type="checkbox"
                ng-model="item._selected"
                ng-if="item._showCheckbox"
                ng-change="args.onCheck()"
                style="margin: 0 10px 0 0; width: 18px; height: 18px;"
              />
              <a
                permission
                permission-only="'change_disbursementbl'"
                ng-click="args.openDisbursementBLModal(item)"
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 150px;"
                title="{[{ item.number }]}"
              >
                # {[{ item.number }]} <i class="fa fa-caret-down"></i>
              </a>
            </div>
            <span
              class="label label_danger pull-right tooltip"
              style="margin-left: 2px"
              ng-if="item.has_logistics"
              data-tip="{[{'has logistics' | translate}]}"
            >
              <i class="fa fa-truck"></i>
            </span>
          `,
          totalTemplate: html`<i class="fa fa-pie-chart"></i>`,
        },
        {
          columnName: 'Actions',
          title: gettext('Actions'),
          class: 'no-blur',
          cellTemplate: html`
            <a
              permission
              permission-only="'change_disbursementbl'"
              ng-click="item.edit = true"
              ng-if="!item.edit"
              class="btn btn-xs btn-blue-border tooltip"
              data-tip="{[{'inline edit' | translate}]}"
            >
              <i class="fa fa-pencil-square"></i>
            </a>
            <a
              ng-if="item.edit"
              class="btn btn-xs btn-success btn_success"
              ng-click="item.edit = false;args.save({item:item});args.setEditAll()"
              class="btn btn-xs btn-green-border tooltip"
              data-tip="{[{'save' | translate}]}"
            >
              <i class="fa fa-floppy-o"></i>
            </a>
            <a
              ng-if="item.edit"
              class="btn btn-xs tooltip"
              data-tip="{[{'close' | translate}]}"
              ng-click="item.edit = false;args.setEditAll()"
            >
              <i class="fa fa-times"></i>
            </a>

            <a
              permission
              permission-only="'change_disbursementbl'"
              ng-click="args.clone(item.id)"
              ng-if="!item.edit"
              class="btn btn-xs btn-green-border tooltip"
              data-tip="{[{'clone' | translate}]}"
            >
              <i class="fa fa-files-o"></i>
            </a>
            <a
              ng-click="args.openDocxModal(item)"
              class="btn btn-xs btn-green-border tooltip"
              data-tip="{[{'make document' | translate}]}"
              ng-if="!item.edit"
            >
              <i class="fa fa-file"></i>
            </a>
            <a
              permission
              permission-only="'delete_disbursementbl'"
              ng-click="args.remove(item)"
              class="btn btn-xs btn-dark-border tooltip"
              data-tip="{[{'delete' | translate}]}"
              ng-if="!item.edit"
            >
              <i class="fa fa-trash"></i>
            </a>
          `,
        },
        {
          columnName: 'Consignments',
          title: gettext('Consignments'),
          cellTemplate: html`
            <span ng-repeat="consignment_number in item.consignment_numbers track by $index">
              <i class="fa fa-file"></i> {[{ consignment_number }]}
              <div class="clearfix"></div>
            </span>
          `,
        },
        {
          columnName: 'status',
          title: gettext('Status'),
          cellTemplate: html`
            <span <select ng-if="!item.edit"
              class="label"
              ng-class="{'label_success': item.status == 'new',
            'label_default': item.status == 'done',
            'label_warning': item.status == 'process',
            'label_success': item.status == 'ready',
            'label_neutral': item.status == 'blocked'}"
            >
              {[{ item.status }]}
            </span>
            <select ng-if="item.edit"
            style="width: 71px !important;"
            ng-model="item.status"
            ng-class="{'label_default': item.status == 'new',
            'label_success': item.status == 'done',
            'label_warning': item.status == 'process',
            'label_calculate': item.status == 'ready',
            'label_neutral': item.status == 'blocked'}"
            >
                <option value="new">New</option>
                <option value="ready">Ready</option>
                <option value="process">Process</option>
                <option value="done">Done</option>
                <option value="blocked">Blocked</option>
            </select>
          `,
        },
        {
          columnName: 'sap_orders',
          title: gettext('Sap Orders'),
          cellTemplate: html`
            <span ng-repeat="sap_order_number in item.sap_order_numbers">
              {[{ sap_order_number }]}
              <div class="clearfix"></div>
            </span>
          `,
        },
        {
          columnName: 'Invoice',
          title: gettext('Invoice'),
          cellTemplate: html`
            <span ng-repeat="invoices in item.invoices_data">
              <a ui-sref="gt.page.payment({ id: invoices.invoice_id})" target="_blank">
                <i class="fa fa-credit-card"></i> {[{ invoices.invoice_number | cut:true:40:'...' ||
                "---" }]}
              </a>
            </span>
          `,
        },
        {
          columnName: 'Reassignment',
          title: gettext('Reassignment'),
          cellTemplate: html`
            <span ng-repeat="reassignment in item.reassignments">
              <span class="label">
                <a ui-sref="gt.reassignment({ id: reassignment.id })" target="_blank">
                  <i class="fa fa-reply-all"></i> {[{ reassignment.number | cut:true:40:'...' ||
                  "---" }]}
                </a>
              </span>
              <div class="clearfix"></div>
            </span>
          `,
        },

        {
          columnName: 'Offset',
          title: gettext('Offset'),
          class: 'wrap-text',
          cellTemplate: html`
            <span class="label">
              <a
                ng-repeat="offset_data in item.offsets_data"
                ui-sref="gt.offset({ id: offset_data.financeoffset_id })"
                target="_blank"
              >
                <i class="fa fa-magnet"></i> {[{ offset_data.financeoffset_number |
                cut:true:40:'...' || "---" }]}
              </a>
              <div class="clearfix"></div>
            </span>
          `,
        },

        {
          columnName: 'Date',
          title: gettext('Date'),
          class: 'td-left-align',
          cellTemplate: html`{[{ item.date | date:'dd.MM.yy' || "---"}]}`,
        },

        {
          columnName: 'Cargo',
          title: gettext('Cargo'),
          class: 'td-left-align',
          cellTemplate: html`
            <span ng-repeat="crop_data in item.logistic_crop_data">
              {[{ crop_data.title }]} - {[{ crop_data.crop_year }]}
              <div class="clearfix"></div>
            </span>
            <span ng-if="!item.logistic_crop_data.length"> {[{ item.cargo_title }]} </span>
          `,
        },
        {
          columnName: 'type_of_activities',
          title: gettext('type of activities'),
          class: 'td-left-align small-font-cell',
          cellTemplate: html`
            <span ng-repeat="activity in item.activity_list">
              <span> {[{ activity }]} </span>
              <div class="clearfix"></div>
            </span>
          `,
        },
        {
          columnName: 'Has_docs_copy',
          title: gettext('Has docs copy'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="!item.edit">
              <span ng-if="item.has_docs_copy"> <i class="fa fa-check-circle"></i> </span>
              <span ng-if="!item.has_docs_copy"> <i class="fa fa-circle-o"></i> </span>
            </div>
            <div ng-if="item.edit">
              <input ng-model="item.has_docs_copy" type="checkbox" />
            </div>
          `,
        },
        {
          columnName: 'Has_docs_original',
          title: gettext('Has docs original'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="!item.edit">
              <span ng-if="item.has_docs_original">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.has_docs_original"> <i class="fa fa-circle-o"></i> </span>
            </div>
            <div ng-if="item.edit">
              <input ng-model="item.has_docs_original" type="checkbox" />
            </div>
          `,
        },

        {
          columnName: 'is_tax_return_registered',
          title: gettext('is tax return registered'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="!item.edit">
              <span ng-if="item.is_tax_return_registered">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.is_tax_return_registered">
                <i class="fa fa-circle-o"></i>
              </span>
            </div>
            <div ng-if="item.edit">
              <input ng-model="item.is_tax_return_registered" type="checkbox" />
            </div>
          `,
        },

        {
          columnName: 'is_sap_registered',
          title: gettext('is sap registered'),
          class: 'td-left-align',
          cellTemplate: html`
            <div ng-if="!item.edit">
              <span ng-if="item.is_sap_registered">
                <i class="fa fa-check-circle"></i>
              </span>
              <span ng-if="!item.is_sap_registered"> <i class="fa fa-circle-o"></i> </span>
            </div>
            <div ng-if="item.edit">
              <input ng-model="item.is_sap_registered" type="checkbox" />
            </div>
          `,
        },
        {
          columnName: 'Supplier',
          title: gettext('Supplier'),
          class: 'td-left-align',
          cellTemplate: html`
            <span class="smaller-label">
              {[{ item.purchase_supplier_name || item.sale_buyer_name || '---' }]}
            </span>
          `,
        },
        {
          columnName: 'Multicontract',
          title: gettext('Multicontract No.'),
          class: 'td-left-align',
          cellTemplate: html`
            <a
              ui-sref="gt.multicontract({ id: item.purchase_contract_multicontract_id })"
              class="smaller-label"
              ng-if="item.purchase_contract_multicontract_number"
            >
              <i class="fa fa-file-text"></i> {[{ item.purchase_contract_multicontract_number }]}
            </a>
          `,
        },
        {
          columnName: 'Contract',
          title: gettext('Contract'),
          class: 'td-left-align',
          cellTemplate: html`
            <a
              ui-sref="gt.page.contract({ id: item.purchase_contract })"
              class="smaller-label"
              ng-if="item.purchase_contract"
            >
              <i class="fa fa-file-text"></i> {[{ item.purchase_contract_number || '---' }]}
            </a>
            <a
              ui-sref="gt.page.contract({ id: item.sale_contract })"
              class="smaller-label"
              ng-if="item.sale_contract"
            >
              <i class="fa fa-file-text"></i> {[{ item.sale_contract_number || '---' }]}
            </a>
          `,
        },
        {
          columnName: 'Volume',
          title: gettext('Volume'),
          class: 'td-right-align',
          cellTemplate: html`
            {[{ item.volume || 0 | number:3 }]}
            <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
          totalTemplate: html`
            {[{ item.volume_sum | number: 3}]}
            <span class="smaller-label">{[{ $root.user.settings.MAIN_MEASUREMENT_UNIT }]}</span>
          `,
        },
        {
          columnName: 'Price',
          title: gettext('Price'),
          class: 'td-right-align',
          cellTemplate: html`{[{ item.price || 0 | number:2}]}`,
        },

        {
          columnName: 'Rate',
          title: gettext('Rate'),
          class: 'td-right-align',
          cellTemplate: html`
            <span ng-if="item.rate"> {[{ item.rate }]} {[{ item.currency_symbol }]} </span>
          `,
          totalTemplate: html`
            {[{ item.average_rate | number: 4}]} {[{ $root.user.settings.LOCAL_CURRENCY }]}
          `,
        },
        {
          columnName: 'Price_without_vat',
          title: gettext('Price without Vat'),
          class: 'td-right-align',
          cellTemplate: html`
            <span ng-if="item.price_local_without_vat != item.price_local">
              {[{ item.price_local_without_vat || 0 | number:2 }]} {[{ item.currency_symbol }]}
            </span>
          `,
        },
        {
          columnName: 'Amount_local',
          title: gettext('Amount local'),
          class: 'td-right-align',
          cellTemplate: html`
            {[{ item.amount_local_with_vat || 0 | number:2 }]} {[{ item.currency_symbol }]}
          `,
          totalTemplate: html`
            {[{ item.amount_local_with_vat_sum | number: 2}]} {[{ $root.user.settings.LOCAL_CURRENCY
            }]}
          `,
        },
        {
          columnName: 'Amount_local_no_vat',
          title: gettext('Amount_local no vat'),
          class: 'td-right-align',
          cellTemplate: html`
            {[{ item.amount_local_without_vat || 0 | number:2 }]} {[{ item.currency_symbol }]}
          `,
          totalTemplate: html`
            {[{ item.amount_local_without_vat_sum | number: 2}]} {[{
            $root.user.settings.LOCAL_CURRENCY }]}
          `,
        },
        {
          columnName: 'amount_to_connect',
          title: gettext('amount to connect'),
          class: 'td-right-align',
          cellTemplate: html`{[{ item.amount_to_connect || 0 | number:2 }]}`,
          totalTemplate: html`{[{ item.amount_to_connect_total | number:2 }]}`,
        },
        {
          columnName: 'amount_dbl_currency_to_connect',
          title: gettext('amount (dbl currency) to connect'),
          class: 'td-right-align',
          cellTemplate: html`{[{ item.amount_dbl_currency_to_connect || 0 | number:2 }]}`,
          totalTemplate: html`{[{ item.amount_dbl_currency_to_connect_total | number:2 }]}`,
        },
        {
          columnName: 'contract_option_title',
          title: gettext('option'),
          class: 'td-right-align',
          cellTemplate: html`{[{ item.contract_option_title || '---' }]}`,
        },
        {
          columnName: 'Premium',
          title: gettext('Premium'),
          class: 'td-right-align',
          cellTemplate: html`
            {[{ item.premium_price || 0 | number:2 }]} {[{ item.currency_symbol }]}
          `,
        },
        {
          columnName: 'Note',
          title: gettext('Note'),
          class: 'wrap-text',
          cellTemplate: html`{[{ item.additional_info || '---'}]}`,
        },
      ];
      if (vm.showConnected) {
        options.columnDefs.push({
          columnName: 'Connect',
          title: gettext('Connect'),
          cellTemplate: html`
            <input
              class="form-control"
              type="checkbox"
              ng-model="item._toConnect"
              ng-change="args.onConnect(item)"
              style="width: 20px !important"
            />
          `,
        });
      }

      return options;
    }
  }
})();
