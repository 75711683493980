import { Root, Thumb } from '@radix-ui/react-switch';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '~/shared/lib/utils';

const switchVariants = cva('', {
  variants: {
    section: {
      company: 'company',
      crm: 'crm',
      deals: 'deals',
      execution: 'execution',
      finance: 'finance',
      reports: 'reports',
    },
    size: {
      small: 'h-4 w-6',
      medium: 'h-5 w-8',
    },
  },
  defaultVariants: {
    section: 'deals',
    size: 'medium',
  },
});

type SwitchVariants = VariantProps<typeof switchVariants>;

type Size = 'medium' | 'small';

const thumbSizes: Record<Size, string> = {
  medium: 'h-4 w-4 group-aria-checked:translate-x-3.5',
  small: 'h-3 w-3 group-aria-checked:translate-x-2.5',
};

const getThumbClassName = (size: Size) => thumbSizes[size];

export const Switch = React.forwardRef<
  React.ElementRef<typeof Root>,
  Omit<React.ComponentPropsWithoutRef<typeof Root>, 'className'> & SwitchVariants
>(({ section, size = 'medium', ...props }, ref) => {
  return (
    <Root
      className={cn(
        'group data-[state=checked]:bg-background-section-primary data-[state=unchecked]:bg-transparent-strong inline-flex cursor-pointer items-center rounded-full border-0 transition-colors disabled:cursor-not-allowed disabled:opacity-50',
        switchVariants({ section, size }),
      )}
      ref={ref}
      {...props}
    >
      <Thumb
        className={cn(
          'bg-background-main-primary pointer-events-none translate-x-0.5 rounded-full transition-transform',
          getThumbClassName(size!),
        )}
      />
    </Root>
  );
});

Switch.displayName = 'Switch';
