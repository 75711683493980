import type ng from 'angular';

import template from './invoice-position-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { html } from '^/shared/utils';

export const InvoicePositionContainer = {
  bindings: {
    filterLevel: '<',
    initQueryParams: '<',
  },
  template,
  controller: [
    '$scope',
    'InvoicePositionService',
    'gtFilterService',
    'GtUtils',
    'gettext',
    'CoreService',
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      $scope: ng.IScope;
      CoreService: CoreService;
      GtUtils: GtUtilsService;
      InvoicePositionService: any;
      filterLevel = 'invoice-position-container';
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      initQueryParams: QueryParams = {};
      queryParams: QueryParams = {};
      reportConfig: any;
      savedFilterChoices: any;
      savedReportConfigs: any;
      showAllCurrenciesTotals: any;
      tableData: any;
      tableOptions: any;
      total: any;
      selectMode: boolean;
      selectedPositions: any;
      positionDataList: any;
      constructor(
        $scope: ng.IScope,
        InvoicePositionService: any,
        gtFilterService: GtFilterService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
        CoreService: CoreService,
        $rootScope: GtRootScopeService,
      ) {
        this.$scope = $scope;
        this.InvoicePositionService = InvoicePositionService;
        this.gtFilterService = gtFilterService;
        this.CoreService = CoreService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;
        this.$rootScope = $rootScope;

        this.showAllCurrenciesTotals = false;
        this.total = {};
        this.tableData = { rows: [], count: 0 };
        this.reportConfig = null;
        this.selectMode = false;
        this.selectedPositions = [];
        this.positionDataList = [];
      }

      $onInit() {
        this.queryParams = { serializer: 'table_info', ...this.initQueryParams };
        this.tableOptions = this.getTableOptions();

        this.$scope.$on('gt-filter-updated_' + this.filterLevel, (ev, data: QueryParams) => {
          this.queryParams = data;
          this.updateData();
        });

        this.$rootScope.$on(
          `gt-report-config-selected_${this.filterLevel}`,
          (event: any, config: any) => {
            this.reportConfig = config ? config.column_params : null;
            this.tableOptions = this.getTableOptions();
          },
        );

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );

        this.$rootScope.$on(
          `gt-report-config-created_${this.filterLevel}`,
          (event: any, config: any) => {
            this.savedReportConfigs = [...this.savedReportConfigs, config];
          },
        );

        this.gtFilterService.setQueryParams(this.queryParams, this.filterLevel);
        this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
        this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
      }

      updateData() {
        this.GtUtils.overlay('show');
        this.toogleSelectMode(false);
        return this.InvoicePositionService.getInvoicePositionTableData(this.queryParams).then(
          (data: any) => {
            this.positionDataList = data.results;
            this.tableData = { rows: this.positionDataList, count: data.count };
            this.InvoicePositionService.getInvoicePositionTotalsData(this.queryParams).then(
              (data: any) => {
                this.total = data;
                this.GtUtils.overlay('hide');
              },
            );
          },
        );
      }

      onCheckboxClick() {
        this.selectedPositions = this.positionDataList.filter(
          (position: any) => position.$_selected,
        );
      }

      toogleSelectMode(flag: boolean) {
        this.selectMode = flag;
        this.tableOptions = this.getTableOptions();
      }

      applyFilters = (event: any) => {
        this.gtFilterService.updateQueryParams(event.params, this.filterLevel);
      };

      getTableOptions() {
        const options: any = {
          tableName: 'invoice-positions',
          tableClass: ['contract-charges-table sticky-table-first-td'],
          applyFilters: this.applyFilters,
          filterLevel: this.filterLevel,
          gtFilterService: this.gtFilterService,
          templateArgs: {
            onCheck: () => this.onCheckboxClick(),
          },
          columnDefs: [] as any,
          tabs: [],
          configurable: true,
          rowData: [],
          rowCount: 0,
          activeReportConfig: this.reportConfig,
          selection: this.selectMode,
          selectionCellTemplate: html`
            <input
              type="checkbox"
              ng-model="item.$_selected"
              ng-change="args.onCheck()"
              style="margin-right:5px; float: none"
            />
          `,
          allSelectedCallback: () => {
            this.onCheckboxClick();
          },
        };
        options.columnDefs = [
          {
            columnName: 'invoice_number',
            title: this.gettext('Invoice number'),
            class: 'gt-featured',
            cellTemplate: html`
              <span>
                <a
                  ui-sref="gt.page.payment({id: item.invoice_id})"
                  class="btn-link"
                  target="_blank"
                >
                  #{[{item.invoice_number | cut:true:30:' ...'}]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'invoice_type',
            title: this.gettext('Invoice type'),
            class: 'td-left-align',
            cellTemplate: html`
              <span
                class="label-strong bigger-label"
                ng-class="{'alert-success': item.invoice_type == 'outgoing',            'alert-danger': item.invoice_type == 'incoming' }"
              >
                {[{ item.invoice_type}]}
              </span>
            `,
          },
          {
            columnName: 'use',
            title: this.gettext('Use'),
            class: 'td-left-align',
          },
          {
            columnName: 'subuse',
            title: this.gettext('Subuse'),
            class: 'td-left-align',
          },
          {
            columnName: 'contract_number',
            title: this.gettext('Contract number'),
            class: 'td-left-align',
            filters: [
              {
                type: 'select',
                predicate: 'contract_deal_type',
                label: this.gettext('Contract deal type'),
                values: {
                  spot: this.gettext('spot'),
                  forward: this.gettext('forward'),
                  brokerage: this.gettext('brokerage'),
                  services: this.gettext('services'),
                  export: this.gettext('export'),
                  intermediate: this.gettext('intermediate'),
                },
              },
            ],
            cellTemplate: html`
              <span ng-if="item.contract_number">
                <a
                  ui-sref="gt.page.contract({id: item.contract_id })"
                  class="btn-link"
                  target="_blank"
                >
                  <i class="fa fa-file-text"></i> #{[{item.contract_number | cut:true:30:' ...'}]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'contract_payment_conditions',
            title: this.gettext('Payment conditions'),
            class: 'td-left-align small-font-cell',
            cellTemplate: html`
            <span class="smaller-label">
              <span ng-if="item.contract_payment_conditions_auto">
                <i class="fa fa-truck"></i> {[{ item.contract_payment_conditions_auto}]}%
              </span>
              <span ng-if="item.contract_payment_conditions">
                <i class="fa fa-train"></i> {[{ item.contract_payment_conditions}]}%
              </span>
            </span>
          </span>
          `,
          },
          {
            columnName: 'passport_title',
            title: this.gettext(this.$rootScope.user.settings.PASSPORT_TITLE),
            class: 'td-left-align',
            cellTemplate: html`
              <span ng-if="item.passport_title" class="btn-link">
                <a ui-sref="gt.page.passport({id: item.passport_id })">
                  <i class="fa fa-exchange"></i> #{[{item.passport_title | cut:true:30:' ...'}]}
                </a>
              </span>
            `,
          },
          {
            columnName: 'quantity',
            title: this.gettext('Quantity'),
            class: 'td-right-align nowrap highlighted-td',
            cellTemplate: ' <span>{[{ item.quantity | number:3 }]}</span> ',
          },
          {
            columnName: 'quantity_total',
            title: this.gettext('Quantity total'),
            class: 'td-right-align nowrap highlighted-td',
            cellTemplate: ' <span>{[{ item.quantity_total | number:3 }]}</span> ',
          },
          {
            columnName: 'executed_volume',
            title: this.gettext('Executed volume'),
            class: 'td-right-align nowrap highlighted-td',
            cellTemplate: ' <span>{[{ item.executed_volume | number:3 }]}</span> ',
          },
          {
            columnName: 'price_per_piece',
            title: this.gettext('Price per piece'),
            class: 'td-right-align nowrap highlighted-td',
            cellTemplate: ' <span>{[{ item.price_per_piece | number:2 }]}</span> ',
          },
          {
            columnName: 'amount',
            title: this.gettext('Amount'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="label-strong bigger-label"
                ng-class="{'alert-success': item.invoice_type == 'outgoing',            'alert-danger': item.invoice_type == 'incoming' }"
              >
                {[{ item.amount | number:2 }]} {[{ item.invoice_currency_symbol }]}</span
              >
            `,
          },
          {
            columnName: 'invoice_currencyexchange_rate',
            title: this.gettext('Exchange rate'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.invoice_currencyexchange_rate">
                {[{ item.invoice_currencyexchange_rate | number:2 }]}</span
              >
            `,
          },
          {
            columnName: 'amountUSD',
            title: this.gettext('Amount, $'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="label-strong bigger-label"
                ng-class="{'alert-success': item.invoice_type == 'outgoing',            'alert-danger': item.invoice_type == 'incoming' }"
              >
                {[{ item.amount_usd | number:2 }]}</span
              >
            `,
          },
          {
            columnName: 'quantity_total_amount',
            title: this.gettext('Quantity total amount'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="!item.quantity_total_amount || item.quantity_total_amount != 0">
                {[{ item.quantity_total_amount | number:2 }]} {[{ item.invoice_currency_symbol }]}
              </span>
            `,
          },
          {
            columnName: 'description',
            title: this.gettext('Description'),
            class: 'td-left-align',
          },
          {
            columnName: 'vat_value',
            title: this.gettext('VAT'),
            class: 'td-right-align',
            cellTemplate: ' <span ng-if="item.vat_option">{[{ item.vat_value }]}%</span> ',
          },
          {
            columnName: 'payment_sum',
            title: this.gettext('Payment'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="label-strong bigger-label"
                ng-class="{'alert-success': item.invoice_type == 'outgoing',            'alert-danger': item.invoice_type == 'incoming' }"
              >
                {[{ item.payment_sum | number:2 }]}</span
              >
            `,
          },
          {
            columnName: 'balance',
            title: this.gettext('Balance'),
            class: 'td-right-align',
            cellTemplate: html`
              <span
                class="label-strong bigger-label label label_warning"
                ng-if="item.amount - item.payment_sum != 0"
              >
                {[{ item.amount - item.payment_sum | number:2 }]}</span
              >
              <span
                class="label-strong bigger-label label label_success"
                ng-if="item.amount - item.payment_sum == 0"
              >
                <i class="fa fa-check"></i> <translate>Paid</translate></span
              >
            `,
          },
          {
            columnName: 'clientrole_from_name',
            title: this.gettext('Company from'),
            class: 'td-left-align highlighted-td',
          },
          {
            columnName: 'clientrole_to_name',
            title: this.gettext('Company to'),
            class: 'td-left-align highlighted-td',
          },
          {
            columnName: 'date',
            title: this.gettext('Date'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date">
                <i class="fa fa-calendar"></i> {[{ item.date || "---" | date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'terminal_name',
            title: this.gettext('Terminal'),
            class: 'td-right-align',
            cellTemplate: html`
              <span ng-if="item.terminal_name">
                <i class="fa fa-anchor"></i> {[{ item.terminal_name | cut:true:30:'...' }]}
              </span>
            `,
          },
          {
            columnName: 'date_of_certification',
            title: this.gettext('Date of certification'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date_of_certification">
                <i class="fa fa-calendar"></i> {[{ item.date_of_certification || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'date_of_receiving',
            title: this.gettext('Date of receiving'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date_of_receiving">
                <i class="fa fa-calendar"></i> {[{ item.date_of_receiving || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'date_of_execution',
            title: this.gettext('Date of execution'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date_of_execution">
                <i class="fa fa-calendar"></i> {[{ item.date_of_execution || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'date_of_execution_fact',
            title: this.gettext('Date of execution fact'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date_of_execution_fact">
                <i class="fa fa-calendar"></i> {[{ item.date_of_execution_fact || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'date_of_withdrawal',
            title: this.gettext('Date of withdrawal'),
            class: 'nowrap',
            cellTemplate: html`
              <span ng-if="item.date_of_withdrawal">
                <i class="fa fa-calendar"></i> {[{ item.date_of_withdrawal || "---" |
                date:'dd.MM.yy'}]}
              </span>
            `,
          },
          {
            columnName: 'bank_account_name',
            title: this.gettext('Bank account'),
            class: 'td-left-align',
          },
          {
            columnName: 'bu_title',
            title: this.gettext('Business unit'),
            class: 'td-left-align',
          },
        ];

        return options;
      }
    },
  ],
};
