import { BehaviorSubject, map, shareReplay, withLatestFrom } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { type EntityColumn, PageViewsStore } from '~/core/page-views';
import {
  SaleContractCreate,
  SaleContractList,
  SaleContractUpdate,
  SaleContractsListParams,
} from '~/features/deals/sale-contracts/lib';
import { SaleContractsModel } from '~/features/deals/sale-contracts/services/sale-contracts.model';
import {
  BaseContractsListParams,
  ContractSerializer,
  ViewMode,
  tableColumnsMap,
} from '~/features/deals/shared/contracts';
import { SaleContract } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

import { SaleContractsRepository } from './sale-contracts.repository';

@injectable()
export class SaleContractsListViewModel extends EntityListEditViewModel<
  SaleContract,
  SaleContractsListParams,
  SaleContractCreate,
  SaleContractList
> {
  resolveEntityRepo() {
    return container.resolve(SaleContractsRepository);
  }
  private readonly entityName = 'sale-contracts-page-view';
  private readonly purposeModel = 'salecontract';

  constructor(
    private readonly saleContractsRepo: SaleContractsRepository,
    private readonly saleContractsModel: SaleContractsModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  private readonly viewModeSubj = new BehaviorSubject<ViewMode>('table');

  readonly viewMode$ = this.viewModeSubj.asObservable();

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    withLatestFrom(this.pageParamsSubj),
    map(([allProps, pageParams]) => this.filterProperties(allProps, pageParams)),
    startWith([] as EntityColumn[]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.saleContractsRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public saleContractsCreated = async ({ entities }: { entities: SaleContractCreate[] }) => {
    await this.saleContractsModel.createSaleContracts(entities);
  };

  public saleContractDeleted = async (id: number) => {
    try {
      await this.saleContractsRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public saleContractsUpdated = async ({ entities }: { entities: SaleContractUpdate[] }) => {
    try {
      await this.saleContractsModel.updateSaleContracts(entities);
      notifySuccess('SaleContracts updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public getInitParams() {
    const serializer: ContractSerializer = 'table_info';
    return { page_size: 25, page: 1, serializer: serializer };
  }

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: SaleContract[]) => {
    await Promise.all(records.map(this.saleContractsRepo.update));
    this.pageParamsChanged({});
  };

  public createSaleContract = async (saleContract: SaleContract) => {
    try {
      await this.saleContractsRepo.create(saleContract);
      notifySuccess('Sale Contract created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  private filterProperties(props: EntityColumn[], pageParams: BaseContractsListParams) {
    const serializer = pageParams.serializer;
    const allowedCols = tableColumnsMap[serializer] ?? [];
    return props.filter((prop) => allowedCols.includes(prop.column_name));
  }
}
