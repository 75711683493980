import type { BusinessUnitRepository } from '~/features/common/business-units';
import type { ClientRoleRepository } from '~/features/common/client-role';
import type { CommodityRepository } from '~/features/common/commodity';
import type { CountryRepository } from '~/features/common/countries';
import type { CustomStatusRepository } from '~/features/common/custom-statuses';
import type { MeasurementRepository } from '~/features/common/measurements';
import type { BasisRepository } from '~/features/deals/basises';
import type { ExportContractCreate } from '~/features/deals/export-contracts';
import type { GeneralAgreementRepository } from '~/features/deals/general-agreements';
import type { IntermediateContractCreate } from '~/features/deals/intermediate-contracts';
import type { PurchaseContractCreate } from '~/features/deals/purchase-contracts';
import type { SaleContractCreate } from '~/features/deals/sale-contracts';
import type { ServicesContractCreate } from '~/features/deals/services-contracts';
import type { WarehouseRepository } from '~/features/execution/warehouses';

type Entity =
  | SaleContractCreate
  | PurchaseContractCreate
  | IntermediateContractCreate
  | ServicesContractCreate
  | ExportContractCreate;

type repo =
  | BusinessUnitRepository
  | ClientRoleRepository
  | CommodityRepository
  | CountryRepository
  | CustomStatusRepository
  | MeasurementRepository
  | BasisRepository
  | GeneralAgreementRepository
  | WarehouseRepository;

type sourceKey =
  | 'crop_title'
  | 'supplier_name'
  | 'buyer_name'
  | 'basis_name'
  | 'broker_name'
  | 'agent_name'
  | 'origin_of_crop_title'
  | 'warehouse_title'
  | 'destination_of_crop_title'
  | 'measurement_title'
  | 'business_unit_title'
  | 'generalagreement_number'
  | 'custom_status_title';

// this whole module is kostyl, will be fixed and deleted after autocomplete is implemented
async function assignEntityField(
  entity: Entity,
  sourceKey: sourceKey,
  queryKey: string,
  repo: repo,
  targetKey: string,
) {
  if (!entity[sourceKey]) {
    return;
  }

  const queryParam: Record<string, string> = { [queryKey]: entity[sourceKey] };
  const response = await repo.query(queryParam);

  let result;
  if ('results' in response) {
    result = response.results?.[0];
  } else if (Array.isArray(response)) {
    result = response[0];
  }

  if (result) {
    (entity as Record<string, unknown>)[targetKey] = result.id;
  }
}

export const populateContractEntities = async (
  entity: Entity,
  repositories: {
    commodityRepo: CommodityRepository;
    clientRoleRepo: ClientRoleRepository;
    warehouseRepo: WarehouseRepository;
    businessUnitRepo: BusinessUnitRepository;
    basisRepo: BasisRepository;
    countryRepo: CountryRepository;
    measurementRepo: MeasurementRepository;
    customStatusRepo: CustomStatusRepository;
    generalAgreementRepo: GeneralAgreementRepository;
  },
) => {
  await Promise.all([
    assignEntityField(entity, 'crop_title', 'title', repositories.commodityRepo, 'cargo'),
    assignEntityField(entity, 'supplier_name', 'name', repositories.clientRoleRepo, 'supplier'),
    assignEntityField(entity, 'buyer_name', 'name', repositories.clientRoleRepo, 'buyer'),
    assignEntityField(entity, 'basis_name', 'name', repositories.basisRepo, 'basis'),
    assignEntityField(entity, 'broker_name', 'name', repositories.clientRoleRepo, 'broker'),
    assignEntityField(entity, 'agent_name', 'name', repositories.clientRoleRepo, 'agent'),
    assignEntityField(
      entity,
      'origin_of_crop_title',
      'title',
      repositories.countryRepo,
      'origin_of_crop',
    ),
    assignEntityField(entity, 'warehouse_title', 'title', repositories.warehouseRepo, 'warehouse'),
    assignEntityField(
      entity,
      'destination_of_crop_title',
      'title',
      repositories.countryRepo,
      'destination_of_crop',
    ),
    assignEntityField(
      entity,
      'measurement_title',
      'title',
      repositories.measurementRepo,
      'measurement',
    ),
    assignEntityField(
      entity,
      'business_unit_title',
      'title',
      repositories.businessUnitRepo,
      'business_unit',
    ),
    assignEntityField(
      entity,
      'generalagreement_number',
      'number',
      repositories.generalAgreementRepo,
      'general_agreement',
    ),
    assignEntityField(
      entity,
      'custom_status_title',
      'title',
      repositories.customStatusRepo,
      'custom_status',
    ),
  ]);
};
