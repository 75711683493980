import React from 'react';

import type { RenderArgs } from '~/core/page-views';
import type { CellDataType, ColumnDef } from '~/shared/ui/components/data-grid';

import {
  CheckboxCellRead,
  DateCellRead,
  EmailCellRead,
  EntityCellRead,
  LabelCellRead,
  MultiselectCellRead,
  NumberCellRead,
  PersonCellRead,
  PhoneCellRead,
  SelectCellRead,
  TextFieldCellRead,
  UrlCellRead,
} from '../cell-table-adapters/';

export const DynamicReadCell = ({
  value,
  valueChanged,
  cellTemplateContext,
  dataType,
  render,
  id,
  typeExtra,
  row,
}: {
  id?: number;
  typeExtra?: string;
  dataType: CellDataType;
  value: unknown;
  valueChanged?: (value?: unknown) => void;
  cellTemplateContext?: ColumnDef<unknown>['cellTemplateContext'];
  render?: (args: RenderArgs) => JSX.Element;
  row: { id: number };
}) => {
  if (render) {
    return render({
      value,
      valueChanged: valueChanged as (value: unknown) => void,
      id,
      record: row,
    });
  }

  if (dataType === 'text') {
    return <TextFieldCellRead value={value} />;
  }

  if (dataType === 'number') {
    return <NumberCellRead value={value} />;
  }

  if (dataType === 'string') {
    return <TextFieldCellRead value={value} />;
  }

  if (dataType === 'url') {
    return <UrlCellRead value={value} />;
  }

  if (dataType === 'date') {
    return <DateCellRead value={value} />;
  }

  if (dataType === 'select') {
    return <SelectCellRead value={value} cellTemplateContext={cellTemplateContext} />;
  }

  if (dataType === 'entity') {
    return <EntityCellRead id={id} value={value} typeExtra={typeExtra} />;
  }

  if (dataType === 'email') {
    return <EmailCellRead value={value} />;
  }

  if (dataType === 'phone') {
    return <PhoneCellRead value={value} />;
  }

  if (dataType === 'checkbox') {
    return <CheckboxCellRead value={value} valueChanged={valueChanged} />;
  }

  if (dataType === 'label') {
    return <LabelCellRead value={value} />;
  }

  if (dataType === 'multiselect') {
    return <MultiselectCellRead value={value} cellTemplateContext={cellTemplateContext} />;
  }

  if (dataType === 'person') {
    return <PersonCellRead value={value} />;
  }
};
