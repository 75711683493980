import { BusinessUnitRepository } from '~/features/common/business-units';
import { CommodityRepository } from '~/features/common/commodity';
import { CounterpartiesRepository } from '~/features/crm/counterparties';
import { ContractsRepository } from '~/features/deals/contracts/services/';
import { VoyageRepository } from '~/features/execution/voyages';
import { WarehouseRepository } from '~/features/execution/warehouses';
import { injectable } from '~/shared/lib/di';

import { ConsignmentCreate, ConsignmentUpdate } from '../lib';
import { ConsignmentsRepository } from './consignments.repository';

@injectable()
export class ConsignmentsModel {
  constructor(
    private readonly consignmentsRepo: ConsignmentsRepository,
    private readonly commodityRepo: CommodityRepository,
    private readonly warehouseRepo: WarehouseRepository,
    private readonly counterpartiesRepo: CounterpartiesRepository,
    private readonly businessUnitRepo: BusinessUnitRepository,
    private readonly voyageRepo: VoyageRepository,
    private readonly contractRepo: ContractsRepository,
  ) {}

  createConsignments = async (entities: ConsignmentCreate[]): Promise<void> => {
    await Promise.all(
      // eslint-disable-next-line sonarjs/cognitive-complexity
      entities.map(async (entity) => {
        if (entity.commodity_title) {
          const commodity = (await this.commodityRepo.query({ title: entity.commodity_title }))
            .results[0];
          if (commodity) {
            entity.commodity = commodity.id;
          }
        }

        if (entity.warehouse_title) {
          const warehouse = (await this.warehouseRepo.query({ title: entity.warehouse_title }))
            .results[0];
          if (warehouse) {
            entity.warehouse = warehouse.id;
          }
        }

        if (entity.receiver_name) {
          const receiver = (await this.counterpartiesRepo.query({ name: entity.receiver_name }))
            .results[0];
          if (receiver) {
            entity.receiver = receiver.id;
          }
        }

        if (entity.producer_name) {
          const producer = (await this.counterpartiesRepo.query({ name: entity.producer_name }))
            .results[0];
          if (producer) {
            entity.producer = producer.id;
          }
        }

        if (entity.business_unit_title) {
          const businessUnit = (
            await this.businessUnitRepo.query({
              title: entity.business_unit_title,
            })
          )[0];
          if (businessUnit) {
            entity.business_unit = businessUnit.id;
          }
        }

        if (entity.voyage_name) {
          const voyage = (await this.voyageRepo.query({ voyage_name: entity.voyage_name }))
            .results[0];
          if (voyage) {
            entity.voyage = voyage.id;
          }
        }

        if (entity.contract_number) {
          const contract = (
            await this.contractRepo.query({ contract_number: entity.contract_number })
          ).results[0];
          if (contract) {
            entity.contract = contract.id;
          }
        }
      }),
    );

    await Promise.all(entities.map(async (entity) => await this.consignmentsRepo.create(entity)));
  };

  updateConsignments = async (entities: ConsignmentUpdate[]) => {
    await Promise.all(entities.map((entity) => this.consignmentsRepo.update(entity)));
  };
}
