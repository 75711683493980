import type ng from 'angular';
import type { Subscription } from 'rxjs';

import {
  type ConsignmentList,
  type ConsignmentListParams,
  ConsignmentsListViewModel,
} from '~/features/execution/consignments';
import type { Consignment, ConsignmentTotals } from '~/shared/api';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import template from './consignments-container.tpl.html?raw';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { DisbursementBlService } from '^/app/execution/components/transport/disbursementbls/disbursementbls.service';

type ViewMode = 'table' | 'spreadsheet';

export class ConsignmentsContainer implements ng.IController {
  viewModel: ConsignmentsListViewModel;
  pageData: { count: number; results: ConsignmentList[] } = { results: [], count: 0 };

  subs: Record<string, Subscription> = {};

  queryParams: ConsignmentListParams = { page_size: 25, page: 1 };
  initQueryParams: Partial<ConsignmentListParams> = {};
  filterLevel = 'consignments-container';
  consignments: ConsignmentList[] = [];
  consignmentsCount = 0;
  totals: Partial<ConsignmentTotals> = {};
  view: ViewMode = 'table';
  tableName?: string;
  showExportButton?: boolean;
  contract?: any;
  globalAddButton?: boolean;
  readonly?: boolean;

  static readonly $inject = [
    '$scope',
    'ContractsService',
    'gtFilterService',
    'gettext',
    'DisbursementBlService',
    'GtUtils',
  ];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly ContractsService: ContractsService,
    private readonly gtFilterService: GtFilterService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly DisbursementBlService: DisbursementBlService,
    private readonly GtUtils: GtUtilsService,
  ) {
    this.viewModel = container.resolve(ConsignmentsListViewModel);
  }

  $onInit() {
    this.tableName = this.tableName ?? this.filterLevel;
    this.showExportButton = this.showExportButton ?? false;
    this.contract = this.contract || {};

    this.queryParams = {
      page_size: this.initQueryParams.page_size ?? 25,
      page: this.initQueryParams.page ?? 1,
      ...this.initQueryParams,
    };
    this.viewModel.pageParamsChanged(this.queryParams);

    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      this.pageData = data;
      this.updateData();
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      this.GtUtils.overlay(loading ? 'show' : 'hide');
      this.$scope.$applyAsync();
    });

    this.subs.totals = this.viewModel.totals$.subscribe((totals) => {
      this.totals = totals;
      this.$scope.$applyAsync();
    });

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);
    this.$scope.$on('consignments-list__remove', (ev, data) => {
      this.remove(data);
    });
    this.$scope.$on('consignments-list__update', () => {
      this.updateData();
    });
    this.$scope.$on('dbl-updated', () => {
      this.updateData();
    });
    this.updateData();
  }

  ////////////////

  updateData() {
    this.$scope.$emit('consignments-container__updated', this.pageData);
    this.consignments = this.pageData.results;
    this.consignmentsCount = this.pageData.count;
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: ConsignmentListParams) => {
    this.queryParams = params;
    this.viewModel.pageParamsUpdated(this.queryParams);
  };

  changeView(view: ViewMode) {
    this.view = view;
  }

  remove(consignment: Consignment) {
    return this.ContractsService.Consignments.delete(
      {
        id: consignment.id,
      },
      () => {
        notify(this.gettext('Consignment removed'));
        return this.updateData();
      },
    ).$promise;
  }

  openConsignmentsModal() {
    const data = { ...this.initQueryParams };
    data.contract = this.initQueryParams.contract;
    data.warehouse = this.contract?.warehouse;

    return this.ContractsService.consignmentsModal(data).then(() => this.updateData());
  }

  createDisbursementBl(consignment: Consignment) {
    return this.DisbursementBlService.createDisbursementBL([consignment]).then(() => {
      this.$scope.$emit('dbl-updated');
    });
  }
}

export const consignmentsContainer: ng.IComponentOptions = {
  bindings: {
    initQueryParams: '<?',
    filterLevel: '<?',
    tableName: '<?',
    showExportButton: '<?',
    contract: '<?',
    globalAddButton: '<',
    readonly: '<',
  },
  template,
  controller: ConsignmentsContainer,
};
