import { coreBusinessUnitsList } from '~/shared/api';

import type { BusinessUnitListParams } from '../types';

export class BusinessUnitRepository {
  query = async (query: BusinessUnitListParams) => {
    const { data } = await coreBusinessUnitsList({ query });
    return data;
  };
}
