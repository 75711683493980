import type { Entity } from '~/shared/common';
import type { CellData, MatrixPrimitive, ObjectArrayPrimitiveType } from '~/shared/lib/spreadsheet';
import { isObjectsEqual } from '~/shared/lib/utils';

import { removeProtectedFields } from '../helpers';
import { fromSpreadsheetValue } from '../mappers';

const compareWithInitialData = <TRecord extends Entity, TRecordUpdate = TRecord>({
  data,
  initialData,
}: {
  data: TRecord[];
  initialData: TRecord[];
}): TRecordUpdate[] => {
  return data
    .map((updatedItem) => {
      const originalItem = initialData.find((record) => record.id === updatedItem.id);

      if (originalItem) {
        const differences: Partial<TRecord> = {};

        for (const key in updatedItem) {
          if (!isObjectsEqual(updatedItem[key], originalItem[key])) {
            differences[key as keyof TRecord] = updatedItem[key];
          }
        }

        return Object.keys(differences).length > 0
          ? ({ ...differences, id: updatedItem.id } as TRecordUpdate)
          : null;
      }

      return null;
    })
    .filter((item): item is TRecordUpdate => item !== null);
};

const updateResultBuild = <TRecord extends Entity>(
  data: MatrixPrimitive<CellData>,
): {
  result: Record<string, TRecord>;
} => {
  const result: Record<string, TRecord> = {};

  const mapRow = <TRecord extends Entity>({
    row,
    result,
  }: {
    row: ObjectArrayPrimitiveType;
    result: Record<string, TRecord>;
  }) => {
    for (const cellKey in row) {
      const cell = row[cellKey];
      if (cell.custom?.id) {
        const id = cell.custom.id as TRecord['id'];
        if (!result[id]) {
          result[id] = { id } as TRecord;
        }
        mapCell<TRecord>({ cell, resultItem: result[id] });
      }
    }
  };

  const mapCell = <TRecord extends Entity>({
    cell,
    resultItem,
  }: {
    cell: CellData;
    resultItem: TRecord;
  }) => {
    const { value } = fromSpreadsheetValue(cell);
    const key = cell.custom?.key as keyof TRecord;

    resultItem[key] = value as TRecord[keyof TRecord];
  };

  for (const rowKey in data) {
    mapRow<TRecord>({ row: data[rowKey], result });
  }

  return { result };
};

export const updatedDataBuilder = <TRecord extends Entity, TRecordUpdate = TRecord>({
  newData,
  initialData,
  allowedFields,
}: {
  newData: MatrixPrimitive<CellData>;
  initialData: TRecord[];
  allowedFields: Set<string>;
}) => {
  const { result } = updateResultBuild<TRecord>(newData);

  const mappedResults = Object.values(result);

  const updatedData = compareWithInitialData<TRecord, TRecordUpdate>({
    data: mappedResults,
    initialData,
  });

  return removeProtectedFields({
    data: updatedData,
    allowedFields,
  });
};
