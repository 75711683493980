import ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import { html } from '^/shared/utils';

(function () {
  'use strict';
  ng.module('reports.legacy').component('signingsContractsPeriod', {
    bindings: {
      initQueryParams: '<?',
      filterLevel: '<?',
      date: '<',
    },
    template: require('./signings-contracts-period.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils', 'ContractsService', 'gtFilterService'];

  function Controller(
    this: any,
    $scope: ng.IScope,
    GtUtils: GtUtilsService,
    ContractsService: ContractsService,
    gtFilterService: GtFilterService,
  ) {
    const vm = this;
    vm.path = '/static/app/reports/containers/signings-contracts-period/';
    vm.queryParams = {};
    vm.contracts = [];
    vm.contractsCount = 0;
    vm.gridOptions = {};
    vm.cellDraftTpl = html`
      <span class="smaller-label label-opacity">
        {[{ COL_FIELD.length }]} <translate>contracts</translate>
      </span>
      <div class="clearfix"></div>
      <span ng-repeat="contract in COL_FIELD">
        <a
          ui-sref="gt.page.contract({id: contract.id})"
          class="content-label"
          ng-if="contract.contract_number"
        >
          #{{ contract.contract_number }}
          <span class="smaller-label"> ({[{ contract.conclusion_date | date:"dd.MM.yy"}]}) </span>
        </a>
        <div class="clearfix"></div>
      </span>
    `;

    vm.$onInit = function () {
      vm.daysInMonth = GtUtils.daysInMonth(vm.date);
      vm.date = vm.date || new Date();
      vm.queryParams = {
        serializer: 'signings_report',
        report_date: formatDate(vm.date, 'dd.MM.yyyy'),
        page_size: 1000,
        ...vm.initQueryParams,
      };
      vm.filterLevel = vm.filterLevel || 'signings-contracts-period';

      gtFilterService.setQueryParams(vm.queryParams, vm.filterLevel);
      $scope.$on('gt-filter-updated_' + vm.filterLevel, function (ev: any, data: any) {
        vm.queryParams = data;
        updateContracts();
      });
      updateContracts();
    };

    vm.$onChanges = function (changes: any) {
      if (changes.date) {
        vm.daysInMonth = GtUtils.daysInMonth(vm.date);
        vm.queryParams.report_date = formatDate(vm.date, 'dd.MM.yyyy');
        gtFilterService.updateQueryParams(vm.queryParams, vm.filterLevel);
      }
    };

    ////////////////

    function updateContracts() {
      return ContractsService.Contract.query(vm.queryParams, function (data: any) {
        vm.contracts = data.results;
        vm.contractsCount = data.count;
        updateGridOptions();
      });
    }

    function updateGridOptions() {
      vm.totals = {
        draft: 0,
        we_signed: 0,
        both_signed: 0,
        all_signed: 0,
      };
      vm.gridOptions = {
        showColumnFooter: true,
        columnDefs: [
          {
            name: 'Date',
            field: 'date',
            cellTemplate: html`
              <div>
                <span class="date-label" ng-if="COL_FIELD">
                  <i class="fa fa-calendar"></i> {{ COL_FIELD | date: 'dd.MM.yy' }}
                </span>
                <span class="date-label" ng-if="!COL_FIELD">
                  <i class="fa fa-calendar"></i> -
                </span>
              </div>
            `,
            width: 80,
          },
          {
            name: 'Draft',
            field: 'signature_stage_draft',
            cellTemplate: vm.cellDraftTpl,
            footerCellTemplate: html`
              <div class="ui-grid-cell-contents">
                {[{ grid.appScope.vm.totals.draft | number:0}]}
              </div>
            `,
          },
          {
            name: 'BrokerSigned',
            field: 'signature_stage_broker_signed',
            cellTemplate: vm.cellDraftTpl,
            footerCellTemplate: html`
              <div class="ui-grid-cell-contents">
                {[{ grid.appScope.vm.totals.broker_signed | number:0}]}
              </div>
            `,
          },
          {
            name: 'SupplierSigned',
            field: 'signature_stage_we_signed',
            cellTemplate: vm.cellDraftTpl,
            footerCellTemplate: html`
              <div class="ui-grid-cell-contents">
                {[{ grid.appScope.vm.totals.we_signed | number:0}]}
              </div>
            `,
          },
          {
            name: 'BuyerSigned',
            field: 'signature_stage_both_signed',
            cellTemplate: vm.cellDraftTpl,
            footerCellTemplate: html`
              <div class="ui-grid-cell-contents">
                {[{ grid.appScope.vm.totals.both_signed | number:0}]}
              </div>
            `,
          },
          {
            name: 'All signed',
            field: 'signature_stage_all_signed',
            cellTemplate: vm.cellDraftTpl,
            footerCellTemplate: html`
              <div class="ui-grid-cell-contents">
                {[{ grid.appScope.vm.totals.all_signed | number:0}]}
              </div>
            `,
          },
        ],
        data: [],
      };
      for (let d = vm.daysInMonth; d > 0; d--) {
        const thisDay = new Date(vm.date.getFullYear(), vm.date.getMonth(), d),
          row: any = {
            date: thisDay,
            signature_stage_draft: [],
            signature_stage_broker_signed: [],
            signature_stage_we_signed: [],
            signature_stage_both_signed: [],
            signature_stage_all_signed: [],
          };
        let emptyRow = true;
        for (const contract of vm.contracts) {
          if (GtUtils.isSameDay(contract.signature_stage_draft, thisDay)) {
            row.signature_stage_draft.push(contract);
            emptyRow = false;
            vm.totals.draft++;
          }
          if (GtUtils.isSameDay(contract.signature_stage_broker_signed, thisDay)) {
            row.signature_stage_broker_signed.push(contract);
            emptyRow = false;
            vm.totals.draft++;
          }
          if (GtUtils.isSameDay(contract.signature_stage_we_signed, thisDay)) {
            row.signature_stage_we_signed.push(contract);
            emptyRow = false;
            vm.totals.we_signed++;
          }
          if (GtUtils.isSameDay(contract.signature_stage_both_signed, thisDay)) {
            row.signature_stage_both_signed.push(contract);
            emptyRow = false;
            vm.totals.both_signed++;
          }
          if (GtUtils.isSameDay(contract.signature_stage_all_signed, thisDay)) {
            row.signature_stage_all_signed.push(contract);
            emptyRow = false;
            vm.totals.all_signed++;
          }
        }
        if (!emptyRow) {
          vm.gridOptions.data.push(row);
        }
      }
    }
  }
})();
