import type { HeaderGroup as HeaderGroupDef } from '@tanstack/react-table';
import React from 'react';

import { cn } from '~/shared/lib/utils';

import { getStickyStyles } from '../helpers';
import type { ColumnDef, HeaderRenderDef } from '../lib/types';

export const HeaderGroup = <TRecord extends { id: number }>({
  headerGroup,
  HeaderRender,
  editing,
}: {
  headerGroup: HeaderGroupDef<TRecord>;
  HeaderRender: React.FC<HeaderRenderDef<TRecord>>;
  editing: boolean;
}) => {
  return (
    <div className="flex min-h-9 w-full">
      {headerGroup.headers.map((header) => {
        return (
          <div
            key={header.id}
            style={{
              width: header.getSize(),
              ...getStickyStyles({ column: header.column, isHeader: true }),
            }}
          >
            <div
              className={cn(
                'border-stroke-main-light !bg-background-main-primary flex h-full w-full items-center justify-between border-r border-b',
                (header.column.columnDef as ColumnDef<TRecord>).accessorKey ===
                  '_selectRowCheckbox' && 'border-r-0 pl-1',
              )}
            >
              <HeaderRender
                columnDef={header.column.columnDef as ColumnDef<TRecord>}
                mode={editing ? 'write' : 'read'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
