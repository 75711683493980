import type { Affiliated } from './types';

export const isAffiliated = (value: unknown): value is Affiliated => {
  return (
    value != null &&
    typeof value === 'object' &&
    'exporter_names' in value &&
    'supplier_names' in value &&
    Array.isArray(value.exporter_names) &&
    Array.isArray(value.supplier_names)
  );
};
