import React from 'react';

import type { EditableOption } from '~/shared/ui/components/data-grid';
import type { TagVariants } from '~/shared/ui/kit/tag';
import { Tag } from '~/shared/ui/kit/tag';

import type { DataTypeProps } from '../../lib';

export const SelectRead: React.FC<
  DataTypeProps<string> & { options: EditableOption<TagVariants>[] }
> = ({ disabled, value, options }) => {
  const valueContext = options?.find((option) => option.value === value);

  return value ? (
    <Tag disabled={disabled} {...valueContext?.props}>
      {value}
    </Tag>
  ) : null;
};
