import {
  FinanceRequest,
  coreApplyVatRetrieve,
  financesFinancesCreate,
  financesFinancesDestroy,
  financesFinancesListInfoList,
  financesFinancesPartialUpdate,
  financesFinancesRetrieve,
  financesFinancesTotalsInfoRetrieve,
} from '~/shared/api';
import { EntityRepository, Paginated } from '~/shared/common';
import { singleton } from '~/shared/lib/di';

import {
  ApplyVatParams,
  Finance,
  FinanceCreate,
  FinanceList,
  FinanceListParams,
  FinanceListTotals,
  FinanceUpdate,
} from '../lib';

@singleton()
export class FinanceRepository extends EntityRepository<
  Finance,
  FinanceListParams,
  FinanceCreate,
  FinanceList
> {
  search = (_input: string) => Promise.resolve([]);
  get = async (id: number): Promise<Finance> => {
    const { data } = await financesFinancesRetrieve({ path: { id } });
    return data;
  };

  query = async (query: FinanceListParams): Promise<Paginated<FinanceList>> => {
    const { data } = await financesFinancesListInfoList({ query });
    return data;
  };

  create = async (dto: FinanceCreate): Promise<Finance> => {
    const res = await financesFinancesCreate({
      body: dto as FinanceRequest,
    });

    return res.data;
  };

  update = async (entity: FinanceUpdate): Promise<Finance> => {
    const { data } = await financesFinancesPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };

  delete = async (id: number): Promise<void> => {
    await financesFinancesDestroy({ path: { id } });
  };

  applyVat = async (params: ApplyVatParams): Promise<number> => {
    const { data } = await coreApplyVatRetrieve({ query: params });
    return data.value;
  };

  getFinanceListTotals = async (query: FinanceListParams): Promise<FinanceListTotals> => {
    const { data } = await financesFinancesTotalsInfoRetrieve({ query });
    return data;
  };
}
