import type ng from 'angular';

import template from './offers-list-page.html?raw';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';

export const OffersListPage = {
  bindings: {
    initQueryParams: '<?',
  },
  template,
  controller: [
    '$rootScope',
    'MarketService',
    'PageService',
    'gettext',
    'gtFilterService',
    'CustomValuesService',
    class {
      $rootScope: GtRootScopeService;
      CustomValuesService: CustomValuesService;
      MarketService: any;
      PageService: PageService;
      filterLevel: string;
      gettext: ng.gettext.gettextFunction;
      gtFilterService: GtFilterService;
      indicatorsQueryParams: QueryParams = {};
      initQueryParams: QueryParams = {};
      purchasePlansFilterLevel: string;
      queryParams: QueryParams = {};
      constructor(
        $rootScope: GtRootScopeService,
        MarketService: any,
        PageService: PageService,
        gettext: ng.gettext.gettextFunction,
        gtFilterService: GtFilterService,
        CustomValuesService: CustomValuesService,
      ) {
        this.$rootScope = $rootScope;
        this.MarketService = MarketService;
        this.PageService = PageService;
        this.gettext = gettext;
        this.gtFilterService = gtFilterService;
        this.CustomValuesService = CustomValuesService;
        this.filterLevel = 'offers-list-page';
        this.queryParams = { ...this.initQueryParams };
        this.indicatorsQueryParams = {
          stage: 'indicator',
          contract_type: 'purchase',
          serializer: 'table_info',
          ordering: '-create_time',
          page_size: 25,
          ...(this.$rootScope.user.perms.includes('view_indicator')
            ? {}
            : { author: this.$rootScope.user.id }),
        };
        this.purchasePlansFilterLevel = 'purchase-plans-filter-level';
      }

      $onInit() {
        this.PageService.setConfig(this.getPageConfig());
      }

      getPageConfig() {
        const config: any = {
          class: 'page-header-main-prices-tab',
          buttons: [new this.PageService.buttons.Filters()],
          pages: this.MarketService.getPageConfig(),
        };

        config.filters = {
          filterLevel: this.filterLevel,
          clicked: false,
          dates: true,
          dateSelects: [
            {
              argument: 'create_time',
              placeholder: this.gettext('Create date'),
            },
            {
              argument: 'conclusion_date',
              placeholder: this.gettext('Conclusion date'),
            },
            {
              argument: 'date_of_execution',
              placeholder: this.gettext('Execution start date'),
            },
            {
              argument: 'end_of_execution',
              placeholder: this.gettext('Execution end date'),
            },
          ],
          selects: [
            {
              argument: 'client_author',
              placeholder: this.gettext('Client author'),
              resource: 'auth.user',
            },
            {
              argument: 'responsible',
              placeholder: this.gettext('Responsible'),
              resource: 'auth.user',
            },
            {
              argument: 'measurement',
              placeholder: this.gettext('Measurement'),
              resource: 'stockexchanges.Measurement',
            },
          ],
          nestedSelects: [],
          multiSelects: [
            {
              argument: 'buyer_list',
              placeholder: this.gettext('buyer'),
              resource: 'clients.buyer',
            },
            {
              argument: 'supplier_list',
              placeholder: this.gettext('supplier'),
              resource: 'clients.supplier',
            },
            {
              argument: 'port_list',
              placeholder: this.gettext('port'),
              resource: 'logistics.port',
            },
            {
              argument: 'destination_of_crop_list',
              placeholder: this.gettext('destination country'),
              resource: 'location.country',
            },
            {
              argument: 'business_unit_list',
              placeholder: this.gettext('business unit'),
              resource: 'core.BusinessUnit',
            },
            {
              argument: 'ticket_multicontract_list',
              placeholder: this.gettext('multiticket'),
              resource: 'contracts.multicontract',
              related_fk: 'contractbase__multicontract',
              related_model: 'contracts.Request',
            },
            {
              argument: 'ticket_list',
              placeholder: this.gettext('ticket'),
              resource: 'contracts.Request',
              related_fk: 'contractbase',
              related_model: 'contracts.Request',
            },
            {
              argument: 'ticket_contract_list',
              placeholder: this.gettext('contract'),
              resource: 'contracts.ContractBase',
              queryParams: { stage: 'contract' },
              related_fk: 'contractbase__request__contractbase',
              related_model: 'contracts.Request',
            },
            {
              argument: 'ticket_contract_multicontract_list',
              placeholder: this.gettext('multicontract'),
              resource: 'contracts.Multicontract',
              queryParams: { stage: 'contract' },
              related_fk: 'contractbase__request__contractbase__multicontract',
              related_model: 'contracts.Request',
            },
            {
              argument: 'season_list',
              placeholder: this.gettext('Season'),
              resource: 'core.Season',
            },
            {
              argument: 'warehouse_with_m2m_list',
              placeholder: this.gettext('Warehouse'),
              resource: 'logistics.warehouse',
            },
          ],
          nestedMultiSelects: [
            this.gtFilterService.getCropsFilter('cargo_list'),
            this.gtFilterService.getRequestStatusFilter('request_status_list'),
          ],
        };

        this.CustomValuesService.getCustomFieldFilters({
          purpose_model: 'indicator',
        }).then((customFieldsFilters: any) => {
          config.filters.multiSelects.push(...customFieldsFilters.multiSelects);
          config.filters.selects.push(...customFieldsFilters.selects);
          config.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
          config.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
          config.filters.dateSelects.push(...customFieldsFilters.dateSelects);
        });

        return config;
      }
    },
  ],
};
