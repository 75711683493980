import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class ShipmentScheduleService {
  $filter: ng.IFilterService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  $uibModal: ng.ui.bootstrap.IModalService;
  ShipmentSchedulePositionDayResource: any;
  ShipmentSchedulePositionResource: any;
  ShipmentScheduleResource: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    gettext: ng.gettext.gettextFunction,
    $filter: ng.IFilterService,
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $uibModal: ng.ui.bootstrap.IModalService,
  ) {
    this.gettext = gettext;
    this.$filter = $filter;
    this.$resource = $resource;
    this.$uibModal = $uibModal;
    this.$q = $q;
    this.ShipmentScheduleResource = $resource(
      '/api/logistics/shipment-schedule/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/shipment-schedule/',
        },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/shipment-schedule-list/info/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        saveDays: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/shipment-schedule/save_days/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/shipment-schedule/bulk_create_or_update/',
        },
      },
    );
    this.ShipmentSchedulePositionResource = $resource(
      '/api/logistics/shipment-schedule-position/:id/',
      {
        id: '@id',
      },
    );
    this.ShipmentSchedulePositionDayResource = $resource(
      '/api/logistics/shipment-schedule-position-day/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
          url: '/api/logistics/shipment-schedule/',
        },
        update: { method: 'PATCH' },
        save: { method: 'POST' },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/logistics/shipment-schedule-position-day/bulk_create_or_update/',
        },
      },
    );
  }

  shipmentScheduleModal(item: any, data: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<shipment-schedule-modal
        item="item"
        data="data"
        modal-instance="$uibModalInstance"
      >
      </shipment-schedule-modal>`,
      controller: [
        '$scope',
        'item',
        'data',
        '$uibModalInstance',
        (
          $scope: ng.IScope,
          item: any,
          data: any,
          $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
        ) => {
          ($scope as any).item = item;
          ($scope as any).data = data;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      size: 'sm',
      resolve: {
        item: () => item,
        data: () => data,
      },
    }).result;
  }

  save(item: any) {
    if (item.id) {
      return this.ShipmentScheduleResource.update(item).$promise;
    }
    return this.ShipmentScheduleResource.save(item).$promise;
  }

  bulkSave(item: any) {
    return this.ShipmentScheduleResource.bulkCreateOrUpdate({
      item: item,
      positions: item.positions,
    }).$promise;
  }

  saveDays(item: any) {
    return this.ShipmentSchedulePositionDayResource.bulkCreateOrUpdate(item.days_set).$promise;
  }

  getTableData(params: object) {
    return this.ShipmentScheduleResource.list(params, (data: any) => {
      if (!data.roles_set?.length) {
        return [];
      }

      return data.roles_set.forEach((role: any) => {
        role.contracts_set.forEach((contract: any) => {
          contract.plans_set.forEach((plan: any) => {
            plan.positions_set.forEach((position: any) => {
              position.days_map_set = {};
              position?.days_set?.forEach((day: any) => {
                day.key = formatDate(day.plan_date, 'yyyy-MM-dd');
                position.days_map_set[day.key] = day;
              });
            });
          });
        });
      });
    }).$promise;
  }

  getDateRange(start: any, end: any) {
    const dateRange: any = [];
    while (start <= end) {
      dateRange.push({
        obj: start,
        key: formatDate(start, 'yyyy-MM-dd'),
        title: formatDate(start, 'dd.MM'),
      });
      const newDate = start.setDate(start.getDate() + 1);
      start = new Date(newDate);
    }
    return dateRange;
  }

  getCurrencyTotalsData(params: object) {
    return this.ShipmentScheduleResource.total(params).$promise;
  }

  getModalData(params: object) {
    return this.ShipmentScheduleResource.get(params).$promise;
  }

  getNumber(params: object) {
    return this.ShipmentScheduleResource.generateNumber(params).$promise;
  }

  delete(params: object) {
    return this.ShipmentScheduleResource.delete(params).$promise;
  }

  deletePosition(params: object) {
    return this.ShipmentSchedulePositionResource.delete(params).$promise;
  }
}
ShipmentScheduleService.$inject = ['gettext', '$filter', '$resource', '$q', '$uibModal'];
