import {
  ExportContractCreate,
  ExportContractList,
  ExportContractUpdate,
  ExportContractsListParams,
} from '~/features/deals/export-contracts';
import {
  type ContractsExportContractsListInfoListData,
  ExportContract,
  contractsExportContractsCreate,
  contractsExportContractsDestroy,
  contractsExportContractsListInfoList,
  contractsExportContractsPartialUpdate,
  contractsExportContractsRetrieve,
  contractsExportContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class ExportContractsRepository extends EntityRepository<
  ExportContract,
  ExportContractsListParams,
  ExportContractCreate,
  ExportContractList
> {
  get = async (id: number) => {
    const { data } = await contractsExportContractsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: ContractsExportContractsListInfoListData['query']) => {
    const { data } = await contractsExportContractsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: ExportContractCreate) => {
    const res = await contractsExportContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: ExportContractUpdate) => {
    const { data } = await contractsExportContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsExportContractsDestroy({ path: { id } });
  };

  getTotals = async (query: ExportContractsListParams) => {
    const { data } = await contractsExportContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
