import { accountsApprovalConfigsRetrieve, accountsApprovalsList } from '~/shared/api';
import { singleton } from '~/shared/lib/di';

import { ApprovalsListParams, ApprovalsPageList } from '../lib/types';

@singleton()
export class AccountsRepository {
  getApprovalConfig = async (id: number) => {
    const { data } = await accountsApprovalConfigsRetrieve({ path: { id } });
    return data;
  };

  queryApprovals = async (params: ApprovalsListParams): Promise<ApprovalsPageList> => {
    const { data } = await accountsApprovalsList({ query: params });
    return data;
  };
}
