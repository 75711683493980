import { type TableOptions, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { allRecordsSelected } from '~/core/page-views/helpers';
import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { SelectRowCheckbox } from '~/shared/ui/components/select-row-checkbox';
import { MinusIcon } from '~/shared/ui/icons';
import { Checkbox } from '~/shared/ui/kit/checkbox';

type UseTableViewProps<T> = Omit<TableOptions<T>, 'getCoreRowModel' | 'columns'> & {
  isRowsSelectable?: boolean;
  selectedRowIds?: number[];
  recordSelected: (ids: number[]) => void;
  columns: ColumnDef<T>[];
};

const getColumnVisibility = <T,>(columns: ColumnDef<T>[]) => {
  return columns.reduce<Record<keyof T | `_${string}`, boolean>>(
    (visibility, column) => {
      if (column.hidden) {
        visibility[column.accessorKey] = false;
      }
      return visibility;
    },
    {} as Record<keyof T | `_${string}`, boolean>,
  );
};

const isSelectedRowCheckbox = (
  value: unknown,
): value is {
  selectedRowIds: number[];
  rowId: number;
} => {
  const typedValue = value as {
    selectedRowIds: number[];
    rowId: number;
  };

  return (
    typeof value === 'object' &&
    value !== null &&
    'selectedRowIds' in value &&
    Array.isArray(typedValue.selectedRowIds) &&
    typedValue.selectedRowIds.every((id) => typeof id === 'number') &&
    'rowId' in value &&
    typeof typedValue.rowId === 'number'
  );
};

export const useTableView = <T extends { id: number }>({
  data,
  columns,
  isRowsSelectable = false,
  selectedRowIds = [],
  recordSelected,
  ...rest
}: UseTableViewProps<T>) => {
  const selectableTableData = React.useMemo(() => {
    return data.map((row) => ({
      ...row,
      _selectRowCheckbox: { rowId: row.id, selectedRowIds: selectedRowIds },
    }));
  }, [data, selectedRowIds]);

  return useReactTable<T>({
    data: isRowsSelectable ? selectableTableData : data,
    columns: isRowsSelectable
      ? [
          {
            id: '_selectRowCheckbox',
            accessorKey: '_selectRowCheckbox',
            header: 'Select',
            headerTemplate: () => (
              <Checkbox
                size="small"
                checked={selectedRowIds?.length !== 0}
                onClick={() =>
                  allRecordsSelected({
                    data,
                    selectedRecordIds: selectedRowIds,
                    onSelect: recordSelected,
                  })
                }
                icon={
                  selectedRowIds?.length !== data.length && (
                    <MinusIcon
                      size={16}
                      strokeWidth={2}
                      className="text-background-main-primary pt-1"
                    />
                  )
                }
              />
            ),
            dataType: 'template',
            editable: false,
            draggable: false,
            enableResizing: false,
            size: 24,
            sticky: columns.length && columns[0].sticky,
            render: ({ value }) => {
              if (isSelectedRowCheckbox(value)) {
                return <SelectRowCheckbox value={value} valueChanged={recordSelected} />;
              }

              return <div />;
            },
          } as ColumnDef<T, unknown>,
          ...columns,
        ]
      : columns,
    state: {
      columnVisibility: getColumnVisibility(columns),
    },
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel(),
    ...rest,
  });
};
