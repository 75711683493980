import ng from 'angular';

import type { ApplyVatParams } from '~/features/finances/invoices';
import { FinanceRepository } from '~/features/finances/invoices';
import { container } from '~/shared/lib/di';

import { type CoreUtils } from '^/app/core/core.utils';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

export class FinancesService {
  readonly Finance: any;
  readonly Payment: any;
  readonly PaymentPlan: any;
  readonly CurrencyExchange: any;
  readonly PaymentCondition: any;
  readonly BalancePayment: any;
  readonly Charge: any;
  readonly PaymentInfo: any;
  readonly BankAccount: any;
  readonly GeneralExpenses: any;
  readonly OtherActivities: any;
  readonly Currency: any;
  readonly BudgetItem: any;
  readonly Budget: any;
  readonly BankOperation: any;
  readonly CreditNote: any;
  readonly FinanceAccount: any;
  readonly InvoicePosition: any;
  readonly CreditNotePosition: any;
  readonly FinanceTransaction: any;
  readonly PassportFinancePosition: any;
  readonly DisbursementBL: any;
  readonly DisbursementBLConsignment: any;
  readonly FinancePurpose: any;
  readonly FinanceBank: any;
  readonly BankBranch: any;
  readonly InvoicePositionBillOfLading: any;
  readonly BankAccountRests: any;

  financesRepo;

  static readonly $inject = ['$rootScope', '$resource', '$uibModal', 'gettext', 'CoreUtils'];

  constructor(
    private readonly $rootScope: GtRootScopeService,
    private readonly $resource: ng.resource.IResourceService,
    private readonly $uibModal: ng.ui.bootstrap.IModalService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly CoreUtils: CoreUtils,
  ) {
    this.financesRepo = container.resolve(FinanceRepository);

    this.Finance = this.$resource(
      '/api/finances/finances/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finances/predictions/',
        },
        totalsInfo: {
          method: 'GET',
          url: '/api/finances/finances-totals/info/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finances/export_column_names/',
        },
        tabInfo: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finances-list/info/',
        },
        generateNumber: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finances/generate_number/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/finances/bulk_create_or_update/',
        },
        bulkCreateOrUpdatePositions: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/finances/bulk_create_or_update_positions/',
        },
        prepareConsolidatedServiceInvoice: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/finances/prepare-consolidated-service-invoice/',
        },
      },
    );
    this.Payment = this.$resource(
      '/api/finances/payments/:id/',
      {
        id: '@id',
      },
      {
        query: {
          method: 'GET',
          isArray: false,
        },
        update: {
          method: 'PATCH',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payments/predictions/',
        },
        tableTotal: {
          method: 'GET',
          url: '/api/finances/payments/table_total/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payments/export_column_names/',
        },
      },
    );
    this.PaymentPlan = this.$resource(
      '/api/finances/payment-plans/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        createPayments: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/payment-plans/:id/create_payments/',
        },
        list: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-plans-list/',
        },
        getPaymentOrders: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-plans/:id/get_payment_orders/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-plans/predictions/',
        },
      },
    );
    this.CurrencyExchange = this.$resource(
      '/api/finances/currency-exchanges/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/currency-exchanges/predictions/',
        },
      },
    );
    this.PaymentCondition = this.$resource(
      '/api/finances/payment-conditions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-conditions/predictions/',
        },
      },
    );
    this.BalancePayment = this.$resource(
      '/api/finances/balance-payment/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/balance-payment/predictions/',
        },
      },
    );
    this.Charge = this.$resource(
      '/api/finances/charges/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/charges/predictions/',
        },
      },
    );
    this.PaymentInfo = this.$resource(
      '/api/finances/payment-infos/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/payment-infos/predictions/',
        },
      },
    );
    this.BankAccount = this.$resource(
      '/api/finances/bank-accounts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-accounts/predictions/',
        },
        turnoverTotal: {
          method: 'GET',
          url: '/api/finances/bank-accounts/turnover_total/',
        },
        getBankAccountsBalance: {
          method: 'GET',
          isArray: true,
          url: '/api/finances/bank-accounts/get_bank_accounts_balance/',
        },
      },
    );
    this.GeneralExpenses = this.$resource(
      '/api/finances/general-expenses/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false, cache: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/general-expenses/predictions/',
        },
      },
    );

    this.OtherActivities = this.$resource(
      '/api/finances/other-activities/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false, cache: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/other-activities/predictions/',
        },
      },
    );

    this.Currency = this.$resource(
      '/api/finances/currencies/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false, cache: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/currencies/predictions/',
        },
      },
    );

    this.BudgetItem = this.$resource(
      '/api/finances/budget-items/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/budget-items/predictions/',
        },
      },
    );

    this.Budget = this.$resource(
      '/api/finances/budgets/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: true },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/budgets/predictions/',
        },
      },
    );

    this.BankOperation = this.$resource(
      '/api/finances/bank-operations/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-operations/predictions/',
        },
        bankBalance: {
          method: 'GET',
          url: '/api/finances/bank-operations/bank_balance/',
        },
        reportList: {
          method: 'GET',
          url: '/api/reports/bank-operations/',
        },
      },
    );

    this.CreditNote = this.$resource(
      '/api/finances/credit-notes/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/credit-notes/predictions/',
        },
        cloneToInvoice: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/credit-notes/:id/clone_to_invoice/',
        },
      },
    );

    this.FinanceAccount = this.$resource(
      '/api/finances/finance-accounts/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finance-accounts/predictions/',
        },
      },
    );

    this.InvoicePosition = this.$resource(
      '/api/finances/invoice-positions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/invoice-positions/predictions/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/invoice-positions/export_column_names/',
        },
      },
    );

    this.CreditNotePosition = this.$resource(
      '/api/finances/creditnote-positions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/creditnote-positions/predictions/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/creditnote-positions/bulk_create_or_update/',
        },
      },
    );

    this.FinanceTransaction = this.$resource(
      '/api/finances/finance-transactions/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finance-transactions/predictions/',
        },
        tabInfo: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finance-transactions-list/',
        },
      },
    );

    this.PassportFinancePosition = this.$resource(
      '/api/finances/passport-finance-position/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/passport-finance-position/predictions/',
        },
        tableTotal: {
          method: 'GET',
          url: '/api/finances/passport-finance-position/table_total/',
        },
      },
    );

    this.DisbursementBL = this.$resource(
      '/api/finances/disbursementbl/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH', isArray: false },
        info: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursement-bill-of-lading-list/info/',
        },
        totals: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursement-bill-of-lading-totals/',
        },
        exportColumnNames: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursementbl/export_column_names/',
        },
        reassignmentPositionBalance: {
          method: 'GET',
          isArray: true,
          url: '/api/finances/disbursementbl/reassignment_position_balance/',
        },
        getDisbursementblLocalPrice: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursementbl/get_disbursementbl_local_price/',
        },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/disbursementbl/predictions/',
        },
        bulkCreateOrUpdate: {
          method: 'POST',
          url: '/api/finances/disbursementbl/bulk_create_or_update/',
        },
      },
    );

    this.DisbursementBLConsignment = this.$resource(
      '/api/finances/disbursementbl-consignment/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        checkVolumeAvailability: {
          method: 'GET',
          isArray: false,
          params: {
            consignment: '@consignment',
            volume_connected: '@volume_connected',
            id: '@id',
          },
          url: '/api/finances/disbursementbl-consignment/check_volume_availability/?consignment=:consignment&volume=:volume_connected&id=:id',
        },
      },
    );

    this.FinancePurpose = this.$resource(
      '/api/finances/finance-purposes/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finance-purposes/predictions/',
        },
      },
    );

    this.FinanceBank = this.$resource(
      '/api/finances/finance-banks/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/finance-banks/predictions/',
        },
      },
    );

    this.BankBranch = this.$resource(
      '/api/finances/bank-branches/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        predictions: {
          method: 'GET',
          isArray: false,
          url: '/api/finances/bank-branches/predictions/',
        },
      },
    );

    this.InvoicePositionBillOfLading = this.$resource(
      '/api/finances/invoice-position-bl/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
        bulkCreateOrUpdate: {
          method: 'POST',
          isArray: false,
          url: '/api/finances/invoice-position-bl/bulk_create_or_update/',
        },
      },
    );

    this.BankAccountRests = this.$resource(
      '/api/finances/bank-account-rests/:id/',
      {
        id: '@id',
      },
      {
        query: { method: 'GET', isArray: false },
        update: { method: 'PATCH' },
      },
    );
  }
  financeModal(finance: any, extraData = {}) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./finance-modal/finance-modal.tpl.html?raw'),
      controller: 'FinancesFinanceModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        finance: () => ({ ...finance }),
        extraData: () => ({ ...extraData }),
      },
    }).result;
  }

  generalTransactionModal(generalTransaction: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./general-transactions-modal/general-transactions-modal.tpl.html?raw'),
      controller: 'GeneralTransactionsModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        generalTransaction: () => generalTransaction,
      },
    }).result;
  }

  bankOperationModal(bankOperation: any, extraData = {}) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./bank-operation-modal/bank-operation-modal.tpl.html?raw'),
      controller: 'BankOperationModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        bankOperationData: () => bankOperation,
        extraData: () => extraData,
      },
    }).result;
  }

  paymentPlanModal(paymentPlan: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./payment-plan-modal/payment-plan-modal.tpl.html?raw'),
      controller: 'PaymentPlanModalController as vm',
      windowClass: 'modal-template modal-template-three-thirds-width',
      appendTo: getModalRoot(),
      resolve: {
        paymentPlan: () => paymentPlan,
      },
    }).result;
  }

  bankAccountActivityModal(queryParams: QueryParams, args: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./bank-account-activity-modal/bank-account-activity-modal.tpl.html?raw'),
      controller: 'BankAccountActivityModalController as vm',
      windowClass: 'modal-template modal-template-three-thirds-width',
      appendTo: getModalRoot(),
      resolve: {
        queryParams: () => queryParams,
        args: () => args,
      },
    }).result;
  }

  bankAccountRestsModal(bankAccount: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<bank-account-rests-modal
        bank-account="bankAccount"
        modal-instance="$uibModalInstance"
      >
      </bank-account-rests-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: any, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          $scope.bankAccount = { id: bankAccount.id };
          $scope.$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-third-width',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  creditNoteModal(creditNote: any, extraData = {}) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./credit-note-modal/credit-note-modal.tpl.html?raw'),
      controller: 'CreditNoteModalController as vm',
      windowClass: 'modal-template modal-template',
      appendTo: getModalRoot(),
      resolve: {
        creditNote: () => creditNote,
        extraData: () => extraData,
      },
    }).result;
  }

  paymentInfoModal(paymentInfo?: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: html`<payment-info-modal
        payment-info="paymentInfo"
        modal-instance="$uibModalInstance"
      >
      </payment-info-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
          ($scope as any).$uibModalInstance = $uibModalInstance;
          ($scope as any).paymentInfo = paymentInfo;
        },
      ],
      windowClass: 'modal-template-third-width',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result;
  }

  disbursementblModal(disbursementbl?: any, extraData?: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./components/disbursementbl-modal/disbursementbl-modal.tpl.html?raw'),
      controller: 'DisbursementBLModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        disbursementbl: () => disbursementbl,
        extraData: () => extraData,
      },
    }).result;
  }

  financeBankModal(financeBank?: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./finance-bank-modal/finance-bank-modal.tpl.html?raw'),
      controller: 'FinanceBankModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        financeBank: () => financeBank,
      },
    }).result;
  }

  bankBranchModal(bank: any) {
    return this.$uibModal.open({
      backdrop: 'static',
      template: require('./bank-branch-modal/bank-branch-modal.tpl.html?raw'),
      controller: 'BankBranchModalController as vm',
      windowClass: 'modal-template modal-template-half-width',
      appendTo: getModalRoot(),
      resolve: {
        bank: () => bank,
      },
    }).result;
  }

  cloneDisbursementBL(instanceId: number) {
    if (!confirm(this.gettext('Do you want to clone this Disbursement bill of lading?'))) {
      return Promise.resolve();
    }
    return this.DisbursementBL.get({
      id: instanceId,
    }).$promise.then((data: any) => {
      const instance = data;
      const newObject = ng.extend({}, instance);
      delete newObject.id;
      return this.disbursementblModal(newObject);
    });
  }

  getPagesConfig() {
    const config: any = [
      {
        title: this.gettext('Invoices'),
        permissions: [],
        state: 'finances.invoices.list',
        icon: 'fa-credit-card',
        counter: 'finances.finance',
      },
    ];

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_paymentplans) {
      config.push({
        title: this.gettext('Payment Plans'),
        state: 'finances.paymentplans',
        icon: 'fa-calendar',
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_charges) {
      config.push({
        title: this.gettext('Charges / Gains'),
        state: 'finances.contractCharges',
        icon: 'fa-money',
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_payments) {
      config.push({
        title: this.gettext('Payments'),
        state: 'finances.payments.list',
        icon: 'fa-credit-card-alt',
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_bankaccounts) {
      config.push({
        title: this.gettext('Bank Accounts'),
        state: 'finances.bankAccount.list',
        icon: 'fa-university',
        tabs: [
          {
            title: this.gettext('Bank Accounts'),
            state: 'finances.bankAccount.list',
            icon: 'fa-bank',
          },
          {
            title: this.gettext('Bank Transactions'),
            state: 'finances.bankAccount.bankTransaction',
            icon: 'fa-exchange',
            permissions: ['view_banktransaction'],
          },
        ],
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_finance_invoicepositions) {
      config.push({
        title: this.gettext('Invoice positions'),
        state: 'finances.invoicePositionPage',
        icon: 'fa-list',
      });
    }

    if (this.$rootScope.user.settings.USE_FINANCE_ACCOUNTING) {
      config.push({
        title: this.gettext('Accounting'),
        state: 'finances.accountActivity',
        icon: 'fa-address-book',
        permissions: ['configurate_finblock_entries'],
        tabs: [
          {
            title: this.gettext('Account activity'),
            state: 'finances.accountActivity',
            icon: 'fa-address-book',
            permissions: ['configurate_finblock_entries'],
          },
          {
            title: this.gettext('Credit Notes'),
            state: 'finances.creditNote.list',
            icon: 'fa-columns',
            permissions: ['configurate_finblock_entries'],
          },
          {
            title: this.gettext('Bank Operations'),
            state: 'finances.bankOperation.list',
            icon: 'fa-compass',
            permissions: ['configurate_finblock_entries'],
          },
          {
            title: this.gettext('Transactions'),
            state: 'finances.transactions',
            icon: 'fa-exchange',
            permissions: ['configurate_finblock_entries'],
          },
          {
            title: this.gettext('General Transactions'),
            state: 'finances.generalTransactions',
            icon: 'fa-wrench',
            permissions: ['configurate_finblock_entries'],
          },
          {
            title: this.gettext('Invoice configuration'),
            state: 'finances.invoicePositionConfigPage',
            icon: 'fa-cog',
            permissions: ['configurate_finblock_entries'],
          },
        ],
      });
    }

    if (this.$rootScope.user.settings.SYSTEM_BLOCKS.block_execution_reassignment) {
      config.push({
        title: this.gettext('Reassignment'),
        state: 'finances.reassignmentsPage',
        icon: 'fa-reply-all',
      });
      config.push({
        title: this.gettext('Offset'),
        state: 'finances.offsetsPage',
        icon: 'fa-magnet',
      });
    }

    return config;
  }

  getLatestExchangeObject(currency: any) {
    return this.CurrencyExchange.query({
      local_currency: currency,
      page_size: 1,
    }).$promise.then((data: any) => {
      return data.results[0];
    });
  }

  getLatestRate(currency: any) {
    return this.getLatestExchangeObject(currency).then((data: any) => {
      let rate = undefined;
      if (data) {
        rate = data.rate;
      }
      return rate;
    });
  }

  getCurrencyExchangeByDate(currency: any, date: any) {
    return this.CurrencyExchange.query({
      local_currency: currency,
      to_date: date,
    }).$promise;
  }

  updateInvoice(finance: { id: number }) {
    return this.Finance.update({ id: finance.id }, finance).$promise;
  }

  cloneInvoice(finance: any) {
    return this.Finance.get({
      id: finance.id,
    }).$promise.then((finance: any) => {
      let newObject = ng.extend({}, finance);
      newObject = this.CoreUtils.cleanBeforeClone(newObject);
      return this.InvoicePosition.get({ invoice: finance.id }).$promise.then((data: any) => {
        data.results.forEach((position: any) => {
          position._charge_id = position.charge || position.reserve_charge;

          this.CoreUtils.cleanBeforeClone(position, [
            'invoice',
            'contractcharge',
            'invoicepositionbilloflading_set',
            'logistic_cost',
            'reserve_amount_used',
            'reserve_amount_used_init',
          ]);
        });
        return this.financeModal(newObject, { financePositions: data.results });
      });
    });
  }

  getRequiredFields(invoicePosition: any) {
    const requiredField = ['use', 'quantity', 'price_per_piece', 'amount'];
    if (this.$rootScope.user.settings.USE_FINANCE_ACCOUNTING) {
      requiredField.push('debit_account', 'credit_account');
    }
    const useTorequiredFields = {
      costs: ['contract', 'costs'],
      gains: ['contract', 'costs'],
      cargo: ['crop', 'contract'],
      washout: ['crop', 'contract'],
      expenses: ['expenses_option'],
      other: ['other_activity'],
      credit: ['contract'],
    };
    if (invoicePosition.use) {
      requiredField.push(
        ...useTorequiredFields[invoicePosition.use as keyof typeof useTorequiredFields],
      );
    }
    if (
      this.$rootScope.user.settings.INVOICE_POSITION_EXPORT_CONTRACT_PASSPORT_VALIDATION &&
      invoicePosition.use === 'cargo' &&
      invoicePosition.contract_deal_type === 'export'
    ) {
      requiredField.push('passport');
    }
    return requiredField;
  }

  validatePositionField(field: any, position: any) {
    if (field == 'costs') {
      return position.contractcharge || position._charge_id || position.reserve_charge || false;
    }
    if (field == 'amount') {
      return position.amount <= (position._max_amount || position.amount) || false;
    }
    if (field == 'contract') {
      const contractNotRequired =
        (!this.$rootScope.user.settings.INVOICE_POSITION_CONTRACT_OR_PASSPORT_REQUIRED &&
          (position.use == 'expenses' || position.use == 'other')) ||
        position.passport ||
        position.logistic;
      return contractNotRequired || position[field] || false;
    }
    return position[field] || false;
  }

  getPaymentInfoList(params: object) {
    return this.PaymentInfo.query(params).$promise;
  }

  savePaymentInfo(paymentInfo: any) {
    const saveFunc = paymentInfo.id ? this.PaymentInfo.update : this.PaymentInfo.save;
    return saveFunc(paymentInfo).$promise;
  }

  deletePaymentInfo(paymentInfo: any) {
    return this.PaymentInfo.delete({ id: paymentInfo.id }).$promise;
  }

  createBankAccount(bankAccount: any) {
    return this.BankAccount.save(bankAccount).$promise;
  }

  getDefaultCurrency() {
    return this.$rootScope.user.settings.CURRENCY_OBJECTS[
      this.$rootScope.user.settings.DEFAULT_CURRENCY
    ];
  }

  getLocalCurrency() {
    return this.$rootScope.user.settings.LOCAL_CURRENCY_OBJECT;
  }

  getDefaultBankAccount(finance: any) {
    const params =
      finance.invoice_type == 'incoming'
        ? {
            client_role: finance.clientrole_to,
            currency: finance.currency,
            has_main_or_all: true,
          }
        : {
            client_role: finance.clientrole_from,
            currency: finance.currency,
            has_main_or_all: true,
          };

    return this.BankAccount.query(params).$promise.then((data: any) => {
      return data.count == 1 ? data.results[0].id : null;
    });
  }

  getBankAccountInfo(id: number) {
    return this.BankAccount.query({ id: id }).$promise;
  }

  getDefaultCounterpatyPaymentInfo(finance: any) {
    const params =
      finance.invoice_type == 'incoming'
        ? {
            client_role: finance.clientrole_from,
            currency_nullable: finance.currency,
            status: 'valid',
          }
        : {
            client_role: finance.clientrole_to,
            currency_nullable: finance.currency,
            status: 'valid',
          };

    return this.PaymentInfo.query(params).$promise.then((data: any) => {
      return data.count == 1 ? data.results[0].id : null;
    });
  }

  getCurrencyExchangeList(params: object) {
    return this.CurrencyExchange.query(params).$promise;
  }

  getDefaultCurrencyId() {
    return this.$rootScope.user.settings.CURRENCY_OBJECTS[
      this.$rootScope.user.settings.DEFAULT_CURRENCY
    ].id;
  }

  getLocalCurrencyId() {
    return this.$rootScope.user.settings.CURRENCY_OBJECTS[
      this.$rootScope.user.settings.LOCAL_CURRENCY
    ].id;
  }

  getLocalCurrencyExchangeId() {
    return this.getCurrencyExchangeList({
      is_local_currency: 1,
      page_size: 1,
      order_by: '-date_exchange',
    }).then((data: any) => {
      return data.results.shift()?.id;
    });
  }

  isInvoiceUnique(invoice: any) {
    return this.Finance.query({ number: invoice.number }).$promise.then((invoices: any) =>
      invoices.results.length &&
      !this.$rootScope.user.settings.ALWAYS_CHECK_INVOICE_NUMBERS_FOR_DUPLICATES
        ? this.isCounterpartiesUnique(invoice, invoices.results)
        : !invoice.results.length,
    );
  }

  isCounterpartiesUnique(invoice: any, invoices: any) {
    const counterparties: any = [invoice.clientrole_to, invoice.clientrole_from];
    return !invoices.some(
      (item: any) =>
        counterparties.includes(item.clientrole_to) &&
        counterparties.includes(item.clientrole_from),
    );
  }

  getUsesList(financePositions: object[]) {
    const usesList = ['cargo', 'gains', 'costs', 'expenses', 'washout', 'credit', 'other'];

    return {
      usesList: usesList,
      financePositions: financePositions,
    };
  }

  getPaymentsExportConfig() {
    return 'payments-export-config';
  }

  invoiceFinesCosts(this: any, invoice: any) {
    return this.financeModal({ ...invoice }, {});
  }
  invoiceFinesPosition(invoice: any, positions: any) {
    positions.push({});
  }

  applyVat = (params: ApplyVatParams) => this.financesRepo.applyVat(params);
}
