import React from 'react';

import { EntityRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from './types';

export const EntityCellRead = ({ value }: CellTypeProps) => {
  return (
    <div className="px-2 py-1">
      <EntityRead value={{ title: String(value) }} />
    </div>
  );
};
