import type ng from 'angular';

import type { AccountsService } from '^/app/accounts/accounts.service';

export class ResourcesService {
  $injector: ng.auto.IInjectorService;
  modelResourceMap: any;
  constructor($injector: ng.auto.IInjectorService) {
    this.$injector = $injector;

    this.modelResourceMap = null;
  }

  getModelResourceMap() {
    if (!this.modelResourceMap) {
      this.modelResourceMap = {
        'core.queryparamsset': this.$injector.get<any>('CoreService').QueryParamsSet,
        'core.businessunit': this.$injector.get<any>('CoreService').BusinessUnit,
        'core.typeofactivity': this.$injector.get<any>('CoreService').TypeOfActivity,
        'core.cropyear': this.$injector.get<any>('CoreService').CropYear,
        'core.season': this.$injector.get<any>('CoreService').Season,
        'core.checklistpoint': this.$injector.get<any>('CoreService').ChecklistPoint,
        'core.comment': this.$injector.get<any>('CoreService').Comment,
        'contenttypes.contenttype': this.$injector.get<any>('CoreService').ContentType,
        'core.collectionexportcolumnparams':
          this.$injector.get<any>('CoreService').CollectionExportColumnParams,
        'core.customfield': this.$injector.get<any>('CustomValuesService').CustomFieldResource,
        'core.customfieldchoice':
          this.$injector.get<any>('CustomValuesService').CustomFieldChoiceResource,
        'core.customfieldvalue': this.$injector.get<any>('CustomValuesService').CustomValueResource,
        'core.customstatus': this.$injector.get<any>('CoreService').CustomStatus,
        'core.control': this.$injector.get<any>('CoreService').controlResource,
        'auth.user': this.$injector.get<AccountsService>('AccountsService').User,
        'auth.group': this.$injector.get<AccountsService>('AccountsService').Group,
        'auth.permission': this.$injector.get<AccountsService>('AccountsService').Permission,
        'accounts.regionalmanager':
          this.$injector.get<AccountsService>('AccountsService').RegionalManager,
        'accounts.approvalconfig':
          this.$injector.get<AccountsService>('AccountsService').ApprovalConfig,
        'accounts.approval': this.$injector.get<AccountsService>('AccountsService').Approval,
        'admin.logentry': this.$injector.get<AccountsService>('AccountsService').LogEntry,
        'documents.document': this.$injector.get<any>('DocumentsService').Document,
        'documents.docxdocument': this.$injector.get<any>('DocumentsService').DocxDocument,
        'documents.documentchecklist':
          this.$injector.get<any>('DocumentsService').DocumentChecklist,
        'documents.documentchecklistpoint':
          this.$injector.get<any>('DocumentsService').DocumentChecklistPoint,
        'emailtemplate.emailtemplate': this.$injector.get<any>('EmailTemplates').EmailTemplate,
        'notifications.telegramchat':
          this.$injector.get<any>('NotificationService').TelegramChatResource,
        'notifications.eventsubscriptiontemplate':
          this.$injector.get<any>('NotificationService').EventSubscriptionTemplateResource,
        'clients.client': this.$injector.get<any>('ClientsService').Client,
        'clients.clientrole': this.$injector.get<any>('ClientsService').ClientRole,
        'clients.customclientrole': this.$injector.get<any>('ClientsService').CustomClientRole,
        'clients.clienttemplate': this.$injector.get<any>('ClientsService').ClientTemplate,
        'clients.bank': this.$injector.get<any>('ClientsService').Bank,
        'clients.broker': this.$injector.get<any>('ClientsService').Broker,
        'clients.buyer': this.$injector.get<any>('ClientsService').Buyer,
        'clients.elevator': this.$injector.get<any>('ClientsService').Elevator,
        'clients.exporter': this.$injector.get<any>('ClientsService').Exporter,
        'clients.farm': this.$injector.get<any>('ClientsService').Farm,
        'clients.insurer': this.$injector.get<any>('ClientsService').Insurer,
        'clients.owner': this.$injector.get<any>('ClientsService').Owner,
        'clients.other': this.$injector.get<any>('ClientsService').Other,
        'clients.person': this.$injector.get<any>('ClientsService').Person,
        'clients.surveyor': this.$injector.get<any>('ClientsService').Surveyor,
        'clients.deliverer': this.$injector.get<any>('ClientsService').Deliverer,
        'clients.supplier': this.$injector.get<any>('ClientsService').Supplier,
        'clients.companygroup': this.$injector.get<any>('ClientsService').CompanyGroup,
        'clients.responsibility': this.$injector.get<any>('ClientsService').Responsibility,
        'clients.clientupdate': this.$injector.get<any>('ClientsService').ClientUpdate,
        'clients.updatetopic': this.$injector.get<any>('ClientsService').UpdateTopic,
        'clients.updatefailurereason':
          this.$injector.get<any>('ClientsService').UpdateFailureReason,
        'clients.clientdocumenttype': this.$injector.get<any>('ClientsService').ClientDocumentType,
        'clients.clientcommentary': this.$injector.get<any>('ClientsService').ClientCommentary,
        'clients.cargobrand': this.$injector.get<any>('ClientsService').CargoBrand,
        'clients.customs': this.$injector.get<any>('ClientsService').Customs,
        'clients.croparea': this.$injector.get<any>('PotentialService').cropAreaResource,
        'logistics.station': this.$injector.get<any>('LogisticsService').Station,
        'logistics.logistic': this.$injector.get<any>('LogisticsService').Logistic,
        'logistics.terminal': this.$injector.get<any>('LogisticsService').Terminal,
        'logistics.port': this.$injector.get<any>('LogisticsService').Port,
        'logistics.vessel': this.$injector.get<any>('LogisticsService').Vessel,
        'logistics.voyage': this.$injector.get<any>('LogisticsService').Voyage,
        'logistics.driver': this.$injector.get<any>('LogisticsService').Driver,
        'logistics.vehicle': this.$injector.get<any>('LogisticsService').Vehicle,
        'logistics.logistictariff': this.$injector.get<any>('LogisticsService').LogisticTariff,
        'logistics.logisticfreight': this.$injector.get<any>('LogisticsService').LogisticFreight,
        'logistics.deliverycondition':
          this.$injector.get<any>('LogisticsService').DeliveryCondition,
        'logistics.basis': this.$injector.get<any>('LogisticsService').Basis,
        'logistics.shipclassification':
          this.$injector.get<any>('LogisticsService').ShipClassification,
        'logistics.placeofdestination':
          this.$injector.get<any>('LogisticsService').PlacesOfDestination,
        'logistics.transshipmenttariff':
          this.$injector.get<any>('LogisticsService').LogisticTransshipmentTariff,
        'logistics.acceptancecertificate':
          this.$injector.get<any>('LogisticsService').AcceptanceCertificate,
        'logistics.vehiclesubtype': this.$injector.get<any>('LogisticsService').VehicleSubtype,
        'logistics.customscargodeclaration': this.$injector.get<any>(
          'CustomsCargoDeclarationService',
        ).customsCargoDeclarationResource,
        'logistics.cdtype': this.$injector.get<any>('CustomsCargoDeclarationService')
          .CDTypeResource,
        'logistics.billoflading':
          this.$injector.get<any>('BillOfLadingService').billOfLadingResource,
        'logistics.logistictype': this.$injector.get<any>('LogisticsService').LogisticType,
        'logistics.vesselsize': this.$injector.get<any>('LogisticsService').VesselSize,
        'logistics.warehouse': this.$injector.get<any>('WarehousesService').warehousesResource,
        'logistics.warehousemovement':
          this.$injector.get<any>('WarehousesService').warehouseMovementsResource,
        'logistics.warehouseloss': this.$injector.get<any>('LogisticsService').WarehouseLoss,
        'warehouses.recorderreference':
          this.$injector.get<any>('WarehousesService').recorderReferenceResource,
        'logistics.stockreserveunit': this.$injector.get<any>('StockReserveUnitsService')
          .StockReserveUnitDetailResource,
        'logistics.transportationstorageexpense': this.$injector.get<any>(
          'TransportationStorageExpenseService',
        ).TransportationStorageExpenseResource,
        'contracts.contractbase': this.$injector.get<any>('ContractsService').Contract,
        'contracts.salecontract': this.$injector.get<any>('ContractsService').SaleContract,
        'contracts.purchasecontract': this.$injector.get<any>('ContractsService').PurchaseContract,
        'contracts.intermediatecontract':
          this.$injector.get<any>('ContractsService').IntermediateContract,
        'contracts.exportcontract': this.$injector.get<any>('ContractsService').ExportContract,
        'contracts.servicescontract': this.$injector.get<any>('ContractsService').ServicesContract,
        'contracts.request': this.$injector.get<any>('ContractsService').Request,
        'contracts.basisdoc': this.$injector.get<any>('ContractsService').BasisDoc,
        'contracts.generalagreement': this.$injector.get<any>('ContractsService').GeneralAgreements,
        'contracts.contractoption': this.$injector.get<any>('ContractsService').ContractOptions,
        'contracts.contractcharge': this.$injector.get<any>('ContractsService').ContractCharge,
        'contracts.papertrade': this.$injector.get<any>('ContractsService').PaperTrade,
        'contracts.consignment': this.$injector.get<any>('ContractsService').Consignments,
        'contracts.saporder': this.$injector.get<any>('ContractsService').SapOrders,
        'contracts.samplingcondition':
          this.$injector.get<any>('ContractsService').SamplingCondition,
        'contracts.multicontract':
          this.$injector.get<any>('MulticontractService').MulticontractResource,
        'contracts.certificationscheme':
          this.$injector.get<any>('ContractsService').CertificationSchemeResource,
        'passports.passport': this.$injector.get<any>('ContractsService').Passport,
        'passports.multipassport': this.$injector.get<any>('ContractsService').Multipassport,
        'passports.margincontrol': this.$injector.get<any>('ContractsService').MarginControl,
        'reports.financeclientdetails':
          this.$injector.get<any>('ReportsService').FinanceClientDetails,
        'reports.pdfreport': this.$injector.get<any>('ReportsService').PdfReport,
        'requests.multirequest':
          this.$injector.get<any>('MulticontractService').MulticontractResource,
        'location.country': this.$injector.get<any>('LocationService').Country,
        'location.district': this.$injector.get<any>('LocationService').District,
        'location.region': this.$injector.get<any>('LocationService').Region,
        'location.city': this.$injector.get<any>('LocationService').City,
        'location.address': this.$injector.get<any>('LocationService').Address,
        'crops.crop': this.$injector.get<any>('CropsService').Crop,
        'crops.quality': this.$injector.get<any>('CropsService').Quality,
        'crops.qualityruletemplate': this.$injector.get<any>('CropsService').QualityRuleTemplate,
        'stockexchanges.operation':
          this.$injector.get<any>('StockExchangesService').OperationResource,
        'stockexchanges.stockexchange':
          this.$injector.get<any>('StockExchangesService').StockExchangeResource,
        'stockexchanges.derivativeaccount':
          this.$injector.get<any>('StockExchangesService').DerivativeAccountResource,
        'stockexchanges.measurement':
          this.$injector.get<any>('StockExchangesService').MeasurementResource,
        'stockexchanges.derivative':
          this.$injector.get<any>('DerivativesService').DerivativeResource,
        'finances.currencyexchange': this.$injector.get<any>('FinancesService').CurrencyExchange,
        'finances.paymentcondition': this.$injector.get<any>('FinancesService').PaymentCondition,
        'finances.balancepayment': this.$injector.get<any>('FinancesService').BalancePayment,
        'finances.finance': this.$injector.get<any>('FinancesService').Finance,
        'finances.invoiceposition': this.$injector.get<any>('FinancesService').InvoicePosition,
        'finances.payment': this.$injector.get<any>('FinancesService').Payment,
        'finances.charge': this.$injector.get<any>('FinancesService').Charge,
        'finances.paymentinfo': this.$injector.get<any>('FinancesService').PaymentInfo,
        'finances.bankaccount': this.$injector.get<any>('FinancesService').BankAccount,
        'finances.financeaccount': this.$injector.get<any>('FinancesService').FinanceAccount,
        'finances.generalexpenses': this.$injector.get<any>('FinancesService').GeneralExpenses,
        'finances.banktransaction':
          this.$injector.get<any>('BankTransactionService').BankTransactionResource,
        'finances.banktransactionoperation':
          this.$injector.get<any>('BankTransactionService').BankTransactionOperationResource,
        'finances.otheractivity': this.$injector.get<any>('FinancesService').OtherActivities,
        'finances.currency': this.$injector.get<any>('FinancesService').Currency,
        'finances.creditnote': this.$injector.get<any>('FinancesService').CreditNote,
        'finances.disbursementbl': this.$injector.get<any>('FinancesService').DisbursementBL,
        'finances.financepurpose': this.$injector.get<any>('FinancesService').FinancePurpose,
        'finances.financebank': this.$injector.get<any>('FinancesService').FinanceBank,
        'finances.budget': this.$injector.get<any>('FinancesService').Budget,
        'finances.reassignment':
          this.$injector.get<any>('ReassignmentService').ReassignmentResource,
        'finances.financeoffset': this.$injector.get<any>('OffsetService').OffsetResource,
        'finances.loan': this.$injector.get<any>('LoanService').LoanResource,
        'finances.paymentplan': this.$injector.get<any>('FinancesService').PaymentPlan,
        'finances.bankbranch': this.$injector.get<any>('FinancesService').BankBranch,
      };
    }
    return this.modelResourceMap;
  }

  getModelNames() {
    return Object.keys(this.getModelResourceMap());
  }

  getResource(modelName: string) {
    return this.getModelResourceMap()[this.getModelName(modelName)];
  }

  getModelName(shortModelName: string): string {
    if (!shortModelName) {
      return 'N/A';
    }
    shortModelName = shortModelName.toLowerCase();
    const modelNames = this.getModelNames();
    if (modelNames.includes(shortModelName)) {
      return shortModelName;
    }
    return modelNames.filter((key) => shortModelName == key.split('.')[1]).shift() ?? 'N/A';
  }
}
ResourcesService.$inject = ['$injector'];
