import ng from 'angular';

import { DataGrid } from '~/shared/ui/components/data-grid';
import { DataList } from '~/shared/ui/components/data-list/data-list';
import { Pagination } from '~/shared/ui/components/pagination';

import { angularize } from '^/shared/utils/react';

const gtUIComponents = ng.module('gtUIComponents', []);

angularize(DataGrid, 'gtuDataGrid', gtUIComponents, {
  rows: '<',
  headerGroups: '<',
  selectedRowIds: '<?',
  stickyHeaders: '<?',
  editing: '<',
  CellRender: '<',
  HeaderRender: '<',
  onEdit: '&',
  onSelect: '&?',
  loading: '<?',
});

angularize(DataList, 'gtuDataList', gtUIComponents, {
  rows: '<',
  selectedRowIds: '<?',
  editing: '<',
  CellRender: '<',
  onEdit: '&',
  onSelect: '&?',
  loading: '<?',
});

angularize(Pagination, 'gtuPagination', gtUIComponents, {
  pageCurrent: '<',
  pageSize: '<',
  recordsTotal: '<',
  pageSizeOptions: '<',
  currentPageChanged: '<',
  pageSizeChanged: '<',
});

export const gtUIComponentsModule = gtUIComponents.name;
