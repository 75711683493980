import React from 'react';

import { PersonRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

const isPerson = (
  value: unknown,
): value is { firstName: string; lastName: string; avatarSrc?: string } => {
  return (
    value !== null &&
    typeof value === 'object' &&
    'firstName' in value &&
    typeof (value as Record<string, unknown>).firstName === 'string' &&
    'lastName' in value &&
    typeof (value as Record<string, unknown>).lastName === 'string'
  );
};

export const PersonCellRead = ({ value }: CellTypeProps) => {
  if (isPerson(value)) {
    return <PersonRead value={value} />;
  }

  return null;
};
