import type { IntermediateContractList } from '~/features/deals/intermediate-contracts';
import type { ContractSerializer } from '~/features/deals/shared/contracts';
import { getBaseContractColumns } from '~/features/deals/shared/contracts/columns';

export const getIntermediateContractColumns = (
  contracts: IntermediateContractList[],
  serializer: ContractSerializer,
) => {
  return getBaseContractColumns(contracts, serializer);
};
