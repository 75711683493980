import React from 'react';

import type { ColumnConfig } from '~/core/page-views/lib';

import { AffiliatedTemplate, TitleTemplate } from '../components';
import { type CounterpartyList, isAffiliated } from '../lib';

export const getCounterpartyColumns = ({
  onOpenCounterpartyModal,
  onCloneCounterparty,
  onOpenEmailModal,
  onApproveCounterparty,
  onOpenDocxModal,
  counterparties,
}: {
  counterparties: CounterpartyList[];
  onOpenCounterpartyModal: (counterparty: CounterpartyList) => void;
  onCloneCounterparty: (counterparty: CounterpartyList) => void;
  onOpenEmailModal: (counterparty: CounterpartyList) => void;
  onApproveCounterparty: (counterparty: CounterpartyList, approve: boolean) => void;
  onOpenDocxModal: (counterparty: CounterpartyList) => void;
}): ColumnConfig[] => [
  {
    key: 'title',
    sourceKey: 'name',
    dataType: 'template',
    editing: true,
    validate: { type: 'textLength', condition: 'moreThan', value: '1' },
    render: ({ value, valueChanged, editing, id }) => {
      const counterparty = counterparties.find((item) => item.id === id);

      if (typeof value === 'string' && counterparty) {
        return (
          <TitleTemplate
            value={value}
            editing={editing ?? false}
            counterparty={counterparty}
            valueChanged={valueChanged}
            onOpenCounterpartyModal={onOpenCounterpartyModal}
            onCloneCounterparty={onCloneCounterparty}
            onOpenEmailModal={onOpenEmailModal}
            onApproveCounterparty={onApproveCounterparty}
            onOpenDocxModal={onOpenDocxModal}
          />
        );
      }

      return <div />;
    },
    sticky: true,
  },
  { key: 'company_group_title', dataType: 'text', editing: true },
  { key: 'paymentConditionOptions', dataType: 'text', editing: true },
  { key: 'updateTime', editing: false, dataType: 'date' },
  { key: 'authorFirstName', editing: false, dataType: 'text' },
  { key: 'editorFirstName', editing: false, dataType: 'text' },
  {
    key: 'status',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'Approved', props: { variant: 'green' } },
        { value: 'Pending', props: { variant: 'yellow' } },
        { value: 'Re-approval', props: { variant: 'yellow' } },
        { value: 'Rejected', props: { variant: 'red' } },
        { value: 'Approved_condition' },
        { value: 'New', props: { variant: 'blue' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['Approved', 'Pending', 'Re-approval', 'Rejected', 'Approved_condition', 'New'],
    },
  },
  {
    key: 'role',
    sourceKey: 'role_names',
    dataType: 'multiselect',
    editing: true,
    cellTemplateContext: {
      props: { rounded: 'full' },
      options: [
        { value: 'broker', props: { rounded: 'full' } },
        { value: 'deliverer', props: { rounded: 'full' } },
        { value: 'insurer', props: { rounded: 'full' } },
        { value: 'exporter', props: { rounded: 'full' } },
        { value: 'customs_broker', props: { rounded: 'full' } },
        { value: 'owner', props: { rounded: 'full' } },
        { value: 'buyer', props: { rounded: 'full' } },
        { value: 'other', props: { rounded: 'full' } },
        { value: 'surveyor', props: { rounded: 'full' } },
        { value: 'farm', props: { rounded: 'full' } },
        { value: 'elevator', props: { rounded: 'full' } },
        { value: 'bank', props: { rounded: 'full' } },
        { value: 'supplier', props: { rounded: 'full' } },
      ],
    },
    validate: {
      type: 'equalValues',
      values: [
        'broker',
        'deliverer',
        'insurer',
        'exporter',
        'customs_broker',
        'owner',
        'buyer',
        'other',
        'surveyor',
        'farm',
        'elevator',
        'bank',
        'supplier',
      ],
    },
    size: 210,
  },
  { key: 'docs', sourceKey: 'documents_count', dataType: 'number', editing: false },
  { key: 'stuff', sourceKey: 'persons_count', dataType: 'number', editing: false },
  { key: 'updates', sourceKey: 'updates_count', dataType: 'number', editing: false },
  { key: 'invoices', sourceKey: 'invoices_count', dataType: 'number', editing: false },
  { key: 'contracts', sourceKey: 'contracts_count', dataType: 'number', editing: false },
  {
    key: 'approval_status',
    editing: true,
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'wait', props: { variant: 'yellow' } },
        { value: 'rejected', props: { variant: 'red' } },
        { value: 'approved', props: { variant: 'green' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['wait', 'rejected', 'approved'],
    },
  },
  { key: 'address', dataType: 'text', editing: true },
  { key: 'notes', sourceKey: 'additional_info', dataType: 'text', editing: true },
  {
    key: 'affiliated',
    dataType: 'template',
    editing: false,
    render: ({ value }) => (isAffiliated(value) ? <AffiliatedTemplate value={value} /> : <div />),
    size: 500,
  },
  {
    key: 'regular_status',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [
        { value: 'active', props: { variant: 'green' } },
        { value: 'potencial' },
        { value: 'inactive', props: { variant: 'red' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['active', 'potencial', 'inactive'],
    },
  },
  { key: 'abbreviation', dataType: 'text', editing: true },
  { key: 'phone', dataType: 'phone', editing: true },
  { key: 'phone_additional', dataType: 'phone', editing: true },
  { key: 'region_titles', dataType: 'text', editing: true },
  { key: 'district_title', dataType: 'text', editing: true },
  { key: 'city', dataType: 'text', editing: true },
  { key: 'current_address', dataType: 'text', editing: true },
  { key: 'email', dataType: 'email', editing: true },
  { key: 'website', dataType: 'url', editing: true },
  {
    key: 'relations',
    dataType: 'select',
    cellTemplateContext: {
      options: [
        { value: 'Hot', props: { variant: 'red' } },
        { value: 'Neutral', props: { variant: 'gray' } },
        { value: 'Warm', props: { variant: 'yellow' } },
      ],
    },
    validate: {
      type: 'equalValue',
      values: ['Hot', 'Warm', 'Neutral'],
    },
    editing: true,
  },
  { key: 'person', dataType: 'text', editing: true },
  { key: 'author', dataType: 'person', editing: false },
  { key: 'editor', dataType: 'person', editing: false },
  { key: 'invoices', editing: false, dataType: 'text' },
  { key: 'additionalInfo', dataType: 'text', editing: true },
  { key: 'createTime', editing: false, dataType: 'date' },
  { key: 'updateTime', editing: false, dataType: 'date' },
  { key: 'companyCode', dataType: 'text', editing: true },
  { key: 'supplierNames', dataType: 'text', editing: true },
  { key: 'exporterNames', dataType: 'text', editing: true },
  { key: 'sapVendorNumber', dataType: 'text', editing: false },
  {
    key: 'relatedCounterparties',
    dataType: 'template',
    render: ({ value }) => {
      if (Array.isArray(value)) {
        return <div className="px-2">{value.map((item: { name: string }) => item.name)}</div>;
      }

      return <div />;
    },
    editing: true,
  },
  { key: 'voyages', dataType: 'text', editing: false },
  { key: 'renewalDate', dataType: 'date', editing: true },
];
