import {
  PurchaseContractCreate,
  PurchaseContractList,
  PurchaseContractUpdate,
  PurchaseContractsListParams,
} from '~/features/deals/purchase-contracts';
import {
  PurchaseContract,
  contractsPurchaseContractsCreate,
  contractsPurchaseContractsDestroy,
  contractsPurchaseContractsListInfoList,
  contractsPurchaseContractsPartialUpdate,
  contractsPurchaseContractsRetrieve,
  contractsPurchaseContractsTotalsExecutionRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class PurchaseContractsRepository extends EntityRepository<
  PurchaseContract,
  PurchaseContractsListParams,
  PurchaseContractCreate,
  PurchaseContractList
> {
  get = async (id: number) => {
    const { data } = await contractsPurchaseContractsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: PurchaseContractsListParams) => {
    const { data } = await contractsPurchaseContractsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: PurchaseContractCreate) => {
    const res = await contractsPurchaseContractsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: PurchaseContractUpdate) => {
    const { data } = await contractsPurchaseContractsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsPurchaseContractsDestroy({ path: { id } });
  };

  getTotals = async (query: PurchaseContractsListParams) => {
    const { data } = await contractsPurchaseContractsTotalsExecutionRetrieve({ query });
    return data;
  };
}
