import React from 'react';

import { cn } from '~/shared/lib/utils';
import { Link } from '~/shared/ui/kit/link';

import type { DataTypeProps } from '../../lib';

export const PhoneRead: React.FC<DataTypeProps<string>> = ({ disabled, value }) =>
  value ? (
    <Link href={`tel:${value}`} disabled={disabled}>
      <span className={cn('text-text-main-primary text-xs', disabled && 'opacity-50')}>
        {value}
      </span>
    </Link>
  ) : null;
