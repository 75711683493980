import React from 'react';

import { NumberRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const NumberCellRead = ({ value }: CellTypeProps) => {
  return (
    <div className="px-2">
      <NumberRead value={Number(value ?? 0)} />
    </div>
  );
};
