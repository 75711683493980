import React from 'react';

import { EntityAutocomplete } from './entity-autocomplete';
import type { Entity, EntityPageParams, EntityRepository } from '../../types';

export const entityAutocompleteWithSearch =
  (search: EntityRepository<Entity, EntityPageParams>['search']) => () => {
    const EntityAutocompleteWithRepo: React.FC<
      Omit<React.ComponentProps<typeof EntityAutocomplete>, 'repo'>
    > = (props) => <EntityAutocomplete {...props} search={search} />;

    EntityAutocompleteWithRepo.displayName = `entityAutocompleteWithRepo(${EntityAutocomplete.displayName ?? EntityAutocomplete.name})`;
    return EntityAutocompleteWithRepo;
  };
