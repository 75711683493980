import React from 'react';

import { UrlWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const UrlCellWrite = ({ value, valueChanged, disabled }: CellTypeProps) => {
  return (
    <div className="px-2">
      <UrlWrite
        value={typeof value === 'string' ? value : ''}
        valueChanged={valueChanged}
        disabled={disabled}
      />
    </div>
  );
};
