import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

import type { ContractsService } from './legacy/contracts.srv';
import type { AccountsService } from '../../accounts/accounts.service';

import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export class ContractFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  ContractsService: ContractsService;
  CoreService: CoreService;
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  LocationService: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    AccountsService: AccountsService,
    ClientsService: any,
    ContractsService: ContractsService,
    LocationService: any,
    FormFieldParamsService: FormFieldParamsService,
    FinancesService: FinancesService,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.CoreService = CoreService;
    this.AccountsService = AccountsService;
    this.ClientsService = ClientsService;
    this.ContractsService = ContractsService;
    this.LocationService = LocationService;
    this.FormFieldParamsService = FormFieldParamsService;
    this.FinancesService = FinancesService;
  }

  getApprovalContractModel(contract: any) {
    const purpose = this.ContractsService.getContractPurpose(contract);
    return 'contracts.' + purpose.charAt(0).toUpperCase() + purpose.slice(1) + 'Contract';
  }

  getFields(contract: any, formName: any, priceWidgetFields: any) {
    const isRequired = (scope: any) => {
      return Boolean(
        scope.fields
          .filter((field: any) => field.key === scope.options.key)
          .find((field: any) => field.templateOptions.required),
      );
    };

    const [col1, col2, col3, col4] = this.getContractBaseFields(contract, isRequired, formName);
    if (contract.deal_type === 'services') {
      this.addServiceContractFields(col1, col2, col3, col4, contract);
    } else {
      this.addGrainContractFields(col1, col2, col3, col4, priceWidgetFields, contract);
    }
    if (
      this.$rootScope.user.settings.CONTRACT_OPTION_MODEL &&
      contract.contract_type === 'purchase'
    ) {
      this.addPurchaseContractFields(col1);
    }
    return {
      formName: formName,
      fieldsDef: [col1, col2, col3, col4],
    };
  }

  getDealTypeChoicesList(contract: any) {
    const choices = {
      grain: [
        { name: this.gettext('Spot'), value: 'spot' },
        { name: this.gettext('Forward'), value: 'forward' },
        { name: this.gettext('Brokerage'), value: 'brokerage' },
      ],
      ticket: [
        { name: this.gettext('Spot'), value: 'spot' },
        { name: this.gettext('Forward'), value: 'forward' },
      ],
      services: [{ name: this.gettext('Services'), value: 'services' }],
      intermediate: [{ name: this.gettext('Intermediate'), value: 'intermediate' }],
      export: [{ name: this.gettext('Export'), value: 'export' }],
    };
    if (!contract.id) {
      return [...choices.grain, ...choices.services, ...choices.intermediate, ...choices.export];
    }

    if (['services', 'intermediate', 'export'].includes(contract.deal_type)) {
      return (choices as any)[contract.deal_type];
    }
    return choices.grain;
  }

  getContractBaseFields(contract: any, isRequired: any, formName: string) {
    let isExchangeFieldRequired: any;
    this.FormFieldParamsService.isRequiredCurrencyExchangeField(
      formName,
      contract.business_unit,
    ).then((required: any) => (isExchangeFieldRequired = required));
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col4: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('NUMBER'),
        },
        fieldGroup: [
          {
            key: 'contract_type',
            type: 'gt-select',
            defaultValue: contract.deal_type === 'services' ? 'purchase' : 'sale',
            templateOptions: {
              label: this.gettext('Type'),
              placeholder: this.gettext('Sale or Purchase'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('Purchase'), value: 'purchase' },
                { name: this.gettext('Sale'), value: 'sale' },
              ],
              disabled: contract.id,
              onSelected: () => {
                [contract.buyer, contract.supplier] = [contract.supplier, contract.buyer];
              },
            },
          },
          {
            key: 'auto_name',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Generate contract name'),
              hint: this.gettext('Check this if you want contract name to be auto generated'),
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.id',
            },
          },
          {
            key: 'contract_number',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Contract number'),
              required: contract.stage === 'contract',
              addon: this.gettext('#'),
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'conclusion_date',
            type: 'gt-date-select',
            defaultValue: new Date(),
            templateOptions: {
              label: this.gettext('Conclusion (date)'),
              placeholder: this.gettext('date'),
              required: true,
              disabled: this.$rootScope.user.settings.DISABLE_CONCLUSION_DATE,
              ...(contract?.conclusionDateRange || {}),
            },
            validation: {
              show: true,
            },
          },
          {
            key: 'buyer',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'sale'
                ? undefined
                : this.$rootScope.user.settings.DEFAULT_VALUES.owner,
            templateOptions: {
              label:
                contract.contract_type === 'sale'
                  ? this.gettext('Buyer')
                  : this.gettext('Buyer/Owner'),
              addFunc:
                contract.contract_type === 'sale'
                  ? () => this.ClientsService.roleModal({ model_name: 'Buyer' })
                  : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
              onSelect: () => {
                if (contract.contract_type === 'sale' && !contract.id) {
                  this.ClientsService.getPaymentConditions('ClientRole', contract.buyer).then(
                    (data: any) => {
                      contract.payment_conditions = data.payment_conditions;
                      contract.payment_conditions_auto = data.payment_conditions_auto;
                    },
                  );

                  this.ClientsService.ClientRole.get({ id: contract.buyer }).$promise.then(
                    (buyerDetails: any) => {
                      contract.buyerStatus = buyerDetails.approval_status;
                      return buyerDetails;
                    },
                  );
                }
              },
              addIcon:
                contract.contract_type === 'sale'
                  ? this.GtUtils.getIcon('clients.Buyer')
                  : this.GtUtils.getIcon('clients.Owner'),
              addPerms: contract.contract_type === 'sale' ? ['add_buyer'] : ['add_owner'],
              title: contract.contract_type === 'sale' ? contract.buyer_name : contract.owner_name,
            },
            expressionProperties: {
              'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                isRequired(scope),
              'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) => {
                if (scope.model.contract_type === 'sale' && scope.model.deal_type === 'services') {
                  return 'clients.ClientRole';
                }
                return this.ClientsService.getClientResource(
                  scope.options.key,
                  scope.model.contract_type,
                );
              },
            },
          },
          {
            template: html`
              <div style="margin: 8px 0">
                <cp-status-display
                  status="to.buyerStatus"
                </cp-status-display>
              </div>
            `,
            expressionProperties: {
              'templateOptions.buyerStatus': (viewValue: any, modelValue: any, scope: any) => {
                return scope.model.buyerStatus;
              },
              'templateOptions.hide': () => !contract.buyer || contract.contract_type !== 'sale',
            },
            defaultValue: contract.buyer
              ? this.ClientsService.ClientRole.get({ id: contract.buyer }).$promise.then(
                  (buyerDetails: any) => {
                    contract.buyerStatus = buyerDetails.approval_status;
                    return buyerDetails;
                  },
                )
              : null,
          },
          {
            key: 'supplier',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'purchase' ||
              contract.deal_type === 'services' ||
              contract.deal_type === 'intermediate'
                ? undefined
                : this.$rootScope.user.settings.DEFAULT_VALUES.owner,
            templateOptions: {
              label:
                contract.contract_type === 'purchase'
                  ? this.gettext('Supplier')
                  : this.gettext('Supplier/Owner'),
              addFunc:
                contract.contract_type === 'purchase'
                  ? () => this.ClientsService.roleModal({ model_name: 'Supplier' })
                  : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
              onSelect: () => {
                if (contract.contract_type === 'purchase' && !contract.id) {
                  this.ClientsService.getPaymentConditions('ClientRole', contract.supplier).then(
                    (data: any) => {
                      contract.payment_conditions = data.payment_conditions;
                      contract.payment_conditions_auto = data.payment_conditions_auto;
                    },
                  );

                  this.ClientsService.ClientRole.get({ id: contract.supplier }).$promise.then(
                    (supplierDetails: any) => {
                      contract.supplierStatus = supplierDetails.approval_status;
                      return supplierDetails;
                    },
                  );
                }
              },
              addIcon:
                contract.contract_type === 'purchase'
                  ? this.GtUtils.getIcon('clients.Supplier')
                  : this.GtUtils.getIcon('clients.Owner'),
              addPerms: contract.contract_type === 'purchase' ? ['add_supplier'] : ['add_owner'],
              title:
                contract.contract_type === 'purchase'
                  ? contract.supplier_name
                  : contract.owner_name,
            },
            expressionProperties: {
              'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                isRequired(scope),
              'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) => {
                if (
                  (scope.model.contract_type === 'purchase' &&
                    scope.model.deal_type === 'services') ||
                  scope.model.deal_type === 'intermediate' ||
                  scope.model.deal_type === 'export'
                ) {
                  return 'clients.ClientRole';
                }
                return this.ClientsService.getClientResource(
                  scope.options.key,
                  scope.model.contract_type,
                );
              },
              'templateOptions.queryParams': (viewValue: any, modelValue: any, scope: any) => {
                if (scope.model.deal_type === 'intermediate') {
                  return this.$rootScope.user.settings.SUPPLIER_MUST_BE_OWNER
                    ? { role_name_list: ['owner'] }
                    : { role_name_list: ['supplier', 'owner'] };
                } else if (scope.model.deal_type === 'export') {
                  return { own_supplier_or_owner: '1' };
                } else {
                  return null;
                }
              },
            },
          },
          {
            template: html`
              <div style="margin: 8px 0">
                <cp-status-display
                  status="to.supplierStatus"
                </cp-status-display>
              </div>
            `,
            expressionProperties: {
              'templateOptions.supplierStatus': (viewValue: any, modelValue: any, scope: any) => {
                return scope.model.supplierStatus;
              },
              'templateOptions.hide': () =>
                !contract.supplier || contract.contract_type !== 'purchase',
            },
            defaultValue: contract.supplier
              ? this.ClientsService.ClientRole.get({ id: contract.supplier }).$promise.then(
                  (supplierDetails: any) => {
                    contract.supplierStatus = supplierDetails.approval_status;
                    return supplierDetails;
                  },
                )
              : null,
          },
          {
            key: 'general_agreement',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('General Agreement'),
              resource: 'contracts.GeneralAgreement',
              addFunc: () => {
                return this.ContractsService.generalAgreementsModal();
              },
              addIcon: 'fa fa-handshake-o',
              addPerms: true,
              queryParams: () => {
                const queryParams: QueryParams = {
                  agreement_type: contract.contract_type,
                };

                if (contract.contract_type === 'purchase' || contract.contract_type === 'sale') {
                  queryParams.buyer = contract.buyer;
                  queryParams.supplier = contract.supplier;
                }

                return queryParams;
              },
            },
          },
          {
            key: 'status',
            type: 'gt-select',
            defaultValue: 'new',
            templateOptions: {
              label: this.gettext('Status'),
              placeholder: this.gettext('Choose status'),
              hint: this.gettext('Status depends on current progress on request'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('New'), value: 'new' },
                { name: this.gettext('Delivery period'), value: 'delivery_period' },
                { name: this.gettext('Shipment'), value: 'shipment' },
                { name: this.gettext('Cargo executed'), value: 'cargo_executed' },
                { name: this.gettext('Executed'), value: 'executed' },
                { name: this.gettext('Cancelled'), value: 'cancelled' },
                { name: this.gettext('Washout'), value: 'washout' },
                { name: this.gettext('Draft'), value: 'draft' },
              ],
              disabled: !this.AccountsService.hasPerm('change_contractstatus'),
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.stage !== 'contract',
            },
          },
          {
            key: 'from_request',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Main ticket'),
              resource: 'contracts.Request',
              queryParams: () => {
                return { stage: 'ticket' };
              },
            },
          },
          {
            key: 'custom_status',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Custom status'),
              placeholder: this.gettext('Choose custom status'),
              resource: 'core.CustomStatus',
              hint: this.gettext('custom status'),
              addFunc: () => {
                this.$window.open('/admin/core/customstatus/add/');
              },
              addIcon: 'fa fa-tasks',
              addPerms: true,
              queryParams: {
                content_type__model: 'contractbase',
              },
            },
          },
          {
            key: 'deal_type',
            type: 'gt-select',
            defaultValue: 'spot',
            templateOptions: {
              label: this.gettext('Deal type'),
              placeholder: this.gettext('Choose type'),
              hint: this.gettext('Spot or Forward'),
              valueProp: 'value',
              labelProp: 'name',
              options: this.getDealTypeChoicesList(contract),
              onSelected: () => {
                this.$rootScope.$broadcast('contract-edit_deal-type-updated');
              },
            },
          },
          {
            key: 'paper_trade',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Paper trade'),
              placeholder: this.gettext('Choose option'),
              resource: 'contracts.PaperTrade',
              addFunc: () => {
                this.$window.open('/admin/contracts/papertrade/add/');
              },
              addPerms: ['add_papertrade'],
            },
          },
          {
            key: 'swap',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Swap'),
              hint: this.gettext('Check this if you want to make contract to swap type'),
            },
          },
          {
            key: 'ignore_limits',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Ignore limits '),
              hint: this.gettext('Ignore counterparty limits'),
            },
            hideExpression: ($viewValue: any, $modelValue: any, scope: any) => {
              return scope.$root.user.settings.CONTRACTS_LIMIT_ACTION === 'forbid';
            },
            expressionProperties: {
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                if (scope.$root.user.settings.CONTRACTS_LIMIT_DEFAULT) {
                  scope.model.ignore_limits = true;
                  return scope.model.ignore_limits;
                } else {
                  return scope.to.initRequired;
                }
              },
            },
          },
          {
            key: 'ignore_allocation',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Ignore allocation'),
              hint: this.gettext('Check this if you want to ignore allocation'),
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                contract.deal_type === 'services' ||
                contract.deal_type === 'intermediate' ||
                contract.deal_type === 'export',
            },
          },
          {
            key: 'ignore_client_status',
            type: 'gt-checkbox',
            defaultValue:
              this.$rootScope.user.settings.DEFAULT_VALUES.ignore_client_compliance_status,
            templateOptions: {
              label: this.gettext('Ignore client status '),
              hint: this.gettext('Ignore counterparty status'),
              addPerms: ['can_ignore_client_compliance_status'],
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !this.AccountsService.hasPerm('can_ignore_client_compliance_status'),
            },
          },
          {
            key: 'hide_in_passport_connection',
            type: 'gt-checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.gettext('Do not display in passport'),
              hint: this.gettext('Do not display in passport'),
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                contract.deal_type === 'services' ||
                contract.deal_type === 'intermediate' ||
                contract.deal_type === 'export',
            },
          },
          {
            key: 'ignore_excess_contract_volume',
            type: 'gt-checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.gettext('Ignore excess contract volume'),
              hint: this.gettext('Ignore excess contract volume'),
              addPerms: ['can_ignore_excess_contract_volume'],
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !this.AccountsService.hasPerm('can_ignore_excess_contract_volume'),
            },
          },
          {
            key: 'internal_chain',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Internal chain'),
              hint: this.gettext('Check this if you want to use it as internal chain'),
            },
          },
          {
            key: 'kpi_signing_success',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Don`t use kpi check'),
              hint: this.gettext(
                'Check this if you don`t want to check with SINGING DAYS QUANTITY value',
              ),
            },
            hide: !this.AccountsService.hasPerm('edit_kpi_signing_success'),
          },
          {
            key: 'iscc',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('ISCC'),
              hint: this.gettext(
                'Check this if the purchase of certified products on iscc standards',
              ),
            },
          },
          {
            key: 'season',
            type: 'gt-ui-select',
            defaultValueResolve: () => this.CoreService.getDefaultSeasonId(),
            templateOptions: {
              label: this.gettext('Season'),
              placeholder: this.gettext('Season'),
              resource: 'core.Season',
              addFunc: () => {
                this.$window.open('/admin/core/season/add/');
              },
              addIcon: 'fa fa-calendar',
              addPerms: true,
              queryParams: () => {
                return { is_current: 1 };
              },
            },
          },
          {
            key: 'business_unit',
            type: 'gt-ui-select',
            defaultValueResolve: () => this.CoreService.getDefaultBuId(contract),
            templateOptions: {
              label: this.gettext('Business unit'),
              placeholder: this.gettext('Business Unit'),
              queryParams: this.GtUtils.getBUQueryParams(),
              resource: 'core.BusinessUnit',
              title: contract.business_unit_title,
              addFunc: () => {
                this.$window.open('/admin/core/businessunit/add/');
              },
              addIcon: 'fa fa-home',
              addPerms: true,
            },
          },
          {
            key: 'responsible',
            type: 'gt-ui-select',
            defaultValue: this.$rootScope.user.id,
            templateOptions: {
              label: this.gettext('Responsible'),
              placeholder: this.gettext('User to be responsible for the contract'),
              resource: 'auth.User',
            },
          },
          {
            template: html`
              <div style="margin: 8px 0">
                <work-status-display
                  status="to.responsibleWorkStatus"
                </work-status-display>
              </div>
            `,
            expressionProperties: {
              'templateOptions.responsibleWorkStatus': (
                viewValue: any,
                modelValue: any,
                scope: any,
              ) => {
                return scope.model.responsibleWorkStatus;
              },
              'templateOptions.hide': () =>
                !contract.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
            },
          },
          {
            key: 'responsible_for_signing',
            type: 'gt-ui-select',
            defaultValue: this.$rootScope.user.id,
            templateOptions: {
              label: this.gettext('Responsible for signing'),
              placeholder: this.gettext('User to be responsible for the contract'),
              resource: 'auth.User',
            },
          },
          {
            template: html`
              <div style="margin: 8px 0">
                <work-status-display
                  status="to.responsibleForSigningWorkStatus"
                </work-status-display>
              </div>
            `,
            expressionProperties: {
              'templateOptions.responsibleForSigningWorkStatus': (
                viewValue: any,
                modelValue: any,
                scope: any,
              ) => {
                return scope.model.responsibleForSigningWorkStatus;
              },
              'templateOptions.hide': () =>
                !contract.responsible_for_signing || !this.$rootScope.user.settings.USE_WORK_STATUS,
            },
          },
          {
            key: 'regional_managers',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Sales managers'),
              resource: 'accounts.RegionalManager',
            },
          },
          {
            key: 'regional_manager_heads',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Heads of sale managers'),
              resource: 'accounts.RegionalManager',
            },
          },
          {
            key: 'approval_config',
            type: 'gt-ui-select',
            defaultValueResolve: () => {
              return this.AccountsService.getDefaultApprovalConfigId(
                this.getApprovalContractModel(contract),
              );
            },
            templateOptions: {
              label: this.gettext('Approval Config'),
              resource: 'accounts.ApprovalConfig',
              addFunc: () => {
                this.$window.open('/admin/accounts/approvalconfig/');
              },
              addIcon: 'fa fa-cogs',
              addPerms: true,
              queryParams: () => {
                return {
                  object_type: this.getApprovalContractModel(contract),
                  is_active: 1,
                  bu_list: contract.business_unit,
                };
              },
            },
          },
          {
            key: 'quality_rule_template',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Quality rule Config'),
              resource: 'crops.QualityRuleTemplate',
              addFunc: () => {
                this.$window.open('/admin/crops/qualityruletemplate/');
              },
              addIcon: 'fa fa-flask',
              addPerms: true,
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.id',
            },
          },
          {
            key: 'sap_orders',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Sap Orders'),
              resource: 'contracts.saporder',
              addFunc: () => {
                this.$window.open('/admin/contracts/saporder/add/');
              },
              addPerms: ['add_saporder'],
            },
          },
          {
            key: 'folder_number',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Folder number'),
              placeholder: this.gettext('Folder number'),
              addon: this.gettext('#'),
            },
          },
          {
            key: 'bill_od_lading',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Bill of lading'),
              placeholder: this.gettext('Bill of lading'),
            },
          },
          {
            key: 'budget',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Budget'),
              resource: 'finances.Budget',
              addFunc: () => {
                this.$window.open('/admin/finances/budget/add/');
              },
              addPerms: true,
              addIcon: 'fa fa-dollar',
              title: contract.budget_title,
            },
          },
          {
            key: 'additional_info',
            type: 'gt-textarea',
            templateOptions: {
              label: this.gettext('Additional Info'),
              placeholder: this.gettext('Specific information about this object'),
              tooltip: contract.additional_info,
              className: 'additional-info',
            },
          },
          {
            key: 'type_of_activities',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Type of activities'),
              resource: 'core.TypeOfActivity',
              hint: this.gettext('If you want to add type or activity to this company'),
              addFunc: () => this.$window.open('/admin/core/typeofactivity/add/'),
              addPerms: true,
              addIcon: 'fa fa-tasks',
            },
          },
          {
            template: html`
              <custom-values-container
                filter-level="'contract-custom-values-container'"
                init-query-params="{
                  page_size: 1000000,
                  object_id: model.id,
                  purpose: model.content_type,
                  purpose_model: 'contractbase',
                }"
                mode="model.id ? 'edit' : 'create'"
                object-id="model.id"
              ></custom-values-container>
            `,
          },
        ],
      },
    ];

    col2.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: '',
        },
        fieldGroup: [
          {
            key: 'volume',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Volume'),
              placeholder: this.gettext('Type volume'),
              type: 'number',
              addon: this.gettext('t'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.quantity',
              'templateOptions.hide': () =>
                Boolean(
                  contract._show_contractprice ||
                    (contract.contract_prices && contract.contract_prices.length > 0),
                ),
            },
          },
          {
            key: 'volume_options',
            type: 'gt-input',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.volume_options,
            templateOptions: {
              label: this.gettext('Tolerance'),
              placeholder: this.gettext('Type tolerance'),
              addon: this.gettext('0-100'),
              type: 'number',
            },
          },
          {
            key: 'volume_min',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('volume min'),
              placeholder: this.gettext('Type volume min'),
              type: 'number',
              addon: this.gettext('t'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.quantity',
            },
          },
          {
            key: 'volume_max',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('volume max'),
              placeholder: this.gettext('Type volume max'),
              type: 'number',
              addon: this.gettext('t'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.quantity',
            },
          },
          {
            key: 'volume_options_company',
            type: 'gt-select',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.volume_options_company,
            templateOptions: {
              label: this.gettext('Option'),
              placeholder: this.gettext('option'),
              hint: this.gettext('Choose who decide the option'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('Buyer'), value: 'buyer' },
                { name: this.gettext('Seller'), value: 'seller' },
              ],
            },
          },
          {
            key: 'measurement',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('measurement'),
              placeholder: this.gettext('measurement'),
              resource: 'stockexchanges.measurement',
              addFunc: () => {
                this.$window.open('/admin/stockexchanges/measurement/add/');
              },
              addIcon: 'fa fa-flask',
              addPerms: true,
            },
          },
          {
            key: 'quantity',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Quantity of measurement'),
              placeholder: this.gettext('Type quantity'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.measurement,
            },
          },
          {
            key: 'measurement_price',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Price for measurement'),
              placeholder: this.gettext('Type price'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.measurement,
            },
          },
          {
            key: 'measurement_price_kg',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Price for measurement / kg'),
              placeholder: this.gettext('Type price'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.measurement,
            },
          },
          {
            key: 'quantity_containers',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('quantity of containers'),
              placeholder: this.gettext('Type quantity'),
              type: 'number',
            },
          },
          {
            key: 'crop_year',
            type: 'gt-ui-select',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
            templateOptions: {
              label: this.gettext('Crop Year'),
              placeholder: this.gettext('Crop Year'),
              resource: 'core.cropyear',
            },
          },
          {
            key: 'volume_with_docs',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Volume With Docs (T)'),
              placeholder: this.gettext('Type volume'),
              type: 'number',
              addon: this.gettext('t'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.volume',
            },
          },
          {
            key: 'final_volume',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Final Volume (T)'),
              placeholder: this.gettext('Type volume'),
              type: 'number',
              addon: this.gettext('t'),
              help: this.gettext('Will be filled automatically, when you set cargo execution'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.volume',
              'templateOptions.hide': () => contract.stage === 'ticket',
            },
          },
          {
            key: 'volume_estimated_open',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Estimated Q (T)'),
              placeholder: this.gettext('Type volume'),
              type: 'number',
              addon: this.gettext('t'),
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.stage === 'ticket',
            },
          },
          {
            key: 'shipment_type',
            type: 'gt-select',
            defaultValue: 'one_full_cargo',
            templateOptions: {
              label: this.gettext('Shipment type'),
              placeholder: this.gettext('Choose type'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('One full cargo'), value: 'one_full_cargo' },
                {
                  name: this.gettext('Partial shipment allowed'),
                  value: 'partial_allowed',
                },
              ],
            },
          },
          {
            key: 'loading_option',
            type: 'gt-select',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Loading option'),
              placeholder: this.gettext('Choose option'),
              valueProp: 'value',
              labelProp: 'name',
              options: [
                { name: this.gettext('Not selected'), value: 0 },
                { name: this.gettext('Loading plus'), value: 2 },
                { name: this.gettext('Loading minus'), value: 1 },
              ],
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.stage === 'ticket',
            },
          },
          {
            key: 'region',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Region'),
              addPerms: ['add_region'],
              queryParams: () => {
                return { country: contract.origin_of_crop };
              },
              addFunc: () => this.$window.open('/admin/location/region/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              resource: 'location.region',
            },
          },
          {
            key: 'district',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('District'),
              resource: 'location.District',
              addPerms: ['add_district'],
              placeholder: this.gettext('a district'),
              queryParams: () => {
                return { regions: contract.regions };
              },
              addFunc: () => this.$window.open('/admin/location/district/add/'),
              addIcon: 'fa fa-location-arrow',
            },
          },
          {
            key: 'sampling_condition',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Sampling Condition'),
              resource: 'contracts.samplingcondition',
            },
          },
          {
            key: 'counterparty_business_reference',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Counterparty business reference'),
              tooltip: () => {
                return contract.counterparty_business_reference;
              },
            },
          },
          {
            key: 'guarantor',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('guarantor'),
              placeholder: this.gettext('guarantor'),
              resource: 'clients.Other',
              title: contract.guarantor_name,
            },
          },
          {
            key: 'exporters',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Exporters'),
              hint: this.gettext(
                'In the case, if contract is PURCHASE - then EXPORTERS are chosen amont the exporters of SUPPLIER of this contract' +
                  'If the contract is SALE, then EXPORTERS are chose from PURCHASE contracts, that are linked with this SALE contract' +
                  'via passport. So, to add Exporters to Sale contract, at first, link this Sale contract with other Purchase contracts via Passport.',
              ),
              resource: 'clients.Exporter',
              addFunc: () => this.ClientsService.roleModal({ model_name: 'Exporter' }),
              addIcon: this.GtUtils.getIcon('clients.Exporter'),
              addPerms: ['add_exporter'],
            },
          },
          {
            key: 'agent',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Agent'),
              resource: 'clients.Clientrole',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Broker' });
              },
              queryParams: () => {
                return { is_agent: 1 };
              },
              addIcon: this.GtUtils.getIcon('clients.Broker'),
              addPerms: ['add_broker'],
              title: contract.agent_name,
            },
          },
          {
            key: 'broker',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Broker'),
              resource: 'clients.Broker',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Broker' });
              },
              addIcon: this.GtUtils.getIcon('clients.Broker'),
              addPerms: ['add_broker'],
              title: contract.broker_name,
            },
          },
          {
            key: 'broker_contacts',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Broker details'),
              resource: 'clients.Person',
              addFunc: () => {
                return this.ClientsService.personModal({ model_name: 'Broker' });
              },
              addIcon: this.GtUtils.getIcon('clients.Person'),
              addPerms: ['add_person'],
              getQueryParams: () => {
                return {
                  is_verified: '1',
                  companies_role_list: contract.broker,
                };
              },
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.stage === 'ticket',
            },
          },
          {
            key: 'broker_cost_t',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Broker cost'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.broker',
              'templateOptions.hide': () => Boolean(contract.id),
            },
          },
          {
            key: 'customs_broker',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Customs broker'),
              placeholder: this.gettext('Customs broker'),
              resource: 'clients.clientrole',
              queryParams: () => ({ role_name: 'customs_broker' }),
            },
          },
          {
            key: 'surveyor',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Surveyor'),
              resource: 'clients.Surveyor',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Surveyor' });
              },
              addIcon: this.GtUtils.getIcon('clients.Surveyor'),
              addPerms: ['add_surveyor'],
              title: contract.surveyor_name,
            },
          },
          {
            key: 'insurer',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Insurer'),
              resource: 'clients.Insurer',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Insurer' });
              },
              addIcon: this.GtUtils.getIcon('clients.Insurer'),
              addPerms: ['add_insurer'],
              title: contract.insurer_name,
            },
          },
          {
            key: 'bank',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Bank'),
              resource: 'clients.Bank',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Bank' });
              },
              addIcon: this.GtUtils.getIcon('clients.Bank'),
              addPerms: ['add_bank'],
              title: contract.bank_name,
            },
          },
          {
            key: 'notify_party',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Notify party'),
              placeholder: this.gettext('Notify party'),
              resource: 'clients.Other',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Other' });
              },

              addIcon: this.GtUtils.getIcon('clients.Other'),
              addPerms: ['add_other'],
            },
          },
          {
            key: 'notify_party_second',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Notify party 2'),
              placeholder: this.gettext('Notify party 2'),
              resource: 'clients.Other',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Other' });
              },

              addIcon: this.GtUtils.getIcon('clients.Other'),
              addPerms: ['add_other'],
            },
          },
          {
            key: 'notify_party_third',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Notify party 3'),
              placeholder: this.gettext('Notify party 3'),
              resource: 'clients.Other',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Other' });
              },

              addIcon: this.GtUtils.getIcon('clients.Other'),
              addPerms: ['add_other'],
            },
          },
          {
            key: '_show_contract_consignees',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Few consignees'),
              hint: this.gettext('Check this if you want add multiple consignees'),
              onChange: () => {
                if (!contract._show_contract_consignees) {
                  contract.contract_consignees = [];
                } else if (contract._show_contract_consignees) {
                  contract.consignee = null;
                }
              },
            },
          },
          {
            key: 'consignee',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Consignee'),
              placeholder: this.gettext('Consignee'),
              resource: 'clients.clientrole',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Other' });
              },

              addIcon: this.GtUtils.getIcon('clients.Other'),
              addPerms: ['add_other'],
            },
            expressionProperties: {
              'templateOptions.hide': () => Boolean(contract._show_contract_consignees),
            },
          },
          {
            key: 'contract_consignees',
            type: 'gt-consignee-list',
            templateOptions: {
              label: this.gettext('Consignee list'),
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract._show_contract_consignees,
            },
          },
        ],
      },
    ];

    col3.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('PRICE'),
        },
        fieldGroup: [
          {
            key: '_show_derivatives',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Derivatives & Rolling'),
              hint: this.gettext('Check this if you to show derivatives widget'),
              onChange: () => {
                if (!contract._show_derivatives) {
                  contract.derivatives = [];
                }
              },
            },
          },
          {
            key: 'derivatives',
            type: 'gt-list-derivatives',
            templateOptions: {
              label: this.gettext('Derivatives list'),
              contractId: contract?.id || -1,
              contractCargo: contract?.cargo,
              contractCurrency: contract?.currency,
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract._show_derivatives,
            },
          },
          {
            key: 'use_open_price',
            type: 'gt-checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.gettext('Use open price'),
            },
          },
          {
            key: 'price',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Price'),
              placeholder: this.gettext('Price'),
              type: 'number',
              expressionProperties: {
                'templateOptions.hide': () => contract.use_open_price,
              },
            },
            expressionProperties: {
              'templateOptions.disabled': 'model.price_premium',
            },
          },
          {
            key: 'price_indicative',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Price (with costs, without VAT)'),
              placeholder: this.gettext('Price indicative'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.price_indicative,
            },
          },
          {
            key: 'price_indicative_default_currency',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Price (with costs, without VAT), $'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.price_indicative_default_currency,
            },
          },
          {
            key: 'price_indicative_with_vat',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Price (with costs, with VAT)'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.price_indicative_with_vat,
            },
          },
          {
            key: 'parent_indicator_price',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Indicator price'),
              placeholder: this.gettext('Indicator price'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
              required: false,
              disabled: true,
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.parent_indicator_price,
            },
          },
          {
            key: 'fixed_price_bonus',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Fixed price bonus'),
              placeholder: this.gettext('Fixed price bonus'),
              type: 'number',
              required: false,
              expressionProperties: {
                'templateOptions.hide': () => !contract.use_open_price,
              },
            },
          },
          {
            key: 'analytical_price',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('analytical price'),
              placeholder: this.gettext('analytical price'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
          },
          {
            key: 'currency',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'purchase' &&
              this.$rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE
                ? this.FinancesService.getLocalCurrencyId()
                : this.FinancesService.getDefaultCurrencyId(),
            templateOptions: {
              label: this.gettext('Currency'),
              placeholder: this.gettext('USD, UAH'),
              resource: 'finances.Currency',
              addFunc: () => {
                this.$window.open('/admin/finances/currency/add/');
              },
              addIcon: 'fa-dollar',
              addPerms: ['add_currency'],
              required: true,
              title: contract.currency_symbol,
              onSelect: () => {
                if (!contract.id) {
                  contract.currency_opposite = contract.currency;
                  contract.freight_currency_opposite = contract.currency;
                }
              },
            },
          },
          {
            key: 'currency_exchange',
            type: 'gt-ui-select',
            defaultValueResolve: () =>
              contract.contract_type === 'purchase' &&
              this.$rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE
                ? this.FinancesService.getLocalCurrencyExchangeId()
                : Promise.resolve(),
            templateOptions: {
              label: this.gettext('Exchange rate'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                this.$window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                if (!contract.id) {
                  if (!contract.conclusion_date) {
                    return { local_currency: contract.currency };
                  } else {
                    return {
                      local_currency: contract.currency,
                      to_date: formatDate(contract.conclusion_date, 'dd.MM.yyyy'),
                    };
                  }
                }
                return { local_currency: contract.currency };
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currencyexchange'],
              help: this.gettext(
                'Fill it only if you want this contract to be converted by a certain rate',
              ),
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.currency',
              'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                isExchangeFieldRequired &&
                scope.model.currency !== this.FinancesService.getDefaultCurrency().id,
            },
          },
          {
            key: 'conclusion_date_currency',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Conclusion date currency'),
              placeholder: this.gettext('USD, UAH'),
              resource: 'finances.Currency',
              addFunc: () => {
                this.$window.open('/admin/finances/currency/add/');
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currency'],
              title: contract.conclusion_date_currency_symbol,
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !this.$rootScope.user.settings.CONCLUSION_DATE_CURRENCY_FIXATION,
            },
          },
          {
            key: 'conclusion_date_currency_exchange',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Conclusion date exchange rate'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                this.$window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                return { local_currency: contract.conclusion_date_currency };
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currencyexchange'],
              title: contract.conclusion_date_currency_exchange_title,
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.conclusion_date_currency',
              'templateOptions.hide': () =>
                !this.$rootScope.user.settings.CONCLUSION_DATE_CURRENCY_FIXATION,
            },
          },
          {
            key: 'VAT_option',
            type: 'gt-checkbox',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.default_vat,
            templateOptions: {
              label: this.gettext('VAT'),
              onChange: () => {
                if (contract.VAT_option) {
                  contract.VAT_value = this.$rootScope.user.settings.VAT_VALUE;
                } else {
                  contract.VAT_value = 0;
                  contract.vat_return_rate = 0;
                }
              },
            },
          },
          {
            key: 'VAT_value',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('VAT value'),
              placeholder: this.gettext('0-100'),
              type: 'number',
              addon: this.gettext('%'),
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract.VAT_option,
            },
          },
          {
            key: 'vat_return_rate',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('vat return rate'),
              defaultValue: 0,
              placeholder: this.gettext('0-100'),
              type: 'number',
              addon: this.gettext('%'),
            },
            expressionProperties: {
              'templateOptions.hide': '!model.VAT_option',
            },
          },
          {
            key: '_priceWoVat',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Price without VAT'),
              type: 'number',
              disabled: true,
            },
            expressionProperties: {
              'templateOptions.hide': '!model.VAT_option',
            },
          },
          {
            key: '_priceVatValue',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Price VAT'),
              type: 'number',
              disabled: true,
            },
            expressionProperties: {
              'templateOptions.hide': '!model.VAT_option',
            },
          },
          {
            key: 'discount',
            type: 'gt-input',
            defaultValue: 100,
            templateOptions: {
              label: this.gettext('Discount, %'),
              placeholder: this.gettext('0-200'),
              hint: this.gettext('Pick from 0 to 200, use this or discount amount'),
              addon: this.gettext('%'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': '!!model.discount_amount',
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 200
                );
              },
            },
          },
          {
            key: 'discount_amount',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Amount of discount'),
              placeholder: this.gettext('Use this or %'),
              hint: this.gettext('Appoint an amount of discount or use discount in %'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': 'model.discount != 100',
            },
          },
          {
            key: 'discount_mt',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Discount per 1 mt'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.disabled': () => {
                return contract.discount != 100 || contract.discount_amount != 0;
              },
            },
          },
          {
            key: 'basis',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'purchase'
                ? this.$rootScope.user.settings.DEFAULT_VALUES.basis_purchase
                : this.$rootScope.user.settings.DEFAULT_VALUES.basis_sale,
            templateOptions: {
              label: this.gettext('Basis'),
              placeholder: this.gettext('Choose option'),
              resource: 'logistics.Basis',
              addFunc: () => {
                this.$window.open('/admin/logistics/basis/add/');
              },
              addIcon: 'fa fa-anchor',
              addPerms: ['add_basis'],
              title: contract.basis_name,
            },
            expressionProperties: {
              'templateOptions.hide': '!!model._show_contractprice',
            },
          },
          {
            key: 'ports',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Ports'),
              resource: 'logistics.port',
              hint: this.gettext('You can pick up multiple objects, clicking with CTRL'),
              addFunc: () => {
                this.$window.open('/admin/logistics/port/add/', '_blank');
              },
              addIcon: this.GtUtils.getIcon('logistics.Port'),
              addPerms: ['add_port'],
            },
            expressionProperties: {
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                if (
                  this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                  scope.model.deal_type !== 'services' &&
                  scope.model.basis &&
                  !scope.model._show_contractprice
                ) {
                  return this.GtUtils.getResource('logistics.basis')
                    .get({ id: scope.model.basis })
                    .$promise.then(
                      (basis: any) =>
                        (scope.to.required = ['FOB', 'CIF', 'CFR'].includes(basis.codename)),
                    );
                } else if (scope.to.initRequired) {
                  return true;
                } else {
                  return false;
                }
              },
              'templateOptions.hide': '!!model._show_contractprice',
            },
          },
          {
            key: 'origin_of_crop',
            type: 'gt-ui-select',
            defaultValueResolve: () => this.GtUtils.getDefaultOriginId(),
            templateOptions: {
              label: this.gettext('Origin'),
              placeholder: this.gettext('Type origin'),
              resource: 'location.Country',
              hint: this.gettext('From which country purchased'),
              addon: this.gettext('country'),
              addFunc: () => this.$window.open('/admin/location/country/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
              onSelect: () => {
                this.GtUtils.getResource('logistics.basis')
                  .get({ id: contract.basis })
                  .$promise.then((basis: any) => {
                    if (['FOB', 'EXW', 'FCA'].includes(basis.codename)) {
                      contract.destination_of_crop = contract.origin_of_crop;
                    }
                  });
              },
            },
          },
          {
            key: 'origins_of_crop',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Origin'),
              resource: 'location.Country',
              addon: this.gettext('country'),
              addFunc: () => this.$window.open('/admin/location/country/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
            },
          },
          {
            key: 'destination_of_crop',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Destination'),
              placeholder: this.gettext('Type destination'),
              resource: 'location.Country',
              hint: this.gettext('To which country delivered'),
              addon: this.gettext('country'),
              addFunc: () => {
                this.$window.open('/admin/location/country/add/');
              },
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
            },
            expressionProperties: {
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                if (
                  this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                  scope.model.deal_type !== 'services' &&
                  scope.model.contract_type === 'sale' &&
                  scope.model.basis
                ) {
                  return this.GtUtils.getResource('logistics.basis')
                    .get({ id: scope.model.basis })
                    .$promise.then(
                      (basis: any) => (scope.to.required = ['CIF', 'CFR'].includes(basis.codename)),
                    );
                } else {
                  return false;
                }
              },
            },
          },
          {
            key: 'destinations_of_crop',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Destinations'),
              resource: 'location.Country',
              hint: this.gettext('To which country delivered'),
              addon: this.gettext('country'),
              addFunc: () => this.$window.open('/admin/location/country/add/', '_blank'),
              addIcon: 'fa fa-location-arrow',
              addPerms: ['add_country'],
            },
          },
          {
            key: '_showMtmFields',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Opposite details'),
              placeholder: this.gettext('Opposite details'),
            },
          },
          {
            key: 'currency_opposite',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'purchase' &&
              this.$rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE
                ? this.FinancesService.getLocalCurrencyId()
                : this.FinancesService.getDefaultCurrencyId(),
            templateOptions: {
              label: this.gettext('Currency opposite'),
              placeholder: this.gettext('USD, UAH'),
              resource: 'finances.Currency',
            },
            expressionProperties: {
              'templateOptions.hide': '!model._showMtmFields && !model.currency_opposite',
            },
          },
          {
            key: 'currency_exchange_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Exchange rate'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                this.$window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                return { local_currency: contract.currency_opposite };
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currencyexchange'],
              help: this.gettext(
                'Fill it only if you want this contract to be converted by a certain rate',
              ),
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.currency_opposite',
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.currency_exchange_opposite,
            },
          },
          {
            key: 'price_opposite',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Opposite price'),
              placeholder: this.gettext('Opposite price'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
              required: false,
              help: this.gettext('Fill if you want to calculate MTM by this contract'),
            },
            expressionProperties: {
              'templateOptions.hide': () => Boolean(contract.derivative_opposite),
            },
          },
          {
            key: 'costs_opposite',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Costs opposite'),
              placeholder: this.gettext('Costs opposite'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract._showMtmFields && !contract.costs_opposite,
            },
          },
          {
            key: 'opposite_freight_level',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Freight opposite'),
              placeholder: this.gettext('Freight level'),
              type: 'number',
              addon: this.gettext('per 1 mt'),
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.opposite_freight_level,
            },
          },
          {
            key: 'freight_currency_opposite',
            type: 'gt-ui-select',
            defaultValue:
              contract.contract_type === 'purchase' &&
              this.$rootScope.user.settings.USE_LOCAL_CURRENCY_FOR_PURCHASE
                ? this.FinancesService.getLocalCurrencyId()
                : this.FinancesService.getDefaultCurrencyId(),
            templateOptions: {
              label: this.gettext('Freight currency opposite'),
              placeholder: this.gettext('USD, UAH'),
              resource: 'finances.Currency',
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.freight_currency_opposite,
            },
          },
          {
            key: 'freight_currency_exchange_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Freight exchange rate'),
              resource: 'finances.CurrencyExchange',
              addFunc: () => {
                this.$window.open('/admin/finances/currencyexchange/add/');
              },
              queryParams: () => {
                return { local_currency: contract.freight_currency_opposite };
              },
              addIcon: 'fa fa-money',
              addPerms: ['add_currencyexchange'],
              help: this.gettext(
                'Fill it only if you want this contract to be converted by a certain rate',
              ),
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.freight_currency_opposite',
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.freight_currency_exchange_opposite,
            },
          },
          {
            key: 'basis_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Opposite basis'),
              placeholder: this.gettext('Choose option'),
              resource: 'logistics.Basis',
              addFunc: () => {
                this.$window.open('/admin/logistics/basis/add/');
              },
              addIcon: 'fa fa-anchor',
              addPerms: ['add_basis'],
            },
            expressionProperties: {
              'templateOptions.hide': () => !contract._showMtmFields && !contract.basis_opposite,
            },
          },
          {
            key: 'counterparty_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Opposite counterparties'),
              placeholder: this.gettext('Opposite counterparties'),
              resource: 'clients.Client',
              required: false,
              queryParams: () => {
                if (contract.contract_type == 'sale') {
                  return { role_name: 'supplier' };
                } else {
                  return { role_name: 'buyer' };
                }
              },
              addFunc: () => {
                this.$window.open('/admin/clients/client/add/');
              },
              addIcon: this.GtUtils.getIcon('clients.Client'),
              addPerms: ['add_client'],
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.counterparty_opposite,
            },
          },
          {
            key: 'market_zone',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('market zone'),
              placeholder: this.gettext('Port'),
              resource: 'logistics.Port',
            },
          },
          {
            key: 'ports_opposite',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Ports opposite'),
              resource: 'logistics.port',
              hint: this.gettext('You can pick up multiple objects, clicking with CTRL'),
              addFunc: () => {
                this.$window.open('/admin/logistics/port/add/', '_blank');
              },
              addIcon: this.GtUtils.getIcon('logistics.Port'),
              addPerms: ['add_port'],
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.ports_opposite?.length,
            },
          },
          {
            key: 'commodity_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Commodity opposite'),
              placeholder: this.gettext('Choose commodity'),
              resource: 'crops.Crop',
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.commodity_opposite,
            },
          },
          {
            key: 'date_of_execution_opposite',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('date of execution opposite'),
              placeholder: this.gettext('date'),
              useWatch: true,
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.date_of_execution_opposite,
            },
          },
          {
            key: 'price_premium_opposite',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Premium price opposite'),
              placeholder: this.gettext('Premium price opposite'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.hide': () =>
                !contract._showMtmFields && !contract.price_premium_opposite,
            },
          },
          {
            key: 'derivative_opposite',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Opposite derivative'),
              placeholder: this.gettext('derivative'),
              resource: 'stockexchanges.Derivative',
              addFunc: () => {
                this.$window.open('/admin/stockexchanges/derivative/add/');
              },
              addIcon: 'fa fa-bolt',
              addPerms: ['add_derivative'],
            },
            expressionProperties: {
              'templateOptions.hide': () => Boolean(contract.price_opposite),
            },
          },
        ],
      },
    ];

    col4.fieldGroup = [
      {
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('EXECUTION'),
          defaultHide: true,
        },
        fieldGroup: [
          {
            key: 'estimated_date_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('Delivery estimated month'),
              startView: 'month',
              minView: 'month',
            },
          },
          {
            key: 'date_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('Delivery (start date)'),
              placeholder: this.gettext('date'),
              useWatch: true,
              onSelected: ($event: { date?: Date | string }) => {
                const date = typeof $event.date === 'string' ? new Date($event.date) : $event.date;
                if (contract.end_of_execution || !date) {
                  return false;
                }
                if (
                  this.$rootScope.user.settings.DEFAULT_VALUES.default_execution_duration !== 30
                ) {
                  contract.end_of_execution = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate() +
                      this.$rootScope.user.settings.DEFAULT_VALUES.default_execution_duration,
                    0,
                  );
                } else {
                  contract.end_of_execution = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                }
              },
            },
            expressionProperties: {
              'templateOptions.hide': '!!model._show_contractprice',
            },
          },
          {
            key: 'end_of_execution',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('Delivery (end date)'),
              placeholder: this.gettext('date'),
              useWatch: true,
              onSelected: ($event: any) => {
                const date = $event?.date;
                if (contract.date_of_execution_opposite || !date) {
                  return false;
                }
                contract.date_of_execution_opposite = contract.end_of_execution;
                contract.estimated_opposite_payment_date =
                  contract.estimated_opposite_payment_date || contract.end_of_execution;
              },
            },
            expressionProperties: {
              'templateOptions.hide': '!!model._show_contractprice',
            },
          },
          {
            key: 'expiration_start',
            type: 'gt-date-select',
            templateOptions: {
              minView: 'minute',
              label: this.gettext('Expiration start'),
              placeholder: this.gettext('Pick a time'),
              className: 'datetimepicker',
            },
          },
          {
            key: 'expiration_end',
            type: 'gt-date-select',
            templateOptions: {
              minView: 'minute',
              label: this.gettext('Expiration end'),
              placeholder: this.gettext('Pick a time'),
              className: 'datetimepicker',
            },
          },
          {
            key: 'arrival_period_start',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('arrival period start'),
              placeholder: this.gettext('date'),
              useWatch: true,
            },
          },
          {
            key: 'arrival_period_end',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('arrival period end'),
              placeholder: this.gettext('date'),
              useWatch: true,
            },
          },

          {
            key: 'loadport',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('loadport'),
              placeholder: this.gettext('loadport'),
              resource: 'logistics.Port',
            },
          },
          {
            key: 'disport',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('disport'),
              placeholder: this.gettext('disport'),
              resource: 'logistics.Port',
            },
          },
          {
            key: 'load_rate',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('load rate'),
              placeholder: this.gettext('load rate'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                if (
                  this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                  scope.model.deal_type !== 'services' &&
                  scope.model.basis
                ) {
                  return this.GtUtils.getResource('logistics.basis')
                    .get({ id: scope.model.basis })
                    .$promise.then(
                      (basis: any) => (scope.to.required = ['FOB'].includes(basis.codename)),
                    );
                } else {
                  return false;
                }
              },
            },
          },
          {
            key: 'discharge_rate',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('discharge rate'),
              placeholder: this.gettext('discharge rate'),
              type: 'number',
            },
            expressionProperties: {
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                if (
                  this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                  scope.model.deal_type !== 'services' &&
                  scope.model.basis
                ) {
                  return this.GtUtils.getResource('logistics.basis')
                    .get({ id: scope.model.basis })
                    .$promise.then(
                      (basis: any) => (scope.to.required = ['CIF', 'CFR'].includes(basis.codename)),
                    );
                } else {
                  return false;
                }
              },
            },
          },
          {
            key: 'demmurage_rate',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('demurrage rate'),
              placeholder: this.gettext('demurrage rate'),
              type: 'number',
              addon: this.gettext('$/day'),
            },
          },
          {
            key: 'dispatch_rate',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('dispatch rate'),
              placeholder: this.gettext('dispatch rate'),
              type: 'number',
              addon: this.gettext('$/day'),
            },
          },
          {
            key: 'implied_freight_level',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Freight'),
              placeholder: this.gettext('Freight level'),
              type: 'number',
              addon: this.gettext('$/t'),
            },
          },
          {
            key: 'is_extension',
            type: 'gt-checkbox',
            defaultValue: true,
            templateOptions: {
              label: this.gettext('Is extension'),
            },
          },
          {
            key: 'is_position_closing',
            type: 'gt-checkbox',
            defaultValue: false,
            templateOptions: {
              label: this.gettext('Is closing of position'),
            },
          },
          {
            key: 'payment_conditions',
            type: 'gt-input',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions,
            templateOptions: {
              label: this.gettext('RW prepay - balance percent'),
              placeholder: this.gettext('0-100'),
              hint: this.gettext('Pick from 0 to 100 to set up prepay'),
              addon: this.gettext('%'),
              type: 'number',
              addFunc: () => {
                this.$window.open('/admin/finances/paymentcondition/');
              },
              addIcon: 'fa fa-dollar',
              addPerms: ['add_paymentcondition'],
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              },
            },
          },
          {
            key: 'payment_conditions_auto',
            type: 'gt-input',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions_auto,
            templateOptions: {
              label: this.gettext('Auto prepay - balance percent'),
              placeholder: this.gettext('0-100'),
              hint: this.gettext('Pick from 0 to 100 to set up prepay'),
              addon: this.gettext('%'),
              type: 'number',
              addFunc: () => {
                this.$window.open('/admin/finances/paymentcondition/');
              },
              addIcon: 'fa fa-dollar',
              addPerms: ['add_paymentcondition'],
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              },
            },
          },
          {
            key: 'payment_conditions_option',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Payment conditions option'),
              placeholder: this.gettext('Choose option'),
              required: true,
              resource: 'finances.PaymentCondition',
              addFunc: () => {
                this.$window.open('/admin/finances/paymentcondition/');
              },
              addIcon: 'fa fa-dollar',
              addPerms: ['add_paymentcondition'],
              title: contract.payment_conditions_option_title,
              onSelect: () => {
                this.GtUtils.getResource('finances.paymentcondition')
                  .get({ id: contract.payment_conditions_option })
                  .$promise.then((paymentConditionsOption: any) => {
                    contract.payment_days = paymentConditionsOption.days;
                  });
              },
              queryParams: () => {
                return { is_active: 1 };
              },
            },
          },
          {
            key: 'payment_conditions_options',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Payment conditions options'),
              placeholder: this.gettext('Choose options'),
              resource: 'finances.PaymentCondition',
              addFunc: () => {
                this.$window.open('/admin/finances/paymentcondition/');
              },
              addIcon: 'fa fa-dollar',
              addPerms: ['add_paymentcondition'],
              getQueryParams: () => {
                return { is_active: 1 };
              },
            },
          },
          {
            key: 'prepay_payment',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Prepay payment'),
              placeholder: this.gettext('Choose option'),
              resource: 'finances.BalancePayment',
              addIcon: this.GtUtils.getIcon('finances.PaymentCondition'),
              queryParams: { invoice_type_list: ['prepay', 'all'] },
            },
          },
          {
            key: 'pre_advice',
            type: 'gt-input',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.default_preadvice_days,
            templateOptions: {
              label: this.gettext('pre advice'),
              placeholder: this.gettext('Type days'),
              type: 'number',
            },
          },
          {
            key: 'days_for_invoice_payment',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('Number of days for payment'),
              placeholder: this.gettext('Number of days for payment'),
              type: 'number',
            },
          },
          {
            key: 'balance_payment',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Balance payment'),
              placeholder: this.gettext('Choose option'),
              resource: 'finances.BalancePayment',
              addIcon: this.GtUtils.getIcon('finances.PaymentCondition'),
              queryParams: { invoice_type_list: ['balance', 'all'] },
            },
          },
          {
            key: 'min_batch_of_payment',
            type: 'gt-input',
            defaultValue: 0,
            templateOptions: {
              label: this.gettext('Minimum batch of payment'),
              placeholder: this.gettext('Choose option'),
              type: 'number',
              required: false,
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return parseFloat($viewValue || $modelValue || 0) >= 0;
              },
            },
          },

          {
            key: 'loan_percentage',
            type: 'gt-input',
            defaultValue: 100,
            templateOptions: {
              label: this.gettext('financing - loan percentage'),
              placeholder: this.gettext('0-100'),
              hint: this.gettext('Pick from 0 to 100 to set up loan percentage'),
              addon: this.gettext('%'),
              type: 'number',
              addIcon: 'fa fa-percent',
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              },
            },
          },
          {
            key: 'interest_rate',
            type: 'gt-input',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.interest_rate,
            templateOptions: {
              label: this.gettext('financing - interest rate'),
              placeholder: this.gettext('0-100'),
              hint: this.gettext('Pick from 0 to 100 to set up interest rate'),
              addon: this.gettext('%'),
              type: 'number',
              addIcon: 'fa fa-percent',
            },
            validators: {
              notFalse: ($viewValue: any, $modelValue: any) => {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              },
            },
          },
          {
            key: 'estimated_opposite_payment_date',
            type: 'gt-date-select',
            templateOptions: {
              label: this.gettext('financing - estimated opposite payment date'),
              useWatch: true,
              placeholder: this.gettext('date'),
            },
          },
          {
            key: 'payment_days',
            type: 'gt-input',
            templateOptions: {
              label: this.gettext('financing - payment days'),
              placeholder: this.gettext('payment days'),
              type: 'number',
            },
          },
          {
            key: 'delivery_condition',
            type: 'gt-ui-select',
            defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.delivery_condition,
            templateOptions: {
              label: this.gettext('Delivery condition'),
              placeholder: this.gettext('Choose option'),
              resource: 'logistics.DeliveryCondition',
              addFunc: () => {
                this.$window.open('/admin/logistics/deliverycondition/add/');
              },
              addIcon: 'fa fa-truck',
              addPerms: ['add_deliverycondition'],
              title: contract.delivery_condition_title,
            },
          },
          {
            key: 'delivery_conditions',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Delivery conditions'),
              placeholder: this.gettext('Choose option'),
              resource: 'logistics.DeliveryCondition',
              addFunc: () => {
                this.$window.open('/admin/logistics/deliverycondition/add/');
              },
              addIcon: 'fa fa-truck',
              addPerms: ['add_deliverycondition'],
            },
          },
          {
            key: 'deliverer',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Forwarder'),
              resource: 'clients.Deliverer',
              addFunc: () => {
                return this.ClientsService.roleModal({ model_name: 'Deliverer' });
              },
              addIcon: this.GtUtils.getIcon('clients.Deliverer'),
              addPerms: ['add_deliverer'],
              title: contract.deliverer_name,
            },
          },
          {
            key: 'warehouse',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Warehouse'),
              resource: 'logistics.warehouse',
              queryParams: { is_group: '0' },
            },
          },
          {
            key: 'warehouses',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Warehouses'),
              resource: 'logistics.warehouse',
              queryParams: { is_group: '0' },
            },
          },
          {
            key: 'text_loading_address',
            type: 'gt-textarea',
            defaultValueResolve: () =>
              this.LocationService.getAddressListByIds(contract.loading_addresses),
            templateOptions: {
              label: this.gettext('Loading address'),
              placeholder: this.gettext('Loading address'),
              onBlurFunc: (address: any) => {
                if (!address) {
                  return false;
                }
                return this.LocationService.Address.query({
                  city_district: address,
                  address_types: 'Loading',
                }).$promise.then((data: any) => {
                  if (data.count) {
                    contract.loading_addresses = data.results.map((address: any) => address.id);
                    return contract.loading_addresses;
                  }
                  return this.LocationService.Address.save({
                    city_district: address.trim(),
                    address_types: ['Loading'],
                  }).$promise.then((data: any) => {
                    contract.loading_addresses = [data.id];
                  });
                });
              },
            },
          },
          {
            key: 'loading_addresses',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Loading addresses'),
              resource: 'location.address',
              required: false,
              addFunc: () => {
                this.$window.open('/admin/location/address/add/');
              },
              addIcon: 'fa-location-arrow',
              addPerms: ['add_station'],
              queryParams: { address_type: 'Loading', is_actual: '1' },
            },
          },
          {
            key: 'delivery_addresses',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Delivery addresses'),
              resource: 'location.address',
              required: false,
              addFunc: () => {
                this.$window.open('/admin/location/address/add/');
              },
              addIcon: 'fa-location-arrow',
              addPerms: ['add_station'],
              queryParams: { address_type: 'Delivery', is_actual: '1' },
            },
          },
          {
            key: 'weighing_address',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Weighing addresses'),
              resource: 'location.address',
              required: false,
              addFunc: () => {
                this.$window.open('/admin/location/address/add/');
              },
              addIcon: 'fa-location-arrow',
              addPerms: ['add_station'],
              queryParams: { address_type: 'Weighing', is_actual: '1' },
            },
          },
          {
            key: 'rerouting_address',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Rerouting addresses'),
              resource: 'location.address',
              required: false,
              addFunc: () => {
                this.$window.open('/admin/location/address/add/');
              },
              addIcon: 'fa-location-arrow',
              addPerms: ['add_station'],
              queryParams: { address_type: 'Rerouting', is_actual: '1' },
            },
          },
          {
            key: 'vehicle_restrictions',
            type: 'gt-textarea',
            templateOptions: {
              label: this.gettext('Vehicle restrictions'),
            },
          },
          {
            key: 'shipment_terminal',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Shipment Terminal'),
              placeholder: this.gettext('Shipment Terminal'),
              resource: 'logistics.Terminal',
              addFunc: () => {
                this.$window.open('/admin/logistics/terminal/add/');
              },
              addIcon: this.GtUtils.getIcon('logistics.Terminal'),
              addPerms: ['add_terminal'],
              hint: this.gettext('Contract can have only one warehouse'),
              title: contract.shipment_terminal_name,
              queryParams: { is_actual: '1' },
            },
            expressionProperties: {
              'templateOptions.disabled': '!!model.elevator || !!model.farm',
              'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                return (
                  this.$rootScope.user.settings.PURCHASE_CONTRACT_TERMINAL_REQUIRED &&
                  scope.model.contract_type === 'purchase' &&
                  scope.model.stage === 'contract' &&
                  scope.model._basis_required_terminal &&
                  scope.model._basis_required_terminal.indexOf(scope.model.basis) !== -1
                );
              },
            },
          },
          {
            key: 'customs_terminals',
            type: 'gt-ui-multiselect',
            templateOptions: {
              label: this.gettext('Customs Terminals'),
              placeholder: this.gettext('Customs Terminals'),
              resource: 'logistics.Terminal',
              addFunc: () => {
                this.$window.open('/admin/logistics/terminal/add/');
              },
              addIcon: this.GtUtils.getIcon('logistics.Terminal'),
              addPerms: ['add_terminal'],
              queryParams: { is_actual: '1' },
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.deal_type !== 'export',
            },
          },
          {
            key: 'station',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Station departure'),
              placeholder: this.gettext('Station'),
              resource: 'logistics.Station',
              addFunc: () => {
                this.$window.open('/admin/logistics/station/add/');
              },
              addIcon: this.GtUtils.getIcon('logistics.Station'),
              addPerms: ['add_station'],
              title: contract.station_name,
            },
          },
          {
            key: 'station_receiving',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Station arrival'),
              placeholder: this.gettext('Station'),
              resource: 'logistics.Station',
              addFunc: () => {
                this.$window.open('/admin/logistics/station/add/');
              },
              addIcon: this.GtUtils.getIcon('logistics.Station'),
              addPerms: ['add_station'],
              title: contract.station_receiving_name,
            },
          },
          {
            key: 'calc_intermediate_logistics',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Intermediate logistics'),
              hint: this.gettext('Use intermediate logistics in execution calculation'),
            },
          },

          {
            key: 'ship_classification',
            type: 'gt-ui-select',
            templateOptions: {
              label: this.gettext('Ship classification'),
              placeholder: this.gettext('Choose ship classification'),
              resource: 'logistics.shipclassification',
              addIcon: 'fa fa-anchor',
              addFunc: () => {
                this.$window.open('/admin/logistics/shipclassification/add/');
              },
              addPerms: ['add_shipclassification'],
            },
          },
          {
            key: 'ending_status',
            type: 'gt-select',
            templateOptions: {
              label: this.gettext('Ending status'),
              options: [
                { title: this.gettext('Open'), id: 'open' },
                { title: this.gettext('Ending'), id: 'ending' },
                { title: this.gettext('Expiring'), id: 'expiring' },
                { title: this.gettext('Expired'), id: 'expired' },
              ],
              disabled: true,
            },
          },
          {
            key: 'days_for_status_ending',
            type: 'gt-input',
            defaultValue: 10,
            templateOptions: {
              label: this.gettext("Days to set 'ending status'(end of execution)"),
              type: 'number',
            },
          },
          {
            key: 'is_according_to_contract_template',
            type: 'gt-select',
            templateOptions: {
              label: this.gettext('According to contract template'),
              options: [
                { title: this.gettext('---'), id: undefined },
                { title: this.gettext('Yes'), id: true },
                { title: this.gettext('No'), id: false },
              ],
            },
          },
          {
            key: 'has_digital_signature',
            type: 'gt-checkbox',
            templateOptions: {
              label: this.gettext('Digital signature'),
              hint: this.gettext('Check this if the contract is digitally signed'),
            },
          },
          {
            key: 'documents',
            type: 'gt-documents',
            templateOptions: {
              label: this.gettext('Contract document'),
              deleteEventSource: 'contract-modal',
              connectedField: 'is_according_to_contract_template',
              onFileSelect: contract.onFileSelect,
              mode: 'contract',
            },
            expressionProperties: {
              'templateOptions.hide': () => contract.is_according_to_contract_template !== false,
            },
          },
        ],
      },
    ];

    return [col1, col2, col3, col4];
  }

  addServiceContractFields(col1: any, col2: any, col3: any, col4: any, contract: any) {
    col2.fieldGroup[0].templateOptions.label = this.gettext('SERVICES');
    col2.fieldGroup[0].fieldGroup.unshift({
      key: 'charge',
      type: 'gt-ui-select',
      templateOptions: {
        label: this.gettext('Charge'),
        placeholder: this.gettext('Choose charge'),
        resource: 'finances.Charge',
        required: true,
        title: contract.charge_title,
        queryParams: () => {
          return {
            use_type_list: contract.charge_use_type_list || contract.positions_charge_use_type_list,
          };
        },
      },
      validation: {
        show: true,
      },
    });
  }

  addGrainContractFields(
    col1: any,
    col2: any,
    col3: any,
    col4: any,
    priceWidgetFields: any,
    contract: any,
  ) {
    const index = col1.fieldGroup[0].fieldGroup.findIndex((field: any) => field.key === 'season');
    col1.fieldGroup[0].fieldGroup.splice(index, 0, {
      key: 'certification_schemes',
      type: 'gt-ui-multiselect',
      templateOptions: {
        label: this.gettext('Certification schemes'),
        resource: 'contracts.CertificationScheme',
        addFunc: () => this.$window.open('/admin/contracts/certificationscheme/add/'),
        addPerms: true,
        addIcon: 'fa fa-tasks',
      },
      expressionProperties: {
        'templateOptions.hide': () => ['export', 'intermediate'].includes(contract.deal_type),
      },
    });
    col2.fieldGroup[0].templateOptions.label = this.gettext('PRODUCT');
    col2.fieldGroup[0].fieldGroup.unshift({
      key: 'cargo',
      type: 'gt-ui-select',
      templateOptions: {
        label: this.gettext('Product'),
        placeholder: this.gettext('Choose product'),
        resource: 'crops.Crop',
        required: true,
        queryParams: () => {
          return {
            origin: contract.origin_of_crop ? contract.origin_of_crop : null,
            origin_list: contract.origins_of_crop ? contract.origins_of_crop : null,
            business_unit: this.$rootScope.user.profile?.business_units_ids
              ? this.$rootScope.user.profile.business_units_ids
              : null,
          };
        },
        addFunc: () => this.$window.open('/admin/crops/crop/add/'),
        onSelect: () => (contract.commodity_opposite = contract.cargo),
        addIcon: 'fa fa-wheat-awn',
        addPerms: ['add_crop'],
        title: contract.crop_title,
      },
      validation: {
        show: true,
      },
      expressionProperties: {
        'templateOptions.hide': () =>
          Boolean(
            contract._show_contractprice ||
              (contract.contract_prices && contract.contract_prices.length > 0),
          ),
      },
    });
    col3.fieldGroup[0].fieldGroup.unshift(
      {
        key: '_show_contractprice',
        type: 'gt-checkbox',
        templateOptions: {
          label: this.gettext('Few prices'),
          hint: this.gettext('Check this if you to show basis-port-price widget'),
          onChange: () => {
            if (!contract._show_contractprice) {
              contract.contract_prices = [];
            } else if (contract._show_contractprice) {
              contract.basis = null;
              contract.ports = [];
            }
          },
        },
      },
      {
        key: 'contract_prices',
        type: 'gt-choice-list',
        templateOptions: {
          label: this.gettext('Price options'),
          contractType: contract.contract_type,
          contractId: contract?.id || -1,
          contractCargo: contract?.cargo,
          contractCurrency: contract?.currency,
          contractOption: contract?.contract_option,
          premiumBonusPercentage: contract?.premium_bonus_percentage,
          contractDealType: contract?.deal_type,
          fieldsConfig: priceWidgetFields,
        },
        expressionProperties: {
          'templateOptions.hide': '!model._show_contractprice',
        },
      },
    );
  }

  addPurchaseContractFields(col1: any) {
    this.ContractsService.getMainContractOptions().then((defaultMainOption: any) => {
      col1.fieldGroup[0].fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {
          label: this.gettext('LOYALITY PROGRAM'),
        },
        fieldGroup: [
          {
            key: 'contract_option',
            type: 'gt-ui-select',
            defaultValue: defaultMainOption.id,
            templateOptions: {
              label: this.gettext('Contract Option'),
              resource: 'contracts.ContractOption',
              addFunc: () => {
                this.$window.open('/admin/contracts/contractoption/add/');
              },
              addPerms: ['add_contractoption'],
            },
          },
          {
            template: html`
              <loyality-program-data
                obj="model"
                date-from="model.estimated_date_execution || model.date_of_execution"
                date-to="model.estimated_date_execution || model.end_of_execution"
                bonus-percentage="model.premium_bonus_percentage"
                filter-level="contract-form"
              >
              </loyality-program-data>
            `,
            expressionProperties: {
              'templateOptions.hide': '!!model._show_contractprice',
            },
          },
        ],
      });
    });
  }
}
ContractFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'CoreService',
  'AccountsService',
  'ClientsService',
  'ContractsService',
  'LocationService',
  'FormFieldParamsService',
  'FinancesService',
];
