import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';
export class FinancesAnalyticsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $log: ng.ILogService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  analyticsData: any;

  static readonly $inject = ['$resource', '$q', '$http', '$filter', '$log'];
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    $log: ng.ILogService,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.$log = $log;

    this.analyticsData = undefined;
  }

  getAnalyticsData(params?: any) {
    if (!params && this.analyticsData) {
      return this.$q.when().then(() => this.analyticsData);
    }
    this.analyticsData = this.$http({
      method: 'GET',
      url: '/api/finances/analytics/',
      params: params,
    }).then((response: any) => {
      this.analyticsData = response.data;
      return this.analyticsData;
    });
    return this.analyticsData;
  }

  getCountsByMonthChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Payments incoming', 'Payments outgoing'],
    };

    return this.getAnalyticsData().then((data: any) => {
      let dates: any = [];
      data.payments_by_month.forEach((i: any) => dates.push(i.group_date_month));
      dates = this.$filter<(str: string) => string>('orderBy')(dates);
      dates = dates.map((d: any) => formatDate(d, 'MMM, yy'));
      dates = dates.reduce((a: any, b: any) => {
        if (a.indexOf(b) < 0) {
          a.push(b);
        }
        return a;
      }, []);
      dates.forEach((d: any) => {
        chartConfig.labels.push(d);
        const payments = data.payments_by_month
          .filter((i: any) => {
            return formatDate(i.group_date_month, 'MMM, yy') == d;
          })
          .shift();
        chartConfig.data[0].push(payments?.income_count || 0);
        chartConfig.data[1].push(payments?.outgone_count || 0);
      });

      return chartConfig;
    });
  }

  getAmountsByMonthChartConfig(params: { use: string }) {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Incoming amount', 'Outgoing amount'],
    };

    if (!params.use) {
      this.$log.warn('Use is undefined!');
      return this.$q.when(chartConfig);
    }
    return this.getAnalyticsData(params).then((data: any) => {
      data.finances_by_month_use.forEach((i: any) => {
        chartConfig.labels.push(formatDate(i.group_date_month, 'MMM, yy'));
        chartConfig.data[0].push(Math.round(i.incoming_amount_USD * 100) / 100);
        chartConfig.data[1].push(Math.round(i.outgoing_amount_USD * 100) / 100);
      });

      return chartConfig;
    });
  }

  getTopOutgoingFinancesData() {
    return this.getAnalyticsData().then((data: any) => data.top_outgoing_finances);
  }

  getTopIncomingFinancesData() {
    return this.getAnalyticsData().then((data: any) => data.top_incoming_finances);
  }

  getTopOutgoingPaymentsData() {
    return this.getAnalyticsData().then((data: any) => data.top_outgoing_payments);
  }

  getTopIncomingPaymentsData() {
    return this.getAnalyticsData().then((data: any) => data.top_incoming_payments);
  }

  getTotalFinances() {
    return this.getAnalyticsData().then((data: any) => data.total_amout_count);
  }

  makePaymentDaysList(paymentDays: any) {
    const daysCount: any = {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      more: 0,
    };
    const newDays: any = [];
    paymentDays.forEach((i: any) => {
      daysCount[i.group_date > 3 ? 'more' : String(i.group_date)] += i.group_date_sum;
    });
    Object.keys(daysCount).forEach((k) => {
      newDays.push({
        group_date: k,
        group_date_sum: daysCount[k],
      });
    });
    return newDays;
  }

  getIncomingPaymentDaysChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Days: [],
      },
    };

    return this.getAnalyticsData().then((data: any) => {
      this.makePaymentDaysList(data.incoming_payment_days).forEach((i: any) => {
        chartConfig.labels.push(i.group_date + ' days');
        chartConfig.data.Days.push(i.group_date_sum);
      });

      return chartConfig;
    });
  }

  getIncomingPaymentDaysData() {
    return this.getAnalyticsData().then((data: any) =>
      this.makePaymentDaysList(data.incoming_payment_days),
    );
  }

  getOutgoingPaymentDaysChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Days: [],
      },
    };

    return this.getAnalyticsData().then((data: any) => {
      this.makePaymentDaysList(data.outgoing_payment_days).forEach((i: any) => {
        chartConfig.labels.push(i.group_date + ' days');
        chartConfig.data.Days.push(i.group_date_sum);
      });

      return chartConfig;
    });
  }

  getOutgoingPaymentDaysData() {
    return this.getAnalyticsData().then((data: any) =>
      this.makePaymentDaysList(data.outgoing_payment_days),
    );
  }
}
