import React from 'react';

import type { ColumnConfig } from '~/core/page-views';
import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

import type { LogisticCreate, LogisticList, LogisticUpdate } from '../../lib';
import { getLogisticColumns } from '../../lib';
import type { LogisticListViewModel } from '../../services/logistic-list.view';

export const LogisticContainer: React.FC<{
  viewModel: LogisticListViewModel;
  viewMode: 'spreadsheet' | 'grid';
}> = ({ viewModel, viewMode }) => {
  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const table = useObservableEagerState(viewModel.table$);

  const createdDataRef = React.useRef<LogisticCreate[]>([]);
  const editedDataRef = React.useRef<LogisticUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder<LogisticList>({
        columns,
        // TO DO: @oleksii-gt fix typing
        columnsConfig: getLogisticColumns(table, pageData.results) as ColumnConfig<LogisticList>[],
      }),
    [columns, table, pageData.results],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.logisticsUpdated(editedDataRef.current);
      await viewModel.logisticsCreated(createdDataRef.current);
      viewModel.setEditing(false);
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);
  return (
    <section className="mt-2 !flex flex-col gap-3" data-testid="logistic-container">
      <DatasetView<LogisticList>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={properties}
        userProperties={userProperties}
        loading={loading}
        onEdit={(diff: LogisticUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: LogisticCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.logisticDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </section>
  );
};
