import ng from 'angular';

import { addDate, endOfMonth, startOfMonth, substractDate } from '~/shared/lib/utils';

import type { GtRootScopeService, QueryParams } from '../../types';
import type { GtUtilsService } from '../gt-utils/gt-utils.srv';

function Service(
  $rootScope: GtRootScopeService,
  $log: ng.ILogService,
  CropsService: any,
  gettext: ng.gettext.gettextFunction,
  GtUtils: GtUtilsService,
) {
  let _queryParams: Record<string, QueryParams> = {};
  const subscribers: Record<string, (<T extends QueryParams>(qp: T) => void)[] | undefined> = {};
  const filterConfigs = {
    crop_type: getCropsFilter(),
    origin: _prepareOriginFilter(),
    status: _prepareStatusFilter(),
  };
  const registeredCleanFilterLevels: any = [];

  const changeStartSub = $rootScope.$on('$routeChangeStart', _initQueryParams);
  _initQueryParams();

  return {
    getQueryParams: getQueryParams,
    setQueryParams: setQueryParams,
    updateQueryParams: updateQueryParams,
    configs: filterConfigs,
    getRelationFilter: getRelationFilter,
    getSizeFilter: getSizeFilter,
    getBoolFilter: getBoolFilter,
    getYesFilter: getYesFilter,
    getTypeFilter: getTypeFilter,
    getTicketPositionStatusFilter: getTicketPositionStatusFilter,
    getCropsFilter: getCropsFilter,
    getStageFilter: getStageFilter,
    getContractStatusFilter: getContractStatusFilter,
    getMultiContractStatusFilter: getMultiContractStatusFilter,
    getPassportStatusFilter: getPassportStatusFilter,
    getRequestStatusFilter: getRequestStatusFilter,
    getDateRangePickerConfig: getDateRangePickerConfig,
    getClientRoleFilter: getClientRoleFilter,
    subscribe: subscribe,
    unsubscribe: unsubscribe,
    getPeriodsConfig: getPeriodsConfig,
    clearFilter: clearFilter,
    registerCleanFilterLevel: registerCleanFilterLevel,
    getRegisteredCleanFilterLevels: getRegisteredCleanFilterLevels,
  };

  ////////////////

  function _initQueryParams() {
    changeStartSub();
    _queryParams = { default: {} };
  }

  function getQueryParams(level = 'default'): QueryParams {
    if (level == 'default') {
      $log.warn('You are getting "default" filter level');
    }
    return { ..._queryParams[level] };
  }

  function updateQueryParams(newParams?: QueryParams, level?: string, silent?: boolean) {
    if (!level) {
      $log.error('You are updating "default" filter level');
    }
    level = level ?? 'default';

    _queryParams[level] = _queryParams[level] ?? {};
    ng.extend(_queryParams[level], newParams ?? {});
    _queryParams[level] = GtUtils.filterEmptyParams(_queryParams[level]);
    if (!silent) {
      notifySubscribers(level);
    }
    delete _queryParams[level]._dontReload;
    return getQueryParams(level);
  }

  function setQueryParams(newParams: QueryParams, level?: string, silent?: boolean) {
    if (!level) {
      $log.error('You are setting "default" filter level');
    }
    level = level ?? 'default';
    _queryParams[level] = { ...newParams };
    if (!silent) {
      notifySubscribers(level, true);
    }
    return getQueryParams(level);
  }

  function subscribe<T extends QueryParams>(
    filterLevel: string,
    cbFunction: (qp: T) => void,
    queryParams: T = {} as T,
  ) {
    updateQueryParams(queryParams, filterLevel, true);
    if (typeof cbFunction != 'function') {
      $log.error('cbFunction argument is not a function');
    }
    subscribers[filterLevel] = subscribers[filterLevel] ?? [];
    const indexSubscribed = subscribers[filterLevel]
      .filter((item) => typeof item == 'function')
      .map((item) => item.toString())
      .indexOf(cbFunction.toString());
    if (indexSubscribed < 0) {
      subscribers[filterLevel].push(cbFunction as (qp: QueryParams) => void);
    }
  }

  function unsubscribe<T extends QueryParams>(filterLevel: string, cbFunction: (qp: T) => void) {
    if (subscribers[filterLevel]) {
      const subscriptionIndex = subscribers[filterLevel].indexOf(
        cbFunction as (qp: QueryParams) => void,
      );
      subscribers[filterLevel].splice(subscriptionIndex, 1);
    }
  }

  function notifySubscribers(level: string, created?: any) {
    $rootScope.$broadcast('gt-filter-updated', _queryParams[level], level);
    $rootScope.$broadcast('gt-filter-updated_' + level, _queryParams[level]);
    if (created) {
      $rootScope.$broadcast('gt-filter-created', _queryParams[level], level);
    }
    if (subscribers[level]) {
      subscribers[level]
        .filter((item) => typeof item == 'function')
        .forEach((callback) => {
          callback(_queryParams[level]);
        });
    }
  }

  // filter configs

  // CROP TYPES
  function getCropsFilter(argument?: any, placeholder?: any, queryParams?: QueryParams) {
    const filter = {
      argument: argument || 'crop_type',
      placeholder: placeholder || gettext('Product'),
      items: [],
    };
    CropsService.Crop.query(queryParams, function (data: any) {
      filter.items = data.results.filter(function (crop: any) {
        return crop.level === 0;
      });
      ng.forEach(filter.items, function (parent: any) {
        parent.extraList = [];
      });
      ng.forEach(data.results, function (value: any) {
        if (value.level === 0) {
          return;
        }
        const parent: any = filter.items
          .filter(function (crop: any) {
            return crop.id == value.parent;
          })
          .shift();
        if (!parent) {
          return;
        }
        parent.extraList = parent?.extraList || [];
        parent.extraList.push(value);
      });
    });
    return filter;
  }

  function getRelationFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'relations',
      class: 'type-filter',
      items: [
        { id: 'Hot', title: 'Hot', icon: 'fa-tachometer' },
        { id: 'Warm', title: 'Warm', icon: 'fa-tachometer' },
        { id: 'Neutral', title: 'Neutral', icon: 'fa-tachometer' },
        { id: 'Cold', title: 'Cold', icon: 'fa-tachometer' },
        { id: 'Angry', title: 'Angry', icon: 'fa-tachometer' },
      ],
      placeholder: placeholder ?? gettext('TOTAL'),
    };
  }

  function _prepareOriginFilter() {
    return {
      argument: 'origin',
      placeholder: gettext('a country'),
      items: [
        { id: 'Ukraine', title: gettext('Ukraine') },
        { id: 'Russia', title: gettext('Russia') },
        { id: 'Europe', title: gettext('Europe') },
        { id: 'USA', title: gettext('USA') },
        { id: 'Kazachstan', title: gettext('Kazachstan') },
        { id: 'Other', title: gettext('Other') },
      ],
    };
  }

  function _prepareStatusFilter() {
    return {
      argument: 'status',
      placeholder: gettext('a status'),
      items: [
        { id: 'Demo', title: gettext('Demo') },
        { id: 'Demo removed', title: gettext('Demo removed') },
        { id: 'Response', title: gettext('Response') },
        { id: 'Contacted', title: gettext('Contacted') },
        { id: 'Not contacted', title: gettext('Not contacted') },
        { id: 'No response', title: gettext('No response') },
        { id: 'Recall', title: gettext('Recall') },
        { id: 'Unrelevant', title: gettext('Unrelevant') },
        { id: 'Paid', title: gettext('Paid') },
      ],
    };
  }

  function getSizeFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'size',
      placeholder: placeholder ?? gettext('a size'),
      items: [
        { id: 'big', title: gettext('Big') },
        { id: 'medium', title: gettext('Medium') },
        { id: 'small', title: gettext('Small') },
      ],
    };
  }

  function getBoolFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'bank_data_is_checked',
      class: '',
      items: [
        { id: 1, title: gettext('Yes') },
        { id: 0, title: gettext('No') },
      ],
      placeholder: placeholder ?? gettext('Bank data is checked'),
    };
  }

  function getYesFilter(argument: string, placeholder: string) {
    return {
      argument: argument,
      class: '',
      items: [{ id: 1, title: gettext('Yes') }],
      placeholder: placeholder,
    };
  }

  function getTypeFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'contract_type',
      placeholder: placeholder ?? gettext('a type'),
      items: [
        { id: 'purchase', title: gettext('Purchase') },
        { id: 'sale', title: gettext('Sale') },
      ],
    };
  }

  function getTicketPositionStatusFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'request_status_list',
      placeholder: placeholder ?? gettext('Status'),
      items: [
        { id: 'new', title: gettext('New') },
        { id: 'approved', title: gettext('Approved') },
        { id: 'rejected', title: gettext('Rejected') },
        { id: 'executed', title: gettext('Executed') },
        { id: 'processed', title: gettext('Processed') },
        { id: 'sent', title: gettext('Sent') },
        { id: 'draft', title: gettext('Draft') },
      ],
    };
  }

  function getContractStatusFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'status',
      placeholder: placeholder ?? gettext('Status'),
      items: [
        { id: 'new', title: gettext('New') },
        { id: 'delivery_period', title: gettext('Delivery period') },
        { id: 'shipment', title: gettext('In progress') },
        { id: 'cargo_executed', title: gettext('Cargo executed') },
        { id: 'executed', title: gettext('Executed') },
        { id: 'cancelled', title: gettext('Cancelled') },
        { id: 'washout', title: gettext('Washout') },
        { id: 'draft', title: gettext('Draft') },
      ],
    };
  }

  function getMultiContractStatusFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'status',
      placeholder: placeholder ?? gettext('Status'),
      items: [
        { id: 'new', title: gettext('New') },
        { id: 'delivery_period', title: gettext('Delivery period') },
        { id: 'shipment', title: gettext('In progress') },
        { id: 'cargo_executed', title: gettext('Cargo executed') },
        { id: 'executed', title: gettext('Executed') },
        { id: 'cancelled', title: gettext('Cancelled') },
        { id: 'washout', title: gettext('Washout') },
      ],
    };
  }

  function getPassportStatusFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'status',
      placeholder: placeholder ?? gettext('Status'),
      items: [
        { id: 'Request', title: gettext('Request') },
        { id: 'Approved', title: gettext('Approved') },
        { id: 'Processing', title: gettext('Processing') },
        { id: 'cargo_partial_executed', title: gettext('Cargo partial executed') },
        { id: 'cargo_executed', title: gettext('Cargo executed') },
        { id: 'Executed', title: gettext('Executed') },
        { id: 'Cancelled', title: gettext('Cancelled') },
      ],
    };
  }

  function getRequestStatusFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'status',
      placeholder: placeholder ?? gettext('Total'),
      items: [
        { id: 'new', title: gettext('New') },
        { id: 'approved', title: gettext('Approved') },
        { id: 'rejected', title: gettext('Rejected') },
        { id: 'cancelled', title: gettext('Cancelled') },
        { id: 'executed', title: gettext('Executed') },
        { id: 'processed', title: gettext('Processed') },
        { id: 'sent', title: gettext('Sent') },
      ],
    };
  }

  function getClientRoleFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'client_role',
      placeholder: placeholder ?? gettext('Client role'),
      items: [
        { id: 'supplier', title: 'supplier' },
        { id: 'exporter', title: 'exporter' },
        { id: 'farm', title: 'farm' },
        { id: 'elevator', title: 'elevator' },
        { id: 'deliverer', title: 'deliverer' },
        { id: 'bank', title: 'bank' },
        { id: 'owner', title: 'owner' },
        { id: 'other', title: 'other' },
        { id: 'surveyor', title: 'surveyor' },
        { id: 'buyer', title: 'buyer' },
        { id: 'broker', title: 'broker' },
        { id: 'insurer', title: 'insurer' },
      ],
    };
  }

  function getStageFilter(argument?: string, placeholder?: string) {
    return {
      argument: argument ?? 'stage',
      placeholder: placeholder ?? gettext('Total'),
      items: [
        { id: 'ticket', title: gettext('Ticket') },
        { id: 'indicator', title: gettext('Offer') },
      ],
    };
  }

  function getDateRangePickerConfig(useRanges = true) {
    const config: any = {
      firstDay: 1,
      showDropdowns: useRanges,
      opens: 'left',
      locale: {
        format: 'MMM d, yyyy',
        separator: ' - ',
        applyLabel: GtUtils.translate(gettext('Apply')),
        cancelLabel: GtUtils.translate(gettext('Clear')),
        fromLabel: GtUtils.translate(gettext('From')),
        toLabel: GtUtils.translate(gettext('To')),
        weekLabel: GtUtils.translate(gettext('W')),
        daysOfWeek: [
          GtUtils.translate(gettext('Su')),
          GtUtils.translate(gettext('Mo')),
          GtUtils.translate(gettext('Tu')),
          GtUtils.translate(gettext('We')),
          GtUtils.translate(gettext('Th')),
          GtUtils.translate(gettext('Fr')),
          GtUtils.translate(gettext('Sa')),
        ],
        monthNames: [
          GtUtils.translate(gettext('January')),
          GtUtils.translate(gettext('February')),
          GtUtils.translate(gettext('March')),
          GtUtils.translate(gettext('April')),
          GtUtils.translate(gettext('May')),
          GtUtils.translate(gettext('June')),
          GtUtils.translate(gettext('July')),
          GtUtils.translate(gettext('August')),
          GtUtils.translate(gettext('September')),
          GtUtils.translate(gettext('October')),
          GtUtils.translate(gettext('November')),
          GtUtils.translate(gettext('December')),
        ],
      },
    };
    if (useRanges) {
      config.ranges = {};
      config.ranges[GtUtils.translate(gettext('Today'))] = [
        new Date(),
        addDate(new Date(), { days: 1 }),
      ];
      config.ranges[GtUtils.translate(gettext('Yesterday'))] = [
        substractDate(new Date(), { days: 1 }),
        substractDate(new Date(), { days: 1 }),
      ];
      config.ranges[GtUtils.translate(gettext('Last 7 Days'))] = [
        substractDate(new Date(), { days: 6 }),
        new Date(),
      ];
      config.ranges[GtUtils.translate(gettext('Last 30 Days'))] = [
        substractDate(new Date(), { days: 29 }),
        new Date(),
      ];
      config.ranges[GtUtils.translate(gettext('This Month'))] = [
        startOfMonth(new Date()),
        endOfMonth(new Date()),
      ];
      config.ranges[GtUtils.translate(gettext('Last Month'))] = [
        startOfMonth(substractDate(new Date(), { months: 1 })),
        endOfMonth(substractDate(new Date(), { months: 1 })),
      ];
    }
    return config;
  }

  function getPeriodsConfig() {
    return [
      { id: 'today', title: GtUtils.translate(gettext('Today')) },
      { id: 'yesterday', title: GtUtils.translate(gettext('yesterday')) },
      { id: 'tomorrow', title: GtUtils.translate(gettext('tomorrow')) },
      { id: 'last7days', title: GtUtils.translate(gettext('last 7 days')) },
      { id: 'last10days', title: GtUtils.translate(gettext('last 10 days')) },
      { id: 'last30days', title: GtUtils.translate(gettext('last 30 days')) },
      { id: 'last_week', title: GtUtils.translate(gettext('last week')) },
      { id: 'this_week', title: GtUtils.translate(gettext('this week')) },
      { id: 'last_month', title: GtUtils.translate(gettext('last month')) },
      { id: 'this_month', title: GtUtils.translate(gettext('this month')) },
    ];
  }

  function clearFilter(filterLevel: string) {
    $rootScope.$broadcast('gt-filter-clear_' + filterLevel);
  }

  function registerCleanFilterLevel(filterLevel: string) {
    if (!registeredCleanFilterLevels.includes(filterLevel)) {
      registeredCleanFilterLevels.push(filterLevel);
    }
  }

  function getRegisteredCleanFilterLevels() {
    return [...registeredCleanFilterLevels];
  }
}

(function () {
  'use strict';
  ng.module('core.legacy').factory('gtFilterService', Service);
  Service.$inject = ['$rootScope', '$log', 'CropsService', 'gettext', 'GtUtils'];
})();

export type GtFilterService = ReturnType<typeof Service>;
