import {
  ConsignmentCreate,
  ConsignmentList,
  ConsignmentListParams,
  ConsignmentUpdate,
} from '~/features/execution/consignments';
import {
  Consignment,
  contractsConsignmentsCreate,
  contractsConsignmentsDestroy,
  contractsConsignmentsPartialUpdate,
  contractsConsignmentsRetrieve,
  contractsContractConsignmentsListInfoList,
  contractsContractConsignmentsTotalsRetrieve,
} from '~/shared/api';
import { EntityRepository } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

@singleton()
export class ConsignmentsRepository extends EntityRepository<
  Consignment,
  ConsignmentListParams,
  ConsignmentCreate,
  ConsignmentList
> {
  get = async (id: number) => {
    const { data } = await contractsConsignmentsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: ConsignmentListParams) => {
    const { data } = await contractsContractConsignmentsListInfoList({ query });
    return data;
  };

  search = (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: ConsignmentCreate) => {
    const res = await contractsConsignmentsCreate({
      body: { ...dto },
    });

    return res.data;
  };

  update = async (entity: ConsignmentUpdate) => {
    const { data } = await contractsConsignmentsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };

  delete = async (id: number) => {
    await contractsConsignmentsDestroy({ path: { id } });
  };

  getTotals = async (query: ConsignmentListParams) => {
    const { data } = await contractsContractConsignmentsTotalsRetrieve({ query });
    return data;
  };
}
