import ng from 'angular';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

(function () {
  'use strict';
  ng.module('deals.passports.legacy').component('passportsTable', {
    bindings: {
      passports: '<',
      count: '<',
      total: '<?',
      currency: '<?',
      filterLevel: '<?',
      onUpdate: '&?',
      view: '<?',
      openPassportModal: '&?',
      openContractModal: '&?',
      openFinanceModal: '&?',
      updateStatus: '&?',
      updateBusinessDate: '&?',
      updateVolume: '&?',
      downloadXlsxReport: '&',
      createUpdateRequest: '&',
    },
    template: require('./passports-table.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    '$rootScope',
    'GtUtils',
    'gtFilterService',
    'FinancesService',
    'CoreService',
    'gettext',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    $rootScope: GtRootScopeService,
    GtUtils: GtUtilsService,
    gtFilterService: GtFilterService,
    FinancesService: FinancesService,
    CoreService: CoreService,
    gettext: ng.gettext.gettextFunction,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;
    vm.tableOptions = getTableOptions();
    vm.columnVisibility = {};
    vm.setHovering = setHovering;
    vm.inlineOrderingParams = [];

    vm.hovering = false;
    vm.curSign = curSign;
    vm.showInCurrency = showInCurrency;
    vm.currencyType = 'default';
    vm.hideTickets = true;
    vm.hideContracts = false;
    vm.hideExecution = true;
    vm.hideInvoices = true;
    vm.hidePayments = true;
    vm.hideCosts = true;
    vm.gtTableAlignColHeight = { alighColHeight: true, colSelector: 'tr td', watch: new Date() };
    vm.getNgClass = getNgClass;
    vm.getCustomFieldTableColumns = getCustomFieldTableColumns;
    vm.reportConfig = null;
    vm.showPassportCostsRow = showPassportCostsRow;
    vm.defaultCurrency = $rootScope.user.settings.DEFAULT_CURRENCY;
    vm.viewParams = {};
    vm.setViewParam = setViewParam;
    vm.getDerivativeMargin = getDerivativeMargin;
    vm.getLabelByValue = getLabelByValue;
    vm.getCosts = getCosts;
    vm.getNumberLabelByValue = getNumberLabelByValue;
    vm.getLabelForMargin = getLabelForMargin;
    vm.getLabelForMarginT = getLabelForMarginT;
    vm.getButtonCollapseClass = getButtonCollapseClass;
    vm.getRoleNameClass = getRoleNameClass;
    vm.hasInvoices = hasInvoices;
    vm.getInvoices = getInvoices;

    vm.$onInit = function () {
      updateColumnVisibility();
      getCustomFieldsTableOptions().then((data: any) => (vm.customFieldsTableOptions = data));

      FinancesService.CurrencyExchange.query(
        {
          currency: GtUtils.getLocalCurrency(),
        },
        function (data: any) {
          vm.uahRate = data.results?.[0].rate;
        },
      );
      vm.viewParams = gtFilterService.getQueryParams(vm.filterLevel);
      $rootScope.$on(
        'gt-filter-updated_' + vm.filterLevel,
        (ev: any, data: any) => (vm.viewParams = data),
      );

      $rootScope.$on(`gt-report-config-selected_${vm.filterLevel}`, (event: any, config: any) => {
        this.reportConfig = config?.column_params;
        updateColumnVisibility();
      });

      $rootScope.$on(`gt-report-config-created_${vm.filterLevel}`, (event: any, config: any) => {
        vm.savedReportConfigs = [...vm.savedReportConfigs, config];
      });

      $rootScope.$on(`gt-report-config-updated_${vm.filterLevel}`, (event: any, config: any) => {
        const index = vm.savedReportConfigs.findIndex((item: any) => item.id === config.id);
        vm.savedReportConfigs[index] = config;
      });

      vm.savedFilterChoices = CoreService.getSavedFilterChoices(vm.filterLevel);
      vm.savedReportConfigs = CoreService.getSavedReportConfigs(vm.filterLevel);

      updateOrdering();
    };

    vm.$onChanges = function () {
      if (vm.passports?.length) {
        updateColumnVisibility();
        vm.tableOptions = getTableOptions();
        vm.gtTableAlignColHeight.watch = new Date();
      }
    };

    function updateOrdering() {
      vm.inlineOrderingParams = [
        { title: 'Conclusion date', value: 'conclusion_date' },
        { title: 'status', value: 'status' },
      ];
    }

    function getCustomFieldTableColumns() {
      return CustomValuesService.getCustomFieldTableColumns({
        purpose_model: 'passport',
      });
    }

    function getCustomFieldsTableOptions() {
      return getCustomFieldTableColumns().then((columns: any) => {
        return {
          tableName: 'passport-custom-fields',
          filterLevel: 'passport-custom-fields',
          columnDefs: columns,
        };
      });
    }

    ////////////////

    function updateColumnVisibility() {
      if (vm.reportConfig) {
        updateVisibility(vm.reportConfig);
      } else {
        CoreService.ColumnParams.query({ table_name: vm.tableOptions.tableName }, updateVisibility);
      }
    }

    function updateVisibility(data: any) {
      const curTab = vm.tableOptions.tabs[0];

      ng.forEach(vm.columnVisibility, function (item: any, key: any) {
        if (curTab.columns.indexOf(key) < 0) {
          vm.columnVisibility[key] = false;
        }
      });

      curTab.columns.forEach(function (columnName: string) {
        let visible = true;
        const colConfig = data
          .filter(function (conf: any) {
            return conf.column_name == columnName;
          })
          .shift();

        const columnIndex = vm.tableOptions.columnDefs.findIndex(
          (column: any) => column.columnName === colConfig?.column_name,
        );

        if (columnIndex >= 0 && colConfig.new_title) {
          vm.tableOptions.columnDefs[columnIndex].title = colConfig.new_title;
        }

        if (
          (colConfig && colConfig.visible === false) ||
          (colConfig && colConfig.users.indexOf($rootScope.user.id) < 0)
        ) {
          visible = false;
        }

        if (vm.tableOptions.alwaysShowColumns.indexOf(columnName) > -1) {
          visible = true;
        }
        vm.columnVisibility[columnName] = visible;
      });
    }

    function setHovering(value: any) {
      vm.hovering = value;
    }

    function getNgClass(passport: any) {
      if (passport.custom_status_codename) {
        return {
          passport_new: passport.custom_status_codename == 'new',
          passport_cancelled: passport.custom_status_codename == 'cancelled',
          passport_executed: passport.custom_status_codename == 'executed',
          passport_approved: passport.custom_status_codename == 'approved',
          passport_processing: passport.custom_status_codename == 'processing',
        };
      } else {
        return {
          passport_new: passport.status == 'Request',
          passport_cancelled: passport.status == 'Cancelled',
          passport_executed: passport.status == 'Executed',
          passport_approved: passport.status == 'Approved',
          passport_processing: passport.status == 'Processing',
        };
      }
    }

    function getTableOptions() {
      const options: any = {
        tableName: 'passports',
        filterLevel: vm.filterLevel,
        columnDefs: [] as any[],
        tabs: [],
        visibleColumns: getVisibleColumns,
        rowData: vm.contracts,
        rowDirective: 'passports-table',
        alwaysShowColumns: ['title'],
        configurable: true,
      };

      options.tabs = [
        {
          title: gettext('Info'),
          icon: 'fa-info-circle',
          columns: ['title', 'status', 'contracts', 'important', 'customs_info'],
        },
      ];

      options.columnDefs = [
        {
          columnName: 'title',
          title: gettext('title'),
          predicate: 'title',
        },
        {
          columnName: 'status',
          title: gettext('status'),
          predicate: 'status',
        },
        {
          columnName: 'contracts',
          title: gettext('P&L'),
        },
        {
          columnName: 'important',
          title: gettext('important'),
        },
        {
          columnName: 'customs_info',
          title: gettext('customs'),
        },
      ];

      return options;
    }

    function getVisibleColumns() {
      return ['title'];
    }

    function showPassportCostsRow(passport: any) {
      return (
        passport.passport_transport_cost_price ||
        passport.passport_cost_price_analytical ||
        passport.passport_cost_price ||
        passport.passport_transport_cost_price ||
        passport.passport_cost_invoiced_amount ||
        passport.passport_cost_paid
      );
    }

    function curSign(currencySymbol: any) {
      const symbol = showInCurrency(vm.defaultCurrency, currencySymbol);
      return (
        (
          {
            EUR: '€',
            USD: '$',
            UAH: '₴',
            RUB: '₽',
            GBP: '£',
            KZT: '₸',
          } as any
        )[symbol] || '¤'
      );
    }

    function showInCurrency(defaultValue: any, localValue: any) {
      return vm.viewParams.currency?.toLowerCase() === 'default' ? defaultValue : localValue;
    }

    function setViewParam(param: any, value: any) {
      vm.viewParams[param] = value;

      if (!value) {
        delete vm.viewParams[param];
      }
      gtFilterService.setQueryParams(vm.viewParams, vm.filterLevel);
    }

    function getDerivativeMargin(passport: any) {
      return vm.viewParams.showDerivatives ? passport.latest_margin_conrol_derivative_margin : 0;
    }

    function getLabelByValue(value: any) {
      return {
        'label-opacity': !value,
        'label label-success': value > 0,
        'label label-danger': value < 0,
      };
    }

    function getLabelForMargin(value: any) {
      return {
        'label-opacity': !value,
        'label label_success': value > 0,
        'label label_danger': value < 0,
      };
    }

    function getLabelForMarginT(value: any) {
      return {
        'label-opacity': !value,
        'alert-success': value > 0,
        'alert-danger': value < 0,
      };
    }

    function hasInvoices(invoicesData: any, uses: any) {
      return invoicesData?.filter((pos: any) => uses.includes(pos.use)).length;
    }
    function getInvoices(invoicesData: any, uses: any) {
      return invoicesData?.filter((pos: any) => uses.includes(pos.use));
    }

    function getButtonCollapseClass(expanded: any) {
      return {
        'fa fa-caret-down': expanded,
        'fa fa-caret-up': !expanded,
      };
    }
    function getRoleNameClass(roleName: string) {
      return {
        'fa fa-bookmark-o': roleName === 'supplier',
        'fa fa-bookmark': roleName === 'buyer',
        'fa fa-home': roleName === 'owner',
      };
    }
    function getNumberLabelByValue(value: any) {
      return {
        'positive-number': value > 0,
        'negative-number': value < 0,
      };
    }

    function getCosts(row: any, level: string) {
      if (!row) {
        return 0;
      }
      const defaultCurrency = vm.viewParams?.currency?.toLowerCase() === 'default';
      if (level === 'total') {
        if (defaultCurrency) {
          return (
            (row.passport_cost_price || 0) +
            (row.sale_facts_cost_price || 0) +
            (row.purchase_facts_cost_price || 0) +
            (row.export_facts_cost_price || 0) +
            (row.intermediate_facts_cost_price || 0)
          );
        }
        return (
          (row.passport_cost_price_local || 0) +
          (row.sale_facts_cost_price_local || 0) +
          (row.purchase_facts_cost_price_local || 0) +
          (row.export_facts_cost_price_local || 0) +
          (row.intermediate_facts_cost_price_local || 0)
        );
      } else if (level === 'passport') {
        return defaultCurrency ? row.passport_cost_price : row.passport_cost_price_local;
      } else if (level === 'sale') {
        return defaultCurrency ? row.sale_facts_cost_price : row.sale_facts_cost_price_local;
      } else if (level === 'purchase') {
        return defaultCurrency ? row.purchase_cost_price : row.purchase_cost_price_local;
      } else if (level === 'export') {
        return defaultCurrency ? row.export_cost_price : row.export_cost_price_local;
      } else if (level === 'intermediate') {
        return defaultCurrency
          ? row.intermediate_facts_cost_price
          : row.intermediate_facts_cost_price_local;
      }
    }
  }
})();
