import React from 'react';

import { Toolbar, ToolbarSeparator } from '~/shared/ui/kit/toolbar';

import { ActionBarList } from './action-bar-list';
import type { ActionBarConfig } from './types';

export const ActionBar: React.FC<{
  title: React.ReactNode;
  config: ActionBarConfig[];
}> = ({ title, config }) => {
  return (
    <Toolbar
      className="!bg-background-main-primary fixed bottom-4 left-1/2 z-40 flex h-[50px] w-max -translate-x-1/2 transform items-center gap-2 px-5"
      data-testid="action-bar"
    >
      {title}
      <ToolbarSeparator className="h-5" />
      <ActionBarList config={config} />
    </Toolbar>
  );
};
