import { locationCountriesList } from '~/shared/api';

import type { CountryListParams } from '../types';

export class CountryRepository {
  query = async (query: CountryListParams) => {
    const { data } = await locationCountriesList({ query });
    return data;
  };
}
