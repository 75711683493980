import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

import type { FinanceCreate, FinanceList, FinanceUpdate } from '../lib';
import { getFinanceColumns } from '../lib';
import type { FinanceListViewModel } from '../services';

export const FinanceContainer: React.FC<{
  viewMode: 'spreadsheet' | 'grid';
  viewModel: FinanceListViewModel;
}> = ({ viewMode, viewModel }) => {
  const { authStore } = useAuth();
  const createdDataRef = React.useRef<FinanceCreate[]>([]);
  const editedDataRef = React.useRef<FinanceUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);
  const user = useObservableEagerState(authStore.currentUser$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getFinanceColumns(pageData.results, user),
      }),
    [columns, pageData.results, user],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.financesUpdated(editedDataRef.current);
      await viewModel.financesCreated(createdDataRef.current);
      viewModel.setEditing(false);
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <section className="mt-2 !flex flex-col gap-3" data-testid="finance-container">
      <DatasetView<FinanceList, FinanceUpdate, FinanceCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: FinanceUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: FinanceCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.financeDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </section>
  );
};
