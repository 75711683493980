import { stockexchangesMeasurementsList } from '~/shared/api';

import type { MeasurementListParams } from '../types';

export class MeasurementRepository {
  query = async (query: MeasurementListParams) => {
    const { data } = await stockexchangesMeasurementsList({ query });
    return data;
  };
}
