import React from 'react';

import { ActionBar, type ActionBarConfig } from '~/shared/ui/components/action-bar';

export const InlineEditToolbar: React.FC<{ config: ActionBarConfig[]; selectedCount?: number }> = ({
  config,
  selectedCount,
}) => {
  return (
    <ActionBar
      title={
        <>
          <span className="text-text-main-primary text-sm font-medium">Inline Editing Mode</span>
          {selectedCount !== undefined && (
            <div className="text-text-main-secondary flex gap-1 text-sm font-medium">
              <span>{selectedCount}</span>
              <span>selected:</span>
            </div>
          )}
        </>
      }
      config={config}
    />
  );
};
