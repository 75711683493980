import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { IconComponent } from '~/shared/ui/icons';
import { Button } from '~/shared/ui/kit/button';

export const ButtonView: React.FC<{
  key: string;
  Icon: IconComponent;
  iconContext: string;
  label: { text: string; context: string };
  action: () => void;
}> = ({ key, Icon, iconContext, label, action }) => (
  <Button aria-hidden key={key} variant="fadedSecondary" onClick={action} size="md">
    <div className="flex items-center gap-1">
      {Icon && <Icon size={14} className={iconContext} />}
      <span className={cn('text-text-main-secondary text-xs font-medium', label?.context)}>
        {label?.text}
      </span>
    </div>
  </Button>
);
