import type { Crop } from '~/shared/api';
import { cropsCropsList } from '~/shared/api';
import type { Paginated } from '~/shared/common';

import type { CommodityListParams } from '../types';

export class CommodityRepository {
  query = async (query: CommodityListParams): Promise<Paginated<Crop>> => {
    const { data } = await cropsCropsList({ query });
    return data;
  };
}
