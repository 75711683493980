import { BehaviorSubject, map, shareReplay, withLatestFrom } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { type EntityColumn, PageViewsStore } from '~/core/page-views';
import {
  PurchaseContractCreate,
  PurchaseContractList,
  PurchaseContractUpdate,
  PurchaseContractsListParams,
} from '~/features/deals/purchase-contracts/lib';
import { PurchaseContractsModel } from '~/features/deals/purchase-contracts/services/purchase-contracts.model';
import {
  BaseContractsListParams,
  ContractSerializer,
  ViewMode,
  tableColumnsMap,
} from '~/features/deals/shared/contracts';
import { PurchaseContract } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

import { PurchaseContractsRepository } from './purchase-contracts.repository';

@injectable()
export class PurchaseContractsListViewModel extends EntityListEditViewModel<
  PurchaseContract,
  PurchaseContractsListParams,
  PurchaseContractCreate,
  PurchaseContractList
> {
  resolveEntityRepo() {
    return container.resolve(PurchaseContractsRepository);
  }
  private readonly entityName = 'purchase-contracts-page-view';
  private readonly purposeModel = 'purchasecontract';

  constructor(
    private readonly purchaseContractsRepo: PurchaseContractsRepository,
    private readonly purchaseContractsModel: PurchaseContractsModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  private readonly viewModeSubj = new BehaviorSubject<ViewMode>('table');

  readonly viewMode$ = this.viewModeSubj.asObservable();

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    withLatestFrom(this.pageParamsSubj),
    map(([allProps, pageParams]) => this.filterProperties(allProps, pageParams)),
    startWith([] as EntityColumn[]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.purchaseContractsRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public purchaseContractsCreated = async ({
    entities,
  }: {
    entities: PurchaseContractCreate[];
  }) => {
    await this.purchaseContractsModel.createPurchaseContracts(entities);
  };

  public purchaseContractDeleted = async (id: number) => {
    try {
      await this.purchaseContractsRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public purchaseContractsUpdated = async ({
    entities,
  }: {
    entities: PurchaseContractUpdate[];
  }) => {
    try {
      await this.purchaseContractsModel.updatePurchaseContracts(entities);
      notifySuccess('PurchaseContracts updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public getInitParams() {
    const serializer: ContractSerializer = 'table_info';
    return { page_size: 25, page: 1, serializer: serializer };
  }

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: PurchaseContract[]) => {
    await Promise.all(records.map(this.purchaseContractsRepo.update));
    this.pageParamsChanged({});
  };

  public createPurchaseContract = async (purchaseContract: PurchaseContract) => {
    try {
      await this.purchaseContractsRepo.create(purchaseContract);
      notifySuccess('Purchase Contract created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  private filterProperties(props: EntityColumn[], pageParams: BaseContractsListParams) {
    const serializer = pageParams.serializer;
    const allowedCols = tableColumnsMap[serializer] ?? [];
    return props.filter((prop) => allowedCols.includes(prop.column_name));
  }
}
