import { User } from '@gt/api';

import { EntityListViewModel } from '~/shared/common/types';
import { container, injectable } from '~/shared/lib/di';

import { UserCreate, UserQueryParams } from '../../lib/types';
import { UserRepository } from '../../services/user.repository';

@injectable()
export class UserListViewModel extends EntityListViewModel<
  User,
  Omit<UserQueryParams, 'page' | 'page_size'> & { page_size: number; page: number },
  UserCreate
> {
  resolveEntityRepo() {
    return container.resolve(UserRepository);
  }
}
