import type { CellData } from '~/shared/lib/spreadsheet';
import type { CommonDataType } from '~/shared/ui/data-types';

type SpreadsheetValue = string | string[] | number | null;

export const toSpreadsheetValue = ({
  value,
  dataType,
}: {
  value: unknown;
  dataType: CommonDataType | 'template';
}) => {
  if (value === undefined || value === null) {
    return '';
  }

  if (dataType === 'template') {
    return JSON.stringify(value);
  }

  if (dataType === 'multiselect' && Array.isArray(value)) {
    return value
      .map((item) => (typeof item === 'object' ? JSON.stringify(item) : String(item)))
      .join(',');
  }

  if (value instanceof Date) {
    return String(value);
  }

  if (dataType === 'number' && typeof value === 'number') {
    return String(value);
  }

  if (dataType === 'person') {
    if (typeof value === 'object' && 'firstName' in value && 'lastName' in value) {
      const firstName = (value.firstName || '') as string;
      const lastName = (value.lastName || '') as string;
      return `${firstName} ${lastName}`.trim();
    } else {
      return '';
    }
  }

  if (typeof value === 'object') {
    return JSON.stringify(value);
  }

  if (typeof value === 'string') {
    return value;
  }

  return '';
};

export const fromSpreadsheetValue = (cell: CellData): { value: SpreadsheetValue } => {
  let result: SpreadsheetValue;

  if (
    cell.custom?.dataType === 'text' ||
    cell.custom?.dataType === 'email' ||
    cell.custom?.dataType === 'phone' ||
    cell.custom?.dataType === 'url'
  ) {
    result = cell.v ? String(cell.v) : null;
  } else if (cell.custom?.dataType === 'select') {
    result = String(cell.v ?? '');
  } else if (cell?.custom?.dataType === 'multiselect') {
    result = String(cell.v ?? '')
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '');
  } else if (cell?.custom?.dataType === 'select') {
    result = String(cell.v ?? '');
  } else if (cell.custom?.dataType === 'number') {
    result = Number(cell.v);
  } else if (cell.custom?.dataType === 'date') {
    result = cell.v ? String(cell.v) : '';
  } else {
    result = String(cell.v ?? '');
  }

  return { value: result };
};
