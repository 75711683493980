import {
  clientsClientsCreate,
  clientsClientsDestroy,
  clientsClientsInfoList,
  clientsClientsPartialUpdate,
  clientsClientsRetrieve,
  clientsClientsUpdateRegularStatusRetrieve,
} from '~/shared/api';
import { EntityRepository, Paginated } from '~/shared/common';
import { singleton } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

import type {
  Counterparty,
  CounterpartyCreate,
  CounterpartyList,
  CounterpartyListParams,
  CounterpartyUpdate,
} from '../lib';

@singleton()
export class CounterpartiesRepository extends EntityRepository<
  Counterparty,
  CounterpartyListParams,
  CounterpartyCreate,
  CounterpartyList
> {
  get = async (id: number): Promise<Counterparty> => {
    const { data } = await clientsClientsRetrieve({ path: { id } });
    return data;
  };

  query = async (query: CounterpartyListParams): Promise<Paginated<CounterpartyList>> => {
    const { data } = await clientsClientsInfoList({ query });
    return data;
  };

  search = async (_input: string): Promise<EntityOption[]> => Promise.resolve([]);

  create = async (dto: CounterpartyCreate): Promise<Counterparty> => {
    const res = await clientsClientsCreate({
      body: { ...dto, client_verification: [] },
    });

    return res.data;
  };

  update = async (entity: CounterpartyUpdate): Promise<Counterparty> => {
    const { data } = await clientsClientsPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };

  delete = async (id: number): Promise<void> => {
    await clientsClientsDestroy({ path: { id } });
  };

  updateRegularStatus = async () => {
    await clientsClientsUpdateRegularStatusRetrieve();
  };
}
