import ng from 'angular';

import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { PageService } from '^/app/core/legacy/components/gt-page/gt-page.srv';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

(function () {
  'use strict';
  ng.module('deals.contracts.legacy').component('generalAgreementsPageView', {
    bindings: {
      queryParams: '<?',
      filterLevel: '<?',
    },
    template: require('./general-agreements-page-view.tpl.html?raw'),
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = [
    'PageService',
    'gettext',
    'gtFilterService',
    'ContractsService',
    'CustomValuesService',
  ];

  function Controller(
    this: any,
    PageService: PageService,
    gettext: ng.gettext.gettextFunction,
    gtFilterService: GtFilterService,
    ContractsService: ContractsService,
    CustomValuesService: CustomValuesService,
  ) {
    const vm = this;
    const relatedModel = 'contracts.GeneralAgreement';
    vm.filterLevel = 'general-agreement-page-view';
    vm.quickAdd = false;
    vm.rebuildPageConfig = rebuildPageConfig;

    vm.$onInit = function () {
      vm.queryParams = { agreement_type: 'sale', ...vm.queryParams };
      PageService.setConfig(getPageConfig(vm.queryParams));
    };

    ////////////////

    function rebuildPageConfig(params: { agreement_type: string; deal_type: string }) {
      PageService.setConfig(getPageConfig(params));
    }

    function getPageConfig(params: { agreement_type: string; deal_type: string }) {
      const config: any = {
        class: 'page-header-main-prices-tab',
        buttons: [
          new PageService.buttons.Filters(),
          new PageService.buttons.Print(),
          new PageService.buttons.Refresh(vm.filterLevel),
          new PageService.buttons.Import('contracts.generalagreement'),
          new PageService.buttons.Export('contracts.generalagreement', vm.filterLevel),
        ],
        quickAdd: () => {
          vm.quickAdd = !vm.quickAdd;
        },
        pages: ContractsService.getPagesConfig(),
      };
      config.filters = {
        filterLevel: vm.filterLevel,
        clicked: false,
        dates: true,
        search: true,
        invert_filters: true,
        nestedMultiSelects: [],
        dateSelects: [
          {
            argument: 'conclusion_date',
            placeholder: gettext('Conclusion date'),
          },
          {
            argument: 'date_of_execution',
            placeholder: gettext('Execution start date'),
          },
          {
            argument: 'end_of_execution',
            placeholder: gettext('Execution end date'),
          },
        ],
        multiSelects: [
          {
            argument: 'owner_list',
            placeholder: gettext('owner'),
            resource: 'clients.owner',
            related_fk: 'owner',
            related_model: relatedModel,
          },
          {
            argument: 'custom_status_list',
            placeholder: gettext('custom status'),
            resource: 'core.CustomStatus',
            related_fk: 'custom_status',
            related_model: relatedModel,
            queryParams: {
              content_type__model: 'generalagreement',
            },
          },
          {
            argument: 'commodity_list',
            placeholder: gettext('commodity'),
            resource: 'crops.crop',
            related_fk: 'commodity',
            related_model: relatedModel,
          },
        ],
        selects: [
          {
            argument: 'budget',
            placeholder: gettext('Budget'),
            resource: 'finances.Budget',
          },
        ],
        nestedSelects: [
          {
            argument: 'tax_registration',
            placeholder: gettext('Tax registration'),
            items: [
              { id: 'free_tax_invoice', title: gettext('Free tax invoice') },
              { id: 'on_first_event', title: gettext('On first event') },
            ],
          },
          {
            argument: 'agreement_type',
            placeholder: gettext('Type'),
            items: [
              { id: 'sale', title: 'Sale' },
              { id: 'purchase', title: 'Purchase' },
            ],
          },
          gtFilterService.getBoolFilter('is_main_deal', gettext('Is main deal')),
        ],
      };

      if (
        params.agreement_type === 'purchase' ||
        ['services', 'intermediate', 'export'].includes(params.deal_type)
      ) {
        config.filters.multiSelects.push({
          argument: 'supplier_list',
          placeholder: gettext('suppliers'),
          resource: 'clients.supplier',
          related_fk: 'supplier',
          related_model: relatedModel,
        });
      }

      if (
        params.agreement_type === 'sale' ||
        ['services', 'intermediate', 'export'].includes(params.deal_type)
      ) {
        config.filters.multiSelects.push({
          argument: 'buyer_list',
          placeholder: gettext('buyers'),
          resource: 'clients.buyer',
          related_fk: 'buyer',
          related_model: relatedModel,
        });
      }

      CustomValuesService.getCustomFieldFilters({
        purpose_model: 'generalagreement',
      }).then((customFieldsFilters: any) => {
        config.filters.multiSelects.push(...customFieldsFilters.multiSelects);
        config.filters.selects.push(...customFieldsFilters.selects);
        config.filters.nestedSelects.push(...customFieldsFilters.nestedSelects);
        config.filters.nestedMultiSelects.push(...customFieldsFilters.nestedMultiSelects);
        config.filters.dateSelects.push(...customFieldsFilters.dateSelects);
      });
      return config;
    }
  }
})();
