import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';

export class PnlMtmService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getTableData(params: object) {
    return this.$http({
      method: 'GET',
      url: '/api/reports/pnl-mtm-consolidated/',
      params: params,
    }).then((response: any) => this.formatData(response.data));
  }

  formatData(data: any) {
    data.results.dict_data.unrealized_months = this.getMonthObjects(
      data.results.dict_data.unrealized_months,
    );
    data.results.dict_data.realized_months = this.getMonthObjects(
      data.results.dict_data.realized_months,
    );
    data.results.dict_data.table_name_colspan =
      (data.results.dict_data.realized_months.length || 1) +
      (data.results.dict_data.unrealized_months.length || 1) +
      2;
    data.results.dict_data.realized_colspan = data.results.dict_data.realized_months.length || 1;
    data.results.dict_data.unrealized_colspan =
      data.results.dict_data.unrealized_months.length || 1;
    return data;
  }

  getMonthObjects(months: any) {
    if (!months) {
      return [];
    }
    return months.map((month: any) => {
      if (month) {
        return {
          key: formatDate(month, 'yyyy-MM-dd'),
          title: formatDate(month, 'MMMM, yyyy'),
        };
      } else {
        return {
          key: null,
          title: '---',
        };
      }
    });
  }
}
PnlMtmService.$inject = ['$http'];
