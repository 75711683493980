import React from 'react';

import { UrlRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const UrlCellRead = ({ value, disabled }: CellTypeProps) => {
  return typeof value === 'string' ? (
    <div className="px-2">
      <UrlRead value={value} disabled={disabled} />
    </div>
  ) : null;
};
