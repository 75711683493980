import React from 'react';

import type { ListOption } from '~/shared/lib/types';
import { isNumber, isString, throttle } from '~/shared/lib/utils';
import { type EntityOption, isEntityOption } from '~/shared/ui/data-types';
import { Autocomplete } from '~/shared/ui/kit/autocomplete';

import type { Entity, EntityPageParams, EntityRepository } from '../../types';

export const EntityAutocomplete: React.FC<
  Omit<
    React.ComponentProps<typeof Autocomplete>,
    'options' | 'onSearch' | 'valueChanged' | 'value'
  > & {
    search?: EntityRepository<Entity, EntityPageParams>['search'];
    value?: EntityOption | number | string;
    valueChanged?: (value?: EntityOption) => void;
  }
> = ({ search, value, valueChanged, ...props }) => {
  const [options, setOptions] = React.useState<ListOption[]>([]);
  const [valueOption, setValueOption] = React.useState<ListOption | undefined>(undefined);

  if (!search) {
    throw new Error('EntityAutocomplete: search is required');
  }

  React.useEffect(() => {
    const setEntity = async (id: number) => {
      const entities = await search('', id);
      const entity = entities.find((e) => e.id === id);
      if (!entity) {
        throw new Error('Entity not found');
      }
      setValueOption({ label: entity.title, value: entity.id.toString() });
    };

    if (!value) {
      setValueOption(undefined);
      return;
    }

    if (isNumber(value)) {
      setValueOption({ label: '', value: value.toString() });
      setEntity(value).catch(console.error);
      return;
    }

    if (isString(value)) {
      setValueOption({ label: value, value: value });
      return;
    }

    if (isEntityOption(value)) {
      setValueOption({ label: value.title, value: `${value.id}` });
      return;
    }

    throw new Error('Invalid value');
  }, [search, value]);

  const onSearchHandler = React.useCallback(
    () =>
      throttle({ interval: 1000, trailing: true }, async (input: string) => {
        const entities = await search(input);
        setOptions(entities.map((o) => ({ label: o.title, value: o.id.toString() })));
      }),
    [search, setOptions],
  );

  const onValueChangeHandler = React.useCallback(
    (option: ListOption) => {
      valueChanged?.({ id: parseInt(option.value, 10), title: option.label });
    },
    [valueChanged],
  );

  const onOpenChangeHandler = React.useCallback(
    (isOpen: boolean) => {
      if (isOpen && !options.length) {
        onSearchHandler()?.('');
      }
    },
    [onSearchHandler, options.length],
  );

  return (
    <Autocomplete
      value={valueOption}
      onSearch={onSearchHandler}
      options={options}
      valueChanged={onValueChangeHandler}
      onOpenChange={onOpenChangeHandler}
      {...props}
    />
  );
};
