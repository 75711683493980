import * as Sentry from '@sentry/react';
import 'i18next';
import React from 'react';

import { useAuth } from '~/core/auth/hooks';
import { GT_CLIENT_NAME, GT_STAGING, GT_VERSION, HOST_URL, SENTRY_DSN } from '~/shared/config';
import { i18next, initReactI18next, resources } from '~/shared/lib/i18n';
import { RouterProvider, createRouter } from '~/shared/lib/router';

import { routeTree } from './route-tree.gen';

const router = createRouter({
  routeTree,
  context: {
    auth: undefined!,
  },
});

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router;
  }
}

console.info('Version:', GT_VERSION);

if (!Sentry.isInitialized()) {
  const integrations = [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    Sentry.replayIntegration({
      maskAllText: !GT_STAGING,
      maskAllInputs: !GT_STAGING,
      blockAllMedia: !GT_STAGING,
    }),
  ];

  if (GT_STAGING) {
    integrations.push(
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        enableScreenshot: true,
      }),
    );
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: GT_CLIENT_NAME,
    release: `graintrack@${GT_VERSION}`,
    integrations,
    beforeSend(event) {
      if (GT_STAGING && event.exception && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    tracesSampleRate: 0.01,
    tracePropagationTargets: ['localhost', new RegExp(`^${HOST_URL}/api`)],
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1,
  });
}
i18next
  .use(initReactI18next)
  .init({
    lng: 'en',
    resources: {
      ua: { translation: resources.ua },
      en: { translation: resources.en },
      ru: { translation: resources.ru },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(console.error);

export const AppInner: React.FC = () => {
  const auth = useAuth();

  return auth.initialized ? <RouterProvider router={router} context={{ auth }} /> : <>Loading...</>;
};
