import React from 'react';

import { GlobalDialogContainer } from '~/core/global-dialog';
import { GlobalSearchContainer } from '~/core/global-search';
import { ToastContainer } from '~/shared/lib/notify';
import { AbilityContext, ability } from '~/shared/lib/perms';

import { AppInner } from './app-inner';

import './globals.css';

export const App: React.FC = () => {
  return (
    <AbilityContext.Provider value={ability}>
      <AppInner />
      <ToastContainer position="bottom-left" theme="colored" style={{ whiteSpace: 'preLine' }} />
      <GlobalSearchContainer />
      <GlobalDialogContainer />
    </AbilityContext.Provider>
  );
};
