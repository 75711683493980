import React from 'react';

import { cn, handleCopyText } from '~/shared/lib/utils';
import { TextFieldWrite } from '~/shared/ui/data-types/components';
import { type DataTypeProps } from '~/shared/ui/data-types/lib';
import { ArrowBottomIcon } from '~/shared/ui/icons';

import { TitleDropdown } from './title-dropdown';
import type { CounterpartyList } from '../../lib/types';

export const TitleTemplate: React.FC<
  DataTypeProps<string> & {
    editing: boolean;
    counterparty: CounterpartyList;
    onOpenCounterpartyModal: (counterparty: CounterpartyList) => void;
    onCloneCounterparty: (counterparty: CounterpartyList) => void;
    onOpenEmailModal: (counterparty: CounterpartyList) => void;
    onApproveCounterparty: (counterparty: CounterpartyList, approve: boolean) => void;
    onOpenDocxModal: (counterparty: CounterpartyList) => void;
  }
> = ({
  value,
  valueChanged,
  editing,
  onOpenCounterpartyModal,
  onCloneCounterparty,
  onOpenEmailModal,
  onApproveCounterparty,
  onOpenDocxModal,
  counterparty,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return editing ? (
    <TextFieldWrite value={value} valueChanged={valueChanged} />
  ) : (
    <span
      className={cn(
        'group hover:bg-background-main-tertiary relative flex h-full w-full cursor-pointer items-center px-2 py-[7px] transition duration-300 ease-in-out',
        isDropdownOpen && 'bg-background-main-tertiary',
      )}
    >
      <button
        className="text-text-main-primary overflow-hidden text-xs overflow-ellipsis whitespace-nowrap"
        onClick={handleCopyText(value)}
        onKeyDown={handleCopyText(value)}
      >
        {value}
      </button>
      <div
        className={cn(
          'bg-background-main-secondary absolute right-1 flex h-6 w-6 items-center justify-center rounded-sm pt-0.5 text-xs opacity-0 group-hover:opacity-100',
          isDropdownOpen && 'opacity-100',
        )}
      >
        <TitleDropdown
          isOpen={isDropdownOpen}
          onOpenChange={(open) => setIsDropdownOpen(open)}
          trigger={<ArrowBottomIcon size={18} />}
          counterparty={counterparty}
          onOpenCounterpartyModal={onOpenCounterpartyModal}
          onCloneCounterparty={onCloneCounterparty}
          onOpenEmailModal={onOpenEmailModal}
          onApproveCounterparty={onApproveCounterparty}
          onOpenDocxModal={onOpenDocxModal}
        />
      </div>
    </span>
  );
};
