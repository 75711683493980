import ng from 'angular';

import { errorHandler } from '~/shared/lib/errors';

import template from './gt-resource-multiselect.tpl.html?raw';
import type { GtUtilsService } from '../../gt-utils/gt-utils.srv';

import type { QueryParams } from '^/app/core/types';

(function () {
  'use strict';
  ng.module('core.legacy').component('gtResourceMultiselect', {
    bindings: {
      ngModel: '=',
      resourceName: '<',
      queryParams: '<?',
      getQueryParams: '<?',
      allowClear: '<?',
      placeholder: '<',
      gtDisabled: '<?',
      updateOnEvent: '<?',
      required: '<?',
      onOpenClose: '&?',
      callOnSelect: '&?',
      callOnRemove: '&?',
    },
    template,
    controller: Controller,
    controllerAs: 'vm',
  });

  Controller.$inject = ['$scope', 'GtUtils'];

  function Controller(this: any, $scope: ng.IScope, GtUtils: GtUtilsService) {
    const vm = this;
    vm.items = [];
    vm.modelCopy = [];
    vm.updateItems = updateItems;
    vm.initLoad = true;
    vm.clear = clear;
    vm.isOpenHandler = isOpenHandler;
    vm.activate = false;

    vm.$onInit = function () {
      vm.ngModel = vm.ngModel || [];
      if (vm.updateOnEvent) {
        $scope.$on(vm.updateOnEvent, function () {
          updateItems().catch(errorHandler);
        });
      }

      $scope.$watch('vm.ngModel', function (newVal: any, oldVal: any) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          updateSelectedItems();
        }
      });

      vm.ngModel.map((item: any, index: number) => {
        if (typeof item === 'string') {
          vm.ngModel[index] = parseInt(item, 10);
        }
      });

      getSavedItems().catch(errorHandler);
    };

    vm.onUiSelectInit = (uiSelect: any) => {
      this.updateItems();
      setTimeout(() => uiSelect.activate());
    };

    vm.isSelected = (item: any) => {
      return vm.ngModel.includes(item.id);
    };

    vm.$onChanges = function () {
      initNgModel();
      vm.resourceName =
        (typeof vm.resourceName == 'function' && vm.resourceName()) || vm.resourceName;
      vm.resource = GtUtils.getResource(vm.resourceName);
      getSavedItems().catch(errorHandler);
    };

    ////////////////
    function initNgModel() {
      vm.ngModel = vm.ngModel || [];
      if (vm.ngModel && !ng.isArray(vm.ngModel)) {
        vm.ngModel = [vm.ngModel];
      }
    }
    function getSavedItems() {
      if (vm.ngModel.length && vm.resourceName) {
        return GtUtils.getPredictions([vm.resourceName], vm.queryParams).then((data: any) => {
          vm.items = data[vm.resourceName];
          vm.modelCopy = [
            ...vm.ngModel.filter(
              (modelItem: any) => !vm.items.map((item: any) => item.id).includes(modelItem),
            ),
          ];
        });
      }

      return Promise.resolve();
    }

    function updateItems(search?: string, force?: boolean): Promise<void> {
      if (vm.initLoad) {
        vm.initLoad = false;
        updateSelectedItems();
        return Promise.resolve();
      }

      let params = {};
      if (vm.getQueryParams) {
        params = vm.getQueryParams() as QueryParams;
      }
      if (vm.queryParams || search) {
        params = { ...params, ...vm.queryParams, search };
      }
      return GtUtils.getPredictions([vm.resourceName], params, undefined, force)
        .then((data: any) => {
          vm.items = data[vm.resourceName];
          $scope.$applyAsync();
          if (!vm.items?.length) {
            return force ? Promise.resolve() : updateItems(search, true);
          }
          updateSelectedItems();
          return Promise.resolve();
        })
        .catch(errorHandler);
    }
    function addItem(item: any) {
      vm.items.push({
        id: item.id,
        title: GtUtils.getObjectTitle(item),
      });
    }

    function updateSelectedItems() {
      initNgModel();
      const itemsIds = vm.items.map((item: any) => item.id);
      const modelIds = vm.ngModel.filter((itemId: number) => itemId && !itemsIds.includes(itemId));

      const selectedItems = vm.ngModel.filter((item: any) => itemsIds.includes(item));
      vm.modelCopy = vm.modelCopy.filter((modelItem: any) => !itemsIds.includes(modelItem));

      selectedItems.map((selectedItem: any) => {
        vm.modelCopy.push(selectedItem);
      });

      vm.ngModel = vm.modelCopy.length ? [...vm.modelCopy] : vm.ngModel;

      if (!modelIds.length) {
        return;
      }
      if (vm.resource.predictions) {
        return vm.resource.predictions({ id: modelIds }, (data: any) =>
          data.results.forEach(addItem),
        );
      }
      modelIds.forEach((modelId: number) => vm.resource.get({ id: modelId }, addItem));
    }

    function clear() {
      vm.ngModel = [];
    }

    vm.clearItem = (e: Event, item: any) => {
      e.stopPropagation();
      vm.ngModel.splice(vm.ngModel.indexOf(item), 1);
    };

    function isOpenHandler(isOpen: boolean) {
      return typeof vm.onOpenClose == 'function' && vm.onOpenClose({ isOpen: isOpen });
    }
  }
})();
