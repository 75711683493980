import univerPresetSheetsCoreEnUS from '@univerjs/presets/preset-sheets-core/locales/en-US';
import {
  UniverDataValidationPlugin,
  UniverSheetsDataValidationPlugin,
  UniverSheetsDataValidationUIPlugin,
} from '@univerjs/presets/preset-sheets-data-validation';
import sheetsDataValidationEnUS from '@univerjs/presets/preset-sheets-data-validation/locales/en-US';
import type React from 'react';
import '@univerjs/sheets-data-validation/facade';

import { errorHandler } from '~/shared/lib/errors';
import {
  type DataValidationResult,
  DataValidationStatus,
  type FUniver,
  LocaleType,
  type Nullable,
  type ObjectMatrix,
  type SpreadsheetDataDef,
  type SpreadsheetRange,
  type UnitModel,
  UniverSheetsCorePreset,
  type Worksheet,
  createUniver,
  defaultTheme,
} from '~/shared/lib/spreadsheet';
import { mergeObjects } from '~/shared/lib/utils';

export const initSpreadsheet = ({
  containerRef,
  spreadsheetRef,
  data = {},
}: {
  containerRef: React.RefObject<HTMLDivElement>;
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
  data?: Partial<SpreadsheetDataDef>;
}) => {
  if (!containerRef.current) {
    throw Error('container not initialized');
  }
  const { univerAPI } = createUniver({
    locale: LocaleType.EN_US,
    locales: {
      enUS: mergeObjects({}, univerPresetSheetsCoreEnUS, sheetsDataValidationEnUS),
    },
    theme: defaultTheme,
    presets: [
      UniverSheetsCorePreset({
        container: containerRef.current,
        toolbar: false,
        footer: false,
        header: false,
        contextMenu: false,
        formulaBar: false,
      }),
    ],
    plugins: [
      UniverDataValidationPlugin,
      UniverSheetsDataValidationPlugin,
      [UniverSheetsDataValidationUIPlugin, { showEditOnDropdown: false }],
    ],
  });

  univerAPI.createWorkbook(data);

  spreadsheetRef.current = univerAPI;
};

export const destroySpreadsheet = ({
  spreadsheetRef,
  workbookRef,
}: {
  spreadsheetRef: React.MutableRefObject<FUniver | null>;
  workbookRef: React.MutableRefObject<UnitModel<object, number> | null>;
}) => {
  spreadsheetRef.current = null;
  workbookRef.current = null;
};

export const validateWorksheetColumns = ({
  worksheet,
  onDataValidation,
}: {
  worksheet?: Worksheet;
  onDataValidation?: ({ allowSave }: { allowSave: boolean }) => void;
}) => {
  const processValidatorStatus = ({
    statusOfSheet,
    onDataValidation,
  }: {
    statusOfSheet: ObjectMatrix<Nullable<DataValidationResult>>;
    onDataValidation: ({ allowSave }: { allowSave: boolean }) => void;
  }) => {
    [statusOfSheet].forEach((matrix) => {
      Object.values(matrix).forEach(
        (innerObject: Record<number, Record<number, DataValidationResult>>) => {
          const hasInvalidStatus = validateStatus(innerObject);
          onDataValidation({ allowSave: !hasInvalidStatus });
        },
      );
    });
  };

  const validateStatus = (
    innerObject: Record<number, Record<number, DataValidationResult>>,
  ): boolean => {
    const cellRecords = Object.values(innerObject).flatMap((item) => Object.values(item));
    return cellRecords.some((obj) => {
      return obj === DataValidationStatus.INVALID;
    });
  };

  if (worksheet && onDataValidation) {
    worksheet
      .getValidatorStatusAsync()
      .then((statusOfSheet) => {
        processValidatorStatus({ statusOfSheet, onDataValidation });
      })
      .catch(errorHandler);
  }
};

export const setProtectedColumns = ({
  ref,
  ranges,
}: {
  ref: FUniver;
  ranges: SpreadsheetRange[];
}) => {
  const workbook = ref.getActiveWorkbook();

  if (!workbook) {
    throw new Error('Workbook not initialized');
  }

  const sheet = workbook.getActiveSheet();
  const unitId = workbook.getId();
  const subUnitId = sheet.getSheetId();

  const permission = workbook.getPermission();
  permission.setPermissionDialogVisible(false);
  permission.addRangeBaseProtection(unitId, subUnitId, ranges).catch(errorHandler);
};
