import { clientsClientrolesList } from '~/shared/api';
import type { Paginated } from '~/shared/common';

import type { ClientRole, ClientRoleListParams } from '../types';

export class ClientRoleRepository {
  query = async (query: ClientRoleListParams): Promise<Paginated<ClientRole>> => {
    const { data } = await clientsClientrolesList({ query });
    return data;
  };
}
