import React from 'react';

import { LabelWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const LabelCellWrite = ({ value, valueChanged, disabled }: CellTypeProps) => {
  return (
    <div className="p-1">
      <LabelWrite
        value={typeof value === 'string' ? value : ''}
        valueChanged={valueChanged}
        disabled={disabled}
      />
    </div>
  );
};
