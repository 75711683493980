import { shareReplay } from 'rxjs';

import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import {
  BillOfLadingCreate,
  BillOfLadingList,
  BillOfLadingListParams,
  BillOfLadingUpdate,
} from '~/features/execution/bills-of-lading';
import { BillsOfLadingModel } from '~/features/execution/bills-of-lading/services/bills-of-lading.model';
import { BillsOfLadingRepository } from '~/features/execution/bills-of-lading/services/bills-of-lading.repository';
import { BillOfLading } from '~/shared/api';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, startWith, switchMap } from '~/shared/lib/state';

@injectable()
export class BillsOfLadingListViewModel extends EntityListEditViewModel<
  BillOfLading,
  Omit<BillOfLadingListParams, 'page' | 'page_size'> & { page: number; page_size: number },
  BillOfLadingCreate,
  BillOfLadingList
> {
  resolveEntityRepo() {
    return container.resolve(BillsOfLadingRepository);
  }
  private readonly entityName = 'bill-of-lading-page-view';
  private readonly purposeModel = 'billoflading';

  constructor(
    private readonly billsOfLadingRepo: BillsOfLadingRepository,
    private readonly billsOfLadingModel: BillsOfLadingModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.billsOfLadingRepo.getTotals(params))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  public billsOfLadingCreated = async ({ entities }: { entities: BillOfLadingCreate[] }) => {
    await this.billsOfLadingModel.createBillsOfLading(entities);
  };

  public billOfLadingDeleted = async (id: number) => {
    try {
      await this.billsOfLadingRepo.delete(id);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public billsOfLadingUpdated = async ({ entities }: { entities: BillOfLadingUpdate[] }) => {
    try {
      await this.billsOfLadingModel.updateBillsOfLading(entities);
      notifySuccess('Bills of lading updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }

  public updateRecords = async (records: BillOfLading[]) => {
    await Promise.all(records.map(this.billsOfLadingRepo.update));
    this.pageParamsChanged({});
  };

  public createBillOfLading = async (billOfLading: BillOfLading) => {
    try {
      await this.billsOfLadingRepo.create(billOfLading);
      notifySuccess('Bill of lading created');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };
}
