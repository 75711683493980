import type ng from 'angular';

import { formatDate } from '~/shared/lib/utils';
export class ContractsAnalyticsService {
  $filter: ng.IFilterService;
  $http: ng.IHttpService;
  $q: ng.IQService;
  $resource: ng.resource.IResourceService;
  analyticsData: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $resource: ng.resource.IResourceService,
    $q: ng.IQService,
    $http: ng.IHttpService,
    $filter: ng.IFilterService,
    gettext: ng.gettext.gettextFunction,
  ) {
    this.$resource = $resource;
    this.$q = $q;
    this.$http = $http;
    this.$filter = $filter;
    this.gettext = gettext;

    this.analyticsData = undefined;
  }

  getAnalyticsData(params?: any) {
    if (!params && this.analyticsData) {
      return this.$q.when().then(() => this.analyticsData);
    }
    this.analyticsData = this.$http({
      method: 'GET',
      url: '/api/contracts/contracts-analytics/',
      params: params,
    }).then((response: any) => {
      this.analyticsData = response.data;
      return this.analyticsData;
    });
    return this.analyticsData;
  }

  getVolumeExecutionChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Sale', 'Purchase'],
      count: 0,
    };

    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.contracts_by_execution_date.length;
      data.contracts_by_execution_date.forEach((i: any) => {
        chartConfig.labels.push(formatDate(i.date_of_execution_month, 'MMM, yy'));
        chartConfig.data[0].push(Math.round(i.sale_volume * 100) / 100);
        chartConfig.data[1].push(Math.round(i.purchase_volume * 100) / 100);
      });

      return chartConfig;
    });
  }

  getPriceExecutionChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Sale', 'Purchase'],
      count: 0,
    };

    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.contracts_by_execution_date.length;
      data.contracts_by_execution_date.forEach((i: any) => {
        chartConfig.labels.push(formatDate(i.date_of_execution_month, 'MMM, yy'));
        chartConfig.data[0].push(Math.round((i.sale_total_value / i.sale_volume) * 100) / 100);
        chartConfig.data[1].push(
          Math.round((i.purchase_total_value / i.purchase_volume) * 100) / 100,
        );
      });

      return chartConfig;
    });
  }

  getValueExecutionChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: [[], []],
      series: ['Sale', 'Purchase'],
    };

    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_execution_date.forEach((i: any) => {
        chartConfig.labels.push(formatDate(i.date_of_execution_month, 'MMM, yy'));
        chartConfig.data[0].push(Math.round(i.sale_total_value * 100) / 100);
        chartConfig.data[1].push(Math.round(i.purchase_total_value * 100) / 100);
      });

      return chartConfig;
    });
  }

  getDealsByConclusionDateChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        'Sale count': [],
        'Purchase count': [],
        'Sale max volume': [],
        'Purchase max volume': [],
        'Sale min volume': [],
        'Purchase min volume': [],
        'Sale avg volume': [],
        'Purchase avg volume': [],
        'Sale sum volume': [],
        'Purchase sum volume': [],
      },
    };

    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_conclusion_date.forEach((i: any) => {
        chartConfig.labels.push(formatDate(i.conclusion_date_month, 'MMM, yy'));
        chartConfig.data['Sale count'].push(i.sale_count);
        chartConfig.data['Purchase count'].push(i.purchase_count);
        chartConfig.data['Sale max volume'].push(Math.round(i.sale_volume_max * 100) / 100);
        chartConfig.data['Purchase max volume'].push(Math.round(i.purchase_volume_max * 100) / 100);
        chartConfig.data['Sale min volume'].push(Math.round(i.sale_volume_min * 100) / 100);
        chartConfig.data['Purchase min volume'].push(Math.round(i.purchase_volume_min * 100) / 100);
        chartConfig.data['Sale avg volume'].push(Math.round(i.sale_volume_avg * 100) / 100);
        chartConfig.data['Purchase avg volume'].push(Math.round(i.purchase_volume_avg * 100) / 100);
        chartConfig.data['Sale sum volume'].push(Math.round(i.sale_volume * 100) / 100);
        chartConfig.data['Purchase sum volume'].push(Math.round(i.purchase_volume * 100) / 100);
      });

      return chartConfig;
    });
  }

  // port
  getSaleByPortChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_port.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_port, 'sale_volume', true).forEach((i: any) => {
        chartConfig.labels.push(i.ports__name);
        chartConfig.data.Volume.push(i.sale_volume);
      });
      return chartConfig;
    });
  }

  getSaleByPortData() {
    return this.getAnalyticsData().then((data: any) => {
      const filteredData = data.contracts_by_port.filter((i: any) => {
        i.sale_volume = Math.round(i.sale_volume * 100) / 100;
        return i.sale_volume;
      });
      return this.$filter('orderBy')(filteredData, 'sale_volume', true);
    });
  }

  getPurchaseByPortChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_port.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_port, 'purchase_volume', true).forEach((i: any) => {
        chartConfig.labels.push(i.ports__name);
        chartConfig.data.Volume.push(i.purchase_volume);
      });
      return chartConfig;
    });
  }

  getPurchaseByPortData() {
    return this.getAnalyticsData().then((data: any) => {
      const filteredData = data.contracts_by_port.filter((i: any) => {
        i.purchase_volume = Math.round(i.purchase_volume * 100) / 100;
        return i.purchase_volume;
      });
      return this.$filter('orderBy')(filteredData, 'purchase_volume', true);
    });
  }
  // elevator
  getSaleByElevatorChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_elevator.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_elevator, 'sale_volume', true).forEach((i: any) => {
        chartConfig.labels.push(i.elevator__name);
        chartConfig.data.Volume.push(i.sale_volume);
      });
      return chartConfig;
    });
  }
  getSaleByElevatorData() {
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_elevator.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      return this.$filter('orderBy')(data.contracts_by_elevator, 'sale_volume', true);
    });
  }

  getPurchaseByElevatorChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_elevator.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_elevator, 'purchase_volume', true).forEach(
        (i: any) => {
          chartConfig.labels.push(i.elevator__name);
          chartConfig.data.Volume.push(i.purchase_volume);
        },
      );
      return chartConfig;
    });
  }

  getPurchaseByElevatorData() {
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_elevator.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      return this.$filter('orderBy')(data.contracts_by_elevator, 'purchase_volume', true);
    });
  }

  // broker
  getSaleByBrokerChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_broker.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_broker, 'sale_volume', true).forEach((i: any) => {
        if (!i.broker__name) {
          return;
        }
        chartConfig.labels.push(i.broker__name);
        chartConfig.data.Volume.push(i.sale_volume);
      });
      return chartConfig;
    });
  }
  getSaleByBrokerData() {
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_broker.forEach((i: any) => {
        i.sale_volume = Math.round(i.sale_volume * 100) / 100;
        i.broker__name = i.broker__name || this.gettext('W/O Broker');
      });
      return this.$filter('orderBy')(data.contracts_by_broker, 'sale_volume', true);
    });
  }

  getPurchaseByBrokerChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_broker.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_broker, 'purchase_volume', true).forEach(
        (i: any) => {
          if (!i.broker__name) {
            return;
          }
          chartConfig.labels.push(i.broker__name);
          chartConfig.data.Volume.push(i.purchase_volume);
        },
      );
      return chartConfig;
    });
  }

  getPurchaseByBrokerData() {
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_broker.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      return this.$filter('orderBy')(data.contracts_by_broker, 'purchase_volume', true);
    });
  }

  // basis
  getSaleByBasisChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_basis.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_basis, 'sale_volume', true).forEach((i: any) => {
        if (!i.basis__name) {
          return;
        }
        chartConfig.labels.push(i.basis__name);
        chartConfig.data.Volume.push(i.sale_volume);
      });
      return chartConfig;
    });
  }

  getPurchaseByBasisChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_basis.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_basis, 'purchase_volume', true).forEach(
        (i: any) => {
          chartConfig.labels.push(i.ports__name);
          chartConfig.data.Volume.push(i.purchase_volume);
        },
      );
      return chartConfig;
    });
  }

  // crop year
  getByCropYearChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_crop_year.forEach((i: any) => {
        chartConfig.labels.push(i.crop_year);
        chartConfig.data.Volume.push(Math.round(i.volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getByCropYearData() {
    return this.getAnalyticsData().then((data: any) => {
      return data.contracts_by_crop_year;
    });
  }

  // origin
  getByOriginChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_origin.forEach((i: any) => {
        chartConfig.labels.push(i.origin_of_crop__title);
        chartConfig.data.Volume.push(Math.round(i.volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getByOriginData() {
    return this.getAnalyticsData().then((data: any) => {
      return data.contracts_by_origin;
    });
  }

  // destination
  getByDestinationChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_destination.forEach((i: any) => {
        chartConfig.labels.push(i.destination_of_crop__title);
        chartConfig.data.Volume.push(Math.round(i.volume * 100) / 100);
      });
      return chartConfig;
    });
  }

  getByDestinationData() {
    return this.getAnalyticsData().then((data: any) => {
      return data.contracts_by_destination;
    });
  }

  // status
  getSaleByStatusChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_status.forEach(
        (i: any) => (i.sale_volume = Math.round(i.sale_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_status, 'sale_volume', true).forEach((i: any) => {
        chartConfig.labels.push(i.status);
        chartConfig.data.Volume.push(i.sale_volume);
      });
      return chartConfig;
    });
  }
  getSaleByStatusData() {
    return this.getAnalyticsData().then((data: any) => {
      const filteredData = data.contracts_by_status.filter((i: any) => {
        i.sale_volume = Math.round(i.sale_volume * 100) / 100;
        return i.sale_volume;
      });
      return this.$filter('orderBy')(filteredData, 'sale_volume', true);
    });
  }

  getPurchaseByStatusChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
    };
    return this.getAnalyticsData().then((data: any) => {
      data.contracts_by_status.forEach(
        (i: any) => (i.purchase_volume = Math.round(i.purchase_volume * 100) / 100),
      );
      this.$filter('orderBy')(data.contracts_by_status, 'purchase_volume', true).forEach(
        (i: any) => {
          chartConfig.labels.push(i.status);
          chartConfig.data.Volume.push(i.purchase_volume);
        },
      );
      return chartConfig;
    });
  }

  getPurchaseByStatusData() {
    return this.getAnalyticsData().then((data: any) => {
      const filteredData = data.contracts_by_status.filter((i: any) => {
        i.purchase_volume = Math.round(i.purchase_volume * 100) / 100;
        return i.purchase_volume;
      });
      return this.$filter('orderBy')(filteredData, 'purchase_volume', true);
    });
  }

  getVolumeByCargoChartConfig() {
    const chartConfig: any = {
      labels: [],
      data: {
        Volume: [],
      },
      count: 0,
    };

    return this.getAnalyticsData().then((data: any) => {
      chartConfig.count = data.volumes_by_cargo.length;
      data.volumes_by_cargo.forEach((i: any) => {
        chartConfig.labels.push(i.cargo__title);
        chartConfig.data.Volume.push(Math.round(i.volume * 100) / 100);
      });
      return chartConfig;
    });
  }
}
ContractsAnalyticsService.$inject = ['$resource', '$q', '$http', '$filter', 'gettext'];
