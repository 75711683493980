import React from 'react';

import type { ColumnConfig } from '~/core/page-views/lib';
import type { ConsignmentList } from '~/features/execution/consignments';

export const getConsignmentColumns = (consignments: ConsignmentList[]): ColumnConfig[] => [
  {
    key: 'number',
    sourceKey: 'number',
    dataType: 'string',
    editing: true,
    validate: { type: 'textLength', condition: 'moreThan', value: '0' },
  },
  {
    key: 'type',
    dataType: 'select',
    editing: true,
    cellTemplateContext: {
      options: [{ value: 'receipt' }, { value: 'dispatch' }],
    },
    validate: {
      type: 'equalValue',
      values: ['receipt', 'dispatch'],
    },
  },
  { key: 'date', dataType: 'date', editing: true, typeExtra: 'Y-m-d' },
  { key: 'volume', dataType: 'number', editing: true },
  {
    key: 'volume_to_connect_dbl',
    sourceKey: 'volume_to_connect_dbl',
    dataType: 'number',
    editing: false,
  },
  { key: 'writeof_volume', sourceKey: 'writeof_volume', dataType: 'number', editing: false },
  { key: 'balance_volume', sourceKey: 'balance_volume', dataType: 'number', editing: false },
  {
    key: 'vat_contract_price',
    sourceKey: 'vat_contract_price',
    dataType: 'number',
    editing: false,
  },
  {
    key: 'contract_price_with_vat',
    sourceKey: 'contract_price_with_vat',
    dataType: 'number',
    editing: false,
  },
  { key: 'amount', sourceKey: 'amount', dataType: 'number', editing: false },
  { key: 'vat_amount', sourceKey: 'vat_amount', dataType: 'number', editing: false },
  { key: 'amount_with_vat', sourceKey: 'amount_with_vat', dataType: 'number', editing: false },
  { key: 'additional_info', sourceKey: 'additional_info', dataType: 'text', editing: true },
  { key: 'commodity', sourceKey: 'commodity_title', dataType: 'text', editing: true },
  { key: 'warehouse', sourceKey: 'warehouse_name', dataType: 'text', editing: true },
  {
    key: 'passport',
    sourceKey: 'passports_data',
    dataType: 'template',
    render: ({ id }) => {
      const consignment = consignments.find((c) => c.id === id);

      if (!consignment?.passports_data?.length) {
        return <span />;
      }

      return (
        <>
          {consignment.passports_data.map((item) => (
            <span key={item.id}>
              {item.title}
              <div className="clear-both block"></div>
            </span>
          ))}
        </>
      );
    },
    editing: false,
  },
  { key: 'receiver', sourceKey: 'receiver_name', dataType: 'text', editing: true },
  { key: 'producer', sourceKey: 'producer_name', dataType: 'text', editing: true },
  {
    key: 'contract_supplier_name',
    sourceKey: 'contract_supplier_name',
    dataType: 'text',
    editing: false,
  },
  {
    key: 'contract_buyer_name',
    sourceKey: 'contract_buyer_name',
    dataType: 'text',
    editing: false,
  },
  { key: 'bu_title', sourceKey: 'business_unit_title', dataType: 'text', editing: true },
  { key: 'editor_last_name', sourceKey: 'editor_last_name', dataType: 'text', editing: false },
  { key: 'voyage', sourceKey: 'voyage_name', dataType: 'text', editing: true },
  { key: 'contract', sourceKey: 'contract_number', dataType: 'text', editing: true },
];
