import { AuthStore } from '~/core/auth/services';
import type { EntityFieldProperty } from '~/core/page-views/lib';
import { EntityFieldsRepository } from '~/core/page-views/services/entity-fields.repository';
import { CounterpartyList } from '~/features/crm/counterparties/lib';
import { injectable } from '~/shared/lib/di';
import { notifySuccess } from '~/shared/lib/notify';
import { BehaviorSubject, Subject, of, switchMap } from '~/shared/lib/state';

import { UserViewRepository } from './user-view.repository';
import { BasicView, ViewConfig, basicViews } from '../components/view-configurator';

@injectable()
export class PageViewsStore {
  // remove basicViews import from component
  private readonly currentViewIdSubj = new BehaviorSubject<number>(basicViews[0].id);
  private readonly availableViewsChangedSubj = new BehaviorSubject<boolean>(false);
  // private readonly editingSubj = new BehaviorSubject(false);
  // wtf
  readonly dataSetId = 'counterparties';
  private readonly viewConfigChangedSubj = new Subject<{
    id: number;
    config: Partial<ViewConfig>;
  }>();
  private readonly deletedViewId = new Subject<number>();
  private readonly pageDataSubj = new BehaviorSubject<CounterpartyList[]>([]);
  private readonly editingPageDataSubj = new BehaviorSubject<CounterpartyList[]>([]);

  constructor(
    private readonly authStore: AuthStore,
    private readonly userViewRepository: UserViewRepository,
    private readonly entityFieldsRepo: EntityFieldsRepository,
  ) {}
  private readonly createViewSubj = new Subject<Omit<BasicView, 'id'>>();
  private readonly entityFieldPropertiesSubj = new BehaviorSubject<EntityFieldProperty[]>([]);

  private entityName = '';
  private purposeModel = '';

  readonly entityFieldProperties$ = this.entityFieldPropertiesSubj.pipe(
    switchMap(() =>
      this.entityName
        ? of(this.entityFieldsRepo.getEntityProperties({ entityName: this.entityName }))
        : of([]),
    ),
  );

  readonly entityFieldUserProperties$ = this.entityFieldPropertiesSubj.pipe(
    switchMap(() =>
      this.purposeModel
        ? of(
            this.entityFieldsRepo.getEntityUserProperties({
              purpose_model: this.purposeModel,
              table_name: this.entityName,
            }),
          )
        : of([]),
    ),
  );
  readonly viewConfigChanged$ = this.viewConfigChangedSubj.pipe(
    switchMap(async ({ id, config }) => {
      await this.userViewRepository.updateUserView(
        { dataSetId: this.dataSetId, viewId: id },
        config,
      );
      return true;
    }),
  );

  public init({ entityName, purposeModel }: { entityName: string; purposeModel: string }) {
    this.entityName = entityName;
    this.purposeModel = purposeModel;
  }

  public viewConfigChanged = <T>(id: number, config: Partial<ViewConfig<T>>) => {
    this.viewConfigChangedSubj.next({ id, config: config as Partial<ViewConfig> });
    this.availableViewsChangedSubj.next(true);
  };

  public createView = async (view: Omit<BasicView, 'id'>) => {
    try {
      await this.userViewRepository.createUserView(view);
    } catch (err) {
      console.error(err);
    }

    this.availableViewsChangedSubj.next(true);
  };

  public viewDeleted = async (id: number) => {
    try {
      await this.userViewRepository.deleteUserView({ dataSetId: this.dataSetId, viewId: id });
      notifySuccess(`View #${id} deleted`);
    } catch (err) {
      console.error(err);
    }

    this.availableViewsChangedSubj.next(true);
  };

  public changesReset = () => {
    this.editingPageDataSubj.next(this.pageDataSubj.value);
  };
}
