import React from 'react';

import { cn } from '~/shared/lib/utils';
import type { ColumnDef } from '~/shared/ui/components/data-grid';
import { DropdownMenu, DropdownMenuTrigger } from '~/shared/ui/kit/dropdown-menu';
import { Tag } from '~/shared/ui/kit/tag';

import { MultiSelectWriteContent } from './multi-select-write-content';
import { type DataTypeProps, messageIntent } from '../../lib';

export const MultiSelectWrite: React.FC<
  DataTypeProps<string[]> & {
    isOpen?: boolean;
    cellTemplateContext: ColumnDef['cellTemplateContext'];
    contentClassName?: string;
  }
> = ({ message, disabled, valueChanged, isOpen, value, cellTemplateContext, contentClassName }) => {
  return (
    <div className={cn('flex h-full w-full items-center', message && messageIntent[message.type])}>
      <DropdownMenu open={isOpen}>
        <DropdownMenuTrigger className="flex h-full w-full items-center gap-2">
          {value ? (
            <>
              {value.slice(0, 2).map((item) => (
                <Tag key={item} disabled={disabled} {...cellTemplateContext?.props}>
                  {item}
                </Tag>
              ))}
              {value.length > 2 && (
                <Tag variant="gray" disabled={disabled}>
                  +{value.length - 2}
                </Tag>
              )}
            </>
          ) : (
            <div className="h-full w-full"></div>
          )}
        </DropdownMenuTrigger>
        <MultiSelectWriteContent
          value={value}
          context={cellTemplateContext?.props ?? {}}
          options={cellTemplateContext?.options ?? []}
          valueChanged={valueChanged}
          contentClassName={contentClassName}
        />
      </DropdownMenu>
    </div>
  );
};
