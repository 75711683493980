import { coreCustomStatusesPredictionsRetrieve, cropsCropsPredictionsRetrieve } from '~/shared/api';
import { container } from '~/shared/lib/di';
import { registerEntityAutocomplete } from '~/shared/ui/data-types';

import { LogisticRepository } from './services/logistic.repository';

registerEntityAutocomplete('Logistic', container.resolve(LogisticRepository).search);

// TO DO: remove after implementing CustomStatusRepository
registerEntityAutocomplete('CustomStatus', async (input: string) => {
  const { data } = await coreCustomStatusesPredictionsRetrieve({
    query: { content_type__model: 'logistic', search: input },
  });
  return data.results.map((r) => ({ title: r.title, id: r.id }));
});
// TO DO: remove after implementing Crop
registerEntityAutocomplete('Crop', async (input: string) => {
  const { data } = await cropsCropsPredictionsRetrieve({
    query: { search: input },
  });
  return data.results.map((r) => ({ title: r.title, id: r.id }));
});
