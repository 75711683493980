import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { forwardRef } from 'react';

import { cn } from '~/shared/lib/utils';

export type TooltipProps = {
  sideOffset?: number;
  side?: 'top' | 'bottom' | 'left' | 'right';
  padding?: number;
  children: React.ReactNode;
  content: React.ReactNode;
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ sideOffset = 4, side = 'bottom', padding = 8, children, content }, ref) => {
    return (
      <TooltipPrimitive.Provider>
        <TooltipPrimitive.Root delayDuration={100}>
          <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
          <TooltipPrimitive.Content
            ref={ref}
            side={side}
            sideOffset={sideOffset}
            className={cn(
              'text-text-main-inverted backdrop-blur-strong animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 z-50 overflow-hidden rounded-md px-3 py-1.5 text-xs',
            )}
            style={{
              padding: `${padding}px`,
              backgroundColor: 'rgba(23, 23, 23, 0.8)',
            }}
          >
            {content}
            <TooltipPrimitive.Arrow />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Root>
      </TooltipPrimitive.Provider>
    );
  },
);

Tooltip.displayName = 'Tooltip';
