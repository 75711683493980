import { PageFiltersStore } from '~/core/page-filters/services/page-filters.store';
import { PageViewsStore } from '~/core/page-views';
import { EntityListEditViewModel } from '~/shared/common';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { defer, from, map, startWith, switchMap } from '~/shared/lib/state';

import { Finance, FinanceCreate, FinanceList, FinanceListParams, FinanceUpdate } from '../lib';
import { FinanceModel } from './finance.model';
import { FinanceRepository } from './finance.repository';

@injectable()
export class FinanceListViewModel extends EntityListEditViewModel<
  Finance,
  Omit<FinanceListParams, 'page' | 'page_size'> & { page_size: number; page: number },
  FinanceCreate,
  FinanceList
> {
  resolveEntityRepo() {
    return container.resolve(FinanceRepository);
  }
  private readonly entityName = 'finances';
  private readonly purposeModel = 'finance';

  constructor(
    private readonly financeRepo: FinanceRepository,
    private readonly financeModel: FinanceModel,
    private readonly pageViewsStore: PageViewsStore,
    private readonly pageFiltersStore: PageFiltersStore,
  ) {
    super();
    this.pageViewsStore.init({ entityName: this.entityName, purposeModel: this.purposeModel });
  }

  readonly properties$ = defer(() => this.pageViewsStore.entityFieldProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    map((properties) => [...new Map(properties.map((p) => [p.column_name, p])).values()]),
    startWith([]),
  );

  readonly userProperties$ = defer(() => this.pageViewsStore.entityFieldUserProperties$).pipe(
    switchMap((propertiesPromise) => from(propertiesPromise)),
    startWith([]),
  );

  readonly totals$ = this.pageParams$.pipe(
    switchMap((params) => from(this.financeRepo.getFinanceListTotals(params))),
  );

  public financesCreated = async (dtos: FinanceCreate[]) => {
    this.pageParamsChanged({});
    try {
      await this.financeModel.createFinances(dtos);
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public financeDeleted = async (id: number) => {
    try {
      await this.financeRepo.delete(id);
    } catch (err) {
      errorHandler(err);
      console.error(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public financesUpdated = async (dtos: FinanceUpdate[]) => {
    try {
      await this.financeModel.updateFinances(dtos);
      notifySuccess('Finances updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  public get viewsStore() {
    return this.pageViewsStore;
  }

  public get filtersStore() {
    return this.pageFiltersStore;
  }
}
