import { injectable } from '~/shared/lib/di';
import { EntityOption } from '~/shared/ui/data-types';

import { LogisticRepository } from './logistic.repository';
import {
  ConnectionMode,
  InvoicePurpose,
  InvoicingVolume,
  InvoicingVolumeBase,
  LogisticBuyerList,
  LogisticCostsList,
  LogisticCreate,
  LogisticExporList,
  LogisticList,
  LogisticListParams,
  LogisticSupplierList,
  LogisticTable,
  LogisticUpdate,
} from '../lib';

const totalsMaxChunkSize = 50;
const volumeBaseMap = {
  volume_departed: 'quantity_to_be_invoiced_volume_departed_based',
  volume_departed_consignment: 'quantity_to_be_invoiced_volume_departed_consignment_based',
  volume_received: 'quantity_to_be_invoiced_volume_received_based',
  volume_departed_real: 'quantity_to_be_invoiced_volume_departed_real_based',
  volume_boarded: 'quantity_to_be_invoiced_volume_boarded_based',
};
@injectable()
export class LogisticModel {
  constructor(private readonly logisticRepo: LogisticRepository) {}

  changeTable = (table: LogisticTable) => {
    this.logisticRepo.setDatasources(table);
  };

  createLogistics = async (entities: LogisticCreate[]): Promise<void> => {
    await Promise.all(entities.map(async (entity) => await this.logisticRepo.create(entity)));
  };

  updateLogistics = async (entities: LogisticUpdate[]) => {
    await Promise.all(entities.map((entity) => this.logisticRepo.update(entity)));
  };

  search = (input: string): Promise<EntityOption[]> => {
    return this.logisticRepo.search(input);
  };

  getLogisticsTotals = async (query: LogisticListParams) => {
    if (query.id_list && query.id_list.length > totalsMaxChunkSize) {
      const chunks = Array.from(
        { length: Math.ceil(query.id_list.length / totalsMaxChunkSize) },
        (_, index) =>
          query.id_list?.slice(index * totalsMaxChunkSize, (index + 1) * totalsMaxChunkSize) ?? [],
      );

      const responses = await Promise.all(
        chunks.map((chunk) => this.logisticRepo.getListTotals({ id_list: chunk })),
      );
      return responses.reduce(
        (acc, totals) => {
          Object.entries(totals).forEach(([key, value]) => {
            acc[key as keyof typeof acc] = (acc[key as keyof typeof acc] || 0) + (value || 0);
          });
          return acc;
        },
        {} as NonNullable<Awaited<ReturnType<LogisticRepository['getListTotals']>>>,
      );
    } else {
      return await this.logisticRepo.getListTotals(query);
    }
  };

  canCreateIncomingInvoiceForLogistic(logistic: LogisticSupplierList) {
    return Boolean(
      logistic.approval_status !== 'rejected' &&
        logistic.supplier_contract &&
        !(logistic.supplier_ten_invoice && logistic.supplier_nineteen_invoice) &&
        !(logistic.has_diff_limit && logistic.approval_status === 'process'),
    );
  }

  canCreateOutgoingInvoiceForLogistic(logistic: LogisticBuyerList) {
    return Boolean(
      logistic.approval_status !== 'rejected' &&
        logistic.buyer_contract &&
        !(logistic.buyer_ten_invoice && logistic.buyer_nineteen_invoice) &&
        !(logistic.has_diff_limit && logistic.approval_status === 'process'),
    );
  }

  canCreateConsolidatedCostsInvoiceForLogistic(logistic: LogisticCostsList) {
    return Boolean(
      logistic.transport_costs_data &&
        logistic.transport_costs_data.filter(
          (cost) => cost.price_per_deal > (cost.invoiced_amount ?? 0),
        ).length > 0,
    );
  }

  canCreateExportInvoiceForLogistic(
    logistic: LogisticExporList,
    invoicingVolume?: InvoicingVolume,
  ) {
    const volumeBase = volumeBaseMap[invoicingVolume ?? 'volume_departed'] as InvoicingVolumeBase;
    return Boolean(logistic[volumeBase] > 0 && logistic.export_contract);
  }

  canCreateCostsInvoiceForLogistic(logistic: LogisticBuyerList | LogisticSupplierList) {
    return Boolean(!logistic.cost_invoiceposition && logistic.costs && logistic.supplier_contract);
  }

  canInvoiceLogistic(
    logistic: LogisticList,
    invoicePurpose: InvoicePurpose,
    invoicingVolume?: InvoicingVolume,
  ) {
    let canInvoice = false;
    if (invoicePurpose == 'incoming') {
      canInvoice = this.canCreateIncomingInvoiceForLogistic(logistic as LogisticSupplierList);
    }
    if (invoicePurpose == 'outgoing') {
      canInvoice = this.canCreateOutgoingInvoiceForLogistic(logistic as LogisticBuyerList);
    }
    if (invoicePurpose == 'consolidated_costs') {
      canInvoice = this.canCreateConsolidatedCostsInvoiceForLogistic(logistic as LogisticCostsList);
    }
    if (invoicePurpose == 'export') {
      canInvoice = this.canCreateExportInvoiceForLogistic(
        logistic as LogisticExporList,
        invoicingVolume,
      );
    }
    if (invoicePurpose == 'costs') {
      canInvoice = this.canCreateCostsInvoiceForLogistic(logistic as LogisticBuyerList);
    }
    return canInvoice;
  }

  canConnectLogisticToConsinment(logistic: LogisticList) {
    return 'writeof_volume_balance' in logistic && (logistic.writeof_volume_balance ?? 0) > 0;
  }

  canConnectLogisticToBl(logistic: LogisticList) {
    return 'bl_volume_balance' in logistic && ((logistic.bl_volume_balance as number) || 0) > 0;
  }

  canConnectLogistic(logistic: LogisticList, connectionMode: ConnectionMode) {
    let canConnect = true;
    if (connectionMode == 'connectingToConsignment') {
      canConnect = this.canConnectLogisticToConsinment(logistic);
    }
    if (connectionMode == 'connectingToBl') {
      canConnect = this.canConnectLogisticToBl(logistic);
    }
    return canConnect;
  }

  isLogisticSelectable(
    logistic: LogisticList,
    invoicePurpose?: InvoicePurpose,
    invoicingVolume?: InvoicingVolume,
    connectionMode?: ConnectionMode,
  ) {
    let isSelectable = false;
    if (invoicePurpose) {
      isSelectable = this.canInvoiceLogistic(logistic, invoicePurpose, invoicingVolume);
    }
    if (connectionMode) {
      isSelectable = this.canConnectLogistic(logistic, connectionMode);
    }
    return isSelectable;
  }
}
