import type { DataDef } from '~/core/page-views/components/data-set-view';
import type { Entity } from '~/shared/common';
import type { CellStyle } from '~/shared/lib/spreadsheet';

import { toSpreadsheetValue } from '../mappers';

export const spreadsheetDataBuilder = <TRecord extends Entity>({
  records,
  properties,
}: DataDef<TRecord>) => {
  const cellData: Record<
    number,
    Record<number, { v: string; custom?: object; s?: CellStyle }>
  > = {};
  const columns = properties;

  columns.forEach((property, j) => {
    cellData[0] = cellData[0] || {};
    cellData[0][j] = {
      v: property.title,
      s: { bl: 1 },
    };
  });

  records.forEach((record, i) => {
    columns.forEach((property, j) => {
      cellData[i + 1] = cellData[i + 1] || {};
      cellData[i + 1][j] = {
        v: toSpreadsheetValue({
          value:
            record[property.sourceKey as keyof TRecord] ?? record[property.key as keyof TRecord],
          dataType: property.dataType,
        }),
        custom: {
          key: property.sourceKey ?? property.key,
          id: record.id,
          dataType: property.dataType,
        },
      };
    });
  });

  return cellData;
};
