import React from 'react';

import { Skeleton } from '~/shared/ui/kit/skeleton';

export const SpreadsheetSkeleton: React.FC<{ recordsCount?: number; columnsCount?: number }> = ({
  columnsCount = 7,
  recordsCount = 22,
}) => (
  <div className="grid grid-cols-7 gap-1.5 px-3" data-testid="spreadsheet-loading">
    {Array.from({ length: recordsCount }).map((_, rowIndex) => {
      const rowKey = `row-${rowIndex}`;

      return (
        <React.Fragment key={rowKey}>
          {Array.from({ length: columnsCount }).map((_, colIndex) => {
            const colKey = `col-${rowIndex}-${colIndex}`;
            return <Skeleton key={colKey} width={210} />;
          })}
        </React.Fragment>
      );
    })}
  </div>
);
