import React from 'react';

import { entityColumnsToPropertiesBuilder } from '~/core/page-views';
import { DatasetView } from '~/core/page-views/components/data-set-view';
import type {
  SaleContractCreate,
  SaleContractList,
  SaleContractUpdate,
} from '~/features/deals/sale-contracts';
import { getSaleContractColumns } from '~/features/deals/sale-contracts';
import { SaleContractsContainerLayout } from '~/features/deals/sale-contracts/components/sale-contracts-container/sale-contracts-container-layout';
import type { SaleContractsListViewModel } from '~/features/deals/sale-contracts/services/sale-contracts-list.view';
import { notifyError } from '~/shared/lib/notify';
import { useObservableEagerState } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';

export const SaleContractsContainer: React.FC<{
  viewMode: 'spreadsheet' | 'grid';
  viewModel: SaleContractsListViewModel;
}> = ({ viewMode, viewModel }) => {
  const createdDataRef = React.useRef<SaleContractCreate[]>([]);
  const editedDataRef = React.useRef<SaleContractUpdate[]>([]);
  const canSaveRef = React.useRef<boolean>(true);

  const pageParams = useObservableEagerState(viewModel.pageParams$);
  const pageData = useObservableEagerState(viewModel.pageData$);
  const columns = useObservableEagerState(viewModel.properties$);
  const userProperties = useObservableEagerState(viewModel.userProperties$);
  const loading = useObservableEagerState(viewModel.loading$);
  const editing = useObservableEagerState(viewModel.editing$);

  const properties = React.useMemo(
    () =>
      entityColumnsToPropertiesBuilder({
        columns,
        columnsConfig: getSaleContractColumns(pageData.results, pageParams.serializer),
      }),
    [columns, pageParams.serializer, pageData.results],
  );

  const visibleProperties = React.useMemo(
    () => properties.filter((property) => property.visible),
    [properties],
  );

  const onSave = React.useCallback(async () => {
    if (canSaveRef.current) {
      await viewModel.saleContractsCreated({ entities: createdDataRef.current });
      await viewModel.saleContractsUpdated({ entities: editedDataRef.current });
    } else {
      notifyError('Please correct the errors');
    }
  }, [viewModel]);

  return (
    <SaleContractsContainerLayout>
      <DatasetView<SaleContractList, SaleContractUpdate, SaleContractCreate>
        view={viewMode}
        pageData={{ count: pageData.count, records: pageData.results }}
        properties={visibleProperties}
        userProperties={userProperties}
        loading={loading}
        editing={editing}
        onEdit={(diff: SaleContractUpdate[]) => {
          editedDataRef.current = diff;
        }}
        onCreate={(diff: SaleContractCreate[]) => {
          createdDataRef.current = diff;
        }}
        onSave={onSave}
        onDataValidation={({ allowSave }: { allowSave: boolean }) => {
          canSaveRef.current = allowSave;
        }}
        onDelete={async (id: number) => {
          return await viewModel.saleContractDeleted(id);
        }}
      />
      <Pagination
        pageCurrent={pageParams.page}
        pageSize={pageParams.page_size}
        recordsTotal={pageData.count}
        currentPageChanged={(page) => viewModel.pageParamsChanged({ page })}
        pageSizeChanged={(pageSize) => viewModel.pageParamsChanged({ page_size: pageSize })}
        pageSizeOptions={[25]}
      />
    </SaleContractsContainerLayout>
  );
};
