import React from 'react';

import type { ColumnConfig } from '~/core/page-views/lib';

import type { ContractList, ContractSerializer } from './types';

export const getBaseContractColumns = (
  contracts: ContractList[],
  serializer: ContractSerializer,
  additionalColumns: Record<string, ColumnConfig> = {},
): ColumnConfig[] => {
  const baseContractColumns: Record<string, ColumnConfig> = {
    number: {
      key: 'number',
      sourceKey: 'contract_number',
      dataType: 'text',
      editing: true,
      validate: { type: 'textLength', condition: 'moreThan', value: '1' },
    },
    status: {
      key: 'status',
      dataType: 'select',
      editing: true,
      cellTemplateContext: {
        options: [
          { value: 'new', props: { variant: 'blue' } },
          { value: 'delivery_period', props: { variant: 'yellow' } },
          { value: 'shipment', props: { variant: 'yellow' } },
          { value: 'cargo_executed', props: { variant: 'green' } },
          { value: 'executed', props: { variant: 'green' } },
          { value: 'cancelled', props: { variant: 'red' } },
          { value: 'washout', props: { variant: 'red' } },
          { value: 'approved', props: { variant: 'green' } },
          { value: 'draft', props: { variant: 'blue' } },
        ],
      },
      validate: {
        type: 'equalValue',
        values: [
          'new',
          'delivery_period',
          'shipment',
          'cargo_executed',
          'executed',
          'cancelled',
          'washout',
          'approved',
          'draft',
        ],
      },
    },
    contract_type: {
      key: 'contract_type',
      sourceKey: 'contract_type',
      dataType: 'text',
      editing: false,
    },
    multicontract: {
      key: 'multicontract',
      sourceKey: 'multicontract_number',
      dataType: 'text',
      editing: false,
    },
    approval_status: {
      key: 'approval_status',
      editing: false,
      dataType: 'select',
      cellTemplateContext: {
        options: [
          { value: 'wait', props: { variant: 'yellow' } },
          { value: 'rejected', props: { variant: 'red' } },
          { value: 'approved', props: { variant: 'green' } },
        ],
      },
    },
    signature: {
      key: 'signature',
      sourceKey: 'signature_stage_all_signed',
      dataType: 'date',
      editing: false,
    },
    passport_status: {
      key: 'passport_status',
      sourceKey: 'passport_status',
      dataType: 'text',
      editing: false,
    },
    shipment_status: {
      key: 'shipment_status',
      sourceKey: 'shipment_status',
      dataType: 'text',
      editing: false,
    },
    finance_status: {
      key: 'finance_status',
      sourceKey: 'finance_status',
      dataType: 'text',
      editing: false,
    },
    docs_status: {
      key: 'docs_status',
      sourceKey: 'docs_status',
      dataType: 'text',
      editing: false,
    },
    ticket: {
      key: 'ticket',
      sourceKey: 'from_request_title',
      dataType: 'text',
      editing: false,
    },
    price_indicative: {
      key: 'price_indicative',
      sourceKey: 'price_indicative',
      dataType: 'number',
      editing: false,
    },
    price_indicative_with_vat: {
      key: 'price_indicative_with_vat',
      sourceKey: 'price_indicative_with_vat',
      dataType: 'number',
      editing: false,
    },
    price_indicative_default_currency: {
      key: 'price_indicative_default_currency',
      sourceKey: 'price_indicative_default_currency',
      dataType: 'number',
      editing: false,
    },
    parent_indicator: {
      key: 'parent_indicator',
      sourceKey: 'parent_indicator_title',
      dataType: 'text',
      editing: false,
    },
    company_group: {
      key: 'company_group',
      sourceKey: 'company_group_title',
      dataType: 'text',
      editing: false,
    },
    parent_indicator_price: {
      key: 'parent_indicator_price',
      sourceKey: 'parent_indicator_price',
      dataType: 'number',
      editing: false,
    },
    pass: {
      key: 'pass',
      sourceKey: 'total_connected',
      dataType: 'number',
      editing: false,
    },
    not_allocated: {
      key: 'not_allocated',
      sourceKey: 'available_to_connect',
      dataType: 'number',
      editing: false,
    },
    concluded: {
      key: 'concluded',
      sourceKey: 'conclusion_date',
      dataType: 'date',
      editing: true,
      typeExtra: 'd-M-y',
    },
    quantity_of_measurement: {
      key: 'quantity_of_measurement',
      sourceKey: 'quantity',
      dataType: 'number',
      editing: false,
    },
    volume: {
      key: 'volume',
      dataType: 'number',
      editing: true,
    },
    price: {
      key: 'price',
      sourceKey: 'price',
      dataType: 'number',
      editing: true,
    },
    costs: {
      key: 'costs',
      sourceKey: 'total_charges',
      dataType: 'number',
      editing: false,
    },
    total_value: {
      key: 'total_value',
      sourceKey: 'total_value',
      dataType: 'number',
      editing: false,
    },
    fixed_price_bonus: {
      key: 'fixed_price_bonus',
      sourceKey: 'fixed_price_bonus',
      dataType: 'number',
      editing: true,
    },
    bill_of_ladings: {
      key: 'bill_of_ladings',
      sourceKey: 'bill_of_ladings',
      dataType: 'text',
      editing: false,
    },
    analytical_price: {
      key: 'analytical_price',
      sourceKey: 'analytical_price',
      dataType: 'number',
      editing: true,
    },
    analytical_value: {
      key: 'analytical_value',
      sourceKey: 'analytical_value',
      dataType: 'number',
      editing: false,
    },
    analytical_diff: {
      key: 'analytical_diff',
      sourceKey: 'analytical_diff',
      dataType: 'number',
      editing: false,
    },
    conclusion_price: {
      key: 'conclusion_price',
      sourceKey: 'conclusion_price',
      dataType: 'number',
      editing: false,
    },
    logistic_executed_volume: {
      key: 'logistic_executed_volume',
      sourceKey: 'logistic_executed_volume',
      dataType: 'number',
      editing: false,
    },
    days_for_invoice_payment: {
      key: 'days_for_invoice_payment',
      sourceKey: 'days_for_invoice_payment',
      dataType: 'number',
      editing: false,
    },
    free_volume_pcd: {
      key: 'free_volume_pcd',
      sourceKey: 'free_volume_pcd',
      dataType: 'number',
      editing: false,
    },
    pre_pcd_logistic_volume: {
      key: 'pre_pcd_logistic_volume',
      sourceKey: 'pre_pcd_logistic_volume',
      dataType: 'number',
      editing: false,
    },
    no_customs_logistics: {
      key: 'no_customs_logistics',
      sourceKey: 'no_customs_logistics',
      dataType: 'number',
      editing: false,
    },
    volume_without_pcd: {
      key: 'volume_without_pcd',
      sourceKey: 'volume_without_pcd',
      dataType: 'number',
      editing: false,
    },
    executed_export_volume: {
      key: 'executed_export_volume',
      sourceKey: 'executed_export_volume',
      dataType: 'number',
      editing: false,
    },
    openQuote: {
      key: 'openQuote',
      sourceKey: 'open_quote',
      dataType: 'number',
      editing: false,
    },
    ccdVolume: {
      key: 'ccdVolume',
      sourceKey: 'ccd_volume',
      dataType: 'number',
      editing: false,
    },
    pcdVolume: {
      key: 'pcdVolume',
      sourceKey: 'pcd_volume',
      dataType: 'number',
      editing: false,
    },
    loses: {
      key: 'loses',
      sourceKey: 'logistic_volume_loses',
      dataType: 'number',
      editing: false,
    },
    blsLossesVolume: {
      key: 'blsLossesVolume',
      sourceKey: 'billofladings_losses_volume_sum',
      dataType: 'number',
      editing: false,
    },
    logisticsLossesVolume: {
      key: 'logisticsLossesVolume',
      sourceKey: 'transport_volume_losses',
      dataType: 'number',
      editing: false,
    },
    customsTerminalsTotalVolume: {
      key: 'customsTerminalsTotalVolume',
      sourceKey: 'customs_terminals_total_volume',
      dataType: 'number',
      editing: false,
    },
    customsTerminalsCustomsClearedVolume: {
      key: 'customsTerminalsCustomsClearedVolume',
      sourceKey: 'customs_terminals_cleared_volume',
      dataType: 'number',
      editing: false,
    },
    customsTerminalsCustomsNotClearedVolume: {
      key: 'customsTerminalsCustomsNotClearedVolume',
      sourceKey: 'customs_terminals_not_cleared_volume',
      dataType: 'number',
      editing: false,
    },
    loadingShortage: {
      key: 'loadingShortage',
      sourceKey: 'transport_loading_shortage',
      dataType: 'number',
      editing: false,
    },
    transport_volume_departed_consignment: {
      key: 'transport_volume_departed_consignment',
      sourceKey: 'transport_volume_departed_consignment',
      dataType: 'number',
      editing: false,
    },
    transport_volume_departed_real: {
      key: 'transport_volume_departed_real',
      sourceKey: 'transport_volume_departed_real',
      dataType: 'number',
      editing: false,
    },
    transport_volume_departed: {
      key: 'transport_volume_departed',
      sourceKey: 'transport_volume_departed',
      dataType: 'number',
      editing: false,
    },
    volume_boarded: {
      key: 'volume_boarded',
      sourceKey: 'transport_volume_boarded',
      dataType: 'number',
      editing: false,
    },
    folder_number: { key: 'folder_number', dataType: 'text', editing: false },
    volume_received: {
      key: 'volume_received',
      sourceKey: 'transport_volume_received',
      dataType: 'number',
      editing: false,
    },
    billofladings_volume_sum: {
      key: 'billofladings_volume_sum',
      sourceKey: 'billofladings_volume_sum',
      dataType: 'number',
      editing: false,
    },
    dbls_volume_sum: {
      key: 'dbls_volume_sum',
      sourceKey: 'dbls_volume_sum',
      dataType: 'number',
      editing: false,
    },
    billofladings_unloading_volume_sum: {
      key: 'billofladings_unloading_volume_sum',
      sourceKey: 'billofladings_unloading_volume_sum',
      dataType: 'number',
      editing: false,
    },
    physicalMargin: {
      key: 'physicalMargin',
      sourceKey: 'physical_margin',
      dataType: 'number',
      editing: false,
    },
    derivativeMargin: {
      key: 'derivativeMargin',
      sourceKey: 'derivative_margin',
      dataType: 'number',
      editing: false,
    },
    mtmMargin: {
      key: 'mtmMargin',
      sourceKey: 'mtm_margin',
      dataType: 'number',
      editing: false,
    },
    totalMargin: {
      key: 'totalMargin',
      sourceKey: 'total_margin',
      dataType: 'number',
      editing: false,
    },
    analyticalPhysicalMargin: {
      key: 'analyticalPhysicalMargin',
      sourceKey: 'physical_analytical_margin',
      dataType: 'number',
      editing: false,
    },
    analyticalDerivativeMargin: {
      key: 'analyticalDerivativeMargin',
      sourceKey: 'derivative_analytical_margin',
      dataType: 'number',
      editing: false,
    },
    analyticalMtmMargin: {
      key: 'analyticalMtmMargin',
      sourceKey: 'mtm_analytical_margin',
      dataType: 'number',
      editing: false,
    },
    analyticalTotalMargin: {
      key: 'analyticalTotalMargin',
      sourceKey: 'total_analytical_margin',
      dataType: 'number',
      editing: false,
    },
    commodity_last_parent: {
      key: 'commodity_last_parent',
      sourceKey: 'commodity_last_parent_title',
      dataType: 'text',
      editing: false,
    },
    info: { key: 'info', sourceKey: 'additional_info', dataType: 'text', editing: true },
    vehicle_restrictions: {
      key: 'vehicle_restrictions',
      sourceKey: 'vehicle_restrictions',
      dataType: 'text',
      editing: true,
    },
    commodity_last_parent_analytic: {
      key: 'commodity_last_parent_analytic',
      sourceKey: 'commodity_last_parent_analytic_title',
      dataType: 'text',
      editing: false,
    },
    cp_approval_status: {
      key: 'cp_approval_status',
      sourceKey: 'cp_approval_status',
      dataType: 'text',
      editing: false,
    },
    cp_custom_status: {
      key: 'cp_custom_status',
      sourceKey: 'cp_custom_status_title',
      dataType: 'text',
      editing: false,
    },
    payment_status: {
      key: 'payment_status',
      sourceKey: 'payment_status',
      dataType: 'text',
      editing: false,
    },
    warehouse_consignments: {
      key: 'warehouse_consignments',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }

        const consignmentVolume = Number(contract.consignment_volume ?? 0).toFixed(3);
        const remainingVolume = Number(
          contract.contract_volume_remaining_after_consignments ?? 0,
        ).toFixed(3);

        return (
          <span className="bigger-label">
            <span>{consignmentVolume}</span> / <span>{remainingVolume}</span>
          </span>
        );
      },
    },
    type_of_activities: {
      key: 'type_of_activities',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.type_of_activities_data) {
          return <div />;
        }

        return (
          <span className="label">
            {contract.type_of_activities_data.map((activity) => (
              <div key={activity.id}>{activity.title}</div>
            ))}
          </span>
        );
      },
    },
    execution: {
      key: 'execution',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.date_of_execution) {
          return <div />;
        }

        return (
          <span>
            <i className="fa fa-calendar"></i>
            {contract.date_of_execution ?? '---'}
            <div className="clear-both block"></div>
            <i className="fa fa-calendar"></i>
            {contract.end_of_execution ?? '---'}
          </span>
        );
      },
    },
    arrival_period: {
      key: 'arrival_period',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.arrival_period_start) {
          return <div />;
        }

        return (
          <span>
            <i className="fa fa-calendar"></i>
            {contract.arrival_period_start ?? '---'}
            <div className="clear-both block"></div>
            <i className="fa fa-calendar"></i>
            {contract.arrival_period_end ?? '---'}
          </span>
        );
      },
    },
    passports: {
      key: 'passports',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.connections || contract.connections.length === 0) {
          return <div />;
        }

        return (
          <span>
            {contract.connections.map((conn) => (
              <span key={conn.passport}>
                <a href={`#/passport/${conn.passport}`} className="btn-link">
                  {conn.passport_title || '---'}
                  <span className="smaller-label label-smoke">
                    {Number(conn.volume ?? 0).toFixed(3)}
                    <span className="smaller-label">{conn.passport_business_date || '---'}</span>
                  </span>
                </a>
                <div className="clear-both block"></div>
              </span>
            ))}
          </span>
        );
      },
    },
    options: {
      key: 'options',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.volume) {
          return <div />;
        }
        if (!contract.volume_options) {
          return <div />;
        }

        const volume = Number(contract.volume) || 0;
        const volOptions = Number(contract.volume_options) || 0;
        const lowerValue = volume - (volume * volOptions) / 100;
        const upperValue = volume + (volume * volOptions) / 100;

        return (
          <span>
            <div className="clear-both block"></div>
            {volOptions.toFixed(1)}% - {lowerValue.toFixed(3)} + {upperValue.toFixed(3)}
          </span>
        );
      },
    },
    allocated: {
      key: 'allocated',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.total_connected) {
          return <div />;
        }

        const baseVolume =
          contract.final_volume ?? contract.volume_estimated_open ?? contract.volume;

        if (contract.total_connected === baseVolume) {
          return (
            <span className="label label_success" data-tip="fully connected to passports">
              <i className="fa fa-exchange"></i> <i className="fa fa-check-circle"></i>
              <div className="clear-both block"></div>
            </span>
          );
        } else {
          return (
            <span>
              <i className="fa fa-exchange"></i> {Number(contract.total_connected || 0).toFixed(3)}
              <div className="clear-both block"></div>
            </span>
          );
        }
      },
    },
    exporter: {
      key: 'exporter',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.exporter_set || contract.exporter_set.length === 0) {
          return <div />;
        }

        return (
          <span>
            <span className="exporter_set">
              {contract.exporter_set.map((exporter, index) => (
                <span key={exporter.id || index}>
                  <i className="fa fa-shield"></i> {exporter.name}
                  {exporter.date_confirmation && <i className="fa fa-check"></i>}
                  <div className="clear-both block"></div>
                </span>
              ))}
            </span>
            <div className="clear-both block"></div>
            <span>{contract.guarantor_name}</span>
          </span>
        );
      },
    },
    responsible: {
      key: 'responsible',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }
        const item = contract;

        return (
          <span className="tooltip" data-tip={`responsible - ${item.responsible_first_name}`}>
            {item.responsible_first_name &&
            item.responsible_avatar &&
            item.responsible_avatar.length !== 0 ? (
              <div className="user-avatar">
                <img
                  src={`/pictures/${item.responsible_avatar}`}
                  alt={item.responsible_first_name}
                />
              </div>
            ) : (
              <div className="no-user-avatar">
                {item.responsible_first_name ? item.responsible_first_name.charAt(0) : ''}
              </div>
            )}
            {item.responsible_first_name}
          </span>
        );
      },
    },
    responsible_for_signing: {
      key: 'responsible_for_signing',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }
        const {
          responsible_for_signing,
          responsible_for_signing_avatar,
          responsible_for_signing_first_name,
        } = contract;

        return (
          <span>
            {responsible_for_signing &&
            responsible_for_signing_avatar &&
            responsible_for_signing_avatar.length !== 0 ? (
              <div className="user-avatar">
                <img
                  src={`/pictures/${responsible_for_signing_avatar}`}
                  alt={responsible_for_signing_first_name}
                />
              </div>
            ) : (
              <div className="no-user-avatar">
                {responsible_for_signing_first_name
                  ? responsible_for_signing_first_name.charAt(0)
                  : ''}
              </div>
            )}
            {responsible_for_signing_first_name}
          </span>
        );
      },
    },
    transport_volume_basis: {
      key: 'transport_volume_basis',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const item = contracts.find((c) => c.id === id);
        if (!item?.transport_volume_basis) {
          return <div />;
        }

        const volumeBasis = Number(item.transport_volume_basis) || 0;
        const openBalance = Number(item.open_balance) || 0;

        let denominator = volumeBasis + openBalance;
        if (denominator === 0) {
          denominator = 1;
        }

        const widthPercentage = ((volumeBasis / denominator) * 100).toFixed(2);

        const volumeBasisFormatted = volumeBasis.toFixed(3);
        const openBalanceFormatted = openBalance.toFixed(3);

        let progressClass = '';
        if (openBalance < 0) {
          progressClass = 'progress_bar_danger';
        } else if (openBalance > 0) {
          progressClass = 'progress_bar_warning';
        } else {
          progressClass = 'progress_bar_default';
        }

        return (
          <div className="progress">
            <div
              className={`progress ${progressClass}`}
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: `${widthPercentage}%` }}
            >
              {volumeBasisFormatted} / {openBalanceFormatted}
            </div>
          </div>
        );
      },
    },
    paymentEvents: {
      key: 'paymentEvents',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }

        return (
          <>
            {contract.prepaypayment_title && (
              <span>
                {'prepay'} {contract.prepaypayment_title}
              </span>
            )}
            <div className="clear-both block"></div>
            {contract.balancepayment_title && (
              <span>
                {'balance'} {contract.balancepayment_title}
              </span>
            )}
          </>
        );
      },
    },
    editor: {
      key: 'editor',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const item = contracts.find((c) => c.id === id);
        if (!item) {
          return <div />;
        }

        const lastUpdatedText = 'last updated';
        const createdText = 'created';
        const hasEditorAvatar =
          item.editor_first_name && item.editor_avatar && item.editor_avatar.length > 0;
        const cutEditorFirstName = item.editor_first_name ? item.editor_first_name.charAt(0) : '';

        return (
          <span
            className="tooltip"
            data-tip={`${lastUpdatedText} - ${item.update_time} - ${item.editor_first_name} ${item.editor_last_name}, ${createdText} - ${item.conclusion_date} - ${item.author_first_name} ${item.author_last_name}`}
          >
            {hasEditorAvatar ? (
              <div className="user-avatar">
                <img src={`/pictures/${item.editor_avatar}`} alt={item.editor_first_name} />
              </div>
            ) : (
              <div className="no-user-avatar">{cutEditorFirstName}</div>
            )}
            {item.editor_first_name}
          </span>
        );
      },
    },
    paymentConditionsOptions: {
      key: 'paymentConditionsOptions',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }

        return (
          <>
            {contract.payment_conditions_option_title && (
              <span>
                {contract.payment_conditions_option_title}
                <div className="clear-both block"></div>
              </span>
            )}
            {contract.payment_conditions_options_data?.map((option) => (
              <span key={option.title}>
                <span>{option.title}</span>
                <div className="clear-both block"></div>
              </span>
            ))}
          </>
        );
      },
    },
    consignees: {
      key: 'consignees',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract) {
          return <div />;
        }

        const { contract_consignees_data = [], consignee_name } = contract;

        return (
          <span className="label">
            {contract_consignees_data.length > 0
              ? contract_consignees_data.map((consignee) => (
                  <div key={consignee.consignee_name}>
                    {consignee.consignee_name}: {consignee.cargo_title} {consignee.volume}t
                  </div>
                ))
              : consignee_name && <div>{consignee_name}t</div>}
          </span>
        );
      },
    },
    costDetails: {
      key: 'costDetails',
      dataType: 'template',
      editing: false,
      render: ({ id }) => {
        const contract = contracts.find((c) => c.id === id);
        if (!contract?.charges_data || contract.charges_data.length === 0) {
          return <div />;
        }

        return (
          <span className="bigger-label">
            {contract.charges_data.map((charge) => (
              <span key={charge.id} className="bigger-label">
                {charge.title}
                <div className="clear-both block"></div>
              </span>
            ))}
          </span>
        );
      },
    },
    cargo: { key: 'cargo', sourceKey: 'crop_title', dataType: 'text', editing: true },
    supplier: { key: 'supplier', sourceKey: 'supplier_name', dataType: 'text', editing: true },
    buyer: { key: 'buyer', sourceKey: 'buyer_name', dataType: 'text', editing: true },
    basis: { key: 'basis', sourceKey: 'basis_name', dataType: 'text', editing: true },
    broker: { key: 'broker', sourceKey: 'broker_name', dataType: 'text', editing: true },
    agent: { key: 'agent', sourceKey: 'agent_name', dataType: 'text', editing: true },
    origin: { key: 'origin', sourceKey: 'origin_of_crop_title', dataType: 'text', editing: true },
    warehouse: { key: 'warehouse', sourceKey: 'warehouse_title', dataType: 'text', editing: true },
    destination: {
      key: 'destination',
      sourceKey: 'destination_of_crop_title',
      dataType: 'text',
      editing: true,
    },
    measurement: {
      key: 'measurement',
      sourceKey: 'measurement_title',
      dataType: 'text',
      editing: true,
    },
    business_unit: {
      key: 'business_unit',
      sourceKey: 'business_unit_title',
      dataType: 'text',
      editing: true,
    },
    market_zone: {
      key: 'market_zone',
      sourceKey: 'market_zone_name',
      dataType: 'text',
      editing: false,
    },
    currency_exchange_rate: {
      key: 'currency_exchange_rate',
      sourceKey: 'currency_exchange_rate',
      dataType: 'text',
      editing: false,
    },
    generalagreement: {
      key: 'generalagreement',
      sourceKey: 'generalagreement_number',
      dataType: 'text',
      editing: true,
    },
    custom_status: {
      key: 'custom_status',
      sourceKey: 'custom_status_title',
      dataType: 'text',
      editing: true,
    },
  };

  const mergedColumns = { ...baseContractColumns, ...additionalColumns };

  return Object.entries(mergedColumns)
    .filter(([key]) => tableColumnsMap[serializer].includes(key))
    .map(([_, col]) => col);
};

export const tableColumnsMap: Record<ContractSerializer, string[]> = {
  table_info: [
    'number',
    'status',
    'contract_type',
    'multicontract',
    'approval_status',
    'signature',
    'passport_status',
    'shipment_status',
    'finance_status',
    'docs_status',
    'ticket',
    'price_indicative',
    'price_indicative_with_vat',
    'price_indicative_default_currency',
    'parent_indicator',
    'parent_indicator_price',
    'pass',
    'not_allocated',
    'concluded',
    'quantity_of_measurement',
    'volume',
    'options',
    'price',
    'costs',
    'currency_exchange_rate',
    'total_value',
    'market_zone',
    'fixed_price_bonus',
    'bill_of_ladings',
    'derivative',
    'analytical_price',
    'analytical_value',
    'analytical_diff',
    'conclusion_price',
    'logistic_executed_volume',
    'free_volume_pcd',
    'pre_pcd_logistic_volume',
    'no_customs_logistics',
    'volume_without_pcd',
    'executed_export_volume',
    'openQuote',
    'ccdVolume',
    'pcdVolume',
    'loses',
    'blsLossesVolume',
    'logisticsLossesVolume',
    'customsTerminalsTotalVolume',
    'customsTerminalsCustomsClearedVolume',
    'customsTerminalsCustomsNotClearedVolume',
    'loadingShortage',
    'transport_volume_departed_consignment',
    'transport_volume_departed_real',
    'transport_volume_departed',
    'volume_boarded',
    'volume_received',
    'billofladings_volume_sum',
    'dbls_volume_sum',
    'billofladings_unloading_volume_sum',
    'physicalMargin',
    'derivativeMargin',
    'mtmMargin',
    'totalMargin',
    'analyticalPhysicalMargin',
    'analyticalDerivativeMargin',
    'analyticalMtmMargin',
    'analyticalTotalMargin',
    'commodity_last_parent',
    'commodity_last_parent_analytic',
    'cp_approval_status',
    'cp_custom_status',
    'payment_status',
    'warehouse_consignments',
    'product',
    'supplier',
    'buyer',
    'type_of_activities',
    'basis',
    'passports',
    'custom_status',
    'info',
    'broker',
    'arrival_period',
    'allocated',
    'exporter',
    'generalagreement',
    'responsible',
    'responsible_for_signing',
    'transport_volume_basis',
    'paymentEvents',
    'editor',
    'measurement',
    'folder_number',
    'vehicle_restrictions',
    'paymentConditionsOptions',
    'consignees',
    'costDetails',
    'days_for_invoice_payment',
    'company_group',
  ],
  table_reconciliation: [],
  table_execution: [],
  table_prepayments_accounting: [],
};
