import React from 'react';

import { NumberWrite } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const NumberCellWrite = ({ value, valueChanged, disabled }: CellTypeProps) => {
  return (
    <div className="p-1">
      <NumberWrite
        value={value ? Number(value) : 0}
        valueChanged={valueChanged}
        disabled={disabled}
      />
    </div>
  );
};
