import React from 'react';

import type { HeaderRenderDef } from '~/shared/ui/components/data-grid';
import { TextFieldRead, TextFieldWrite } from '~/shared/ui/data-types/components';

export const HeaderRender = <TData,>({ columnDef, mode }: HeaderRenderDef<TData>) => {
  if (columnDef.headerTemplate) {
    const HeaderTemplate = columnDef.headerTemplate;

    return <HeaderTemplate value={columnDef.header} />;
  }

  if (mode === 'write') {
    return (
      <TextFieldWrite value={columnDef.header ?? ''} textColor="tertiary" fontWeight="semibold" />
    );
  } else {
    return (
      <TextFieldRead
        value={columnDef.header ?? ''}
        className="text-text-main-tertiary font-semibold"
      />
    );
  }
};
