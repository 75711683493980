import React from 'react';

import { MultiSelectRead } from '~/shared/ui/data-types';

import type { CellTypeProps } from '../types';

export const MultiselectCellRead = ({ value, cellTemplateContext }: CellTypeProps) => {
  return (
    <div className="px-2">
      <MultiSelectRead
        value={value as string[]}
        options={cellTemplateContext?.options ?? []}
        cellTemplateContext={cellTemplateContext}
      />
    </div>
  );
};
