import React from 'react';

import type { Entity } from '~/shared/common';
import {
  type CellData,
  LocaleType,
  type MatrixPrimitive,
  type SpreadsheetRef,
} from '~/shared/lib/spreadsheet';
import { ActionView } from '~/shared/ui/components/action-bar/types';
import { Spreadsheet } from '~/shared/ui/components/spreadsheet';
import { AddFileIcon, BucketIcon } from '~/shared/ui/icons';

import type { DataDef } from '../data-set-view';
import { columnValidationBuilder } from './builders/column-validator-builder';
import { createdDataBuilder } from './builders/created-data-builder';
import { spreadsheetDataBuilder } from './builders/spreadsheet-data-builder';
import { updatedDataBuilder } from './builders/updated-data-builder';
import { InlineEditToolbar } from '../inline-edit-toolbar/inline-edit-toolbar';

export const SheetView = <
  TRecord extends Entity,
  TRecordUpdate = TRecord,
  TRecordRequest = TRecord,
>({
  data,
  loading,
  initialRecords,
  onEdit,
  onDataValidation,
  onCreate,
  onSave,
  onDelete,
}: {
  data: DataDef<TRecord>;
  onEdit: (diff: TRecordUpdate[]) => void;
  onCreate: (diff: TRecordRequest[]) => void;
  onSave: () => Promise<void>;
  onDelete: (id: number) => Promise<void>;
  loading?: boolean;
  initialRecords?: DataDef<TRecord>['records'];
  onDataValidation?: ({ allowSave }: { allowSave: boolean }) => void;
}) => {
  const sheetRef = React.useRef<SpreadsheetRef | null>(null);
  const [createdDataLength, setCreatedDataLength] = React.useState(0);

  const mappedData = React.useMemo(() => spreadsheetDataBuilder<TRecord>(data), [data]);
  const rowCount = React.useMemo(() => data.records.length + 25, [data.records.length]);

  const allowedFields = React.useMemo(
    () =>
      new Set([
        'id',
        ...data.properties.filter((p) => p.editing).map((prop) => prop.sourceKey ?? prop.key),
      ]),
    [data.properties],
  );

  const initialCellRecords = React.useMemo(
    () =>
      spreadsheetDataBuilder<TRecord>({
        records: initialRecords ?? data.records,
        properties: data.properties,
      }),
    [initialRecords, data],
  );

  const onEditHandler = React.useCallback(
    (newData: MatrixPrimitive<CellData>) => {
      const updatedData = updatedDataBuilder<TRecord, TRecordUpdate>({
        newData,
        initialData: data.records,
        allowedFields,
      });
      const createdData = createdDataBuilder<TRecordRequest>({
        data: newData,
        allowedFields,
      });
      onEdit(updatedData);
      onCreate(createdData);
      setCreatedDataLength(createdData.length);
    },
    [onEdit, onCreate, data.records, allowedFields],
  );

  return (
    <>
      <Spreadsheet
        ref={sheetRef}
        data={{
          id: 'workbook-01',
          name: 'sheet',
          sheetOrder: ['sheet-01'],
          appVersion: '3.0.0-alpha',
          locale: LocaleType.EN_US,
          styles: {},
          sheets: {
            'sheet-01': {
              id: 'sheet-01',
              cellData: mappedData,
              name: 'sheet1',
              rowCount: rowCount,
              columnCount: data.properties.length,
              defaultColumnWidth: 220,
              defaultRowHeight: 30,
              showGridlines: 1,
              rowHeader: {
                width: 46,
              },
              columnHeader: {
                height: 20,
              },
              zoomRatio: 0.92,
              freeze: {
                xSplit: 1,
                ySplit: 1,
                startRow: 1,
                startColumn: 1,
              },
            },
          },
        }}
        initialCellRecords={initialCellRecords}
        loading={loading}
        onDataValidation={onDataValidation}
        onEdit={onEditHandler}
        validationBuild={() => {
          if (sheetRef?.current) {
            columnValidationBuilder({
              properties: data.properties,
              ref: sheetRef.current,
              recordsLength: data.records.length + createdDataLength,
              rowCount,
            });
          }
        }}
      />
      <InlineEditToolbar
        config={[
          {
            label: {
              text: 'Save',
              context: 'text-text-additional-success',
            },
            Icon: AddFileIcon,
            iconContext: 'text-text-additional-success',
            view: ActionView.Button,
            key: 'save',
            action: onSave,
          },
          {
            label: {
              text: 'Delete',
              context: 'text-text-additional-danger',
            },
            view: ActionView.Button,
            Icon: BucketIcon,
            iconContext: 'text-text-additional-danger',
            key: 'delete',
            action: () => {
              if (sheetRef.current) {
                sheetRef.current.getSelectedRows().forEach((id) => void onDelete(id));
              } else {
                console.error('sheetRef is not init');
              }
            },
          },
        ]}
      />
    </>
  );
};
